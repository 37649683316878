import React, { SVGProps } from 'react';

const EnergyConsumption= (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 39.42 39.42" fill='currentColor' className='svg-icon' {...props}>
			<g id="图层_2" data-name="图层 2">
				<g id="图层_1-2" data-name="图层 1">
					<rect className="cls-1" width="39.42" height="39.42" rx="8" />
					<path className="cls-2" d="M19.71,27.08c-5.07,0-9.32-2-9.32-8.33S19.71,4.36,19.71,4.36,29,12.53,29,18.91,24.78,27.08,19.71,27.08Zm1-16.51-4.58,6.54,3.6,1-2,5.89,5.72-7-3.76-.66,1-5.72Z" />
					<path className="cls-3" d="M16,30.47a5.4,5.4,0,0,1,.73,1.3l-.5.21c0-.09-.07-.19-.12-.31a9.08,9.08,0,0,0-1.9.21,4.36,4.36,0,0,0-.19-.53c.1,0,.19-.1.28-.23a5,5,0,0,0,.59-1l.58.17a6,6,0,0,1-.69,1.06l1.12-.07a6,6,0,0,0-.36-.6ZM16.49,35c0,.26-.06.41-.23.49a2,2,0,0,1-.82.1,1.94,1.94,0,0,0-.18-.51h.58c.08,0,.1,0,.1-.1v-.57H14.83v1.17h-.52V32.24h2.18ZM14.83,32.7v.43h1.11V32.7ZM15.94,34v-.45H14.83V34Zm1.84-1.27c-.63,0-.78-.16-.78-.71V30.12h.55v.95a10.55,10.55,0,0,0,1.26-.54l.36.4a11.37,11.37,0,0,1-1.62.59V32c0,.19,0,.23.29.23h.82c.22,0,.26-.1.29-.66a1.35,1.35,0,0,0,.49.21c-.07.74-.22.94-.72.94Zm.92,2.34c.22,0,.26-.11.29-.75a2,2,0,0,0,.5.21c-.07.81-.22,1-.75,1H17.8c-.63,0-.8-.16-.8-.72v-2h.56v.9a6.09,6.09,0,0,0,1.3-.61l.37.42a8.66,8.66,0,0,1-1.67.65v.6c0,.2,0,.23.3.23Z" />
					<path className="cls-3" d="M21.58,33.18c.25.27.69.79.8.93l-.35.43c-.11-.18-.33-.49-.53-.76v1.83H21v-1.8a4.31,4.31,0,0,1-.81,1.12,4.81,4.81,0,0,0-.25-.51,4,4,0,0,0,.91-1.24H20V32.7h1v-.52H20.2v-.47H21V31.2h-.9v-.47H21v-.62h.52v.62h.77v.47H21.5v.51h.66v.47H21.5v.52h.89v.48Zm3.22,1.88c.17,0,.2-.16.23-.85a1.68,1.68,0,0,0,.47.22c-.05.83-.18,1.1-.66,1.1h-.73c-.54,0-.67-.16-.67-.78v-.91l-1,.15-.07-.51,1.07-.15v-.82l-.87.14-.08-.51,1-.14v-.79c-.29.1-.58.18-.86.25a2,2,0,0,0-.19-.42,8.18,8.18,0,0,0,2.27-.91l.46.37A7.29,7.29,0,0,1,24,31v.89l1.2-.19.08.49L24,32.43v.81L25.4,33l.07.49-1.5.24v1c0,.27,0,.31.23.31Z" />
				</g>
			</g>
		</svg>
	);
};

export default EnergyConsumption;
