import {Form, Input, Button as Buttons, Tooltip} from "antd";
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { editPassword, editUser, getUser, openNotificationWithIcon } from "../../../api/common";
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { demoPages } from "../../../menu";
import {curTimeStamp, getRecords, passwordRegex, validatePassword} from "../../../utils";
import { useTranslation } from "react-i18next";
import { ExclamationOutlined } from '@ant-design/icons';
const EditPassword = () => {
	// 路由控制
	const navigate = useNavigate();

	// 查询路由参数
	const { username } = useParams<string>();

	useEffect(() => {
		formUnit.setFieldsValue({
			account: username
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [username])

	//多语言
	const { t } = useTranslation('common' || 'menu');

	// 操作
	const [formUnit] = Form.useForm(); //form表单组件
	const onFinish = async (values: any) => {
		try {
			if (values.new_password !== values.affirm_new_password) {
				return openNotificationWithIcon(
					"消息提示",
					"两次密码不一致",
					"warning",
				);
			}

			// 获取-用户数据
			let response = await getRecords(getUser, {
				filter: [
					`username,eq,${username}`,
				],
				size: 1
			})

			let userInfo = response.length >= 1 ? response[0] : {}
			if (JSON.stringify(userInfo) !== "{}") {
				let passwordCode: any = await editPassword({
					username: userInfo.username,
					password: values.old_password,
					newPassword: values.affirm_new_password
				});

				if (passwordCode) {
					await editUser(passwordCode.id, {
						resetting_code: 0,
						last_time: curTimeStamp()
					})

					openNotificationWithIcon(
						"消息提示",
						"密码修改成功，请重新登录！",
						"success",
					);

					//跳转登录
					navigate(`../${demoPages.login.path}`)
				} else {
					return openNotificationWithIcon(
						"异常提示",
						"旧密码有误，请重新填写！",
						"error",
					);
				}
			}

		} catch (error: any) {
			console.error(error)

			return openNotificationWithIcon(
				"异常提示",
				error.code !== 1012 ? error.message : "旧密码有误，请重新填写！",
				"error",
			);
		}
	}

	return (
		<PageWrapper
			isProtected={false}
			title={"设置密码"}
			className={'bg-warning'}>
			<Page className='p-0' >
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='EditPassword-page'>
							<div className='text-center my-5'>
								<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<span style={{ fontSize: '2.5rem' }}>设置新密码</span>
								</div>
							</div>

							<CardBody>
								<Form
									form={formUnit}
									layout='vertical'
									name='dynamic_form_complex'
									onFinish={onFinish}>

									<Form.Item
										label={t('Old Password')}
										name="old_password"
										rules={[{required: true, message: '旧密码，不能为空!'}]}
									>
										<Input.Password size="large"/>
									</Form.Item>

									<Form.Item
										label={t('New Password')}
										name="new_password"
										rules={[
											{required: true, message: '请输入新密码！'},
											{
												pattern: passwordRegex,
												message: "注：必须包含(大写字母,小写字母,数字,特殊字符),密码长度不能小于8个字符！",
											}
										]}
									>
										<Input.Password size="large"/>
									</Form.Item>

									<Form.Item
										label={t('Confirm Password')}
										name="affirm_new_password"
										rules={[{required: true, message: '确认新密码，不能为空'}]}
									>
										<Input.Password size="large" />
									</Form.Item>

									<Form.Item>
										<Button
											color='warning'
											className='w-100 py-3'
											type={"submit"}
											tag={"button"}
										>
											{t('Confirm')}
										</Button>
									</Form.Item>
								</Form>
							</CardBody>
						</Card>
					</div>
				</div>
				<span className='fw-light' style={{ position: "absolute", bottom: "0", right: "0", padding: "0 1%" }}>Copyright © 2023 - Version 4.0.11</span>
			</Page>
		</PageWrapper>
	);
};
EditPassword.propTypes = {
	isSignUp: PropTypes.bool,
};
EditPassword.defaultProps = {
	isSignUp: false,
};

export default EditPassword;
