import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { demoPages } from '../../menu';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import Config from "../../config";
import LANG, { ILang, getLangWithKey } from "../../lang";
import i18n from "../../i18n";
import { getStorages, setStorages, removeStorages } from "../../utils/localStorage";
import { sendEntryMessages } from "../../api/meeting";

const User = () => {
	const { userData, setUser } = useContext(AuthContext);

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	const callbackCode = getStorages('callbackCode');
	
	const src = userData.src && userData.src != '' ? (userData.src.indexOf('archive/uploads/') != -1 ? ("/" + userData.src) : (userData.src.indexOf("wanna") != -1 ? (userData.src.indexOf("uploads/face/") != -1 ? ("/" + userData.src) : ("/uploads/face/" + userData.src)) : ("/uploads/face/" + userData.src))) : "";
	
	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'>
					<img
						src={Config.siteAddress  + src}
						alt='Avatar'
						width={128}
						height={128}
						onError={(e: any) => {
							e.target.src = process.env.PUBLIC_URL + "/uploads/face/wanna4.png";
						}}
					/>
				</div>
				<div className='user-info'>
					<div className='user-name d-flex align-items-center'>
						{`${i18n.language as ILang['key']['lng'] == "zh_CN" ? userData?.real_name:(userData?.englishName == null || userData?.englishName == "" ? t("Unknown") : userData?.englishName) } ${userData?.surname}`}
						<Icon icon='Verified' className='ms-1' color='info' />
					</div>
					<div className='user-sub-title'>{userData?.position}</div>
				</div>
			</div>
			<DropdownMenu>
				<DropdownItem>
					<Button
						icon='AccountBox'
						onClick={() =>
							navigate(
								`../${demoPages.employeeID.path}/1`,
							)
						}>
						Profile
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
			</DropdownMenu>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() =>
									navigate(
										`../${demoPages.employeeID.path}/1`,
										// @ts-ignore
										handleItem(),
									)
								}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon icon='AccountBox' className='navigation-icon' />
										<span className='navigation-text'>{t('menu:Profile')}</span>
									</span>
								</span>
							</div> 
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus ? t('menu:DarkMode') : t('menu:LightMode')}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								if (getStorages('callbackCode') && getStorages('callbackCode') != '') {
									//sendEntryMessages({
									//	url: "http://localhost/poleStarLoginOut.php",
									//	access_token: userData.access_token,
									//}).then((response5: any) => {
										if (setUser) {
											setUser('');
										}
										setStorages('callbackCode', "");
									removeStorages('login');
										let userAgent = navigator.userAgent;
										if (userAgent.indexOf('Firefox') !== -1 || userAgent.indexOf('Chrome') !== -1) {
											window.location.replace('about:blank');
										} else {
											window.opener = null;
											window.open('', '_self');
										}
										window.close();
										
									//});
								} else {
									if (setUser) {
										setUser('');
									}
									navigate(`../${demoPages.login.path}`);
								}
								
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>{t('menu:Logout')}</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
