import request from '../utils/request2';
import { uuid2 } from "../utils/index"
import Config from '../config';

const server = Config.siteAddress;
const on_off = Config.on_off ? Config.on_off : false;


// ===============================================================================================
// 设备列表
// 设备类型
export function getDeviceType(query: any) {
	return request({
		url: server + '/api.php/records/think_device_type',
		method: "get",
		params: { ...query, database: 'door_control_system' }
	})
}
export function getCameraBySearch(query: any) {
	return request({
		url: server + "/api.php/records/think_device_list",
		method: 'get',
		params: { ...query, database: 'door_control_system' },
	})
}
export function addCamera(data: any) {
	return request({
		url: server + '/api.php/records/think_device_list',
		method: 'post',
		data,
		params: { database: 'door_control_system' },
	})
}
export function editCamera(data: any) {
	return request({
		url: server + '/api.php/records/think_device_list/' + data.id,
		method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: { database: 'door_control_system' },
	})
}
export function delCamera(ids: string) {
	return request({
		url: server + '/api.php/records/think_device_list/' + ids,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: { database: 'door_control_system' },
	})
}


//=======================================================================
// 设备组
export function getDeviceGroup(query: any) {
	return request({
		url: server + '/api.php/records/think_device_group',
		method: 'get',
		params: { ...query, database: 'door_control_system' }
	});
}
// 添加用户组
export function addDeviceGroup(data: any) {
	return request({
		url: server + '/api.php/records/think_device_group',
		method: 'post',
		data,
		params: { database: 'door_control_system' }
	});
}
// 编辑用户组
export function editDeviceGroup(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_device_group/' + id,
		method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: { database: 'door_control_system' },
	});
}
// 删除用户组
export function delDeviceGroup(ids: any) {
	return request({
		url: server + '/api.php/records/think_device_group/' + ids,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: { database: 'door_control_system' },
	});
}


// 设备组开关任务=========================================================================
export function addDeviceGroupOpeningTask(data: any) {
	return request({
		url: server + '/api.php/records/think_device_group_opening_task',
		method: "post",
		params: { database: 'door_control_system' },
		data: data
	})
}


// ===============================================================================================
//获取区域列表
export function getAllArea(query: any) {
	return request({
		url: server + '/api.php/records/think_area',
		method: "get",
		params: { ...query, database: 'door_control_system' }
	})
}

//更新区域
export function editArea(id: any, data: any) {
	return request({
		url: server + '/api.php/records/think_area/' + id,
		method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		params: { database: 'door_control_system' },
		data: data
	})
}

//添加区域
export function addArea(data: any) {
	return request({
		url: server + '/api.php/records/think_area',
		method: "post",
		params: { database: 'door_control_system' },
		data: data
	})
}

//删除区域
export function delArea(ids: any) {
	return request({
		url: server + '/api.php/records/think_area/' + ids,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: { database: 'door_control_system' },
	})
}

// ===================================================================
// 获取部门列表-条件搜索
export function getDepartmentBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_department',
		method: 'get',
		params: { ...query, database: 'zuad_common' },
	});
}

// 添加部门
export function addDepartment(data: any) {
	return request({
		url: server + '/api.php/records/think_department',
		method: 'post',
		data,
		params: { database: 'zuad_common' },
	});
}

// 编辑部门
export function editDepartment(data: any) {
	return request({
		url: server + '/api.php/records/think_department/' + data.id,
		method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: { database: 'zuad_common' },
	});
}

// 删除部门
export function delDepartment(id: any) {
	return request({
		url: server + '/api.php/records/think_department/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: { database: 'zuad_common' },
	});
}

// ===============================================================================================
// 获取规则类型
export function getAllRuleType(query: any) {
	return request({
		url: server + '/api.php/records/think_rule_type',
		method: "get",
		params: { ...query, database: 'door_control_system' }
	})
}
//获取规则列表
export function getAllRule(query: any) {
	return request({
		url: server + '/api.php/records/think_rule_management',
		method: "get",
		params: { ...query, database: 'door_control_system' }
	})
}
//更新规则
export function editRule(id: any, data: any) {
	return request({
		url: server + '/api.php/records/think_rule_management/' + id,
		method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		params: { database: 'door_control_system' },
		data: data
	})
}
//添加规则
export function addRule(data: any) {
	return request({
		url: server + '/api.php/records/think_rule_management',
		method: "post",
		params: { database: 'door_control_system' },
		data: data
	})
}
//删除规则
export function delRule(ids: any) {
	return request({
		url: server + '/api.php/records/think_rule_management/' + ids,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: { database: 'door_control_system' },
	})
}

// 访客预约规则管理==================================================================

export function getUserRule(query: any) {
	return request({
		url: server + '/api.php/records/think_user_rule',
		method: 'get',
		params: { ...query, database: 'door_control_system' }
	})
}
export function addUserRule(data: any) {
	return request({
		url: server + '/api.php/records/think_user_rule',
		method: 'post',
		data,
		params: { database: 'door_control_system' }
	});
}
export function editUserRule(id: any, data: any) {
	return request({
		url: server + '/api.php/records/think_user_rule/' + id,
		method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: { database: 'door_control_system' },
	});
}

// 用户管理==================================================================
// 获取用户列表
export function getAllUsers(query: any) {
	return request({
		url: server + '/api.php/records/think_admin',
		method: 'get',
		params: { ...query, database: 'zuad_common' }
	})
}
export function getUserId(id: number, query: any) {
	return request({
		url: server + '/api.php/records/think_admin/' + id,
		method: 'get',
		params: { ...query, database: 'zuad_common' }
	})
}
export function editUser(id: any, data: any) {
	return request({
		url: server + '/api.php/records/think_admin/' + id,
		method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: { database: 'zuad_common' },
	});
}

// 获取用户类型
export function getAllUserType(query: any) {
	return request({
		url: server + '/api.php/records/think_user_type',
		method: "get",
		params: { ...query, database: 'door_control_system' }
	})
}

// 获取角色组
export function getRoleGroup(query: any) {
	return request({
		url: server + '/api.php/records/think_auth_group',
		method: "get",
		params: { ...query, database: 'zuad_common' }
	})
}


//=======================================================================
// 查询用户组
export function getUserGroup(query: any) {
	return request({
		url: server + '/api.php/records/think_admin_group',
		method: 'get',
		params: { ...query, database: 'door_control_system' }
	});
}
// 添加用户组
export function addUserGroup(data: any) {
	return request({
		url: server + '/api.php/records/think_admin_group',
		method: 'post',
		data,
		params: { database: 'door_control_system' }
	});
}
// 编辑用户组
export function editUserGroup(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_admin_group/' + id,
		method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: { database: 'door_control_system' },
	});
}
// 删除用户组
export function delUserGroup(ids: any) {
	return request({
		url: server + '/api.php/records/think_admin_group/' + ids,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: { database: 'door_control_system' },
	});
}


// ===============================================================================================
// 绑定卡
export function getAllCollectUserCard(query: any) {
	return request({
		url: server + '/api.php/records/think_collect_user_card',
		method: "get",
		params: { ...query, database: 'door_control_system' }
	})
}
export function editCollectUserCard(id: any, data: any) {
	return request({
		url: server + '/api.php/records/think_collect_user_card/' + id,
		method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		params: { database: 'door_control_system' },
		data: data
	})
}
export function addCollectUserCard(data: any) {
	return request({
		url: server + '/api.php/records/think_collect_user_card',
		method: "post",
		params: { database: 'door_control_system' },
		data: data
	})
}
export function delCollectUserCard(ids: any) {
	return request({
		url: server + '/api.php/records/think_collect_user_card/' + ids,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: { database: 'door_control_system' },
	});
}


// 门开关任务===============================================================================================
export function getAllDoorOpeningTask(query: any) {
	return request({
		url: server + '/api.php/records/think_door_opening_task',
		method: "get",
		params: { ...query, database: 'door_control_system' }
	})
}
export function editDoorOpeningTask(id: any, data: any) {
	return request({
		url: server + '/api.php/records/think_door_opening_task/' + id,
		method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		params: { database: 'door_control_system' },
		data: data
	})
}
export function addDoorOpeningTask(data: any) {
	return request({
		url: server + '/api.php/records/think_door_opening_task',
		method: "post",
		params: { database: 'door_control_system' },
		data: data
	})
}
export function delDoorOpeningTask(ids: any) {
	return request({
		url: server + '/api.php/records/think_door_opening_task/' + ids,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: { database: 'door_control_system' },
	});
}


// =====================================================================
// 通行记录
export function getAllIdentify(query: any) {
	return request({
		url: server + "/api.php/records/think_traffic_records",
		method: 'get',
		params: { ...query, database: 'door_control_system' },
	})
}
// 删除考勤记录
export function delIdentify(id: any) {
	return request({
		url: server + '/api.php/records/think_traffic_records/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: { database: 'door_control_system' },
	})
}
// 添加考勤记录
export function addIdentify(data: any) {
	return request({
		url: server + "/api.php/records/think_traffic_records",
		method: 'post',
		data,
		params: { database: 'door_control_system' },
	})
}

// 访客列表
export function getAllGuestList(query: any) {
	return request({
		url: server + '/api.php/records/think_admin',
		method: 'get',
		params: { ...query, database: 'visitor' }
	})
}

// 访客列表Id
export function getGuestId(id: any, query: any) {
	return request({
		url: server + '/api.php/records/think_admin/' + id,
		method: 'get',
		params: { ...query, database: 'visitor' }
	})
}

// =========================================
// 控制日志|事件中心
export function getAllEvent(query: any) {
	return request({
		url: server + '/api.php/records/think_event',
		method: 'get',
		params: { ...query, database: 'zuad_common' }
	})
}
export async function addCloudLog(data: any) {
	const logInfo = {
		name: '', //事件名称
		desc: '', //描述
		type: 1, //事件类型，1通用，2预警，3告警
		model: 7, //关联模块，包含1信发，2会议，3中控，4能耗，5墨水屏，6无纸化，7门禁
		create_time: Math.round(Date.now() / 1000), //创建时间
		creator: 0, //创建人
		org_num: 999999,
		oid: uuid2(32, 16),
		status: 0,//事件状态，0正常，1失败
		sub_type: 0,//事件子类型 0:默认 1:会议结束控制设备事件 2:会议开始控制设备事件3:人体感应联动事件4:设备按键事件 5:中控控制事件 6:管理员控制设备事件 7:场景控制设备事件 8:情景控制设备事件 9:普通设备联动 10:自动化场景控制 11:自动化情景控制
	}

	let result: any = 0;
	try {
		result = await request({
			url: server + '/api.php/records/think_event',
			method: "post",
			data: { ...logInfo, ...data },
			params: { database: 'zuad_common' }
		})
	} catch (error: any) {
		console.log('Failure to report logs: ', error.message)
	}
	return result
}