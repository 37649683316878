export interface ILang {
	[key: string]: {
		text: string;
		lng: 'en_US'|'zh_CN' |'zh_HK';
		icon: string;
	};
}

const LANG: ILang = {
	EN: {
		text: 'English',
		lng: 'en_US',
		icon: 'CustomUsa',
	},
	CN: {
		text: '简体中文',
		lng: 'zh_CN',
		icon: 'CustomChina',
	},
	FR: {
		text: '繁体中文',
		lng: 'zh_HK',
		icon: 'CustomFrance',
	},

};

export const getLangWithKey = (key: ILang['key']['lng']): ILang['key'] => {
	// @ts-ignore
	return LANG[Object.keys(LANG).filter((f) => LANG[f].lng === key)];
};

export default LANG;
