import React, { SVGProps } from 'react';

const SystemLayouts = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 39.42 39.42" fill='currentColor' className='svg-icon' {...props}>
			<g id="图层_2" data-name="图层 2">
			<g id="图层_1-2" data-name="图层 1">
				<rect className="cls-1" width="39.42" height="39.42" rx="8"/>
				<path className="cls-2" d="M31.84,19.05a2,2,0,0,0-1.1-1.57,6.93,6.93,0,0,1-2.8-2.66A6.48,6.48,0,0,1,27.08,11a2,2,0,0,0-.88-1.81q-.42-.27-.84-.51a2,2,0,0,0-2.08.11A6.42,6.42,0,0,1,19.71,9.9,6.68,6.68,0,0,1,16.1,8.8a2,2,0,0,0-2-.13,8.24,8.24,0,0,0-.95.57A2,2,0,0,0,12.32,11a7.57,7.57,0,0,1-.83,3.81v0a7,7,0,0,1-2.81,2.66,2,2,0,0,0-1.1,1.57q0,.33,0,.66c0,.22,0,.45,0,.67A2,2,0,0,0,8.69,22a7,7,0,0,1,2.8,2.67h0a7.64,7.64,0,0,1,.84,3.76,2,2,0,0,0,.88,1.8c.28.19.57.36.86.52a2,2,0,0,0,2.06-.1,6.7,6.7,0,0,1,3.58-1.09,6.49,6.49,0,0,1,3.61,1.1,2,2,0,0,0,2,.14c.33-.18.64-.36.94-.56a2,2,0,0,0,.83-1.77,7.66,7.66,0,0,1,.83-3.8h0A7,7,0,0,1,30.74,22a2,2,0,0,0,1.1-1.56c0-.23,0-.46,0-.68S31.86,19.27,31.84,19.05Zm-8.29,4.79a5.45,5.45,0,0,1-7.79,0,5.67,5.67,0,0,1-.06-8,5.46,5.46,0,0,1,7.82,0,5.69,5.69,0,0,1,0,7.94Z"/>
				</g>
			</g>
		</svg>
	);
};

export default SystemLayouts;
