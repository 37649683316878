import React, { FC, ReactNode, useContext, useLayoutEffect, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Alert from '../../../components/bootstrap/Alert';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import LANG, { getLangWithKey, ILang } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Spinner from '../../../components/bootstrap/Spinner';
import AuthContext from '../../../contexts/authContext';
import { getRecords, mediaEventLogData } from "../../../utils";
import { addEventLog, getEventLog, openNotificationWithIcon } from "../../../api/common";
import { getAllAuditMeeting, getAllConfig } from "../../../api/meeting";
import Avatar from '../../../components/Avatar';
import i18n from "../../../i18n";

import Config from "../../../config";

interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {

	//const { i18n } = useTranslation<any>();

	const { t } = useTranslation('translation');

	// 用户信息
	const { userData } =  useContext(AuthContext);

	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const changeLanguage = (lng: ILang['key']['lng']) => {
		i18n.changeLanguage(lng).then(() => {});

		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
				<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
			</span>,
			<>{ t('text1') }</>,
		)
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		// i18n.changeLanguage(lng);
		document.documentElement.setAttribute('lang', i18n.language);
	});

	//多语言切换刷新界面
	const Refresh = () => {
		window.location.reload()
	}

	// 通知
	const [informs, setAllInform] = useState<any>([])
	const getAllInform = async () => {
		try {
			let response = await getRecords(getEventLog, {
				filter1: [
					`org_num,in,${userData.orgNum}`,
					`type,in,1,2`
				],
				size: 50,
				order: "create_time,desc"
			});

			let list: any = [];

			if (response.length > 0) {

				response.map((item: any) => {

					if (item.desc && item.desc != undefined && item.desc != null && item.desc.indexOf('获取app_token') < 0 && item.desc.indexOf('Authentication ') < 0 && item.desc.indexOf('成功！') < 0 && item.desc.indexOf('会议室:【') < 0 && item.desc.indexOf('获取user_token') < 0) {
						list.push(item);
					}

				});

            }
			setAllInform(list);
		}catch (error: any) {
			//记录 错误日志
			await addEventLog({
				...mediaEventLogData(),
				name: "通知",
				desc: error.message,
				creator: userData.id,
				org_num: userData.orgNum,
				operation: "Query"
			})

			return openNotificationWithIcon(
				t('Tip'),
				error.message,
				"error",
			);
		}
	}





	useEffect(() => {
		if (offcanvasStatus) {
			getAllInform().then(() => { })
		}

	}, [offcanvasStatus, userData]);

	const [show11, setShow11] = useState<any>(0);

	const [isAudit, setIsAudit] = useState<any>(false);

	let audit: any = false;

	// 审核列表
	const getAllAuditMeetingData = (key?: string) => {

		if (userData.orgNum != undefined) {

			if (!isAudit && !audit) {

				getAllConfig({
					filter1: [
						//`org_num,eq,${activationCode ? data.org_num : 999999}`,
						`org_num,eq,${userData.orgNum}`,
						`name,eq,is_audit_entry`
					]
				}).then((response: any) => {

					let configs: any = response.records;

					if (configs.length > 0) {

						for (let i of configs) {

							if (i.name == "is_audit_entry" && i.value != '') {

								if (i.value == "1") {

									setIsAudit(true);

									audit = true;

									getAllAuditMeetingData();

								}

							}
						}

					}
				}).catch((error) => {
					showNotification(
						t('Tip'), // String, HTML or Component
						error.message, // String, HTML or Component
						'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
					);
					return null;
				})

			} else {

				let arr: any = [`status,nin,0,2,3`, `start_time,gt,${parseInt((new Date().getTime() / 1000).toString())}`];
				if (userData.view_permissions != 3) {
					arr.push(`org_num,eq,${userData.orgNum}`);
				}
				if (key && key.length > 0) {
					arr.push(`name,cs,${key}`);
				}
				getAllAuditMeeting({
					filter: arr
				}).then((response: any) => {
					let resultData = response.records;
					if (!resultData) {
						resultData = []
					}

					if (resultData.length > 0) {

						setShow11(resultData.length);

					} else {

						setShow11(0);

					}

					// console.log(newArr)
				}).catch((error) => {
					console.error(error);
				})

			}

		} else {
			// console.log(userData)
        }
	};

	const [place3, setPlace3] = useState<any>("");

	const [place4, setPlace4] = useState<any>("");

	const [place5, setPlace5] = useState<any>("");

	const [place6, setPlace6] = useState<any>("");

	const [languageChangeShow, setLanguageChangeShow] = useState<any>(false);

	useEffect(() => {

		setInterval(function () { getAllAuditMeetingData(); }, 30000);

		setInterval(function () {

			setPlace3(t('Dark / Light Mode'));

			setPlace4(t('Full Screen'));

			setPlace5(t('Language change2'));

			setPlace6(t('Event')); }, 100);

		getAllAuditMeetingData();

		//获取-服务器配置

		getAllConfig({
			filter1: [
				//`org_num,eq,${activationCode ? data.org_num : 999999}`,
				`org_num,eq,999999`,
				`name,in,default_language,language_change_show`
			]
		}).then((response: any) => {

			let configs: any = response.records;

			if (configs.length > 0) {

				for (let i of configs) {

					if (i.name == "language_change_show" && i.value != '') {
						console.log(i.value == "1" ? true : false);
						setLanguageChangeShow(i.value == "1" ? true : false);

					}
				}

			}
		}).catch((error) => {
			showNotification(
				t('Tip'), // String, HTML or Component
				error.message, // String, HTML or Component
				'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
			);
			return null;
		})

	}, [userData]);



	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}

				{/* Dark Mode */}
				<div className='col-auto'>
					<Popovers trigger='hover'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon'
							data-tour='dark-mode'>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div>

				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div>

				{/* Lang Selector */}
				{languageChangeShow ? <div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							{
								typeof getLangWithKey(i18n.language as ILang['key']['lng'])?.icon === 'undefined' ? (
									//<Button
									//	// eslint-disable-next-line react/jsx-props-no-spreading
									//	{...styledBtn}
									//	className='btn-only-icon'
									//	aria-label='Change language'
									//	data-tour='lang-selector'>
									//	<Spinner isSmall inButton='onlyIcon' isGrow />
									//</Button>
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										icon={
											getLangWithKey("zh_CN")?.icon
										}
										aria-label='Change language'
										data-tour='lang-selector'
									/>
								) : (
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										icon={
											getLangWithKey(i18n.language as ILang['key']['lng'])?.icon
										}
										aria-label='Change language'
										data-tour='lang-selector'
									/>
								)
							}
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button
										icon={LANG[i].icon}
										onClick={() => { changeLanguage(LANG[i].lng); Refresh();}}>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div> : ""}

				{/*	Notifications */}
				<div className='col-auto position-relative'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Notifications'
						onClick={() => setOffcanvasStatus(true)}
						aria-label='Notifications'
					/>
					{show11>0 ?
						<span className='position-absolute top-15 start-85 translate-middle badge rounded-pill bg-danger'>
							{show11} <span className='visually-hidden'>unread messages</span>
						</span>
					 : ""}
				</div>
				{afterChildren}
			</div>

			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>事件通知</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					{informs.length > 0 ? informs.map((item: any) => (
						<Alert key={item.id} icon='ViewInAr' isLight color='info' className='flex-nowrap'>
							{item.desc}
						</Alert>

					)) : <div style={{ fontSize: "1.25rem", textAlign: "center", marginTop: "1rem" }} >暂无数据</div>}
				</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
