import React, { useContext, useEffect, useState } from "react";
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import {
	Button as Buttons,
	Form,
	Input,
	Select as Selects,
	Upload,
	UploadProps
} from "antd";
import {
	addBackupsRecord,
	addEventLog, editBackupsRecord, getBackupsRecord,
	onDatabaseBackup,
	openNotificationWithIcon
} from "../../api/common";
import AuthContext from '../../contexts/authContext';
import {
	curTimeStamp,
	DATABASE_ACTIONS,
	downloadFile,
	getDateTime,
	getRecordFind,
	inArrays,
	mediaEventLogData,
	uuid2
} from "../../utils";
import { InboxOutlined } from "@ant-design/icons";
import Config from "../../config";
import { useTranslation } from "react-i18next";

const DatabaseOperationsPage = () => {
	// 用户信息
	const { userData } = useContext(AuthContext);

	//操作
	const [formUnit] = Form.useForm(); //form表单组件
	const onFinish = async (values: any) => {
		try {
			switch (values.action_id) {
				case 1:
					const databaseBackup: any = await onDatabaseBackup({
						database: values.database
					})
					const returnPath = databaseBackup.path
					const fileName = (returnPath).substring((returnPath).lastIndexOf('/') + 1);

					const backupsRecord = await getRecordFind(getBackupsRecord, {
						filter: [
							`database,eq,${fileName}`,
							`date,eq,${getDateTime("Date")}`,
						],
						size: 1
					})
					if (!backupsRecord) {
						await addBackupsRecord({
							oid: uuid2(32, 16),
							database: fileName,
							route: returnPath,
							date: getDateTime("Date"),
							last_time: curTimeStamp()
						})
					}

					//触发下载
					downloadFile({
						orgNum: userData.orgNum ?? 0,
						uid: userData.id ?? 0,
						username: userData.username ?? ""
					},fileName, Config.siteAddress + '/' + returnPath)

					break
			}
		} catch (error: any) {
			//记录 错误日志
			await addEventLog({
				...mediaEventLogData(),
				name: "数据库操作",
				desc: error.message,
				creator: userData.id,
				org_num: userData.orgNum,
				operation: "Backups"
			})

			return openNotificationWithIcon(
				"异常提示",
				error.message,
				"error",
			);
		}
	}
	useEffect(() => {
		formUnit.setFieldsValue({
			action_id: 1
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])



	// 动作选择
	const [onSetSelectCode, setSelectCode] = useState<any>({
		action_id: 1,
		action_url: ""
	})
	const SelectClick = (value: number) => {
		setSelectCode({
			action_id: value,
			action_url: value !== 2 ? 'databaseReplace' : "databaseRestore"
		})
	}

	//文件上传
	const [loadingFile, setLoadingFile] = useState(false)
	const props: UploadProps = {
		name: 'fileInfo',
		multiple: true,
		action: Config.siteAddress + '/api.php/' + onSetSelectCode.action_url,
		data: {
			database: formUnit.getFieldValue("database") ?? ""
		},
		onChange(info: any) {
			const fileInfo = info.file;
			let resCode = false,
				resData: any = null;

			//上传完成
			if (fileInfo.status === 'done') {
				resData = fileInfo.response
				resCode = ("code" in resData);

				if (resCode) {
					openNotificationWithIcon("信息提示", resData.details.message, 'error');
				} else {
					openNotificationWithIcon("信息提示", resData.message, 'success');
				}
			}

			//上传失败
			if (fileInfo.status === 'error') {
				resData = fileInfo.response
				resCode = ("code" in resData);

				openNotificationWithIcon("信息提示", resData.details.message, 'error');
			}

			if (resData !== null) {
				//记录 错误日志
				addEventLog({
					...mediaEventLogData(),
					status: Number(!resCode),
					name: "数据库-操作",
					desc: (onSetSelectCode !== 2 ? "替换" : "恢复") + "-数据库," + (resCode ? resData?.details.message : resData?.message),
					creator: userData.id,
					org_num: userData.orgNum,
					operation: (onSetSelectCode !== 2 ? "Replace" : "Restore")
				})
			}
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
		onRemove() {
			setLoadingFile(false)
		}
	};

	//多语言
	const { t } = useTranslation('common' || 'menu');

	return (
		<PageWrapper >
			<Page container="fluid">
				<Form
					form={formUnit}
					layout='vertical'
					name='dynamic_form_complex'
					style={{ width: "50%" }}
					onFinish={onFinish}
					autoComplete='off'>
					<Form.Item name='database' label={t('database')} style={{ width: "100%" }} rules={[{ required: true, message: '不能为空！' }]}>
						<Input placeholder={t('peadntettede')} min={1} max={20} style={{ width: "100%" }} />
					</Form.Item>

					<Form.Item name="action_id" label={t('Execute Action')} rules={[{ required: true, message: '不能为空！' }]}>
						<Selects
							showSearch
							placeholder={t('Please Select')}
							filterOption={
								(input: string, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
							}
							onChange={SelectClick}
							style={{ width: '100%' }}
							options={DATABASE_ACTIONS}
						/>
					</Form.Item>

					{
						inArrays(onSetSelectCode.action_id, [2, 3]) ? (
							<>
								<Form.Item label="备份库文件">
									<Upload.Dragger
										{...props}
										maxCount={1} accept={"application/spl"}>
										<p className="ant-upload-drag-icon">
											<InboxOutlined />
										</p>
										<p className="ant-upload-text">{t('Click File')}</p>
										<p className="ant-upload-hint">{t('Support Single Upload')}</p>
									</Upload.Dragger>
								</Form.Item>
							</>
						) : (
							<>
								<Form.Item shouldUpdate>
									<Buttons
										type="primary"
										htmlType="submit"
										className='w-100'
										disabled={
											loadingFile
										}
									>
										{t('Save')}
									</Buttons>
								</Form.Item>
							</>
						)
					}
				</Form>
			</Page>
		</PageWrapper>
	);
};

export default DatabaseOperationsPage;
