import React, { SVGProps } from 'react';

const Visitor = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 39.42 39.42" fill='currentColor' className='svg-icon' {...props}>
			<g id="图层_2" data-name="图层 2">
			<g id="图层_1-2" data-name="图层 1">
				<rect className="cls-1" width="39.42" height="39.42" rx="8"/>
				<path className="cls-2" d="M19.71,8.21a7.19,7.19,0,1,1-7.26,7.19A7.23,7.23,0,0,1,19.71,8.21Zm10.39,23H9.33a6.5,6.5,0,0,1-.07-1,8.56,8.56,0,0,1,3.58-6.82,1.83,1.83,0,0,1,2.14,0,8.19,8.19,0,0,0,9.46,0,1.88,1.88,0,0,1,2.24,0,8.57,8.57,0,0,1,3.48,6.74C30.15,30.56,30.14,30.89,30.1,31.21Z"/>
				</g>
			</g>
		</svg>
	);
};

export default Visitor;
