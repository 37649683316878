export function getStorage(key: string):string {
    return sessionStorage.getItem(key) || ''
}
export function setStorage(key: string, value: string):void {
    sessionStorage.setItem(key, value);
}
export function removeStorage(key: string):void {
    sessionStorage.removeItem(key);
}
export function clearStorage():void {
    sessionStorage.clear();
}
