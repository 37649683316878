import { AutoComplete, Button as Buttons, notification } from "antd";
import classNames from 'classnames';
import { useFormik } from 'formik';
import { Base64 } from 'js-base64';
import PropTypes from 'prop-types';
import { FC, useCallback, useContext, useEffect, useState, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAllInstallationPackage2 } from '../../../api/centralControl2';
import {
	addOperateLog,
	echoPutBacks,
	editUser,
	encryptionModel,
	getActivationConfig,
	getAuthGroup,
	getAuthRule,
	getDepartment,
	getEmpower,
	getOrganization,
	getUsers,
	openNotificationInteraction,
	openNotificationWithIcon
} from "../../../api/common";
import { addEventLogKey, getConfigKey, getOrganizationKey } from "../../../api/commonKey";
import { getApkRenewKey, getApkRenewKey2 } from "../../../api/media";
import { doLogin, getAllMedias as getAllMedias2, userRegister, sendEntryMessages, getAllUsers2 } from "../../../api/meeting";
import { getAllMedias, getApkRenews } from '../../../api/paperless';
import Img1 from '../../../assets/39.png';
import Img2 from '../../../assets/41.png';
import Img3 from '../../../assets/42.png';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import showNotification from '../../../components/extras/showNotification';
import Config from "../../../config";
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { demoPages } from "../../../menu";
import { selectAllmenu, selectTopmenu } from '../../../store/actions2';
import "../../../styles/notification.scss";
import {
	JsDate,
	SystemDefaultConfig,
	SystemDefaultConfigField,
	constSystemData,
	curTimeStamp,
	downloadFile,
	getRecordFind,
	getRecords,
	inArrays,
	meetingEventLogData,
	uuid2
} from "../../../utils";
import { getStorages, setStorages } from "../../../utils/localStorage";
import { useTranslation } from "react-i18next";
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import LANG, { ILang, getLangWithKey } from "../../../lang";
import i18n from "../../../i18n";
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import { TInputTypes } from '../../../type/input-type';
import "./check-in.css";

interface ILoginHeaderProps {
	isNewUser?: boolean;
}

const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	//多语言
	const { t } = useTranslation('common' || 'menu');
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>{t('Create An Account')},</div>
				<div className='text-center h4 text-muted mb-5'>{t('Register now')}</div>
			</>
		);
	}
	return (
		<>
			{/* <div className='text-center h1 fw-bold mt-5'>欢迎来到安踏会务系统,</div> */}
			<div className='text-center h4 text-muted mb-5 mt-5'>{t('Please log in')}</div>
		</>
	);
}

const CheckIn: FC<any> = ({ isSignUp }) => {
	const { setUser, userData } = useContext(AuthContext);

	const dispatch = useDispatch();

	const { darkModeStatus } = useDarkMode();

	const [signInPassword] = useState<boolean>(false);
	const [singUpStatus] = useState<boolean>(!!isSignUp);

	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [searchParams] = useSearchParams();

	let type = searchParams.get('type');

	useEffect(() => {

		let host: any = window.location.hostname;

		if (type != undefined && type != '') {

			setStorages('access_token', "1234");

			setStorages('login', 'true');

			getAllUsers2({
				filter: [
					`username,eq,admin`
				]
			}).then(async (response: any) => {

				let LoginData = response.records;

				if (LoginData.length == 0) {

					showNotification(
						t('Tip'), // String, HTML or Component
						t('The Feishu account does not exist in the system'), // String, HTML or Component
						'danger' // 'default' || 'info' || 'warning' || 'success' || 'danger',
					);

					return false;

				}

				// 用户登录
				let LoginData2: any = await doLogin({
					"username": "admin",
					"password": "123456"
				});

				LoginData = LoginData[0];

				if (LoginData && LoginData.status != undefined) {

					if (LoginData.status == 0) {

						setError(t('Login account stop'));

						return openNotificationWithIcon(
							t('Tip'),
							t('Login account stop') + `！`,
							"error",
						);

					}

				}

				LoginData.access_token = "1234";

				// 获取-组织数据
				const organization = await getRecordFind(getOrganization, {
					filter: [
						`org_num,eq,${LoginData.org_num}`,
					],
					include: "validation,have_system",
					size: 1
				});

				setStorages('loginOrg', LoginData.org_num);

				setStorages('loginNum', "0");

				setStorages('loginTime', "0");

				//处理登录信息
				await LoginFun({
					...LoginData,
					...organization
				});

			}).catch((error) => {
				console.error(error);

				setError(t('Login error'));
				showNotification(
					t('Tip'), // String, HTML or Component
					error.message, // String, HTML or Component
					'danger' // 'default' || 'info' || 'warning' || 'success' || 'danger',
				);
			});

		} else {

			if (host == "guest-uat.polestar-auto.com") {

				window.location.href = " https://ssotest.flyme.com/esc-sso/oauth2.0/authorize?client_id=b25ecca5744cf14a4588&response_type=code&redirect_uri=https%3A%2F%2F" + window.location.hostname + "%2F%23%2Fcallback";

			} else {

				window.location.href = "https://sso.polestar-auto.com/esc-sso/oauth2.0/authorize?client_id=bc4801615afccb4bf7b9&response_type=code&redirect_uri=https%3A%2F%2F" + window.location.hostname + "%2F%23%2Fcallback";

			}

		}
		
		return;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const navigate = useNavigate();

	const LoginFun = async (loginData: any) => {
		const btn = (
			<Buttons type="link" size="small" onClick={
				() => {
					notification.destroy()
					navigate(`../${demoPages.empowerPage.path}`)
				}
			}>
				{t('Go To Activate')}
			</Buttons>
		);

		//获取-是否激活 //查询-许可证
		let expireEmpower: any = await getRecordFind(getEmpower, {
			filter: [
				`org_num,eq,${loginData.org_num}`,
			],
			size: 1
		})
		if (("validation" in loginData) && loginData.validation !== 0) {
			//获取-服务器当前docker容器编号
			let containerId: any = await encryptionModel({
				method: "getDockerId"
			})
			if ("code" in containerId) {
				//setLogining(false);

				return openNotificationWithIcon(
					t('Tip'),
					t('Failed to obtain uinID, please contact the developer') + "！",
					"error",
				);
			}
			containerId = Base64.decode(containerId.data)

			if (!expireEmpower || (expireEmpower.container_id !== containerId)) {
				//setLogining(false);

				const description = (
					<p style={{
						marginBottom: "0rem"
					}}>
						{t('The system has not been activated yet. Please activate the system')}！
					</p>
				)
				//消息提示
				return openNotificationInteraction("warning", description, btn)
			}

			if (curTimeStamp() > expireEmpower.end_time) {
				const description = (
					<ul style={{
						listStyle: "none",
						padding: "0rem",
						fontWeight: 700
					}}>
						<li>{t('The system authorization time has expired')}</li>
						<li>{t('Please contact the administrator to reactivate the system')}！</li>
						<li>
							{t('Authorization expiration time')}：{JsDate(expireEmpower.start_time, "date")} - {JsDate(expireEmpower.end_time, "date")}
						</li>
					</ul>
				)
				//setLogining(false);

				//消息提示
				return openNotificationInteraction("warning", description, btn)
			}
		}

		// 处理头像
		let portrait = loginData.portrait;
		if (portrait.indexOf("wanna") !== -1 && portrait.indexOf("uploads/face/") === -1) {
			portrait = "/uploads/face/" + portrait;
		}

		//获取角色
		let rolesData = await getRecordFind(getAuthGroup, {
			filter: [
				`id,eq,${loginData.groupid ?? 0}`
			],
			size: 1
		});

		if (!rolesData) {
			//setLogining(false);

			return openNotificationWithIcon(
				t('Tip'),
				t('User') + "：" + `${loginData.username},` + t('No allocation permissions') + `！`,
				"warning",
			)
		}

		//处理-不是管理员账号，但是角色菜单为空的时候
		if (rolesData.rules === "" && loginData.username !== "uniadmin") {
			const empowerArray = JSON.parse(expireEmpower.menu)
			rolesData.rules = empowerArray.length >= 1 ? empowerArray.join(",") : "";

		}

		if (rolesData.rules != "" && loginData.view_permissions != 0 && loginData.org_num == 573270) {

			var rulez = rolesData.rules.split(',');

			if (rulez.indexOf("5") == -1) {

				rulez.push('5');

			}

			if (rulez.indexOf("6") == -1) {

				rulez.push('6');

			}

			rolesData.rules = rulez.join(",");
		}

		let rulesWhere = [
			`id,in,${rolesData.rules}`
		];

		//旧的筛选条件
		if (inArrays(loginData.username, ["uniadmin"])) {
			rulesWhere = []
		}
		//if (loginData.view_permissions ==2 && inArrays(loginData.username, ["admin"])) {
		//	rulesWhere = []
		//}
		//.concat(!inArrays(rolesData.id,[0, 1]) ? `id,in,${rolesData.rules}` : ""),
		//获取菜单
		let authRuleData = await getRecords(getAuthRule, {
			filter: [
				`status,eq,1`,
				...rulesWhere
			],
			order: "sort"
		});

		if (authRuleData.length <= 0) {
			//setLogining(false);

			return openNotificationWithIcon(
				t('Tip'),
				t('User') + "：" + `${loginData.username},` + t('No menu permissions') + `！`,
				"warning",
			);
		}

		let parentData: any = [],
			menuData: any = [],
			target: any = "/";

		// 使用 reduce() 替换 map()
		parentData = authRuleData.reduce((acc: any, i: any) => {
			acc[i.id] = i;
			return acc;
		}, {});

		// 使用 for-of 循环来遍历数组，减少不必要的计算
		for (const i of authRuleData) {
			if (i.id === rolesData.target) target += parentData[i.id].name;
		}

		authRuleData.map((j: any) => {
			if (j.pid === 0) {
				menuData.push(j);
			} else {
				if (parentData[j.pid] !== undefined) {
					if (parentData[j.pid].child === undefined) {
						parentData[j.pid].child = [];
					}
					parentData[j.pid].child.push(j);
				}
			}
		});

		//处理部门
		let departmentData = await getRecords(getDepartment, {
			filter: [
				`id,eq,${loginData.department_id ?? 0}`
			]
		});

		//
		loginData = {
			...loginData,
			portrait: portrait,
			orgNum: loginData.org_num,
			groupname: rolesData.title,
			view_permissions: rolesData ? (rolesData.view_permissions != undefined ? rolesData.view_permissions : 0) : 0,
			depart_room: departmentData.length > 0 ? departmentData[0].room_id.split(',') : [],
			menu: menuData,
			refer: authRuleData
		}

		//设置登录信息
		if (setUser) {
			setUser(JSON.stringify(loginData));
		}
		// 设置首页
		handleOnClick(target);

		// console.log(menuData)
		let menuArr1 = []
		let menuArr2 = []
		for (let i = 0; i < menuData.length; i++) {
			if (menuData[i]['child']) {
				menuArr1.push(menuData[i])
			} else {
				menuArr2.push(menuData[i])
			}
		}
		//console.log('角色菜单1', menuArr1)
		// console.log('角色菜单2', menuArr2)
		// debugger;

		const arr: any = {};
		for (let i in menuArr1) {
			arr[menuArr1[i].oid] = {
				id: menuArr1[i].oid,
				text: menuArr1[i].title,
				en_title: menuArr1[i].en_title,
				icon: menuArr1[i].css,
				path: menuArr1[i].name,
				child: menuArr1[i].child,
				sort: menuArr1[i].sort,
				subMenu: null,
			};
		}

		for (let k in arr) {
			let list = arr[k].child;
			if (list && list.length > 0) {
				arr[k].subMenu = {};
				for (let ke in list) {
					arr[k].subMenu[list[ke].oid] = {
						id: list[ke].oid,
						text: list[ke].title,
						en_title: list[ke].en_title,
						icon: list[ke].css,
						path: '/' + list[ke].name,
						subMenu: null,
					};

					if (list[ke].child?.length > 0) {

						arr[k].subMenu[list[ke].oid].subMenu = {};

						let array = list[ke].child;

						for (let key in array) {

							arr[k].subMenu[list[ke].oid].subMenu[array[key].oid] = {
								id: array[key].oid,
								text: array[key].title,
								en_title: array[key].en_title,
								icon: array[key].css,
								path: '/' + array[key].name,
								subMenu: null,
							}

						}

					}

				}
			}
		}

		// 第一个会务系统
		// const specifiedProperties = ['meeting', 'centralControl', 'media', 'paperless', '363F7A81209962C8B9BB100624FC961D', 'visitor', 'energyConsumption', 'digitalTwinSystem', 'systemLayouts']; // 指定属性的顺序
		// 提取键到一个数组
		// const keys = Object.keys(arr);
		// 按照指定的顺序进行排序
		// keys.sort((a, b) => specifiedProperties.indexOf(a) - specifiedProperties.indexOf(b));
		// 将排序后的键映射为数组
		// const sortedKeysArray = keys.map(key => arr[key]);

		const jsonArray: any = Object.values(arr);
		jsonArray.sort((a: any, b: any) => a.sort - b.sort);
		// debugger;

		dispatch(selectTopmenu('0'));
		dispatch(selectAllmenu(jsonArray));
		// debugger

		//修改登录时间
		await editUser(loginData.id, {
			last_login_time: curTimeStamp()
		});

		openNotificationWithIcon(
			t('Tip'),
			t('User') + "：" + `${loginData.username},` + t('Log On') + t('Success') + `！`,
			"success",
		);

	}

	//设置-系統首页
	const handleOnClick = useCallback((url: string = "/") => {
		navigate(url);
	}, [navigate]);

	

	const [userInfo, setUserInfo] = useState<any>(null);

	const [error, setError] = useState<any>("");


	//多语言
	const { t } = useTranslation('common' || 'menu');

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? t('Register') : t('Log On')}
			className={classNames({ 'bg-warning': !singUpStatus, 'bg-info': singUpStatus,'check-in':true })}>
			<Page className='p-0' >
				<div className='row h-100 align-items-center justify-content-center' style={{ fontSize: "5vh"} }>
					{error==''?t('Loading'):error }
					{/*<iframe*/}
					{/*	src={process.env.PUBLIC_URL + '/meeting/ceshi.html'}*/}
					{/*	style={{ width: '100%', height: '100%', border: 'none' }}*/}
					{/*	ref={iframeDom}*/}
					{/*	onLoad={iframeDomOnload}*/}
					{/*></iframe>*/}
				</div>
			</Page>
		</PageWrapper>
	)
};
CheckIn.propTypes = {
	isSignUp: PropTypes.bool,
};
CheckIn.defaultProps = {
	isSignUp: false,
};

export default CheckIn;
