import React, { SVGProps } from 'react';

const DigitalTwinSystem = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 39.42 39.42" fill='currentColor' className='svg-icon' {...props}>
			<g id="图层_2" data-name="图层 2">
				<g id="图层_1-2" data-name="图层 1">
					<rect className="cls-1" width="39.42" height="39.42" rx="8" />
					<path className="cls-2" d="M19.72,8.29A11.42,11.42,0,1,0,31.13,19.71,11.42,11.42,0,0,0,19.72,8.29Zm7.5,17-1.65-.9-5.39,3.16a.61.61,0,0,1-.39.13.68.68,0,0,1-.4-.13L16.6,26l-2.67-1.54-1.67.95-.6-.83,1.8-1v-6.7a.91.91,0,0,1,.1-.43,1.06,1.06,0,0,1,.41-.35l5.39-3.07V11h.95v2l5.39,3.15a.78.78,0,0,1,.43.7v6.7l1.63.93Z" />
					<polygon className="cls-2" points="24.54 16.8 19.76 19.44 15.1 16.84 19.82 14.13 24.54 16.8" />
					<polygon className="cls-2" points="19.15 20.61 19.15 26.13 14.61 23.67 14.55 17.97 19.15 20.61" />
					<polygon className="cls-2" points="25.07 18.01 25.04 23.42 20.43 26.09 20.43 20.61 25.07 18.01" />
				</g>
			</g>
		</svg>
	);
};

export default DigitalTwinSystem;
