import { Store } from 'react-notifications-component';

const showNotification = (
	title: string | JSX.Element,
	message: string | JSX.Element,
	type = 'default',
	duration = 1000,
) => {
	const settings = {
		insert: 'top',
		container: 'top-right',
		animationIn: ['animate__animated', 'animate__fadeIn'],
		animationOut: ['animate__animated', 'animate__fadeOut'],
		dismiss: {
			duration: duration,
			pauseOnHover: true,
			onScreen: true,
			showIcon: true,
			waitForAnimation: true,
		},
	};

	Store.addNotification({
		title,
		message,
		// @ts-ignore
		type,
		...settings,
	});
};

export default showNotification;
