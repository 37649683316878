import request from "../utils/noVoucher";
import requestKey from "../utils/secretKey";
import Config from "../config";
import { notification } from "antd";
import {
	MULTILINGUAL,
	uuid2,
} from "../utils";
import i18n from "../i18n";
import axios from "axios";
import { saveAs } from 'file-saver';
import CryptoJS from 'crypto-js';
import { Base64 } from 'js-base64';

const server = Config.siteAddress;
const serverUrl = Config.remoteServices
const activateServices = Config.activateServices
const on_off:any = Config.on_off ? Config.on_off : false;

// i18n


export const constSystemData = [
	{
		label: "新媒体信发系统",
		value: 1
	},
	{
		label: "智能会务系统",
		value: 2
	},
	{
		label: "无纸化智能会议系统",
		value: 3
	},
	{
		label: "智能物联中控系统",
		value: 4
	}
]

// 流量套餐列表
export const onProductList = [
	{
		id: 1,
		name: '10GB',
		flow: 10 * 1024 * 1024 * 1024, // 10GB in bytes
		price: 19.90,
		original_price: 25.90
	},{
		id: 2,
		name: '50GB',
		flow: 50 * 1024 * 1024 * 1024, // 40GB in bytes
		price: 99.90,
		original_price: 110
	},{
		id: 3,
		name: '100GB',
		flow: 100 * 1024 * 1024 * 1024, // 100GB in bytes
		price: 199.90,
		original_price: 210
	}
]


// 通知类型
export const onNotificationTypeOptions = [
	{
		id: 1,
		name: "邮件",
	},
	{
		id: 2,
		name: "短信",
	},
]



export function authentication(e: any) {
	return e.view_permissions >= 1;
}

// 定义文件下载函数
export const downloadFileCommon = async (info: any, fileUrl: string, fileName: string) => {
	try {
		const {orgNum, uid, username} = info

		// 使用axios发送GET请求获取文件
		const signKey = CryptoJS.MD5(`${orgNum},${uid},1,uad360`).toString();

		// 假设 md5 是你的 MD5 加密函数
		const response = await axios.get(fileUrl, {
			responseType: 'blob',  // 指定响应类型为blob，适用于文件下载
			headers: {
				"Org-Num": orgNum,
				"dev": uid.toString(),
				"Dev-Type": "1",
				"Dev-Name": username,
				"File-Name": Base64.encode(fileName),
				"sign": signKey
			},
		});

		// 使用file-saver保存文件
		saveAs(response.data, fileName);
	} catch (error) {
		console.error('Error downloading file:', error);
		// 处理错误
	}
};


// 调用php接口
export function sendEntryMessage(data: any) {
	return request({
		url: server + 'sendpost',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'multipart/form-data', // 关键
		},
	})
}


/**
 * 文件上传
 * @param data
 */
export async function uploadFile(data: any) {
	return request({
		url: server + '/api.php/uploadMaterialFile',
		method: 'post',
		data: data,
	});
}

/**
 * 系统升级
 * @param data
 */
export async function systemUpdateSh(data: any) {
	return request({
		url: server + '/api.php/systemUpdateSh',
		method: 'post',
		data: data,
	});
}


/**
 * 获取-系统激活状态
 */
export async function getActivationConfig() {
	return request({
		url: '/activationConfig.json?i=' + Math.round(Number(new Date()) / 1000),
		method: 'get',
	});
}

/**
 * 获取-系统配置
 */
export async function getSetting() {
	return request({
		url: '/setting.json?t=' + Math.round(Number(new Date()) / 1000),
		method: 'get',
	});
}

/**
 * 获取-注册配置
 */
export async function getRegister() {
	return request({
		url: '/register.json?t=' + Math.round(Number(new Date()) / 1000),
		method: 'get',
	});
}

export async function getLanguageFiles(path: any) {
	return request({
		url: `${path}/?t=${Math.round(Number(new Date()) / 1000)}`,
		method: 'get',
	});
}






//====================服务器转发========================================
export async function commonModelApi(query: any) {
	return request({
		url: server + '/commonModel.php',
		method: 'post',
		data: {
			...query,
			url: serverUrl,
			method: "serverForwarding",
		},
		headers: {
			'Content-Type': "multipart/form-data", // 关键
		},
	})
}






// ================================================================
export async function encryptionModel(query: any) {
	return request({
		url: server + '/commonModel.php',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "multipart/form-data", // 关键
		},
	});
}

// ===========================Rsa加密=====================================
export async function encryptionData(query: any) {
	return request({
		url: activateServices + '/encryptionModel.php',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "multipart/form-data", // 关键
		},
	});
}




//==================验证服务器授权=======================================
export async function encryptionModelApi(query: any) {
	return request({
		url: activateServices + '/encryptionModel.php',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "multipart/form-data", // 关键
		}
	});
}



// ===========================Rsa解密=====================================
export async function decryptData(query: any) {
	return request({
		url: activateServices + '/encryptionModel.php',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "multipart/form-data", // 关键
		},
	});
}


//============================获取Docker容器Id=====================================
export async function getDockerId(query: any) {
	return request({
		url: server + '/commonModel.php',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "multipart/form-data", // 关键
		},
	});
}



type NotificationType = 'success' | 'info' | 'warning' | 'error';
export function openNotificationWithIcon(
	message:string,
	description:string,
	type: NotificationType,
	duration: number = 4.5,
){
	if (typeof description !== "undefined" && description.indexOf("Authentication required") !== -1) {
		description = "用户登录过期，请重新登录"
	}

	notification[type]({
		message: message,
		description: description,
		className: 'custom-class',
		showProgress: true,
		duration: duration,
		style: {
			zIndex: 99999,
		},
	});

	return false
}


export function openNotificationInteraction(type: NotificationType, description: any, btn: any) {
	//消息提示
	notification[type]({
		message: "系统消息通知",
		duration: 60,
		description: description,
		className: 'custom-class',
		style: {
			zIndex: 5040,
		},
		btn: btn,
		key: uuid2(32,16),
	});


	return false
}




export function openNotificationWithIconForLong(
	message:string,
	description:string,
	type: NotificationType
){
	if (description.indexOf("Authentication required") !== -1) {
		description = "用户登录过期，请重新登录"
	}

	notification[type]({
		message,
		description,
		className: 'custom-class',
		style: {
			zIndex: 5040,
		},
		duration: 0,
	});

	return false
}



/**
 * 消息提示
 * @param response
 */
export function echoPutBacks(response: any) {
	let records = response.hasOwnProperty("records") ? response.records : response,
		TipsCode:NotificationType = "warning"

	if (records.length >= 1 || records >= 1) {
		TipsCode = "success"
	}

	if (!Boolean(records)) {
		TipsCode = "error"
	}

	const language = MULTILINGUAL[i18n.language??"zh_CN"]
	notification[TipsCode]({
		message: language['prompt'],
		description:
			response.hasOwnProperty("records") ?
				(`${language["operate"]}${language["success"]},${language["obtain"]}${records.length} ${language["strip"]}${language["data"]}！`)
			:
				(`${language["operate"]}${Boolean(records) ? language["success"] : language["error"]}！`),
	});
}

// // 创建解密函数
// export function decrypt(cipher: any, privateKey: any, data: any) {
// 	const key: any = KEYUTIL.getKey(privateKey);
// 	return KJUR.crypto.Cipher.decrypt(cipher, key, data);
// }


export async function getSchedule() {
	return request({
		url: 'https://echarts.apache.org//examples/data/asset/data/airport-schedule.json',
		method: 'get'
	});
}



//====================备份指定数据库========================================
export async function onDatabaseBackup(query: any) {
	return request({
		url: server + '/api.php/databaseBackup',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "multipart/form-data", // 关键
		},
	});
}


//====================恢复指定数据库========================================
export async function onDatabaseRestore(query: any) {
	return request({
		url: server + '/api.php/onDatabaseRestore',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

// ===========================数据库备份记录=====================================
export async function getBackupsRecord(query: any) {
	return request({
		url: server + '/api.php/records/think_database_backups?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addBackupsRecord(query: any) {
	return request({
		url: server + '/api.php/records/think_database_backups?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editBackupsRecord(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_database_backups/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delBackupsRecord(id: any) {
	return request({
		url: server + '/api.php/records/think_database_backups/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}

// =============================用户管理=================================
export async function getUser(query: any) {
	return request({
		url: server + '/api.php/records/think_admin?database=zuad_common',
		method: 'get',
		params: query
	});
}

export async function getUserAssociation(query: any) {
	return request({
		url: server + '/api.php/records/think_admin?database=zuad_common&join=think_department',
		method: 'get',
		params: query
	});
}

export async function getUsers(data: any) {
	return request({
		url: server + '/apikey.php/records/think_admin?database=zuad_common',
		method: 'get',
		params: data,
		headers: {
			"X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
		}
	});
}

export async function addUser(query: any) {
	return request({
		url: server + '/api.php/records/think_admin?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editUser(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_admin/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delUser(id: any) {
	return request({
		url: server + '/api.php/records/think_admin/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


// ===========================部门管理======================================
export async function getDepartment(query: any) {
	return request({
		url: server + '/api.php/records/think_department?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addDepartment(query: any) {
	return request({
		url: server + '/api.php/records/think_department?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editDepartment(id:any,data: any) {
	return request({
		url: server + '/api.php/records/think_department/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delDepartment(id: any) {
	return request({
		url: server + '/api.php/records/think_department/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


// =============================菜单管理===================================
export async function getAuthRule(query: any) {
	return request({
		url: server + '/api.php/records/think_auth_rule?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addAuthRule(query: any) {
	return request({
		url: server + '/api.php/records/think_auth_rule?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editAuthRule(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_auth_rule/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delAuthRule(id: any) {
	return request({
		url: server + '/api.php/records/think_auth_rule/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}

// ===========================日志管理=====================================
export async function getOperateLog(query: any) {
	return request({
		url: server + '/api.php/records/think_operation_log?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addOperateLog(query: any) {
	return request({
		url: server + '/api.php/records/think_operation_log?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editOperateLog(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_operation_log/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delOperateLog(id: any) {
	return request({
		url: server + '/api.php/records/think_operation_log/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}



// ===========================事件管理=====================================
export async function getEventLog(query: any) {
	return request({
		url: server + '/api.php/records/think_event?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addEventLog(query: any) {
	return requestKey({
		url: server + '/apikey.php/records/think_event?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editEventLog(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_event/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delEventLog(id: any) {
	return request({
		url: server + '/api.php/records/think_event/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


// ===========================组织管理======================================
export async function getOrganization(query: any) {
	return request({
		url: server + '/api.php/records/think_organization',
		method: 'get',
		params: query,
	});
}

export async function addOrganization(query: any) {
	return request({
		url: server + '/api.php/records/think_organization?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editOrganization(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_organization/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delOrganization(id: any) {
	return request({
		url: server + '/api.php/records/think_organization/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}

// ===========================角色管理======================================
export async function getAuthGroup(query: any) {
	return request({
		url: server + '/api.php/records/think_auth_group?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addAuthGroup(query: any) {
	return request({
		url: server + '/api.php/records/think_auth_group?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editAuthGroup(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_auth_group/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delAuthGroup(id: any) {
	return request({
		url: server + '/api.php/records/think_auth_group/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}

// ===========================系统版本管理======================================
export async function getSystemVersion(query: any) {
	return request({
		url: server + '/api.php/records/think_system_version?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addSystemVersion(query: any) {
	return request({
		url: server + '/api.php/records/think_system_version?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editSystemVersion(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_system_version/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delSystemVersion(id: any) {
	return request({
		url: server + '/api.php/records/think_system_version/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


// ===========================授权管理======================================
export async function getEmpower(query: any) {
	return request({
		url: server + '/api.php/records/think_empower?database=zuad_common&join=think_system_version',
		method: 'get',
		params: query,
	});
}

export async function addEmpower(query: any) {
	return request({
		url: server + '/api.php/records/think_empower?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editEmpower(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_empower/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delEmpower(id: any) {
	return request({
		url: server + '/api.php/records/think_empower/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}

// ===========================系统版本升级记录======================================
export async function getRecord(query: any) {
	return request({
		url: server + '/api.php/records/think_system_upgrade_record?database=zuad_common',
		method: 'get',
		params: query,
	});
}

export async function addRecord(query: any) {
	return request({
		url: server + '/api.php/records/think_system_upgrade_record?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editRecord(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_system_upgrade_record/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delRecord(id: any) {
	return request({
		url: server + '/api.php/records/think_system_upgrade_record/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


// ===========================登录管理=====================================
export async function doLogin(query: any) {
	return request({
		url: server + '/api.php/login',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}



// ===========================修改密码=====================================
export async function editPassword(query: any) {
	return request({
		url: server + '/api.php/password',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

// ==========================系统配置===============================
export function getConfig(query: any) {
	return request({
		url: server + '/api.php/records/think_config?database=zuad_common',
		method: 'get',
		params: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	})
}

export async function addConfig(query: any) {
	return request({
		url: server + '/api.php/records/think_config?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editConfig(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_config/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delConfig(id: any) {
	return request({
		url: server + '/api.php/records/think_config/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


// 编辑配置
export async function editConfigz(data: any) {
	return request({
		url: server + '/api.php/sendpost',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'multipart/form-data', // 关键
		},
	})
}
// 新增配置
export async function addConfigs(data: any) {
	return request({
		url: server + '/api.php/records/think_configs',
		method: 'post',
		data,
		params: {
			database: "zuad_common"
		}
	})
}
// 编辑配置
export async function editConfigs(data: any) {
	return request({
		//url: server+"/api/v5.entry/editConfig",
		url: server + '/api.php/records/think_configs/' + data.id ,
		method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: "zuad_common"
		}
	})
}


// ===========================设备授权管理======================================
export async function getDeviceEmpower(query: any) {
	return request({
		url: server + '/api.php/records/think_device_empower?database=zuad_common&join=think_empower',
		method: 'get',
		params: query,
	});
}

export async function addDeviceEmpower(query: any) {
	return request({
		url: server + '/api.php/records/think_device_empower?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editDeviceEmpower(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_device_empower/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,

		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delDeviceEmpower(id: any) {
	return request({
		url: server + '/api.php/records/think_device_empower/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


// ===============================订单管理=========================================
export async function getOrder(query: any) {
	return request({
		url: server + '/api.php/records/think_order?database=zuad_common&join=think_organization',
		method: 'get',
		params: query,
	});
}

export async function addOrder(query: any) {
	return request({
		url: server + '/api.php/records/think_order?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editOrder(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_order/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,
		headers: on_off ? {
			'Content-Type': 'application/json',
			"X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delOrder(id: any) {
	return request({
		url: server + '/api.php/records/think_order/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE",
		headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


// ===============================消耗管理=========================================

export async function getDownloadLog(query: any) {
	return request({
		url: server + '/api.php/records/think_download_log?database=zuad_common&join=think_organization',
		method: 'get',
		params: query,
	});
}

export async function addDownloadLog(query: any) {
	return request({
		url: server + '/api.php/records/think_download_log?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editDownloadLog(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_download_log/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delDownloadLog(id: any) {
	return request({
		url: server + '/api.php/records/think_download_log/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE",
		headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}


//=========================流量池===========================================
export async function getFlowPool(query: any) {
	return request({
		url: server + '/api.php/records/think_flow_pool?database=zuad_common&join=think_organization',
		method: 'get',
		params: query,
	});
}

export async function addFlowPool(query: any) {
	return request({
		url: server + '/api.php/records/think_flow_pool?database=zuad_common',
		method: 'post',
		data: query,
		headers: {
			'Content-Type': "application/json", // 关键
		},
	});
}

export async function editFlowPool(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_flow_pool/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "PUT",
		data,
		headers: on_off ? {
			'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'application/json'
		},
	});
}

export async function delFlowPool(id: any) {
	return request({
		url: server + '/api.php/records/think_flow_pool/' + id +'?database=zuad_common',
		method: on_off ? 'post' : "DELETE",
		headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}

