import request from "../utils/request2";
import requests from "../utils/request";
import Config from "../config";
import { uuid2 } from "../utils/index"

const server = Config.siteAddress;

const on_off = Config.on_off ? Config.on_off : false;
console.log(on_off);
interface Device {
    orgNum: number,
    uid: number,
    oid: string,
    deviceId: string,
    deviceName: string,
    remark: string,
}

// 获取系统整体信息
export function getBases(query: any) {
    return request({
        url: server + "/api/v5.entry/getBases",
        method: 'post',
        params: query
    })
}
// ===============================================================================================
// 获取设备列表
export async function getDevices(query: any) {
    return request({
        // url: server + "/api/v5/equipment_model/getDevices",
        url: server + '/api.php/records/think_device?database=meeting_dingding&join=think_default_play',
        method: 'get',
        params: query,
    });
}
// 获取设备列表
export function getAllDevices(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllDevices",
        url: server + "/api.php/records/think_device?database=meeting_dingding",
        method: 'get',
        params: query
    })
}

// 获取设备列表-条件搜索
export function getDeviceBySearch(query: any) {
    return request({
        //url: server+"/api/v5.entry/getDeviceBySearch",
        url: server + "/api.php/records/think_device?database=meeting_dingding&join=think_room&join=think_device_type",
        method: 'get',
        params: query
    })
}

// 添加设备
export function addDevice(data: any) {
    return request({
        //url: server+'/api/v5.entry/addDevice',
        url: server + '/api.php/records/think_device',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑设备
export function editDevice(data: any) {
    return request({
        url: server + '/api.php/records/think_device/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑设备
export async function editDevices(id: any, data: any) {
    return request({
        url: server + '/api.php/records/think_device/' + id,
        method: on_off ? 'post' : "PUT",
        data,
        params: {
            database: 'media',
        },
        headers: on_off ? {
            'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
        } : {
            'Content-Type': 'application/json'
        },
    });
}

// 删除设备
export function delDevice(data: any) {
    return request({
        //url: server+'/api/v5.entry/delDevice',
        url: server + '/api.php/records/think_device/' + data,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: {
            database: "meeting_dingding"
        }
    })
}

//=============================设备下载日志管理=====================================
export async function getDownloadLog(query: any) {
    return request({
        // url: server + "/api/v5/equipment_model/getDevices",
        url: server + '/api.php/records/think_download_log?database=meeting_dingding&join=think_device&join=think_layout',
        method: 'get',
        params: query,
    });
}

//======================设备配置================================
// 获取设备配置
export async function getFindDeviceConfig(data: any) {
    return request({
        url: server + '/api.php/records/think_device_config?database=meeting_dingding',
        method: 'get',
        params: data,
    });
}

//修改-设备配置
export async function editFindDeviceConfig(id: any, data: any) {
    return request({
        // url: server + "/api.php/records/think_device_config?database=media",
        url: server + '/api.php/records/think_device_config/' + id,
        method: on_off ? 'post' : "PUT",
        data,
        params: {
            database: 'meeting_dingding',
        },
        headers: on_off ? {
            'Content-Type': 'application/json', "X-HTTP-Method-Override": "PUT"
        } : {
            'Content-Type': 'application/json'
        },
    });
}

//===============================任务管理=========================================

//任务列表
export async function getTask(query: any) {
    return request({
        // url: server + "/api/v5/task_model/getTasks",
        url: server + '/api.php/records/think_task?database=meeting_dingding',
        method: 'get',
        params: query,
    });
}

//添加任务
export async function addTask(data: any) {
    return request({
        // url: server + "/api/v5/task_model/addTask",
        url: server + '/api.php/records/think_task',
        method: 'post',
        data,
        params: {
            database: 'meeting_dingding',
        },
        headers: {
            'Content-Type': 'application/json'
        },
    });
}

// 删除任务
export async function delTask(id: any) {
    return request({
        url: server + '/api.php/records/think_task/' + id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: {
            database: 'meeting_dingding',
        },
    });
}
// ===============================================================================================

// 获取人体识别列表
export function getAllIdentify(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllDevices",
        url: server + "/api.php/records/think_camera_identify?database=meeting_dingding&order=identify_time,desc",
        method: 'get',
        params: query
    })
}
// 获取人体识别列表
export function getAllIdentifys(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllDevices",
        url: server + "/api.php/records/think_camera_identify?database=meeting_dingding&size=20&order=identify_time,desc",
        method: 'get',
        params: query
    })
}
// 删除人体识别记录
export function delIdentify(data: any) {
    return request({
        //url: server+'/api/v5.entry/delDevice',
        url: server + '/api.php/records/think_camera_identify/' + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: {
            database: "meeting_dingding"
        }
    })
}
// 获取摄像机列表-条件搜索
export function getCameraBySearch(query: any) {
    return request({
        //url: server+"/api/v5.entry/getDeviceBySearch",
        url: server + "/api.php/records/think_camera?database=meeting_dingding",
        method: 'get',
        params: query
    })
}

// 添加摄像机
export function addCamera(data: any) {
    return request({
        //url: server+'/api/v5.entry/addDevice',
        url: server + '/api.php/records/think_camera',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑摄像机
export function editCamera(data: any) {
    return request({
        url: server + '/api.php/records/think_camera/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 删除摄像机
export function delCamera(ids: string) {
    return request({
        //url: server+'/api/v5.entry/delDevice',
        url: server + '/api.php/records/think_camera/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: {
            database: "meeting_dingding"
        }
    })
}

//=========================安装包========================================



// 获取安装包
export function getApkRenew(query: any) {
    return request({
        url: server + '/api.php/records/think_apk_renew?database=meeting_dingding&order=create_time,desc&limit=1000',
        method: 'get',
        params: query,
    });
}
// 获取安装包
export function getApkRenews(data: any) {
    return request({
        url: server + '/apikey.php/records/think_apk_renew?database=meeting_dingding&order=create_time,desc&limit=1000',
        method: 'get',
        params: data,
        headers: {
            "X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
        }
    });
}

// 添加安装包
export function addApkRenew(data: any) {
    return request({
        url: server + '/api.php/records/think_apk_renew?database=meeting_dingding',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data', // 关键
        },
    });
}

// 编辑安装包
export function editApkRenew(id: number, data: any) {
    return request({
        url: server + '/api.php/records/think_apk_renew/' + id,
        method: on_off ? 'post' : "PUT",
        headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: 'meeting_dingding',
        },
    });
}


// 删除安装包
export function delApkRenew(id: any) {
    return request({
        url: server + '/api.php/records/think_apk_renew/' + id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: {
            database: 'meeting_dingding',
        },
    });
}

// ===============================================================================================

// 获取素材列表
export function getAllMedia(query: any) {
    return request({
        url: server + '/api.php/records/think_document?database=meeting_dingding&order=last_time,desc&limit=1000',
        method: 'get',
        params: query,
    });
}
// 获取素材列表
export function getAllMedias(query: any) {
    return request({
        url: server + '/apikey.php/records/think_document?database=meeting_dingding&order=last_time,desc',
        method: 'get',
        params: query,
        headers: {
            "X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
        }
    });
}

// 添加素材
export function addMedia(data: any) {
    return request({
        url: server + '/api.php/records/think_document',
        method: 'post',
        data,
        params: {
            database: 'meeting_dingding',
        },
        headers: {
            'Content-Type': 'multipart/form-data', // 关键
        },
    });
}
// ===============================================================================================
// 获取设备组列表
export function getAllDeviceGroup(query: any) {
    return request({
        url: server + "/api/v5.entry/getAllDeviceGroup",
        method: 'get',
        params: query
    })
}

// 添加设备组
export function addDeviceGroup(data: any) {
    return request({
        url: server + '/api/v5.entry/addDeviceGroup',
        method: 'post',
        data
    })
}

// 编辑设备组
export function editDeviceGroup(data: any) {
    return request({
        url: server + '/api/v5.entry/editDeviceGroup',
        method: 'post',
        data
    })
}

// 删除设备组
export function delDeviceGroup(data: any) {
    return request({
        url: server + '/api/v5.entry/delDeviceGroup',
        method: 'get',
        params: data
    })
}

// =======================================================================
// 获取设备类型列表
export function getAllDeviceTypes(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllDeviceTypes",
        url: server + "/api.php/records/think_device_type?database=meeting_dingding&order=last_time,desc",
        method: 'get',
        params: query
    })
}

// 获取设备类型列表-条件搜索
export function getDeviceTypeBySearch(query: any) {
    return request({
        //url: server+"/api/v5.entry/getDeviceTypeBySearch",

        url: server + "/api.php/records/think_device_type?database=meeting_dingding&order=last_time,desc",
        method: 'get',
        params: query
    })
}

// 添加设备类型
export function addDeviceType(data: any) {
    return request({
        //url: server+'/api/v5.entry/addDeviceType',
        url: server + "/api.php/records/think_device_type",
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑设备类型
export function editDeviceType(data: any) {
    return request({
        //url: server+'/api/v5.entry/editDeviceType',
        url: server + "/api.php/records/think_device_type/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 删除设备类型
export function delDeviceType(data: any) {
    return request({
        //url: server+'/api/v5.entry/delDeviceType',
        url: server + "/api.php/records/think_device_type/" + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// =======================================================================
// 获取会议室设备列表
export function getAllRoomDevice(query: any) {
    return request({
        url: server + "/api.php/records/think_room_device?database=meeting_dingding",
        method: 'get',
        params: query
    })
}

// 添加区域
export function addRoomDevice(data: any) {
    return request({
        //url: server + '/api/v5.entry/addArea',
        url: server + "/api.php/records/think_room_device",
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑区域
export function editRoomDevice(data: any) {
    return request({
        //url: server+'/api/v5.entry/editArea',
        url: server + "/api.php/records/think_room_device/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 删除区域
export function delRoomDevice(data: any) {
    return request({
        //url: server+'/api/v5.entry/delArea',
        url: server + "/api.php/records/think_room_device/" + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// =======================================================================
// 获取区域列表
export function getAllArea(query: any) {
    return request({
        url: server + "/api.php/records/think_area?database=meeting_dingding&join=think_room",
        method: 'get',
        params: query
    })
}

// 获取区域列表-条件搜索
export function getAreasBySearch(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAreasBySearch",
        url: server + "/api.php/records/think_area?database=meeting_dingding",
        method: 'get',
        params: query
    })
}

// 添加区域
export function addArea(data: any) {
    return request({
        //url: server + '/api/v5.entry/addArea',
        url: server + "/api.php/records/think_area",
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑区域
export function editArea(data: any) {
    return request({
        //url: server+'/api/v5.entry/editArea',
        url: server + "/api.php/records/think_area/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 删除区域
export function delArea(data: any) {
    return request({
        //url: server+'/api/v5.entry/delArea',
        url: server + "/api.php/records/think_area/" + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// =======================================================================
// 获取会议室租赁列表-条件搜索
export function getRoomRentsBySearch(query: any) {
    return request({
        url: server + "/api.php/records/think_rent_order?database=meeting_dingding",
        method: 'get',
        params: query
    })
}
// =======================================================================
// 获取小程序列表-条件搜索
export function getAppletBySearch(query: any) {
    return request({
        url: server + "/api.php/records/think_wxapp?database=meeting_dingding",
        method: 'get',
        params: query
    })
}
// =======================================================================
// 获取会议室视频列表-条件搜索
export function getPageVideosBySearch(query: any) {
    return request({
        url: server + "/api.php/records/think_wxapp_page?database=meeting_dingding&order=sort,asc",
        method: 'get',
        params: query
    })
}
// 添加会议室视频
export function addPageVideo(data: any) {
    return request({
        //url: server+'/api/v5.entry/addRoom',
        url: server + '/api.php/records/think_wxapp_page',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑会议室视频
export function editPageVideo(data: any) {
    return request({
        //url: server+'/api/v5.entry/editRoom',
        url: server + '/api.php/records/think_wxapp_page/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 删除会议室视频
export function delPageVideo(data: any) {
    return request({
        //url: server+'/api/v5.entry/delRoom',
        url: server + '/api.php/records/think_wxapp_page/' + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// =======================================================================
// 获取会议室视频列表
export function getAllRoomVideos(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllRooms",
        url: server + "/api.php/records/think_video?database=meeting_dingding",
        method: 'get',
        params: query
    })
}

// 获取会议室视频列表-条件搜索
export function getRoomVideosBySearch(query: any) {
    return request({
        url: server + "/api.php/records/think_video?database=meeting_dingding",
        method: 'get',
        params: query
    })
}
// 添加会议室视频
export function addRoomVideo(data: any) {
    return request({
        //url: server+'/api/v5.entry/addRoom',
        url: server + '/api.php/records/think_video',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑会议室视频
export function editRoomVideo(data: any) {
    return request({
        //url: server+'/api/v5.entry/editRoom',
        url: server + '/api.php/records/think_video/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 删除会议室视频
export function delRoomVideo(data: any) {
    return request({
        //url: server+'/api/v5.entry/delRoom',
        url: server + '/api.php/records/think_video/' + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// =======================================================================
// 获取会议室列表
export function getAllRooms(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllRooms",
        url: server + "/api.php/records/think_room?database=meeting_dingding&join=think_area",
        method: 'get',
        params: query
    })
}

// 获取会议室列表-条件搜索
export function getRoomsBySearch(query: any) {
    return request({
        url: server + "/api.php/records/think_room?database=meeting_dingding&join=think_area",
        method: 'get',
        params: query
    })
}

// 获取可用的会议室列表-条件搜索
export function getRoomBySearch(query: any) {
    return request({
        url: server + "/api.php/records/think_room?database=meeting_dingding&join=think_area&order=room_name",
        method: 'get',
        params: query
    })
}
// 获取指定会议室
export function getRoomByKey(query: any) {
    return request({
        url: server + "/api/v5.entry/getRoomByKey",
        method: 'post',
        data: query
    })
}

// 获取所有会议室会议信息
export function getAllTodayMeetings(query: any) {
    return request({
        url: server + "/api.php/records/think_entry?database=meeting_dingding&order=start_time,desc&size=5",
        method: 'get',
        data: query
    })
}

// 添加会议室
export function addRoom(data: any) {
    return request({
        //url: server+'/api/v5.entry/addRoom',
        url: server + '/api.php/records/think_room',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑会议室
export function editRoom(data: any) {
    return request({
        //url: server+'/api/v5.entry/editRoom',
        url: server + '/api.php/records/think_room/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 删除会议室
export function delRoom(data: any) {
    return request({
        //url: server+'/api/v5.entry/delRoom',
        url: server + '/api.php/records/think_room/' + data.id,
        method: on_off ? 'post' : "DELETE",
        data,
        params: {
            database: "meeting_dingding"
        }, headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
    })
}

// 停用或启用会议室
export function doSetRoomStatus(data: any) {
    return request({
        //url: server + '/api/v5.entry/doSetRoomStatus',
        url: server + '/api.php/records/think_room/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// =======================================================================
// 获取预设人员列表
export function getAllGroups(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllGroups",
        url: server + "/api.php/records/think_group?database=meeting_dingding",
        method: 'get',
        params: query
    })
}

// 获取预设人员列表-条件搜索
export function getGroupBySearch(query: any) {
    return request({
        //url: server+"/api/v5.entry/getGroupBySearch",
        url: server + "/api.php/records/think_group?database=meeting_dingding",
        method: 'get',
        params: query
    })
}

// 添加预设人员
export function addGroup(data: any) {
    return request({
        //url: server+'/api/v5.entry/addGroup',
        url: server + "/api.php/records/think_group",
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑预设人员
export function editGroup(data: any) {
    return request({
        //url: server+'/api/v5.entry/editGroup',
        url: server + "/api.php/records/think_group/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 删除预设人员
export function delGroup(data: any) {
    return request({
        //url: server+'/api/v5.entry/delGroup',
        url: server + "/api.php/records/think_group/" + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 停用或启用会议室
export function doSetGroupStatus(data: any) {
    return request({
        //url: server + '/api/v5.entry/doSetGroupStatus',
        url: server + "/api.php/records/think_group/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}


// =======================================================================
// 获取显示屏模板列表
export function getAllShows(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllShows",
        url: server + "/api.php/records/think_show?database=meeting_dingding",
        method: 'get',
        params: query
    })
}
// 获取指定模板
export function getShowByKey(query: any) {
    return request({
        //url: server+"/api/v5.entry/getShowByKey",
        url: server + "/api.php/records/think_show?database=meeting_dingding",
        method: 'get',
        params: query
    })
}
// 模糊搜索显示屏模板列表
export function getShowsBySearch(query: any) {
    return request({
        url: server + "/api.php/records/think_show?database=meeting_dingding",
        method: 'get',
        params: query
    })
}
// 添加显示屏模板
export function addShow(data: any) {
    return request({
        //url: server+'/api/v5.entry/addShow',
        url: server + "/api.php/records/think_show",
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑显示屏模板
export function editShow(data: any) {
    return request({
        //url: server+'/api/v5.entry/editShow',
        url: server + "/api.php/records/think_show/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 删除显示屏模板
export function delShow(data: any) {
    return request({
        //url: server+'/api/v5.entry/delShow',
        url: server + "/api.php/records/think_show/" + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 获取控制模板列表
export function getAllControlTemplates(query: any) {
    return request({
        url: server + '/api.php/records/think_show_configuration',
        method: 'get',
        params: { ...query, database: 'meeting_dingding' }
    })
}


// =======================================================================
// 搜索会中服务
export function getMeetingServiceBySearch(query: any) {
    return request({
        //url: server + "/api/v5.entry/getMeetingsBySearch",
        url: server + "/api.php/records/think_entry_service?database=meeting_dingding&join=think_room&join=think_entry&order=create_time,desc",
        method: 'get',
        params: query
    });
}

// =======================================================================
// 会议审核列表
export function getAllAuditMeeting(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllAuditMeeting",
        url: server + "/api.php/records/think_entry?database=meeting_dingding&join=think_room&order=start_time,desc",
        method: 'get',
        params: query
    })
}
// 会议审核列表
export function getAllAuditMeetings(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllAuditMeeting",
        url: server + "/api.php/records/think_entry?database=meeting_dingding&join=think_room&order=start_time,asc",
        method: 'get',
        params: query
    })
}
// 管理员审核
export function auditMeeting(data: any) {
    return request({
        //url: server+"/api/v5.entry/auditMeeting",
        url: server + "/api.php/records/think_entry/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 我的会议列表
export function getMyMeetings(query: any) {
    return request({
        // url: server+"/api/v5.entry/getMyMeetings",
        url: server + "/api.php/records/think_entry?database=meeting_dingding&join=think_room&order=start_time,desc",
        method: 'get',
        params: query
    })
}

// 获取指定会议
export function getMeetingByKey(query: any) {
    return request({
        //url: server+"/api/v5.entry/getMeetingByKey",
        url: server + "/api.php/records/think_entry?database=meeting_dingding&join=think_room",
        method: 'get',
        params: query
    })
}

// 获取会议列表
export function getAllMeetings(query: any) {
    return request({
        //url: server + "/api/v5.entry/getAllMeetings",
        url: server + "/api.php/records/think_entry?database=meeting_dingding&join=think_room&order=start_time,desc",
        method: 'get',
        params: query
    })
}

// 搜索会议
export function getMeetingsBySearch(query: any) {
    return request({
        //url: server + "/api/v5.entry/getMeetingsBySearch",
        url: server + "/api.php/records/think_entry?database=meeting_dingding&join=think_room&order=start_time,desc",
        method: 'get',
        params: query
    });
}

// 企业微信-搜索会议
export function getAllMeetingDatas(query: any) {
    return request({
        //url: server + "/api/v5.entry/getMeetingsBySearch",
        url: server + "/apikey.php/records/think_entry?database=meeting_dingding&join=think_room&order=start_time,desc",
        method: 'get',
        params: query,
        headers: {
            "X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
        }
    });
}
// 搜索时间段是否已存在会议
export function getMeetingsBySearchs(query: any) {

    return request({
        url: server + "/api.php/records/think_entry?database=meeting_dingding",
        method: 'get',
        params: query
    });
}

// 添加会议
export function doBookMeeting(data: any) {
    return request({
        //url: server+'/api/v5.entry/doBookMeeting',
        url: server + "/api.php/records/think_entry",
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑会议
export function editMeeting(data: any) {
    return request({
        //url: server + '/api/v5.entry/editMeeting/' ,
        url: server + "/api.php/records/think_entry/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑会议
export function editMeeting2(id:any,data: any) {
    return request({
        //url: server + '/api/v5.entry/editMeeting/' ,
        url: server + "/api.php/records/think_entry/" + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 删除会议
export function delMeeting(data: any) {
    return request({
        //url: server+'/api/v5.entry/delMeeting',
        url: server + "/api.php/records/think_entry/" + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 获取待进行会议
export function getToMeeting(data: any) {
    return request({
        //url: server + '/api/v5.entry/getToMeeting',
        url: server + "/api.php/records/think_entry?database=meeting_dingding&join=think_room&order=start_time,desc",
        method: 'get',
        params: data
    })
}
// 获取最近会议
export function getOldMeeting(data: any) {
    return request({
        url: server + '/api/v5.entry/getOldMeeting',
        method: 'post',
        data: data
    })
}
// 补签
export function counterSign(data: any) {
    return request({
        //url: server + '/api/v5.entry/counterSign',
        url: server + '/api.php/records/think_entry_sign_in/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: 'meeting_dingding',
        },
    })
}
// 上传会后文件
export function uploadEntryFile(data: any) {
    return request({
        url: server + '/api/v5.entry/uploadEntryFile',
        method: 'post',
        data: data
    })
}
// 发送会后通知
export function sendEntryNotice(data: any) {
    return request({
        url: server + '/api/v5.entry/sendEntryNotice',
        method: 'post',
        data: data
    })
}
// 发送会后通知
export function sendEntryMessage(data: any) {
    return request({
        url: server + '/api.php/sendpost',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data', // 关键
        },
    })
}
// 发送会后通知
export function sendEntryMessages(data: any) {
    return request({
        url: server + '/apikey.php/sendpost',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
            "X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
        },
    })
}
// ========================================================

// 发送钉钉测试
export function sendDingdingTest(data: any) {
    return request({
        url: server + '/apikey.php/sendpost',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
            "X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
        },
    })
}

// ========================================================
// 清除日志
export function deleteLogs(data: any) {
    return request({
        url: server + '/api.php/sendpost',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data', // 关键
        },
    })
}
// ========================================================
// 拖拉预约会议
export function day(data: any) {
    return request({
        url: server + '/api/v5.entry/day',
        method: 'post',
        data
    })
}



// =======================================================================
// 获取角色列表
export function getAllRoles(query: any) {
    return request({
        //url: server+"/api/Zuad/getAllRoles",
        url: server + "/api.php/records/think_auth_group?database=zuad_common",
        method: 'get',
        params: query
    })
}

// 获取角色列表-条件搜索
export function getRoleBySearch(query: any) {
    return request({
        //url: server + "/api/Zuad/getRoleBySearch",
        url: server + "/api.php/records/think_auth_group?database=zuad_common&order=create_time,desc",
        method: 'get',
        params: query
    })
}

// 添加角色
export function addRole(data: any) {
    return request({
        //url: server+'/api/Zuad/addRole',
        url: server + "/api.php/records/think_auth_group",
        method: 'post',
        data,
        params: {
            database: "zuad_common"
        }
    })
}

// 编辑角色
export function editRole(data: any) {
    return request({
        //url: server+'/api/Zuad/editRole',
        url: server + "/api.php/records/think_auth_group/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}

// 删除角色
export function delRole(data: any) {
    return request({
        //url: server+'/api/Zuad/delRole',
        url: server + "/api.php/records/think_auth_group/" + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 启用或禁用角色
export function doSetRoleStatus(data: any) {
    return request({
        //url: server + '/api/Zuad/doSetRoleStatus',
        url: server + "/api.php/records/think_auth_group/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 获取用户角色及权限
export function getUserRoleByKey(data: any) {
    return request({
        url: server + '/api/Zuad/getUserRoleByKey',
        method: 'post',
        data: data
    })
}
// 获取用户权限树
export function getUserRole(data: any) {
    return request({

        //url: server + '/api/Zuad/getUserRole',
        url: server + "/api.php/records/think_auth_group?database=zuad_common",
        method: 'get',
        params: data
    })
}
// 分配用户权限
export function setUserRole(data: any) {
    return request({
        //url: server + '/api/Zuad/setUserRole',
        url: server + "/api.php/records/think_auth_group/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 获取所有用户权限
export function getUserRoles(data: any) {
    return request({
        url: server + '/api/Zuad/getUserRoles',
        method: 'post',
        data: data
    })
}


// =======================================================================
// 获取菜单列表
export function getAllMenu(query: any) {
    return request({
        //url: server + "/api/Zuad/getAllMenu",
        url: server + "/api.php/records/think_auth_rule?database=zuad_common&order=sort&order=id",
        method: 'get',
        params: query
    })
}

// 获取菜单列表-条件搜索
export function getMenuBySearch(query: any) {
    return request({
        //url: server + "/api/Zuad/getMenuBySearch",
        url: server + "/api.php/records/think_auth_rule?database=zuad_common",
        method: 'get',
        params: query
    })
}

// 添加菜单
export function addMenu(data: any) {
    return request({
        //url: server + '/api/Zuad/addMenu',
        url: server + "/api.php/records/think_auth_rule",
        method: 'post',
        data,
        params: {
            database: "zuad_common"
        }
    })
}

// 编辑菜单
export function editMenu(data: any) {
    return request({
        //url: server + '/api/Zuad/editMenu',
        url: server + "/api.php/records/think_auth_rule/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}

// 删除菜单
export function delMenu(data: any) {
    return request({
        //url: server + '/api/Zuad/delMenu',
        url: server + "/api.php/records/think_auth_rule/" + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 停用或启用菜单
export function doSetMenuStatus(data: any) {
    return request({
        //url: server + '/api/zuad/doSetMenuStatus',
        url: server + "/api.php/records/think_auth_rule/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// =======================================================================
// 获取语言列表
export function getAllLanguage(query: any) {
    return request({
        //url: server+"/api/Zuad/getAllDepartments",
        url: server + "/api.php/records/think_language?database=zuad_common",
        method: 'get',
        params: query
    })
}

// =======================================================================
// 获取部门列表
export function getAllDepartments(query: any) {
    return request({
        //url: server+"/api/Zuad/getAllDepartments",
        url: server + "/api.php/records/think_department?database=zuad_common",
        method: 'get',
        params: query
    })
}

// 获取部门列表-条件搜索
export function getDepartmentBySearch(query: any) {
    return request({
        //url: server+"/api/Zuad/getDepartmentBySearch",
        url: server + "/api.php/records/think_department?database=zuad_common",
        method: 'get',
        params: query
    })
}

// 添加部门
export function addDepartment(data: any) {
    return request({
        //url: server+'/api/Zuad/addDepartment',
        url: server + "/api.php/records/think_department",
        method: 'post',
        data,
        params: {
            database: "zuad_common"
        }
    })
}

// 编辑部门
export function editDepartment(data: any) {
    return request({
        //url: server+'/api/Zuad/editDepartment',
        url: server + "/api.php/records/think_department/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}

// 删除部门
export function delDepartment(data: any) {
    return request({
        //url: server+'/api/Zuad/delDepartment',
        url: server + "/api.php/records/think_department/" + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 停用或启用部门
export function doSetDepartmentStatus(data: any) {
    return request({
        //url: server + '/api/zuad/doSetDepartmentStatus',
        url: server + "/api.php/records/think_department/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}

// =======================================================================
// 获取组织列表
export function getAllOrganizations(query: any) {
    return request({
        //url: server + "/api/Zuad/getAllOrganizations",
        url: server + "/api.php/records/think_organization?database=zuad_common",
        method: 'get',
        params: query
    })
}
// 获取组织列表
export function getAllOrganizationz(query: any) {
    return request({
        //url: server + "/api/Zuad/getAllOrganizations",
        url: server + "/apikey.php/records/think_organization?database=zuad_common&include=org_num,org_name",
        method: 'get',
        params: query,
        headers: {
            "X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
        }
    })
}
// 获取组织列表-条件搜索
export function getOrganizationBySearch(query: any) {
    return request({
        //url: server + "/api/Zuad/getOrganizationBySearch",
        url: server + "/api.php/records/think_organization?database=zuad_common",
        method: 'get',
        params: query
    })
}

// 添加组织
export function addOrganization(data: any) {
    return request({
        //url: server + '/api/Zuad/addOrganization',
        url: server + "/api.php/records/think_organization",
        method: 'post',
        data,
        params: {
            database: "zuad_common"
        }
    })
}

// 编辑组织
export function editOrganization(data: any) {
    return request({
        //url: server + '/api/Zuad/editOrganization',
        url: server + "/api.php/records/think_organization/" + data.org_num,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}

// 删除组织
export function delOrganization(data: any) {
    return request({
        //url: server + '/api/Zuad/delOrganization',
        url: server + "/api.php/records/think_organization/" + data.org_num,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 停用或启用组织
export function doSetOrganizationStatus(data: any) {
    return request({
        //url: server + '/api/zuad/doSetOrganizationStatus',
        url: server + "/api.php/records/think_organization/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}


// =======================================================================
// 获取要备份的数据表
export function getTableData2(data: any) {
    return request({
        url: server + '/api.php/sendpost',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data', // 关键
        },
    })
}

// =======================================================================
// 获取要备份的数据表
export function getTableData(query: any) {
    return request({
        url: server + "/api/Zuad/getTableData",
        method: 'post',
        data: query
    })
}
// 优化数据表
export function optimize(query: any) {
    return request({
        url: server + "/api/Zuad/optimize",
        method: 'post',
        data: query
    })
}
// 修复数据表
export function repair(query: any) {
    return request({
        url: server + "/api/Zuad/repair",
        method: 'post',
        data: query
    })
}
// 备份数据表
export function exports(query: any) {
    return request({
        url: server + "/api/Zuad/export",
        method: 'post',
        data: query
    })
}
// 获取备份数据表文件
export function getBackupData(query: any) {
    return request({
        url: server + "/api/Zuad/getBackupData",
        method: 'post',
        data: query
    })
}
// 还原数据表
export function revert(query: any) {
    return request({
        url: server + "/api/Zuad/revert",
        method: 'post',
        data: query
    })
}
// 删除备份文件
export function delBackup(query: any) {
    return request({
        url: server + "/api/Zuad/delBackup",
        method: 'post',
        data: query
    })
}

// =======================================================================
// 获取用户列表
export function getAllUsers(query: any) {
    return request({
        //url: server+"/api/zuad/getAllUsers",
        url: server + '/api.php/records/think_admin?database=zuad_common&join=think_department',
        method: 'get',
        params: query
    })
}
export function getAllUsers2(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllConfig",
        url: server + '/apikey.php/records/think_admin?database=zuad_common&join=think_department',
        method: 'get',
        params: query,
        headers: {
            'Content-Type': "application/json", // 关键
            "X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
        },
    })
}
// 获取所有用户，并按部门划分
export function getAllUserToDepartment(query: any) {
    return request({
        //url: server + "/api/zuad/getAllUserToDepartment",
        url: server + '/api.php/records/think_department?database=zuad_common&join=think_admin',
        method: 'get',
        params: query
    })
}
// 获取用户列表-条件搜索
export function getUserBySearch(query: any) {
    return request({
        //url: server+"/api/zuad/getUserBySearch",
        url: server + '/api.php/records/think_admin?database=zuad_common&join=think_department',
        method: 'get',
        params: query
    })
}
// 获取用户列表-条件搜索
export function getUserBySearch2(query: any) {
    return request({
        //url: server+"/api/zuad/getUserBySearch",
        url: server + '/api.php/records/think_admin?database=zuad_common',
        method: 'get',
        params: query
    })
}

// 用户登录
export function doLogin(query: any) {
    return request({
        //url: server+"/api/commons.user_model/doLogin",
        url: server + "/api.php/login",
        method: 'post',
        data: query
    })
}
// 用户密码更新
export function doPassword(data: any) {
    return request({
        //url: server+"/api/commons.user_model/doLogin",
        url: server + "/api.php/password",
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data', // 关键
        },
    })
}

// 用户注册
export function userRegister(query: any) {
    return request({
        url: server + "/api/commons.user_model/doRegister",
        method: 'post',
        data: query
    })
}

// 添加用户
export function doRegister(data: any) {
    return request({
        //url: server+'/api/zuad/addUser',
        url: server + "/api.php/records/think_admin",
        method: 'post',
        data,
        params: {
            database: "zuad_common"
        }
    })
}

// 编辑用户
export function editUser(data: any) {
    return request({
        //url: server+'/api/Zuad/editUser',
        url: server + "/api.php/records/think_admin/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}

// 删除用户
export function delUser(data: any) {
    return request({
        //url: server+'/api/Zuad/delUser',
        url: server + "/api.php/records/think_admin/" + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}

// 解除微信绑定
export function unbindUserById(data: any) {
    return request({
        //url: server + "/api/zuad/unbindUserById",
        url: server + "/api.php/records/think_admin/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 导入用户
export function labelUpUser(data: any) {
    return request({
        url: server + '/api/zuad/labelUpUser',
        method: 'post',
        data
    })
}
// 导出用户
export function labelDownUser(data: any) {
    return request({
        url: server + '/api/zuad/downloadUser',
        method: 'post',
        data
    })
}

// =========================================

// 获取邮件配置
export function getEmailConfig(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllConfig",
        url: server + '/api.php/records/think_variables?database=meeting_dingding',
        method: 'get',
        params: query
    })
}
// 获取全部配置
export function getAllConfig(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllConfig",
        url: server + '/apikey.php/records/think_config?database=zuad_common',
        method: 'get',
        params: query,
        headers: {
            'Content-Type': "application/json", // 关键
            "X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
        },
    })
}
// 获取邮箱全部配置
export function getAllEmailConfig(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllConfig",
        url: server + '/api.php/records/think_variables?database=meeting_dingding',
        method: 'get',
        params: query
    })
}
// 获取企业微信全部配置
export function getAllEnterWechatConfig(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllConfig",
        url: server + '/api.php/records/think_enter_wechat?database=meeting_dingding',
        method: 'get',
        params: query
    })
}
// 获取飞书全部配置
export function getAllFlyBookConfig(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllConfig",
        url: server + '/api.php/records/think_flyBook?database=meeting_dingding',
        method: 'get',
        params: query
    })
}
// 新增飞书配置
export function addFlyBookConfig(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_flyBook',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 删除飞书配置
export function delFlyBookConfig(data: any) {
    return request({
        //url: server + '/api/Zuad/delOrganization',
        url: server + "/api.php/records/think_flyBook/" + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑飞书配置
export function editFlyBookConfig(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_flyBook/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 获取微信小程序全部配置
export function getAllWechatConfig(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllConfig",
        url: server + '/api.php/records/think_wxapp?database=meeting_dingding',
        method: 'get',
        params: query
    })
}
// 新增配置
export function addWechatConfig(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_wxapp',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 获取微信小程序全部配置
export function getAllEnterWechatRoomConfig(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllConfig",
        url: server + '/api.php/records/think_enter_wechat_room?database=meeting_dingding',
        method: 'get',
        params: query
    })
}

// 编辑配置
export function editConfig(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_config/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 添加配置
export function addEnterWechatRoomConfig(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_enter_wechat_room',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 编辑配置
export function editEnterWechatRoomConfig(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_enter_wechat_room/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 新增配置
export function addConfig(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_config',
        method: 'post',
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 新增配置
export function addConfigs(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_configs',
        method: 'post',
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 编辑配置
export function editConfigs(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_configs/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 编辑配置
export function editConfigz(data: any) {
    return request({
        url: server + '/api.php/sendpost',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data', // 关键
        },
    })
}
// 新增配置
export function addEmailConfig(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_variables',
        method: 'post',
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 编辑邮箱配置
export function editEmailConfig(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_variables/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 编辑企业微信配置
export function editEnterWechatConfig(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_enter_wechat/' + data.wx_id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 新增企业微信配置
export function addEnterWechatConfig(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_enter_wechat',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 删除企业微信配置
export function delEnterWechatConfig(data: any) {
    return request({
        //url: server + '/api/Zuad/delOrganization',
        url: server + "/api.php/records/think_enter_wechat/" + data.wx_id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑小程序配置
export function editWechatConfig(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_wxapp/' + data.wxapp_id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 删除配置
export function delConfig(data: any) {
    return request({
        //url: server + '/api/Zuad/delOrganization',
        url: server + "/api.php/records/think_config/" + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// =========================================
// 查询钉钉同步用部门
export function getAllDingTalkDepartment(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_dingtalk_department?database=meeting_dingding',
        method: 'get',
        params: data
    })
}
// 新增outlook账号
export function addDingTalkDepartment(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_dingtalk_department',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 删除outlook账号
export function delDingTalkDepartment(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_dingtalk_department/' + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// =========================================
// 查询outlook账号
export function getAllOutlook(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_outlook_account?database=meeting_dingding',
        method: 'get',
        params: data
    })
}
// 新增outlook账号
export function addOutlook(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_outlook_account',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 删除outlook账号
export function delOutlook(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_outlook_account/' + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// =========================================
// 查询Teams账号
export function getAllTeams(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_teams_account?database=meeting_dingding',
        method: 'get',
        params: data
    })
}
// 新增Teams账号
export function addTeams(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_teams_account',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 删除Teams账号
export function delTeams(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_teams_account/' + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// =========================================
// 查询Zoom账号
export function getAllZoom(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_zoom_account?database=meeting_dingding',
        method: 'get',
        params: data
    })
}
// 新增Zoom账号
export function addZoom(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_zoom_account',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 删除Zoom账号
export function delZoom(data: any) {
    return request({
        //url: server+"/api/v5.entry/editConfig",
        url: server + '/api.php/records/think_zoom_account/' + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// =========================================
// 接口日志
export function getApiLogs(query: any) {
    return request({
        url: server + '/api.php/records/think_api_log?order=create_time,desc&order=time,desc&order=oid,asc&size=1000',
        method: 'get',
        params: query
    });
}
// 删除接口日志
export function delApiLogs(data: any) {
    return request({
        url: server + '/api.php/records/think_api_log',
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    });
}
// =========================================
// 会议签到
export function downMeetingSigns(query: any) {
    return request({
        //url: server+"/api/v5.entry/downMeetingSigns",
        url: server + '/api.php/records/think_entry?database=meeting_dingding&order=start_time,desc&join=think_entry_sign_in&join=think_room',
        method: 'get',
        params: query
    });
}
// 会议邮件回复
export function downMeetingSigns3(query: any) {
    return request({
        //url: server+"/api/v5.entry/downMeetingSigns",
        url: server + '/api.php/records/think_entry?database=meeting_dingding&order=start_time,desc&join=think_entry_return&join=think_room',
        method: 'get',
        params: query
    });
}
// 会议签到
export function downMeetingSigns2(query: any) {
    return request({
        //url: server+"/api/v5.entry/downMeetingSigns",
        url: server + '/api.php/records/think_entry?database=meeting_dingding&order=start_time,desc&join=think_entry_sign_in&join=think_room&join=think_entry_visitor_sign_in',
        method: 'get',
        params: query
    });
}
// 会议签到
export function getMeetingSigns(query: any) {
    return request({
        //url: server+"/api/v5.entry/downMeetingSigns",
        url: server + '/api.php/records/think_entry_sign_in?database=meeting_dingding',
        method: 'get',
        params: query
    });
}
// 添加会议签到记录
export function addMeetingSigns(data: any) {
    return request({
        //url: server+"/api/v5.entry/downMeetingSigns",
        url: server + '/api.php/records/think_entry_sign_in',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    });
}
// 添加会议签到记录
export function addMeetingVisitorSigns(data: any) {
    return request({
        //url: server+"/api/v5.entry/downMeetingSigns",
        url: server + '/api.php/records/think_entry_visitor_sign_in',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    });
}

// 删除会议签到记录
export function delMeetingSigns(data: any) {
    return request({
        //url: server+"/api/v5.entry/downMeetingSigns",
        url: server + '/api.php/records/think_entry_sign_in/' + data.id,
        method: on_off ? 'post' : "delete", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    });
}

// =========================================
// 行为日志
// 会议签到
export function downMeetingDecision(query: any) {
    return request({
        //url: server+"/api/v5.entry/downMeetingSigns",
        url: server + '/api.php/records/think_entry_decision?database=meeting_dingding&order=deadline_time,desc&join=think_entry_decision_user',
        method: 'get',
        params: query
    });
}
// =========================================
// 行为日志
export function getAllLogs(query: any) {
    return request({
        //url: server+"/api/v5.entry/getAllLogs",
        url: server + '/api.php/records/think_log?database=meeting_dingding&order=add_time,desc&limit=1000',
        method: 'get',
        params: query
    })
}
// =========================================
// 获取系统信息
export function getSystemInfo(data: any) {
    return request({
        //url: server + '/api/Zuad/getSystemInfo',
        url: server + '/api.php/records/think_config?database=zuad_common',
        method: 'get',
        params: data
    })
}
// 获取组织能使用哪些项目
export function getSystemModelInfo(data: any) {
    return request({
        url: server + '/api/Zuad/getSystemModelInfo',
        method: 'get',
        params: data
    })
}
// =========================================
// 获取事件
export function getAllEvent(data: any) {
    return request({
        //url: server + '/api/Zuad/getAllEvent',
        url: server + '/api.php/records/think_event?database=zuad_common&order=create_time,desc&join=think_event_handle&size=1000',
        method: 'get',
        params: data
    })
}
// 获取事件-条件搜索
export function getEventBySearch(data: any) {
    return request({
        //url: server + '/api/Zuad/getEventBySearch',
        url: server + '/api.php/records/think_event?database=zuad_common&order=create_time,desc&join=think_event_handle&size=1000',
        method: 'get',
        params: data
    })
}

// 获取事件
export function getAllEvents(data: any) {
    return request({
        //url: server + '/api/Zuad/getAllEvent',
        url: server + '/api.php/records/think_event?database=zuad_common&size=20&order=create_time,desc',
        method: 'get',
        params: data
    })
}
// 添加事件
export function addEvent(data: any) {
    return requests({
        //url: server + '/api/Zuad/addEvent',
        url: server + '/api.php/records/think_event',
        method: 'post',
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 修改事件
export function editEvent(data: any) {
    return request({
        //url: server + '/api/Zuad/editEvent',
        url: server + '/api.php/records/think_event/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 删除事件
export function delEvent(data: any) {
    return request({
        //url: server + '/api/Zuad/delEvent',

        url: server + '/api.php/records/think_event/' + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 事件二次处理
export function doSetEvent(data: any) {
    return request({
        //url: server + '/api/Zuad/doSetEvent',
        url: server + '/api.php/records/think_event_handle',
        method: 'post',
        data,
        params: {
            database: "zuad_common"
        }
    })
}

// =========================================
// 获取通知
export function getAllNotice(data: any) {
    return request({
        //url: server + '/api/Zuad/getAllNotice',
        url: server + '/api.php/records/think_notice?database=zuad_common&order=create_time,desc&size=1000',
        method: 'get',
        params: data
    })
}
// 获取通知-条件搜索
export function getNoticeBySearch(data: any) {
    return request({
        //url: server + '/api/Zuad/getNoticeBySearch',
        url: server + '/api.php/records/think_notice?database=zuad_common&order=create_time,desc&size=1000',
        method: 'get',
        params: data
    })
}
// 添加通知
export function addNotice(data: any) {
    return request({
        //url: server + '/api/Zuad/addNotice',
        url: server + '/api.php/records/think_notice',
        method: 'post',
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 修改通知
export function editNotice(data: any) {
    return request({
        //url: server + '/api/Zuad/editNotice',
        url: server + '/api.php/records/think_notice/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}
// 删除通知
export function delNotice(data: any) {
    return request({
        url: server + '/api.php/records/think_notice/' + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}


// =========================================
// 获取报修
export function getAllRepair(data: any) {
    return request({
        //url: server + '/api/Zuad/getAllRepair',
        url: server + '/api.php/records/think_repair?database=meeting_dingding&join=think_room&order=create_time,desc',
        method: 'get',
        params: data
    })
}
// 获取报修-条件搜索
export function getRepairBySearch(data: any) {
    return request({
        //url: server + '/api/Zuad/getRepairBySearch',
        url: server + '/api.php/records/think_repair?database=meeting_dingding&join=think_room',
        method: 'get',
        params: data
    })
}
// 添加报修
export function addRepair(data: any) {
    return request({
        //url: server + '/api/Zuad/addRepair',
        url: server + '/api.php/records/think_repair',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 修改报修
export function editRepair(data: any) {
    return request({
        //url: server + '/api/Zuad/editRepair',
        url: server + '/api.php/records/think_repair/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// 删除报修
export function delRepair(data: any) {
    return request({
        //url: server + '/api/Zuad/delNotice',
        url: server + '/api.php/records/think_repair/' + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}
// =========================================
// 上传文件
export function upload(data: any) {
    return request({
        url: server + '/api.php/uploadMaterialFile',
        method: 'post',
        data: data
    })
}
// 上传文件
export function upload2(data: any) {
    return request({
        url: server + '/api.php/uploadValiCompFile',
        method: 'post',
        data: data
    })
}

// =========================================
// 获取墨水屏模板列表-条件搜索
export function getInkscreenTemplateBySearch(data: any) {
    return request({
        //url: server + "/api/v5.entry/getInkscreenTemplateBySearch",
        url: server + '/api.php/records/think_template?database=meeting_dingding',
        method: 'get',
        params: data
    })
}

// 添加墨水屏模板
export function inkscreenTemplateAdd(data: any) {
    return request({
        //url: server + '/api/v5.entry/inkscreenTemplateAdd',
        url: server + '/api.php/records/think_template',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 获取指定模板数据
export function getInkscreenTemplateByKey(id: any) {
    return request({
        //url: server + "/api/v5.entry/getInkscreenTemplateByKey",
        url: server + '/api.php/records/think_template/' + id,
        method: 'get',
        params: { database: 'meeting_dingding' },
    })
}

// 编辑墨水屏模板
export function inkscreenTemplateEdit(id: any, data: any) {
    return request({
        //url: server + '/api/v5.entry/inkscreenTemplateEdit',
        url: server + '/api.php/records/think_template/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'meeting_dingding' },
        data,
    })
}

// 删除墨水屏模板
export function inkscreenTemplateDel(data: any) {
    return request({
        //url: server + '/api/v5.entry/inkscreenTemplateDel',
        url: server + '/api.php/records/think_template/' + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 获取墨水屏列表-条件搜索
export function getInkscreenBySearch(data: any) {
    return request({
        //url: server + "/api/v5.entry/getInkscreenBySearch",
        url: server + '/api.php/records/think_inkscreen?database=meeting_dingding',
        method: 'get',
        params: data
    })
}

// 获取墨水屏-会议室
export function getInkscreenRoomAll(query: any) {
    return request({
        //url: server + "/api/v5.entry/getInkscreenRoomAll",
        url: server + '/api.php/records/think_inkscreen?database=meeting_dingding',
        method: 'post',
        params: query
    })
}

// 添加墨水屏
export function inkscreenAdd(data: any) {
    return request({
        //url: server + '/api/v5.entry/inkscreenAdd',
        url: server + '/api.php/records/think_inkscreen',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑墨水屏
export function inkscreenEdit(data: any) {
    return request({
        //url: server + '/api/v5.entry/inkscreenEdit',
        url: server + '/api.php/records/think_inkscreen/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 删除墨水屏
export function inkscreenDel(data: any) {
    return request({
        //url: server + '/api/v5.entry/inkscreenDel',
        url: server + '/api.php/records/think_inkscreen/' + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 获取墨水屏刷新记录
export function getAllInkScreenRefreshRecord(query: any) {
    return request({
        url: server + '/api.php/records/think_event',
        method: 'get',
        params: { ...query, database: "zuad_common" }
    })
}

// 根据条件获取墨水屏
export function getAllInkscreen(query: any) {
    return request({
        url: server + '/api.php/records/think_inkscreen',
        method: "get",
        params: { ...query, database: 'meeting_dingding' },
    })
}

// 获取墨水屏刷新记录
export function getAllTestInkScreenRefreshRecord(query: any) {
    return request({
        url: server + '/api.php/records/think_inkscreen_test_refresh',
        method: 'get',
        params: { ...query, database: "meeting_dingding" }
    })
}

// 删除墨水屏刷新记录
export function deleteTestInkScreenRefreshRecord(data: any) {
    return request({
        url: server + '/api.php/records/think_inkscreen_test_refresh/' + data.id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: { database: "meeting_dingding" }
    })
}

// =========================================
// 获取外部人员
export function getOutside(query: any) {
    return request({
        //url: server + "/api/v5.entry/getInkscreenRoomAll",
        url: server + '/api.php/records/think_outside?database=meeting_dingding',
        method: 'get',
        params: query
    })
}

// 添加外部人员
export function outsideAdd(data: any) {
    return request({
        //url: server + '/api/v5.entry/inkscreenAdd',
        url: server + '/api.php/records/think_outside',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 编辑外部人员
export function outsideEdit(data: any) {
    return request({
        //url: server + '/api/v5.entry/inkscreenEdit',
        url: server + '/api.php/records/think_outside/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}


export function addLabelTask(data: any) {
    return request({
        //url: server + '/api/v5.entry/inkscreenAdd',
        url: server + '/api.php/records/think_lable_task',
        method: 'post',
        data,
        params: {
            database: "meeting_dingding"
        }
    })
}

// 控制日志|事件中心
export function addCloudLog(data: any) {
    const logInfo = {
        name: '', //事件名称
        desc: '', //描述
        type: 1, //事件类型，1通用，2预警，3告警
        model: 2, //关联模块，包含1信发，2会议，3中控，4能耗，5墨水屏，6无纸化
        create_time: Math.round(Date.now() / 1000), //创建时间
        creator: 0, //创建人
        org_num: 999999,
        oid: uuid2(32, 16),
        status: 0,//事件状态，0正常，1失败
        sub_type: 0,//事件子类型 0:默认 1:会议结束控制设备事件 2:会议开始控制设备事件3:人体感应联动事件4:设备按键事件 5:中控控制事件 6:管理员控制设备事件 7:场景控制设备事件 8:情景控制设备事件 9:普通设备联动 10:自动化场景控制 11:自动化情景控制
    }
    return request({
        url: server + '/api.php/records/think_event',
        method: "post",
        data: { ...logInfo, ...data },
        params: { database: 'zuad_common' }
    })
}