let Config: any = {
    API_BATCH_SIZE: 500, // 表示 API 每批次处理的数量
};

if (window.zuadConfig) {
    Config = {
        ...Config,
        ...window.zuadConfig
    }
}

export default Config;
