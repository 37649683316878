import { Tabs, TabsProps } from "antd";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../components/bootstrap/Breadcrumb";
import Card from '../../components/bootstrap/Card';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from "../../layout/SubHeader/SubHeader";
import BackupsRecordPage from "./BackupsRecordPage";
import DatabaseOperationsPage from "./DatabaseOperationsPage";
import UpdateSystemPage from "./UpdateSystemPage";


const SystemPage = () => {
	//多语言
	const { t } = useTranslation('common' || 'menu');
	const items: TabsProps['items'] = [
		{
			key: '1',
			label: (
				<span>
					{t('Svu')}
				</span>
			),
			children: (
				<UpdateSystemPage />
			),
		},
		{
			key: '2',
			label: (
				<span>
					{t('DL')}
				</span>
			),
			children: (
				<DatabaseOperationsPage />
			),
		},
		{
			key: '3',
			label: (
				<span>
					{t('Database backup records')}
				</span>
			),
			children: (
				<BackupsRecordPage />
			),
		},
	];

	return (
		<PageWrapper >
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ title: t('System Maintenance'), to: '' }
						]}
					/>
				</SubHeaderLeft>
			</SubHeader>
			<Page container="fluid">

				<Card stretch data-tour="list">
					<div id='bootstrap' style={{
						padding: "2%"
					}}>
						<Tabs
							defaultActiveKey="1"
							items={items}
						/>
					</div>
				</Card>
			</Page>
		</PageWrapper>
	);
};

export default SystemPage;
