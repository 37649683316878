import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { HashRouter } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import { Provider } from 'react-redux';
import store from './store';
import { initializeI18n } from './i18n';
import './table.css';
import zhCN from 'antd/locale/zh_CN'
import enUS from 'antd/locale/en_US';
import zhTW from 'antd/locale/zh_TW';
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/zh-tw'
import 'dayjs/locale/en';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';
import { ConfigProvider } from 'antd';
import Config from "./config";
import { getStorages } from "./utils/localStorage";
import { getStorage } from './utils/sessionStorage';

// ��ʼ�� i18n
initializeI18n(Config.defaultLanguage);

let code = '';
var url: any = window.location.href;
var querys: any = url.substring(url.indexOf('?') + 1).split('&');

for (var i = 0; i < querys.length; i++) {
	var temp = querys[i].split('=');
	if (temp.length < 2) {

	}
	else {
		if (temp[0] == 'code') {

			code = temp[1];

		}
	}
}

if (Config.sso_url != undefined && Config.sso_url != "" && (code == undefined || code == '') && (getStorages('callbackCode') == undefined || getStorage('callbackCode') == '') && url.indexOf('auth-pages/login?check=123&type=login') == -1 && url.indexOf('check-in?type=login') == -1) {

	window.location.href = Config.sso_url;

}

// antd 中英文切换
const language = localStorage.getItem('i18nextLng');
let languageCode
switch (language) {
	case "en_US":
		languageCode = enUS;
		dayjs.locale('en');
		moment.locale('en');
		break
	case "zh_HK":
		languageCode = zhTW;
		dayjs.locale('zh-tw');
		moment.locale('zh-tw');
		break
	default:
		languageCode = zhCN
		dayjs.locale('zh-cn');
		moment.locale('zh-cn');
}

const children = (
	<Provider store={store}>
		<AuthContextProvider>
			<ThemeContextProvider>
				<HashRouter>
					{/* <React.StrictMode> */}
					<ConfigProvider locale={languageCode}>
						<App/>
					</ConfigProvider>
					{/* </React.StrictMode> */}
				</HashRouter>
			</ThemeContextProvider>
		</AuthContextProvider>
	</Provider>
);


const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
