export function getStorages(key: string):string {
    return localStorage.getItem(key) || ''
}
export function setStorages(key: string, value: string):void {
    localStorage.setItem(key, value);
}
export function removeStorages(key: string):void {
    localStorage.removeItem(key);
}
export function clearStorages():void {
    localStorage.clear();
}
