import { useFormik } from 'formik';
import moment from 'moment';
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	addIssue,
	addMedia,
	delIssue,
	editIssue,
	editMeeting,
	getMeetingByKey,
	getRoomsBySearch,
	getUserBySearch,
	uploadFile
} from '../../../../api/paperless';
import Buttons from '../../../../components/bootstrap/Buttons';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle
} from '../../../../components/bootstrap/Card';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Select from '../../../../components/bootstrap/forms/Select';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../../components/bootstrap/OffCanvas';
import Option from '../../../../components/bootstrap/Option';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import { abnormalPrompt, curTimeStamp, uuid2 } from '../../../../utils/index';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import showNotification from '../../../../components/extras/showNotification';
import useDarkMode from '../../../../hooks/useDarkMode';
import { Button, Modal as Modals, Spin, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { Add } from '../../../../components/icon/material-icons';
import AuthContext from '../../../../contexts/authContext';
import "./style.css";



const MeetingDetail = () => {
	/**
	 * For Tour
	 */
	// useTourStep(6);
	const { themeStatus } = useDarkMode();

	//多语言
	const { t } = useTranslation('common');
	
	// 用户信息
	const { userData, setUser } = useContext(AuthContext);

	// 会议状态
	const [controlType, setControlType] = useState(false);


	// 设备表单(新增|编辑)
	interface InitialEventItem {
		id: number;
		oid: string;
		name: string;
		reactor: string;
		type: string;
		status: string;
		description: string;
		room_id: string;
		room_name: string;
		address: string;
		title: string;
		control_type: any,
		control_types: any;
		meeting_rank: any;
		start_time: string;
		end_time: string;
		document_watermark: any;
		date: string;
		enforce_signature: any;
		meeting_issue:any,
		use_by: any,
	}
	const formFields: InitialEventItem = {
		id: 0,
		oid: '',
		name: '',
		room_id: '',
		room_name:'',
		reactor: '',
		type: '',
		description: '',
		status: '',
		address: '',
		title: '',
		control_type: 4,
		control_types: 2,
		use_by: [],
		meeting_rank: 0,
		start_time: '',
		end_time: '',
		document_watermark: 0,
		date: '',
		enforce_signature: '',
		meeting_issue:'',
	};
	const [eventItem, setEventItem] = useState<InitialEventItem>(formFields);

	// 弹窗
	const [toggleInfoEventCanvas, setToggleInfoEventCanvas] = useState(false);
	const setInfoEvent = () => setToggleInfoEventCanvas(!toggleInfoEventCanvas);

	// 会议
	const [meeting, setMeeting] = useState([]);

    // 表格
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    // const {items, requestSort, getClassNamesFor} = useSortableData(meeting);
    // const onCurrentPageItems = dataPagination(items, currentPage, perPage);
    // const {selectTable, SelectAllCheck} = useSelectTable3(onCurrentPageItems);

	

	//弹窗
	const [toggleInfoEventCanvasMeeting, setToggleInfoEventCanvasMeeting] = useState(false);
	const setInfoEventMeeting = () => setToggleInfoEventCanvasMeeting(!toggleInfoEventCanvasMeeting);

	//  编辑会议
	const [eventMeeting, setMeetings] = useState(false);
	const handleMeetingSelect = () => {
		setMeetings(true);
		setToggleInfoEventCanvasMeeting(true);
		//setEventItem(formFields);
		editClick(formik.values);
	}
	//const handleApkSelect = () => {
	//	setApkStyle(true);
	//	setToggleInfoEventCanvasApk(true);
	//	//setEventItem(formFields);
	//	editClick(formik.values);
	//};


	// 新增|编辑
	const [eventAdding, setEventAdding] = useState(false);

	const formik = useFormik({
		initialValues: {
			id: 0,
			oid: '',
			name: '',
			room_id: '',
			room_name:'',
			reactor: '',
			type: '',
			description: '',
			status: '',
			address: '',
			meeting_issue:[],
			title: '',
			control_type: 4,
			control_types: '2',
			control_name:'',
			use_by: [],
		    meeting_rank: '',
			document_watermark: '',	
			enforce_signature: '',
			start_times: "",
			start_time: "",
			end_times: "",
			end_time: "",
			date: moment().format('YYYY-MM-DD HH:mm:ss'),// 当天日期
		},
		onSubmit: (values) => {
			editMeeting({
				id: values.id,
				name: values.name,
				title: values.title,
				description: values.description,
				start_time: parseInt((new Date(values.start_time).getTime() / 1000).toString()),
				end_time: parseInt((new Date(values.end_time).getTime() / 1000).toString()),
				end_time1: parseInt((new Date(values.end_time).getTime() / 1000).toString()),
				room_id: values.room_id,
				type: values.type,
				enforce_signature: values.enforce_signature,
				document_watermark: values.document_watermark,
				status: 0,
				address: values.address,
				control_type: values.control_type,
				control_types: values.control_types,
				meeting_rank: values.meeting_rank,
			}).then((response: any) => {

				let listz: any = titleList;

				title.map((itii: any, iind: any) => {

					if (itii.id == 0 && itii.name.length > 0) {
						addIssue({
							name: itii.name,
							vote: itii.check ? 1 : 0,
							meeting_id: values.id
						}).then((response5: any) => {


						}).catch((error) => {
							console.error(error)
							showNotification(
								'错误', // String, HTML or Component
								error.message, // String, HTML or Component
								'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
							);
						});

					} else {

						listz.map((iit2: any, inde2: any) => {

							if (iit2.id == itii.id) {

								editIssue(JSON.stringify({
									id: itii.id,
									name: itii.name,
									vote: itii.check ? 1 : 0
								})).then((response5: any) => {

									listz.splice(inde2, 1);

									if (iind == title.length - 1 && inde2 == listz.length - 1) {

										if (listz.length > 0) {

											let iids: any = [];

											listz.map((iit3: any, inde3: any) => {
												iids.push(iit3.id);
											});

											delIssue({
												id: iids.join(',')
											}).then((response8: any) => {

											}).catch((error) => {
												console.error(error)
												showNotification(
													'错误', // String, HTML or Component
													error.message, // String, HTML or Component
													'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
												);
											})

										}

									}

								}).catch((error) => {
									console.error(error)
									showNotification(
										'错误', // String, HTML or Component
										error.message, // String, HTML or Component
										'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
									);
								});



							}

						});

					}

				});

			}).catch((error) => {
				console.error(error)
				showNotification(
					'错误', // String, HTML or Component
					error.message, // String, HTML or Component
					'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
				);
			})
		},
	});

	function timeout(delay: number) {
		return new Promise(res => setTimeout(res, delay));
	}

	const onSubmit = () => {
		let values: any = formik.values;

		setLoading(true);

		editMeeting({
			id: values.id,
			name: values.name,
			title: values.title,
			description: values.description,
			start_time: parseInt((new Date(values.start_time).getTime() / 1000).toString()),
			end_time: parseInt((new Date(values.end_time).getTime() / 1000).toString()),
			end_time1: parseInt((new Date(values.end_time).getTime() / 1000).toString()),
			room_id: values.room_id,
			type: values.type,
			enforce_signature: values.enforce_signature,
			document_watermark: values.document_watermark,
			status: 0,
			address: values.address,
			control_type: values.control_type,
			control_types: values.control_types,
			meeting_rank: values.meeting_rank,
		}).then(async (response: any) => {

			let listz: any = titleList;

			title.reverse();

			let fileCount = title.length;

			let text: any = "";

			while (fileCount > 0) {

				try {

					if (text == "") {

						let fileInfo: any = title[fileCount - 1];

						text = "123214";

						if (fileInfo.id == 0 && fileInfo.name.length > 0) {

							addIssue({
								name: fileInfo.name,
								vote: fileInfo.check ? 1 : 0,
								meeting_id: values.id
							}).then((response5: any) => {

								text = "";

								fileCount = fileCount - 1;

								if (fileCount == 0) {

									if (listz.length > 0) {

										let iids: any = [];

										listz.map((iit3: any, inde3: any) => {
											iids.push(iit3.id);
										});

										delIssue({
											id: iids.join(',')
										}).then((response8: any) => {

											showNotification(
												'提示', // String, HTML or Component
												"编辑成功", // String, HTML or Component
												'success' // 'default' || 'info' || 'warning' || 'success' || 'danger',
											);

											setTitles(deepCopyObj([{ id: 0, name: "", check: false }]));

											setToggleInfoEventCanvasMeeting(false);
											setTitleList([]);
											getMeetingInfo();
											setLoading(false);

											text = "";

											fileCount = 0;

										}).catch((error) => {
											console.error(error)
											showNotification(
												'错误', // String, HTML or Component
												error.message, // String, HTML or Component
												'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
											);
										})

									} else {
										console.log(5);
										showNotification(
											'提示', // String, HTML or Component
											"编辑成功", // String, HTML or Component
											'success' // 'default' || 'info' || 'warning' || 'success' || 'danger',
										);

										setTitles(deepCopyObj([{ id: 0, name: "", check: false }]));

										setToggleInfoEventCanvasMeeting(false);

										getMeetingInfo();

										setLoading(false);

										text = "";

										fileCount = 0;
									}

								}

							}).catch((error) => {
								console.error(error)
								showNotification(
									'错误', // String, HTML or Component
									error.message, // String, HTML or Component
									'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
								);
							});

						} else {

							let leng = listz.length;

							if (leng > 0) {

								listz.map((iit2: any, inde2: any) => {

									if (iit2.id == fileInfo.id) {

										editIssue(JSON.stringify({
											id: fileInfo.id,
											name: fileInfo.name,
											vote: fileInfo.check ? 1 : 0
										})).then((response5: any) => {

											listz.splice(inde2, 1);

											text = "";

											fileCount = fileCount - 1;

											if (fileCount == 0) {

												if (listz.length > 0) {

													let iids: any = [];

													listz.map((iit3: any, inde3: any) => {
														iids.push(iit3.id);
													});

													delIssue({
														id: iids.join(',')
													}).then((response8: any) => {

														showNotification(
															'提示', // String, HTML or Component
															"编辑成功", // String, HTML or Component
															'success' // 'default' || 'info' || 'warning' || 'success' || 'danger',
														);

														setTitles(deepCopyObj([{ id: 0, name: "", check: false }]));

														setToggleInfoEventCanvasMeeting(false);

														getMeetingInfo();

														setLoading(false);

														text = "";

														fileCount = 0;

													}).catch((error) => {
														console.error(error)
														showNotification(
															'错误', // String, HTML or Component
															error.message, // String, HTML or Component
															'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
														);
													})

												} else {
													console.log(5);
													showNotification(
														'提示', // String, HTML or Component
														"编辑成功", // String, HTML or Component
														'success' // 'default' || 'info' || 'warning' || 'success' || 'danger',
													);

													setTitles(deepCopyObj([{ id: 0, name: "", check: false }]));

													setToggleInfoEventCanvasMeeting(false);
													setTitleList([]);
													getMeetingInfo();

													setLoading(false);
													text = "";

													fileCount = 0;
												}

											}

										}).catch((error) => {
											console.error(error)
											showNotification(
												'错误', // String, HTML or Component
												error.message, // String, HTML or Component
												'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
											);
										});

									}

								});

							} else {
								text = "";

								fileCount = fileCount - 1;

								if (fileCount == 0) {

									setTitles(deepCopyObj([{ id: 0, name: "", check: false }]));

									setToggleInfoEventCanvasMeeting(false);

									getMeetingInfo();
									setLoading(false);
									break;

								}

							}

						}

						await timeout(1000);

					}

				} catch (err: any) {

					text = "";

					fileCount = 0;
					setLoading(false);

					showNotification(
						'提示', // String, HTML or Component
						"编辑保存失败！", // String, HTML or Component
						'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
					);
					break;
				}

			}

		}).catch((error) => {
			console.error(error)
			showNotification(
				'错误', // String, HTML or Component
				error.message, // String, HTML or Component
				'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
			);
		})
	};

	// 用户列表
	const [users, setAllUsers] = useState([]);


	// 会议室列表
	const [rooms, setAllRooms] = useState([]);

	const [flag, setFlag] = useState(false);

	const getAllRoomsData = useCallback((key?: string) => {
		let arr:any = [];
		if(userData.id!="1"){
			arr.push(`org_num,eq,${userData.orgNum}`);
		}
		if(key){
			arr.push(`roomName,cs,${key}`);
		}
		getRoomsBySearch({
			filter:arr
		}).then((response: any) => {

			setAllRooms(response.records);

		}).catch((error) => {
			console.error(error)
			showNotification(
				'错误', // String, HTML or Component
				error.message, // String, HTML or Component
				'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
			);
		});
		
	}, [userData]);
	useEffect(() => {
		getAllRoomsData()
	}, [getAllRoomsData])

	// 会议列表
	const getAllMeetingsData = useCallback((key?: string) => {
		//let arr:any = [];
		//if(userData.id!="1"){
		//	arr.push(`org_num,eq,${userData.orgNum}`);
		//}
		//if(key){
		//	arr.push(`name,cs,${key}`);
		//}
		//getMeetingsBySearch({
		//	filter:arr
		//}).then((response: any) => {
		//	setAllMeetings(response.records);

		//}).catch((error) => {
		//	console.error(error)
		//	showNotification(
		//		'错误', // String, HTML or Component
		//		error.message, // String, HTML or Component
		//		'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
		//	);
		//});
	}, [userData]);
	useEffect(() => {
		getAllMeetingsData()
	}, [getAllMeetingsData])

	useEffect(() => {
		if (eventAdding) {
			setInfoEvent();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventAdding]);

	useEffect(() => {
		if (eventItem) {
			formik.setValues({
				...formik.values,
				// @ts-ignore
				id: eventItem.id || 0,
				oid: eventItem.oid || '',
				name: eventItem.name || '',
				use_by: eventItem.use_by || [],
				type: eventItem.type || '',
				room_id: eventItem.room_id || '',
				room_name:eventItem.room_name || '',
				reactor: eventItem.reactor || '',
				description: eventItem.description || '',
				status: eventItem.status || '',
				address: eventItem.address || '',
				title: eventItem.title || '',
				control_type: eventItem.control_type || 4,
				control_types: eventItem.control_types || 2,
				meeting_rank: eventItem.meeting_rank || '',
				meeting_issue: eventItem.meeting_issue || [],
				start_time: eventItem.start_time || '',
				end_time: eventItem.end_time || '',
				document_watermark: eventItem.document_watermark || '',
				date: eventItem.date || '',
				enforce_signature: eventItem.enforce_signature || '',
			});
		}
		return () => { };
		//	eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventItem]);


	const editClick = (i: any) => {

		const obj1: InitialEventItem = {
			id: i.id,
			oid: i.oid,
			name: i.name,
			type: i.type,
			use_by:  [],
			room_id: i.room_id,
			room_name: i.room_name,
			reactor: i.reactor,
			description: i.description,
			meeting_issue: i.meeting_issue,
			status: i.status,
			address: i.address,
			title: i.title,
			control_type: i.control_type,
			control_types: i.control_types,
			meeting_rank: i.meeting_rank.toString(),
			start_time: i.start_time,
			end_time: i.end_time,
			document_watermark: i.document_watermark.toString(),
			date: moment(i.date).format(), 
			enforce_signature: i.enforce_signature.toString(),	
		}
		if (i.meeting_issue.length > 0) {

			setTitleList(i.meeting_issue);

			let arra: any = deepCopyObj(title);

			i.meeting_issue.map((iiet: any, idi: any) => {
				arra[idi] = { id: iiet.id, name: iiet.name, check: iiet.vote == 1 ? true : false };
			});

			setTitles(arra);

		} else {

			setTitleList([]);

			let arra: any = deepCopyObj([{ id: 0, name: "", check: false }]);

			setTitles(arra);

		}
		setInfoEvent();
		setEventItem(obj1);
	}

	const { id } = useParams();

	const getMeetingInfo = useCallback(() => {

		let arra: any = [];
		if (userData.id != "1") {
			arra.push(`org_num,eq,${userData.orgNum}`);
		}
		getUserBySearch({
			filter: arra
		}).then((response: any) => {
			let resultData = response.records;
			if (!resultData) {
				resultData = []
			}
			setAllUsers(resultData);

			getMeetingByKey({
				filter: [`id,eq,${id}`]
			}).then((response2: any) => {

				let meetingInfo: any = response2.records[0];

				if(parseInt(meetingInfo.control_type) == 4){
					setControlType(false)
				}else{
					setControlType(true)
				}

				setMeeting(meetingInfo);

				let control_name: any = "";

				if (meetingInfo.control_types == 2 && meetingInfo.think_entry_sign_in.length > 0) {

					meetingInfo.think_entry_sign_in.map((item5: any) => {

						if (item5.secretary_code == 1) {

							resultData.map((item6: any) => {

								if (item5.user_id == item6.id) {

									control_name = item6.real_name;

								}

							});

						}

					});

				}

				formik.setValues({
					...formik.values,
					id: meetingInfo.id,
					oid: meetingInfo.oid,
					name: meetingInfo.name,
					type: meetingInfo.type,
					use_by: meetingInfo.use_by?.split(','),
					room_id: meetingInfo.room_id.id,
					room_name: meetingInfo.room_id.room_name,
					reactor: meetingInfo.reactor,
					description: meetingInfo.description,
					meeting_issue: meetingInfo.think_issue,
					status: meetingInfo.status,
					address: meetingInfo.address,
					title: meetingInfo.title,
					control_type: meetingInfo.control_type,
					control_types: meetingInfo.control_types,
					control_name: control_name,
					meeting_rank: meetingInfo.meeting_rank,
					start_times: moment(meetingInfo.start_time * 1000).format('YYYY年MM月DD日 HH:mm'),
					start_time: moment(meetingInfo.start_time * 1000).format('YYYY-MM-DD HH:mm'),
					end_times: moment(meetingInfo.end_time * 1000).format('YYYY年MM月DD日 HH:mm'),
					end_time: moment(meetingInfo.end_time * 1000).format('YYYY-MM-DD HH:mm'),
					document_watermark: meetingInfo.document_watermark,
					date: moment(meetingInfo.date).format(),
					enforce_signature: meetingInfo.enforce_signature,
				});

			}).catch((error) => {
				console.error(error)
				showNotification(
					'错误', // String, HTML or Component
					error.message, // String, HTML or Component
					'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
				);
			});
		}).catch((error) => {
			console.error(error)
			showNotification(
				'错误', // String, HTML or Component
				error.message, // String, HTML or Component
				'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
			);
		})
	}, [userData]);

	useEffect(() => {

		getMeetingInfo();
		
	}, []);
	
	let [title, setTitles] = useState<any>([{ id: 0, name: "", check: false }]);

	let [titleList, setTitleList] = useState<any>([]);

	const [divkey, setDivkey] = useState<any>(Math.random());

	const titleChange = (obj: any, indexx: any) => {

		let titles: any = title;

		titles[indexx].name = obj.target.value;

		setTitles(deepCopyObj(titles));

	}

	const titleChanges = (obj2: any, indexx2: any) => {
		console.log(obj2);
		let titles2: any = title;

		titles2[indexx2].check = obj2.target.checked;

		setTitles(deepCopyObj(titles2));
	}

	const deepCopyObj = (obj: any) => { //对象及数组深拷贝
		if (Object.prototype.toString.call(obj) == '[object Object]') {
			var newObj: any = {};
			for (var key in obj) {
				if (Object.prototype.toString.call(obj[key]) == '[object Object]') {
					var newChildObj = deepCopyObj(obj[key]);
					newObj[key] = newChildObj;
				} else {
					newObj[key] = obj[key];
				}
			}
			return newObj;
		} else if (Object.prototype.toString.call(obj) == '[object Array]') {
			let temp: any = []
			obj.forEach((item5: any) => {
				let map;
				map = deepCopyObj(item5)
				temp.push(map);
			})
			return temp;
		} else {
			return obj;
		}

	}

	const addElement = () => {

		let arra: any = deepCopyObj(title);

		arra.push({ id: 0, name: "", check: false });

		setTitles(arra);

	}
	const delElement = () => {

		let arra: any = deepCopyObj(title);

		arra.pop();

		setTitles(arra);

	}

	const inputFile = useRef<HTMLInputElement>(null);

	// 效验文件合法性
	const fileType = function (file: any) {
		console.log(file.type);
		const pictureExt = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
		const videoFrequencyExt = ['video/mp4', 'video/rmvb', 'video/mkv'];
		const fileExt = ['.doc', '.pdf', '.xls', '.xlsx', '.docx', '.ppt', '.pptx', 'application/vnd.ms-powerpoint', 'application/msword', 'application/vnd.ms-excel', 'text/plain', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'];

		if (pictureExt.includes(file.type)) {
			//判断文件大小
			if (file.size > 600000000) {
				return '文件太大了';
			}

			return file.type;
		} else if (fileExt.includes(file.type)) {
			//判断文件大小
			if (file.size > 600000000) {
				return '文件太大了';
			}
			return file.type;
		} else if (videoFrequencyExt.includes(file.type)) {
			//判断文件大小
			if (file.size > 600000000) {
				return '文件太大了';
			}

			return file.type;
		} else {
			//return abnormalPrompt('文件不支持！', 'warning');
			return '';
		}
	};
	//部门操作
	const [windowCode, setWindowCode] = useState<any>(false);

	const handleChangeFile = (event: any) => {
		//const files = event.target.files
		setWindowCode(true);
		let file = event.file;

		if (event.file.uid == event.fileList[event.fileList.length - 1].uid) {

			let issueName = event.file.originFileObj.webkitRelativePath.split('/')[0];

			for (let ii = 0; ii < event.fileList.length; ii++) {

				let files = event.fileList[ii];
				console.log(files);
				let img = fileType(files);

				if (img == '') {

					return abnormalPrompt('不支持上传 ' + files.name + ' ！', 'warning');

					break;

				} else if (img == '文件太大了') {

					return abnormalPrompt('上传文件的大小不能超出550MB！', 'warning')

					break;
				}

			}

			addIssue({
				name: issueName,
				vote: 0,
				meeting_id: id
			}).then((response6: any) => {

				let array3: any = [];
				
				for (let ii = 0; ii < event.fileList.length; ii++) {

					let files = event.fileList[ii].originFileObj;

					let formData: any = new FormData();
					console.log(files);
					formData.append('AllFile[]', files);

					uploadFile(formData).then((e: any) => {

							if (e.resultData) {

								let fileInfo = e.resultData[0];

								addMedia(JSON.stringify({
									oid: uuid2(32, 16),
									sourceName: fileInfo.sourceName,
									encryptionName: fileInfo.encryptionName,
									recordStart: fileInfo.recordStart,
									fileMd5: fileInfo.fileMd5,
									fileSize: fileInfo.fileSize,
									fileSourceType: fileInfo.fileSourceType,
									uid: userData.id,
									org_num: userData.orgNum,
									grouping_id:  999999999,
									create_time: curTimeStamp(),
									last_time: curTimeStamp(),
								})).then((response3: any) => {

									let newfile = response3;

									array3.push(newfile);

									if (ii == event.fileList.length - 1) {

										setWindowCode(false);

										editIssue(JSON.stringify({
											id: response6,
											file: array3.join(",")
										})).then((response4: any) => {

											showNotification(
												'提示', // String, HTML or Component
												"上传成功", // String, HTML or Component
												'success' // 'default' || 'info' || 'warning' || 'success' || 'danger',
											);

											setTitles(deepCopyObj([{ id: 0, name: "", check: false }]));
											getMeetingInfo();

										}).catch((error) => {
											console.error(error);
										});

									}

								}).catch((error) => {
									console.error(error);
								});

							} else {

								return abnormalPrompt(files.name + ' 文件上传失败！', 'danger');

							}
					}).catch((error) => {
						console.error(error);
						return abnormalPrompt(error.message, 'danger');
					});

                }

			}).catch((error) => {
				console.error(error)
				showNotification(
					'错误', // String, HTML or Component
					error.message, // String, HTML or Component
					'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
				);
			})
		}

		setDivkey(Math.random());

		
	}

	const [loading, setLoading] = useState(false);

	return (
		<PageWrapper > 
			<Card data-tour='list' style={{height:'100%', width:'100%', margin:'auto'}}>
			    <OffCanvasBody tag='form' onSubmit={formik.handleSubmit}  className='p-4'>
			        <div className='row g-4' style={{height: "500px", paddingLeft:"20px", paddingTop:"15px"}}>
						<div className='col-6' id='margin1'>
							<FormGroup
								style={{ fontSize: "14px", fontWeight: "bold" }}
								id='name'
								label={t('Meeting Name')+":"}
								isColForLabel
								labelClassName='col-auto text-sm-start text-md-end text-black'
								childWrapperClassName='col-auto'>
								<Input
								    className='col-7'
									onChange={formik.handleChange}
									value={formik.values.name}
									disabled
								/>
							</FormGroup>
						</div>
						<div className='col-6' id='margin1'>
							<FormGroup
								style={{ fontSize: "14px", fontWeight: "bold" }}
								id='room_id'
								label={t('Meeting Room')+":"}
								isColForLabel
								labelClassName='col-auto text-sm-start text-md-end text-black'
								childWrapperClassName='col-auto'>
								<Input
									disabled
									id='room_id'
									value={formik.values.room_name}
									onChange={formik.handleChange}>
								</Input>
							</FormGroup>
						</div>
						<div className='col-6' id='margin1'>
							<FormGroup
								style={{ fontSize: "14px", fontWeight: "bold" }}
								id='control_types'
								label={t('Control Type')+":"}
								isColForLabel
								labelClassName='col-auto text-sm-start text-md-end text-black'
								childWrapperClassName='col-auto'>
								<Input
									disabled
									id='control_types'
									value={formik.values.control_types=="2"?t('Secretary'):formik.values.control_types=="3"?t('Null'):""}
									onChange={formik.handleChange}>
								</Input>
							</FormGroup>
						</div>
						<div className='col-6' id='margin1'>
							<FormGroup
								style={{ fontSize: "14px", fontWeight: "bold" }}
								id='control_types'
								label={"会议"+t('Secretary')+":"}
								isColForLabel
								labelClassName='col-auto text-sm-start text-md-end text-black'
								childWrapperClassName='col-auto'>
								<Input
									disabled
									onChange={formik.handleChange}
									value={formik.values.control_types == "2" ? formik.values.control_name : ""}
								/>
							</FormGroup>
						</div>
						<div className='col-6' id='margin1'>
							<FormGroup
								style={{ fontSize: "14px", fontWeight: "bold" }}
								id='start_time' label={t('Start Time')+":"}
								isColForLabel labelClassName='col-auto text-sm-start text-md-end text-black'
								childWrapperClassName='col-auto'>
								<Input
									disabled
									type='text'
									value={formik.values.start_times}
									onChange={formik.handleChange} />
							</FormGroup>
						</div>
						<div className='col-6' id='margin1'>
							<FormGroup
								style={{ fontSize: "14px", fontWeight: "bold" }}
								id='end_time' label={t('End Time')+":"}
								isColForLabel labelClassName='col-auto text-sm-start text-md-end text-black'
								childWrapperClassName='col-auto'>
								<Input
									disabled
									type='text'
									value={formik.values.end_times}
									onChange={formik.handleChange} />
							</FormGroup>
						</div>						
						<div className='col-6' id='margin1'>
							<FormGroup
								style={{ fontSize: "14px", fontWeight: "bold" }}
								id='address'
								label={t('Meeting Address')+":"}
								isColForLabel
								labelClassName='col-auto text-sm-start text-md-end text-black'
								childWrapperClassName='col-auto'>
								<Input
									disabled
									onChange={formik.handleChange}
									value={formik.values.address}
									className='col-7'
								/>
							</FormGroup>
						</div>				
						<div className='col-6' id='margin1'>
							<FormGroup
								style={{ fontSize: "14px", fontWeight: "bold" }}
								id='description'
								label={t('meetingDetail')+":"}
								isColForLabel
								labelClassName='col-auto text-sm-start text-md-end text-black'
								childWrapperClassName='col-auto'>
								<Input
									disabled
									onChange={formik.handleChange}
									value={formik.values.description}
									style={{ resize: "none" }}
								/>
							</FormGroup>
						</div>						
						<div className='col-6' id='margin1'>
							<FormGroup
								style={{ fontSize: "14px", fontWeight: "bold" }}
								id='meeting_rank'
								label={t('Meeting Rank')+":"}
								isColForLabel
								labelClassName='col-auto text-sm-start text-md-end text-black'
								childWrapperClassName='col-auto'>
								<Input
									disabled
									id='meeting_rank'
									value={formik.values.meeting_rank=="0"?t('Normal'):t('Secrecy')}
									onChange={formik.handleChange}>
								</Input>
							</FormGroup>
						</div>
						<div className='col-6' id='margin1'>
							<FormGroup
								style={{ fontSize: "14px", fontWeight: "bold" }}
								id='document_watermark'
								label={t('Add Document Watermark')+":"}
								isColForLabel
								labelClassName='col-auto text-sm-start text-md-end text-black'
								childWrapperClassName='col-auto'>
								<Input
									disabled
									id='document_watermark'
									value={formik.values.document_watermark=="0"?t('Yes'):t('No')}
									onChange={formik.handleChange}>
								</Input>
							</FormGroup>
						</div>						
						<div className='col-6' id='margin1'>
							<FormGroup
								style={{ fontSize: "14px", fontWeight: "bold" }}
								id='enforce_signature'
								label={t('Enforce Signature')+":"}
								isColForLabel
								labelClassName='col-auto text-sm-start text-md-end text-black'
								childWrapperClassName='col-auto'>
								<Input
									disabled
									id='enforce_signature'
									value={formik.values.enforce_signature=="0"?t('Open'):t('Close')}
									onChange={formik.handleChange}>
								</Input>
							</FormGroup>
						</div>
						<div className='col-6' id='margin1'>
							<FormGroup style={{ fontSize: "14px", fontWeight: "bold" }} id='meeting_issue' label='会议议题:' isColForLabel 
							labelClassName='col-auto text-sm-start text-md-end text-black' childWrapperClassName='col-auto psl'>
								{
									formik.values.meeting_issue.map((iit: any, iid: any) => {
										return (<><span>{iid + 1} : {iit.name}</span><br></br></>)
									})
								}
							</FormGroup>
						</div>
						{controlType ? (<FormGroup labelClassName='col-sm-1 text-sm-start text-md-end' childWrapperClassName='col-sm-10'>
							    <Buttons color='info' icon='Edit' isLight id='button-editon' title='编辑会议'
							        onClick={handleMeetingSelect} style={{ marginRight: "5px" }}>{t('Edit')}
							    </Buttons>
							    <div key={divkey} style={{ display: "inline-block" } }>
							    	<Upload directory onChange={handleChangeFile}>
							    		<Buttons color='success' isLight id='button-editon' icon='Upload' title='上传议题文件夹'>{t('Upload')}</Buttons>
							    	</Upload>
							    </div>
						    </FormGroup>):( <div style={{ width: "30%" } }><Buttons className='w-100' color='info' icon='Filter' isLight title='查看批注记录'
							    href={`#/` + 'paperless/meetingList/_details/annotationRecord' + '/' + id} tag={"a"} 
							    style={{ marginRight: "5px"}}>{t('Check')}
							</Buttons></div>)}
						</div>
					</OffCanvasBody>	
				</Card>

                 {/* //编辑 */}
                  <OffCanvas
					setOpen={(status: boolean) => {
						setToggleInfoEventCanvasMeeting(status);
						setMeetings(status);
					}}
					isOpen={toggleInfoEventCanvasMeeting}
					titleId='canvas-title'
					isBodyScroll
					placement='end'>
					<OffCanvasHeader
						setOpen={(status: boolean) => {
							setToggleInfoEventCanvasMeeting(status);
							setMeetings(status);
						}}>
						<OffCanvasTitle id='canvas-title'>
						{ t('Edit Meeting') }
						</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody tag='form' onSubmit={formik.handleSubmit} className='p-4'>
						<div className='row g-4'>
							<div className='col-12'>
								<FormGroup>
									<label style={{ marginBottom: "0.5rem", fontWeight: "500" }}>{ t('Meeting Name') }<span style={{ color: "red" }}>*</span></label>
									<Input id='name'
										required
										onChange={formik.handleChange}
										value={formik.values.name}
										placeholder={ t('Meeting Name') }
									    ariaLabel={ t('Meeting Name') }
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup>
									<label style={{ marginBottom: "0.5rem", fontWeight: "500" }}>{t('Meeting Address')} </label>
									<Input id='address'
										onChange={formik.handleChange}
										value={formik.values.address}
										placeholder={t('Meeting Address')}
									    ariaLabel={t('Meeting Address')}
									/>
								</FormGroup>
							</div>
							{/* <div className='col-12'>
								<FormGroup>
									<label style={{ marginBottom: "0.5rem", fontWeight: "500" }}>{ t('Large Screen Title') } </label>
									<Textarea id='title'
										onChange={formik.handleChange}
										value={formik.values.title}
										placeholder={ t('Large Screen Title') }
									    ariaLabel={ t('Large Screen Title') }
									/>
								</FormGroup>
							</div> */}
							<div className='col-12'>
								<FormGroup>
									<label style={{ marginBottom: "0.5rem", fontWeight: "500" }}>{t('meetingDetail')} </label>
									<Textarea id='description'
										onChange={formik.handleChange}
										value={formik.values.description}
										placeholder={t('meetingDetail')} 
									    ariaLabel={t('meetingDetail')} 
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup>
									<Fragment>
										<label style={{ paddingBottom: "0.5rem", fontWeight: "500", width: "100%", position: "relative" }}>
											<span>{t('Meeting Topics')} </span>
											<Button style={{ color: "#428bca", position: "absolute", right: 0, bottom: 0, }} icon={<Add />} type='text' onClick={addElement}>
												{t('Add Topic')}
											</Button>
										</label>
										{title.map((item4: any, index4: any) => {
											if (index4 == title.length - 1 && title.length > 1) {
												return (<div id={"dtitle" + index4} className="titleTop">
													<span className="title1">{index4 + 1} :</span>
													<input className="title2 tw1" type="text" id={"title" + index4} name="topic_title[]" placeholder="" onChange={(obj) => titleChange(obj, index4)} value={item4.name} />
													<span className="title3" title="设为表决议题"><input type="checkbox" name="topic_check[]" checked={item4.check} onChange={(obj) => titleChanges(obj, index4)} /></span>
													<span id="remScnt" title={t('Delete')}> <button className="delete" type="button" onClick={delElement}>{t('Delete')}</button></span>
												</div>);
											} else if (index4 == title.length - 1 && title.length == 1) {
												return (<div id={"dtitle" + index4} className="titleTop">
													<span className="title1">{index4 + 1} :</span>
													<input className="title2 tw2" type="text" id={"title" + index4} name="topic_title[]" placeholder="" onChange={(obj) => titleChange(obj, index4)} value={item4.name} />
													<span className="title3" title="设为表决议题"><input type="checkbox" name="topic_check[]" checked={item4.check} onChange={(obj) => titleChanges(obj, index4)} /></span>
												</div>);
											} else {
												return (<div id={"dtitle" + index4} className="titleTop" >
													<span className="title1">{index4 + 1} :</span>
													<input className="title2 tw2" type="text" id={"title" + index4} name="topic_title[]" placeholder="" onChange={(obj) => titleChange(obj, index4)} value={item4.name} />
													<span className="title3" title="设为表决议题"><input type="checkbox" name="topic_check[]" checked={item4.check} onChange={(obj) => titleChanges(obj, index4)} /></span>
												</div>);
											}

										})}
									</Fragment>
								</FormGroup>
							</div>
								<div className='col-12'>
							<Card className='mb-0 bg-l10-info' shadow='sm'>
								<CardHeader className='bg-l25-info'>
									<CardLabel icon='DateRange' iconColor='info'>
										<CardTitle className='text-info'>
											{t('Select Meeting Time')} <span style={{ color: "red" }}>*</span>
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row g-3'>
										<div className='col-12'>
											<FormGroup id='start_time' label={t('Start Time')}>
												<Input
													type='datetime-local'
													value={formik.values.start_time}
													onChange={formik.handleChange}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup id='end_time' label={t('End Time')}>
												<Input
													type='datetime-local'
													value={formik.values.end_time}
													onChange={formik.handleChange}
												/>
											</FormGroup>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
							<div className='col-12'>
								<FormGroup>
									<label style={{ marginBottom: "0.5rem", fontWeight: "500" }}>{t('Meeting Rank')} </label>
									<Select id='meeting_rank'
										placeholder={t('Please Select')}
										value={formik.values.meeting_rank}
										onChange={formik.handleChange}
										ariaLabel='Employee select'>
										<Option value='0'>{t('Normal')}</Option>
										<Option value='1'>{t('Secrecy')}</Option>
									</Select>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup>
									<label style={{ marginBottom: "0.5rem", fontWeight: "500" }}>{t('Add Document Watermark')}</label>
									<Select id='document_watermark'
										placeholder={t('Please Select')}
										value={formik.values.document_watermark}
										onChange={formik.handleChange}
										ariaLabel='Employee select'>
										<Option value='0'>{t('Yes')}</Option>
										<Option value='1'>{t('No')}</Option>
									</Select>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup>
									<label style={{ marginBottom: "0.5rem", fontWeight: "500" }}>{t('Control Type')} </label>
									<Select id='control_types'
										placeholder={t('Please Select')}
										value={formik.values.control_types}
										onChange={formik.handleChange}
										ariaLabel='Employee select'>
										<Option value='2'>{t('Secretary')}</Option>
										<Option value='3'>{t('Null')}</Option>
									</Select>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup>
									<label style={{ marginBottom: "0.5rem", fontWeight: "500" }}>{t('Enforce Signature')} </label>
									<Select id='enforce_signature'
										placeholder={t('Please Select')}
										value={formik.values.enforce_signature}
										onChange={formik.handleChange}
										ariaLabel='Employee select'>
										<Option value='0'>{t('Open')}</Option>
										<Option value='1'>{t('Close')}</Option>
									</Select>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup>
									<label style={{ marginBottom: "0.5rem", fontWeight: "500" }}>{t('Meeting Room')} </label>
									<Select id='room_id'
										required
										ariaLabel={t('Please Select')}
										placeholder={t('Please Select')}
										onChange={formik.handleChange}
										value={formik.values.room_id}>
										{rooms.map((item: any) => (
											<Option
												key={item.id}
												value={item.id}>
												{item.room_name}
											</Option>
										))}
									</Select>
								</FormGroup>
							</div>
						</div>
						<div className='row m-0'>
							<div className='col-12 p-3'>
								<Buttons
									color='info'
									className='w-100'
									type='button'
									onClick={onSubmit}
									isDisable={loading }
								>
								{loading?t('Saving'):t('Save')}
								</Buttons>
							</div>
						</div>
					</OffCanvasBody>
				</OffCanvas>
			<Modals
				zIndex={1040}
				title={t('Tip')}
				open={windowCode}
				footer={[]}
				closable={false }
			>
				<>
					<Spin tip={t('Uploading')}></Spin>
				</>
			</Modals>
		</PageWrapper>
	);
};

export default MeetingDetail;
