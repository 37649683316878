import React, { useContext, useEffect, useState } from "react";
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import Footer from '../../../layout/Footer/Footer';
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import {
	CardBody,
} from '../../../components/bootstrap/Card';
import { Collapse, Space, Tabs } from 'antd';
import AuthContext from "../../../contexts/authContext";
import { getStorages } from "../../../utils/localStorage";
import i18n from "../../../../src/i18n";
import { ILang } from "../../../lang";

const DefaultFooter = () => {
	// 用户
	const { userData } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const [configFind, setConfigFind] = useState<any>({
		siteLogoShow: 0,
		siteLogoWrapShow: 0,
		sitePortrait: "/favicon.ico",
		siteTitle: "后台管理系统"
	});


	useEffect(() => {
		const systemConfig = getStorages("systemConfig")
		setConfigFind(systemConfig !== "" ? JSON.parse(systemConfig) : {});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[userData])




	const [SelectProgramWindow, setSelectProgramWindow] = useState(false);

	const { Panel } = Collapse;

	const tabs = [
		{
		label: `会务系统`,
		key: "1",
		children: (
			<>
				<Space direction="vertical" className='col-12' >
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.23"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>修改仪表盘会议室预览显示</li>
									<li>报表管理增加会议室、预约人等条件查询</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.20"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>增加会议预约日期限制，限制不能预约指定日期之后的时间</li>
									<li>修正日程表的会议排程显示</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.18"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>增加微信移动端预约界面</li>
									<li>添加邮箱发送功能</li>
									<li>仪表盘增加微信页面的二维码</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.10"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>1.新增外部OA对接会议预约接口，详情请联系提供方；</li>
									<li>2.新增会议主题长度限制，限制可在配置设置；</li>
									<li>3.新增默认OA对接用户默认角色跟默认部门设置；</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.9"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>1.为短信发送添加配置值，两个配置值同时为1的时候才会触发发送；</li>
									<li>2.修改全选删除后无法再次全选；</li>
									<li>3.增加企业微信页面跳转；</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
				</Space>
			</>
		),
	}, {
		label: `信发系统`,
		key: "2",
		children: (
			<>
				<Space direction="vertical" className='col-12' >
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 5.0.1"} key={"1"} extra="">
							<div style={{ textAlign: "left" }}>
								<span>新媒体-信发系统：</span>
								<ul>
									<label>全部列表</label>
									<li>新增-关联数据，删除限制</li>
									<li>优化-描述字段显示</li>
								</ul>

								<ul>
									<label>树状列表</label>
									<li>新增-可收缩</li>
								</ul>

								<ul>
									<label>模板列表</label>
									<li>新增-文档类控件</li>
								</ul>

								<ul>
									<label>节目列表</label>
									<li>新增-文档类控件</li>
									<li>新增-素材库列选中</li>
								</ul>

								<ul>
									<label>素材管理</label>
									<li>新增-文件转换过渡动画</li>
								</ul>

								<ul>
									<label>设备列表</label>
									<li>修复-节目组快捷下发</li>
									<li>添加-设备批量下发任务</li>
								</ul>

								<ul>
									<label>组织管理</label>
									<li>新增-增删改查</li>
									<li>新增-快捷添加用户</li>
									<li>新增-快捷预览用户</li>
								</ul>

								<ul>
									<label>角色管理</label>
									<li>修复-勾选上级，全选下级，取消下级上级消失问题</li>
								</ul>

								<p>信发系统pc终端</p>
								<ul>
									<li>新增-后台相应功能</li>
								</ul>
							</div>
						</Panel>
					</Collapse>

					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.16"} key={"1"} extra="">
							<div style={{ textAlign: "left" }}>
								<span>新媒体-信发系统：</span>
								<ul>
									<label>模板列表</label>
									<li>新增-模板预览</li>
								</ul>

								<ul>
									<label>设备列表</label>
									<li>新增-区域选择</li>
								</ul>
							</div>
						</Panel>
					</Collapse>

					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.15"} key={"1"} extra="">
							<div style={{ textAlign: "left" }}>
								<span>新媒体-信发系统：</span>
								<ul>
									<label>节目列表</label>
									<li>跟换-媒体库列表，新增-分组选择</li>
									<li>优化-多文件上传</li>
									<li>优化-消息提示</li>
								</ul>
								<p>信发系统pc终端</p>
								<ul>
									<li>修复-节目排程播放</li>
									<li>修复-下载文件中，设置页拿不到数据问题</li>
								</ul>
							</div>
						</Panel>
					</Collapse>

					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.14"} key={"1"} extra="">
							<div style={{ textAlign: "left" }}>
								<span>新媒体-信发系统：</span>
								<ul>
									<label>仪表盘</label>
									<li>修复-类型统计不对</li>
									<li>修复-素材数不对</li>
									<li>添加-通知，显示”最近5条“发生的播放通知</li>

									<label>任务管理</label>
									<li>修复-任务删除不了</li>

									<label>素材库</label>
									<li>修复-每次新增或修改,文件上传控件,记住上一次数据问题</li>
									<li>优化-分类选择的不清晰，导致上传归入的分类混乱</li>

									<label>输入节点</label>
									<li>修复-新增不了，没有显示</li>

									<label>模板列表</label>
									<li>修复-图片，视频，音频上传失败问题</li>
									<li>修复-模板大小为0kb问题</li>

									<label>节目列表</label>
									<li>修复-节目大小为0kb问题</li>
									<li>添加-节目快捷下发</li>

									<label>节目组</label>
									<li>添加-节目组快捷下发</li>

									<label>菜单管理</label>
									<li>优化-过滤其他系统无用的菜单</li>

									<label>角色管理</label>
									<li>优化-过滤其他系统无用的角色</li>

									<label>用户管理</label>
									<li>添加-重置用户密码，且该用户登录的时候，如果密码已重置就必须改密码</li>
								</ul>
								<p>信发系统pc终端</p>
								<ul>
									<li>添加-节目排程播放</li>
									<li>添加-节目插播播放</li>
									<li>修复-紧急插播只能播放不能停</li>
								</ul>
							</div>
						</Panel>
					</Collapse>

					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.13"} key={"1"} extra="">
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>修改-输入源修改为“输入节点“</li>
									<li>添加-输入节点类型（数据）</li>
									<li>添加-更新包类型（数据）</li>
									<li>修复-设备详情页，变成空白页问题</li>
								</ul>
								<p>信发系统pc终端</p>
								<ul>
									<li>修复-节目列表（播放状态,显示问题）</li>
									<li>修复-状态列表（当前节目,显示问题）</li>
									<li>修复-信息列表（播放节目组，不显示数据问题）</li>
									<li>优化-信息列表（文件大小，“节目id“替换成“节目名称”，文件名：原“下载路径”改成“文件名称”）</li>
								</ul>

							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.12"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>新增-更新包类型（增删改查）</li>
									<li>新增-更新包列表（查看详情）</li>
									<li>修改-更新包列表（替换掉更新包类型，变成灵活的 可添加的）</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.11"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>新增版本控制（增删查）</li>
									<li>修复设备详情页（配置信息，截屏，关机，缓存）</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
				</Space>
			</>
		),
	}, {
		label: `无纸化系统`,
		key: "3",
		children: (
			<>
				<Space direction="vertical" className='col-12' >
				<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.20"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<p>无纸化会议系统</p>
								<span>要点：</span>
								<ul>
									<li>新增用户时手机号已修改为非必填</li>
									<li>用户模板下载格式已修改为.xlsx格式</li>
									<li>座位图管理已修改为不可绑定同一个账号</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
				    <Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.19"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<p>无纸化会议系统</p>
								<span>要点：</span>
								<ul>
									<li>删除主持人及大屏标题</li>
									<li>修改添加参会人员框架</li>
									<li>用户管理添加按部门搜索</li>
									<li>修复投票组的编辑及删除功能</li>
									<li>支持对文件管理及视频管理处的文件名称进行编辑</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.18"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<p>无纸化会议系统</p>
								<span>要点：</span>
								<ul>
									<li>权限管理修复新增及更改权限</li>
									<li>支持一键清除所有议题的文件</li>
									<li>安装包列表支持更换安装包背景图</li>
									<li>添加下发设备状态的提示以及日志记录</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
				    <Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.17"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<p>无纸化会议系统</p>
								<span>要点：</span>
								<ul>
									<li>新增快速建会功能</li>
									<li>新增生成纪要功能</li>
									<li>支持多语言（中文简体、中文繁体、英文）</li>
									<li>修复密码重置，新增用户默认密码【123456】</li>
									<li>隐藏超级管理员，不开放新增超级管理员功能</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.16"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<p>无纸化会议系统</p>
								<span>要点：</span>
								<ul>
								    <li>刷新桌牌接口</li>
									<li>墨水屏模板添加【参会人名称】</li>
									<li>墨水屏设备编辑，修改类型（bluetooth，wifi）</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.15"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<p>无纸化会议系统</p>
								<span>要点：</span>
								<ul>
									<li>禁用客户端菜单管理</li>
									<li>修复用户操作日志管理</li>
									<li>清除资料库的文件上传缓存</li>
									<li>安装包列表显示最新的两条记录</li>
									<li>修正议题删除后保存失败的问题</li>
									<li>清除会议详情下文件管理处的文件上传缓存</li>
									<li>全局将确认删除提示框选项英文版修改为中文版</li>
									<li>添加用户管理处的用户搜索以及部门新增、编辑、删除</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.14"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<p>无纸化会议系统</p>
								<span>要点：</span>
								<ul>
									<li>修改公共资料库列表的排序</li>
									<li>添加会议详情返回会议列表按钮</li>
									<li>修复会议详情下文件管理处的删除功能</li>
									<li>添加会议详情下参会人员处的取消签到功能</li>
									<li>添加会议室管理下座位图编辑返回上一级按钮</li>
									<li>调整会议室管理下座位图编辑处的清空按钮位置</li>
									<li>修改安装包列表的排序;修复安装包及其他遗漏项的确认删除提示</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.13"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<p>无纸化会议系统</p>
								<span>要点：</span>
								<ul>
									<li>添加确认删除提示</li>
									<li>修复参会人员是否代签列表及导出显示(0否,1是)</li>
									<li>更新安装包列表，删除背景图名称，预览改为背景图</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.12"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<p>无纸化会议系统</p>
								<span>要点：</span>
								<ul>
									<li>修改网站编辑界面</li>
									<li>修改操作日志列表的排序</li>
									<li>修复参会人员签到图片显示</li>
									<li>更新安装包列表，新增背景图名称及预览</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.11"} key={"1"} extra="">
							<div style={{ textAlign: "left" }}>
								<p>无纸化会议系统</p>
								<span>要点：</span>
								<ul>
									<li>修改各导出列表的文件名称</li>
									<li>修复用户管理，使点击组织架构节点后更新用户列表信息</li>
									<li>修改安装包背景图的上传，于安装包管理处点击新增按钮上传背景图</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.10"} key={"1"} extra="">
							<div style={{ textAlign: "left" }}>
								<p>无纸化会议系统</p>
								<span>要点：</span>
								<ul>
									<li>增加会议详情子菜单项：语音纪要</li>
									<li>修改座位图安排中的与会人座位安排</li>
									<li>为文件管理处的各项文档添加类型标符</li>
									<li>修复用户管理，包括用户信息的增删改、导入、导出等功能</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
				</Space>
			</>
		),
	}, {
		label: `物联中控`,
		key: "4",
		children: (
			<>
				<Space direction="vertical" className='col-12' >

					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.11"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>对接新接口，去掉一些不用的页面</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.12"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>物品添加图片，修改设备类型编辑</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.23"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>修改规则管理-新</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
				</Space>
			</>
		),
	}, {
		label: `总系统`,
		key: "5",
		children: (
			<>
				<Space direction="vertical" className='col-12' >
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.22"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>增加多组织概念</li>
									<li>登录页面能切换组织查看标题及logo</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.19"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>修改多语言</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.18"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>修改登录页面排版</li>
									<li>增加用户第一次登录时需要修改登录密码</li>
									<li>事件中心增加7天数据清空功能</li>
									<li>通知中心增加删除功能</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.10"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>登录页请求数据方式修改</li>
									<li>增加网站logo显示选择</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="header" defaultActiveKey={['1']} key="1">
						<Panel header={"Version 4.0.9"} key={"1"} extra=""
						>
							<div style={{ textAlign: "left" }}>
								<span>要点：</span>
								<ul>
									<li>添加版本记录显示</li>
								</ul>
							</div>
						</Panel>
					</Collapse>
				</Space>
			</>
		),
	}];

	return (
		<Footer>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						<span className='fw-light' >
							{configFind.web_site_copy}
						</span>
					</div>
					<div className='col-auto'>
						<label
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							<small className='fw-bold'>
								{i18n.language as ILang['key']['lng'] == "zh_CN" ? (configFind?.web_site_title ?? "联展管理系统"):(configFind?.web_site_title_en ?? "System")}
							</small>
						</label>
					</div>
				</div>
			</div>
			<Modal
				isOpen={SelectProgramWindow}
				setIsOpen={setSelectProgramWindow}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}
				isScrollable={false}
				isCentered={true}
				size="lg"
				fullScreen={false}
				isAnimation={true}>
				<ModalHeader setIsOpen={setSelectProgramWindow}>
					<ModalTitle id='exampleModalLabel'> 版本记录</ModalTitle>
				</ModalHeader>
				<CardBody style={{ height: "600px" }} className="table-responsive text-center" isScrollable>
					<ModalBody style={{ height: "550px" }}>
						<Tabs
							tabPosition="left"
							items={tabs}
						/>
					</ModalBody>
				</CardBody>
			</Modal>
		</Footer>
	);
};

export default DefaultFooter;
