import React from 'react';
import { demoPages, layoutMenu, mediaPages } from '../menu';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides = [
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.signUp.path, element: null, exact: true },
	{ path: demoPages.generateKeyPage.path, element: null, exact: true },
	{ path: layoutMenu.blank.path, element: null, exact: true },
	{ path: layoutMenu.excelMap.path, element: null, exact: true },
	{ path: 'paperless/meetingList/_details/meetingDetail/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/participants/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/addParticipants/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/annotationRecord/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/fileManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/videoManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/votingManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/votingDetailedManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/votingGroupDetailedManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/votingStatistics/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/voiceSummary/:id', element: null, exact: true },
	{ path: 'PdfPreview/:fileMd5', element: null, exact: true },
	{ path: 'check-in', element: null, exact: true },
	{ path: 'callback', element: null, exact: true },
	{ path: mediaPages.mobilePhoneScanModel.subMenu.empowerVersionList.path, element: null, exact: true },
	{ path: `${mediaPages.programManagement.subMenu.programAdd.path}/:templateId`, element: null, exact: true },
	{ path: `${mediaPages.programManagement.subMenu.programEdit.path}/:templateId`, element: null, exact: true },
	{
		path: `${demoPages.editPassword.path}/:username`, element: null, exact: true
	},
	{
		path: `${demoPages.empowerPage.path}`, element: null, exact: true
	},

	// { path: mediaPages.systemManagement.subMenu.operateRoleManagement.path + "/:powerId", element: null, exact: true },

	{
		path: 'paperless/meetingList/_details/votingGroupManagement/:id',
		element: null,
		exact: true,
	},
	{
		path: 'paperless/meetingList/_details/informationManagement/:id',
		element: null,
		exact: true,
	},
	{ path: 'paperless/meetingList/_details/conferenceRecords/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/seatMapManagement/:id', element: null, exact: true },
	{ path: 'paperless/meetingList/_details/seatMapArrangement/:id', element: null, exact: true },
	{ path: '*', element: <DefaultAside />, exact: true },
];

export default asides;
