import React, { SVGProps } from 'react';

const Media = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 39.42 39.42" fill='currentColor' className='svg-icon' {...props}>
			<g id="图层_2" data-name="图层 2">
				<g id="图层_1-2" data-name="图层 1"><rect className="cls-1" width="39.42" height="39.42" rx="8" /><path className="cls-2" d="M27.13,20.9l-7.22,7.22h-8.5a1.9,1.9,0,0,1-1.89-1.9V11.05a1.9,1.9,0,0,1,1.89-1.9H25.23a1.9,1.9,0,0,1,1.9,1.9h0ZM13.31,18.64a1.08,1.08,0,1,0,0,2.16H22a1.08,1.08,0,1,0,0-2.16H13.31Zm0-5.42a1.08,1.08,0,1,0,0,2.16h4.61a1.08,1.08,0,0,0,0-2.16H13.31Zm13.2,9.87L28.42,25l-4.56,4.57a1.34,1.34,0,0,1-.69.37l-1.61.32a.27.27,0,0,1-.32-.21.2.2,0,0,1,0-.11l.33-1.6a1.27,1.27,0,0,1,.37-.68l4.57-4.57Zm2.7-1.17.38.38a1.1,1.1,0,0,1,0,1.54l-.68.67L27,22.6l.68-.68a1.09,1.09,0,0,1,1.53,0Z" /></g>
			</g>
		</svg>
	);
};

export default Media;
