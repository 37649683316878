	import React, { FC, useState, useEffect, useContext } from 'react';
	import PropTypes from 'prop-types';
	import AuthContext from '../contexts/authContext';
	import Config from "../config";
import { getStorages } from "../utils/localStorage";
import i18n from "../../src/i18n";
import { ILang } from "../lang";
	interface ILogoProps {
		width?: number;
		height?: number;
	}
	const Logo: FC<ILogoProps> = ({ width, height }) => {
		// 用户信息
		const { userData } = useContext(AuthContext);
		const [configFind, setConfigFind] = useState<any>({
			web_site_logo_show: 1,
			siteLogoShow: 0,
			siteLogoWrapShow: 0,
			sitePortrait: "/default.png",
			web_site_title: "智能综合管理系统",
			web_site_title_en: "Zuad Synthesize System"
		});

		useEffect(() => {
			const systemConfig = getStorages("systemConfig")

			setConfigFind({
				...configFind,
				...(systemConfig !== "" ? JSON.parse(systemConfig) : {})
			})

			// eslint-disable-next-line react-hooks/exhaustive-deps
		},[userData])

		return (
			<>
				{
					configFind ? (
						<>
							<div style={{
								width: "100%",
								display: "flex",
								gap: "10px",
								flexWrap: "wrap",
								alignItems: "center",
								justifyContent: "center"
							}}>
								{
									(Number(configFind.web_site_logo_show)) ? (
										<img
											src={Config.siteAddress + "/" + configFind.web_site_logo}
											alt='logo'
											style={{
												width: "100%",
												height: "3rem",
												objectFit: "contain"
											}}
											onError={
												(e: any) => {
													e.target.src = process.env.PUBLIC_URL + "/default.png";
												}
											}
										/>
									) : (
										<></>
									)
								}
								<span style={{
									fontSize: '1.3rem',
								}}>
									{
										i18n.language as ILang['key']['lng'] === "zh_CN" ? (
											configFind?.web_site_title ? configFind?.web_site_title : "后台管理系统"
										) : (
											configFind?.web_site_title_en ? configFind?.web_site_title_en : "System"
										)
									}
								</span>
							</div>
						</>
					) : (
						<></>
					)
				}
			</>
		);
	};
	Logo.propTypes = {
		width: PropTypes.number,
		height: PropTypes.number,
	};
	Logo.defaultProps = {
		width: 2155,
		height: 854,
	};

	export default Logo;
