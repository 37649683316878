import React, { lazy } from 'react';
import {
	componentsMenu,
	antiEpidemicPages,
	dashboardMenu,
	demoPages,
	layoutMenu,
	mediaPages,
	meetingRoomPages,
	paperlessPages,
	pipeGalleryPages,
	energyConsumptionPages,
	centralControlPages,
	systemLayoutPages,
	attendancePages, stationPages,
	doorControlSystemPages, stealthAttendanceSystemPages,
} from "../menu";
import MeetingDetail from '../pages/paperless/meetingList/_details/MeetingDetail';
import MeetingDetails from '../pages/paperless/meetingList/_details/MeetingDetails';
import Login from '../pages/presentation/auth/Login';
import CheckIn from '../pages/presentation/auth/CheckIn';
import Callback from '../pages/presentation/auth/Callback';
//import GenerateKeyPage from '../pages/presentation/auth/GenerateKeyPage';
import EditPassword from '../pages/presentation/auth/EditPassword';
import EmpowerPage from '../pages/presentation/auth/EmpowerPage';
import Configuration from '../pages/presentation/auth/Configuration';

//系統更新
import UPDATE_SYSTEM_PAGE from '../pages/_common/SystemPage';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	DASHBOARD_BOOKING: lazy(() => import('../pages/dashboard/DashboardBookingPage')),
	Event_Center: lazy(() => import('../pages/dashboard/DashboardEventCenterPage')),
	Notice_Center: lazy(() => import('../pages/dashboard/DashboardNoticeCenterPage')),
	SUMMARY: lazy(() => import('../pages/SummaryPage')),
};
const SINGLE = {
	BOXED: lazy(() => import('../pages/presentation/single-pages/SingleBoxedPage')),
	FLUID: lazy(() => import('../pages/presentation/single-pages/SingleFluidPage')),
};
const LIST = {
	BOXED: lazy(() => import('../pages/presentation/demo-pages/ListBoxedPage')),
	FLUID: lazy(() => import('../pages/presentation/demo-pages/ListFluidPage')),
};
const GRID = {
	BOXED: lazy(() => import('../pages/presentation/demo-pages/GridBoxedPage')),
	FLUID: lazy(() => import('../pages/presentation/demo-pages/GridFluidPage')),
};
const EDIT = {
	MODERN: lazy(() => import('../pages/presentation/demo-pages/EditModernPage')),
	BOXED: lazy(() => import('../pages/presentation/demo-pages/EditBoxedPage')),
	FLUID: lazy(() => import('../pages/presentation/demo-pages/EditFluidPage')),
	WIZARD: lazy(() => import('../pages/presentation/demo-pages/EditWizardPage')),
	IN_CANVAS: lazy(() => import('../pages/presentation/demo-pages/EditInCanvasPage')),
	IN_MODAL: lazy(() => import('../pages/presentation/demo-pages/EditInModalPage')),
};
const PRICING = {
	PRICING_TABLE: lazy(() => import('../pages/presentation/pricing/PricingTablePage')),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const APP = {
	PROJECT_MANAGEMENT: {
		PROJECTS_LIST: lazy(
			() => import('../pages/presentation/project-management/ProjectManagementsList'),
		),
		PROJECT: lazy(
			() => import('../pages/presentation/project-management/ProjectManagementsProject'),
		),
	},
	KNOWLEDGE: {
		GRID: lazy(() => import('../pages/presentation/knowledge/KnowledgeGridPage')),
		VIEW: lazy(() => import('../pages/presentation/knowledge/KnowledgeViewPage')),
	},
	SALES: {
		TRANSACTIONS: lazy(() => import('../pages/presentation/sales/TransActionsPage')),
		PRODUCTS: lazy(() => import('../pages/presentation/sales/SalesListPage')),
		PRODUCTS_GRID: lazy(() => import('../pages/presentation/sales/ProductsGridPage')),
		PRODUCTS_VIEW: lazy(() => import('../pages/presentation/sales/ProductViewPage')),
	},
	APPOINTMENT: {
		CALENDAR: lazy(() => import('../pages/presentation/appointment/CalendarPage')),
		EMPLOYEE_LIST: lazy(() => import('../pages/presentation/appointment/EmployeeList')),
		EMPLOYEE_VIEW: lazy(() => import('../pages/presentation/appointment/EmployeePage')),
		APPOINTMENT_LIST: lazy(() => import('../pages/presentation/appointment/AppointmentList')),
	},
	CRM: {
		CRM_DASHBOARD: lazy(() => import('../pages/presentation/crm/CrmDashboard')),
		CUSTOMERS: lazy(() => import('../pages/presentation/crm/CustomersList')),
		CUSTOMER: lazy(() => import('../pages/presentation/crm/Customer')),
	},
	CHAT: {
		WITH_LIST: lazy(() => import('../pages/presentation/chat/WithListChatPage')),
		ONLY_LIST: lazy(() => import('../pages/presentation/chat/OnlyListChatPage')),
	},
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	EXCELMAP: lazy(() => import('../pages/presentation/page-layouts/ExcelMap')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const CONTENT = {
	CONTENTS: lazy(() => import('../pages/documentation/content/ContentListPage')),
	TYPOGRAPHY: lazy(() => import('../pages/documentation/content/TypographyPage')),
	IMAGES: lazy(() => import('../pages/documentation/content/ImagesPage')),
	TABLES: lazy(() => import('../pages/documentation/content/TablesPage')),
	FIGURES: lazy(() => import('../pages/documentation/content/FiguresPage')),
};
const FORMS_PAGE = {
	FORMS: lazy(() => import('../pages/documentation/forms/FormsListPage')),
	FORM_GROUP: lazy(() => import('../pages/documentation/forms/FormGroupPage')),
	FORM_CONTROLS: lazy(() => import('../pages/documentation/forms/FormControlsPage')),
	SELECT: lazy(() => import('../pages/documentation/forms/SelectPage')),
	CHECKS_AND_RADIO: lazy(() => import('../pages/documentation/forms/ChecksAndRadioPage')),
	RANGE: lazy(() => import('../pages/documentation/forms/RangePage')),
	INPUT_GROUP: lazy(() => import('../pages/documentation/forms/InputGroupPage')),
	VALIDATION: lazy(() => import('../pages/documentation/forms/ValidationPage')),
	WIZARD: lazy(() => import('../pages/documentation/forms/WizardPage')),
};
const COMPONENTS_PAGE = {
	COMPONENTS: lazy(() => import('../pages/documentation/components/ComponentsListPage')),
	ACCORDION: lazy(() => import('../pages/documentation/components/AccordionPage')),
	ALERT: lazy(() => import('../pages/documentation/components/AlertPage')),
	BADGE: lazy(() => import('../pages/documentation/components/BadgePage')),
	BREADCRUMB: lazy(() => import('../pages/documentation/components/BreadcrumbPage')),
	BUTTON: lazy(() => import('../pages/documentation/components/ButtonPage')),
	BUTTON_GROUP: lazy(() => import('../pages/documentation/components/ButtonGroupPage')),
	CARD: lazy(() => import('../pages/documentation/components/CardPage')),
	CAROUSEL: lazy(() => import('../pages/documentation/components/CarouselPage')),
	COLLAPSE: lazy(() => import('../pages/documentation/components/CollapsePage')),
	DROPDOWN: lazy(() => import('../pages/documentation/components/DropdownsPage')),
	LIST_GROUP: lazy(() => import('../pages/documentation/components/ListGroupPage')),
	MODAL: lazy(() => import('../pages/documentation/components/ModalPage')),
	NAVS_TABS: lazy(() => import('../pages/documentation/components/NavsTabsPage')),
	OFF_CANVAS: lazy(() => import('../pages/documentation/components/OffCanvasPage')),
	PAGINATION: lazy(() => import('../pages/documentation/components/PaginationPage')),
	POPOVERS: lazy(() => import('../pages/documentation/components/PopoversPage')),
	PROGRESS: lazy(() => import('../pages/documentation/components/ProgressPage')),
	SCROLLSPY: lazy(() => import('../pages/documentation/components/ScrollspyPage')),
	SPINNER: lazy(() => import('../pages/documentation/components/SpinnersPage')),
	TABLE: lazy(() => import('../pages/documentation/components/TablePage')),
	TOASTS: lazy(() => import('../pages/documentation/components/ToastsPage')),
	TOOLTIP: lazy(() => import('../pages/documentation/components/TooltipPage')),
};
const UTILITIES = {
	UTILITIES: lazy(() => import('../pages/documentation/utilities/UtilitiesListPage')),
	API: lazy(() => import('../pages/documentation/utilities/ApiPage')),
	BACKGROUND: lazy(() => import('../pages/documentation/utilities/BackgroundPage')),
	BORDERS: lazy(() => import('../pages/documentation/utilities/BordersPage')),
	COLORS: lazy(() => import('../pages/documentation/utilities/ColorsPage')),
	DISPLAY: lazy(() => import('../pages/documentation/utilities/DisplayPage')),
	FLEX: lazy(() => import('../pages/documentation/utilities/FlexPage')),
	FLOAT: lazy(() => import('../pages/documentation/utilities/FloatPage')),
	INTERACTIONS: lazy(() => import('../pages/documentation/utilities/InteractionsPage')),
	OVERFLOW: lazy(() => import('../pages/documentation/utilities/OverflowPage')),
	POSITION: lazy(() => import('../pages/documentation/utilities/PositionPage')),
	SHADOWS: lazy(() => import('../pages/documentation/utilities/ShadowsPage')),
	SIZING: lazy(() => import('../pages/documentation/utilities/SizingPage')),
	SPACING: lazy(() => import('../pages/documentation/utilities/SpacingPage')),
	TEXT: lazy(() => import('../pages/documentation/utilities/TextPage')),
	VERTICAL_ALIGN: lazy(() => import('../pages/documentation/utilities/VerticalAlignPage')),
	VISIBILITY: lazy(() => import('../pages/documentation/utilities/VisibilityPage')),
};
const ICONS = {
	ICONS_LIST: lazy(() => import('../pages/documentation/icons/IconsListPage')),
	ICON: lazy(() => import('../pages/documentation/icons/IconPage')),
	MATERIAL: lazy(() => import('../pages/documentation/icons/MaterialPage')),
};
const CHARTS_PAGE = {
	CHART_LIST: lazy(() => import('../pages/documentation/charts/ChartsListPage')),
	GENERAL_USAGE: lazy(() => import('../pages/documentation/charts/ChartGeneralUsagePage')),
	SPARKLINE: lazy(() => import('../pages/documentation/charts/ChartSparklinePage')),
	LINE: lazy(() => import('../pages/documentation/charts/ChartLinePage')),
	AREA: lazy(() => import('../pages/documentation/charts/ChartAreaPage')),
	COLUMN: lazy(() => import('../pages/documentation/charts/ChartColumnPage')),
	BAR: lazy(() => import('../pages/documentation/charts/ChartBarPage')),
	MIXED: lazy(() => import('../pages/documentation/charts/ChartMixedPage')),
	TIMELINE: lazy(() => import('../pages/documentation/charts/ChartTimelinePage')),
	CANDLESTICK: lazy(() => import('../pages/documentation/charts/ChartCandlestickPage')),
	BOX_WHISKER: lazy(() => import('../pages/documentation/charts/ChartBoxWhiskerPage')),
	PIE_DONUT: lazy(() => import('../pages/documentation/charts/ChartPieDonutPage')),
	RADAR: lazy(() => import('../pages/documentation/charts/ChartRadarPage')),
	POLAR: lazy(() => import('../pages/documentation/charts/ChartPolarPage')),
	RADIAL_BAR: lazy(() => import('../pages/documentation/charts/ChartRadialBarPage')),
	BUBBLE: lazy(() => import('../pages/documentation/charts/ChartBubblePage')),
	SCATTER: lazy(() => import('../pages/documentation/charts/ChartScatterPage')),
	HEAT_MAP: lazy(() => import('../pages/documentation/charts/ChartHeatMapPage')),
	TREE_MAP: lazy(() => import('../pages/documentation/charts/ChartTreeMapPage')),

};
const EXTRA = {
	NOTIFICATION: lazy(() => import('../pages/documentation/extras/NotificationPage')),
	HOOKS: lazy(() => import('../pages/documentation/extras/HooksPage')),

};





//智慧防疫
const ANTI_EPIDEMIC_PAGES = {
	//仪表盘
	DASHBOARD: lazy(() => import('../pages/anti-epidemic/other/Dashboard')),

	//管控信息录入
	REGULATORY_INPUT_LIST: lazy(
		() => import('../pages/anti-epidemic/informationInput/RegulatoryInputList'),
	),

	//预警信息列表
	EARLY_WARNING_LIST: lazy(
		() => import('../pages/anti-epidemic/earlyWarningModel/EarlyWarningList'),
	),

	//打卡列表
	CLOCK_IN_LIST: lazy(() => import('../pages/anti-epidemic/dailyModel/ClockInList')),

	//区域列表
	REGION_LIST: lazy(() => import('../pages/anti-epidemic/regionModel/RegionList')),

	//风险地区
	RISK_AREA_LIST: lazy(() => import('../pages/anti-epidemic/regionModel/RiskAreaList')),

	//地区代理
	AGENT_LIST: lazy(() => import('../pages/anti-epidemic/regionModel/AgentList')),

	//用户列表
	USER_LIST: lazy(() => import('../pages/anti-epidemic/userModel/UserList')),
};
const antiEpidemicAppointment = [
	//仪表盘
	{
		path: antiEpidemicPages.dashboard.path,
		element: <ANTI_EPIDEMIC_PAGES.DASHBOARD />,
		exact: true,
	},
	//管控信息录入
	{
		path: antiEpidemicPages.informationInputModel.subMenu.regulatoryInputList.path,
		element: <ANTI_EPIDEMIC_PAGES.REGULATORY_INPUT_LIST />,
		exact: true,
	},
	//预警管理
	{
		path: antiEpidemicPages.earlyWarningModel.subMenu.earlyWarningList.path,
		element: <ANTI_EPIDEMIC_PAGES.EARLY_WARNING_LIST />,
		exact: true,
	},
	//日常管理
	{
		path: antiEpidemicPages.dailyModel.subMenu.clockInList.path,
		element: <ANTI_EPIDEMIC_PAGES.CLOCK_IN_LIST />,
		exact: true,
	},
	//地区管理
	{
		path: antiEpidemicPages.regionModel.subMenu.regionList.path,
		element: <ANTI_EPIDEMIC_PAGES.REGION_LIST />,
		exact: true,
	},
	{
		path: antiEpidemicPages.regionModel.subMenu.riskAreaList.path,
		element: <ANTI_EPIDEMIC_PAGES.RISK_AREA_LIST />,
		exact: true,
	},
	{
		path: antiEpidemicPages.regionModel.subMenu.agentList.path,
		element: <ANTI_EPIDEMIC_PAGES.AGENT_LIST />,
		exact: true,
	},
	//用户管理
	{
		path: antiEpidemicPages.userModel.subMenu.userList.path,
		element: <ANTI_EPIDEMIC_PAGES.USER_LIST />,
		exact: true,
	},
];

/**
 * 工位系统
 */
const STATION_POSITION = {
	//仪表盘
	DASHBOARD: lazy(() => import('../pages/station-pages/other/Dashboard')),
	//区域列表
	REGION_LIST: lazy(() => import('../pages/station-pages/stationModel/regionList')),
	//工位列表
	STATION_LIST: lazy(() => import('../pages/station-pages/stationModel/stationList')),
	//工位列表
	LABEL_LIST: lazy(() => import('../pages/station-pages/stationModel/labelList')),
	//添加工位
	STATION_UPLOAD: lazy(() => import('../pages/station-pages/stationModel/stationUpload')),
	//工位预约-视图版
	STATION_RESERVATION_VIEW: lazy(() => import('../pages/station-pages/reservationModel/appointmentView')),
	//工位预约
	STATION_RESERVATION: lazy(() => import('../pages/station-pages/reservationModel/stationReservation')),
	//我的预约
	MINE_RESERVATION: lazy(() => import('../pages/station-pages/reservationModel/mineReservation')),
	//预约记录
	RESERVATION_RECORD: lazy(() => import('../pages/station-pages/reservationModel/reservationRecord')),
	//会议审核
	EXAMINE_LIST: lazy(() => import('../pages/station-pages/reservationModel/examineList')),
	//签到记录
	ATTENDANCE_RECORD: lazy(() => import('../pages/station-pages/reportModel/attendanceRecord')),
	//使用记录
	USAGE_RECORD: lazy(() => import('../pages/station-pages/reportModel/usageRecord')),
	//便签记录
	MEMO_RECORD: lazy(() => import('../pages/station-pages/reportModel/memoRecord')),
	//报修记录
	REPAIR_RECORD: lazy(() => import('../pages/station-pages/reportModel/repairRecord')),
}

const stationAppointment = [
	{
		path: stationPages.dashboard.path,
		element: <STATION_POSITION.DASHBOARD />,
		exact: true,
	},
	{
		path: stationPages.stationModel.subMenu.regionList.path,
		element: <STATION_POSITION.REGION_LIST />,
		exact: true,
	},
	{
		path: stationPages.stationModel.subMenu.stationList.path,
		element: <STATION_POSITION.STATION_LIST />,
		exact: true,
	},
	{
		path: stationPages.stationModel.subMenu.labelList.path,
		element: <STATION_POSITION.LABEL_LIST />,
		exact: true,
	},
	{
		path: `${stationPages.stationModel.subMenu.stationUpload.path}/:station_id`,
		element: <STATION_POSITION.STATION_UPLOAD />,
		exact: true,
	},
	{
		path: `${stationPages.reservationModel.subMenu.appointmentView.path}`,
		element: <STATION_POSITION.STATION_RESERVATION_VIEW />,
		exact: true,
	},
	{
		path: `${stationPages.reservationModel.subMenu.stationReservation.path}`,
		element: <STATION_POSITION.STATION_RESERVATION />,
		exact: true,
	},
	{
		path: `${stationPages.reservationModel.subMenu.mineReservation.path}/:operate_id`,
		element: <STATION_POSITION.MINE_RESERVATION />,
		exact: true,
	},
	{
		path: `${stationPages.reservationModel.subMenu.reservationRecord.path}`,
		element: <STATION_POSITION.RESERVATION_RECORD />,
		exact: true,
	},
	{
		path: `${stationPages.reservationModel.subMenu.examineList.path}`,
		element: <STATION_POSITION.EXAMINE_LIST />,
		exact: true,
	},
	{
		path: `${stationPages.reportModel.subMenu.attendanceRecord.path}`,
		element: <STATION_POSITION.ATTENDANCE_RECORD />,
		exact: true,
	},
	{
		path: `${stationPages.reportModel.subMenu.usageRecord.path}`,
		element: <STATION_POSITION.USAGE_RECORD />,
		exact: true,
	},
	{
		path: `${stationPages.reportModel.subMenu.memoRecord.path}`,
		element: <STATION_POSITION.MEMO_RECORD />,
		exact: true,
	},
	{
		path: `${stationPages.reportModel.subMenu.repairRecord.path}`,
		element: <STATION_POSITION.REPAIR_RECORD />,
		exact: true,
	}
]



/***
 * 媒体信发系统
 */
const MEDIA_FILE = {
	DASHBOARD: lazy(() => import('../pages/media-pages/other/Dashboard')),

	TASK_LISTS: lazy(() => import('../pages/media-pages/taskManagement/TaskList')),

	//区域列表
	REGION_LIST: lazy(() => import('../pages/media-pages/regionManagement/RegionList')),

	//预案列表
	PLAN_LIST: lazy(() => import('../pages/media-pages/planManagement/PlanList')),

	//设备默认播放
	DEFAULT_PLAYBACK: lazy(() => import('../pages/media-pages/defaultManagement/PlaybackList')),

	//节目列表
	PROGRAM_LIST: lazy(() => import('../pages/media-pages/programManagement/ProgramList')),
	//排程
	SCHEDULING_LIST: lazy(() => import('../pages/media-pages/programManagement/SchedulingList')),
	//排程
	SCHEDULING_LISTS: lazy(() => import('../pages/media-pages/programManagement/SchedulingLists')),
	//节目组
	PROGRAM_GROUP_LIST: lazy(
		() => import('../pages/media-pages/programManagement/ProgramGroupList'),
	),
	//素材库
	MATERIAL_LIBRARY: lazy(() => import('../pages/media-pages/programManagement/MaterialLibrary')),
	//紧急插播
	INSERTION_LIST: lazy(() => import('../pages/media-pages/programManagement/InsertionList')),
	//节目添加
	PROGRAM_ADD: lazy(() => import('../pages/media-pages/programManagement/ProgramAdd')),
	//节目编辑
	PROGRAM_EDIT: lazy(() => import('../pages/media-pages/programManagement/ProgramEdit')),

	//监控流
	MONITOR_LIST: lazy(() => import('../pages/media-pages/monitorModel/MonitorList')),

	//输入流列表
	INPUT_STREAM_LIST: lazy(() => import('../pages/media-pages/panelPointModel/PanelPointList')),

	//模板
	TEMPLATE_LIST: lazy(() => import('../pages/media-pages/programManagement/TemplateList')),
	TEMPLATE_ADD: lazy(() => import('../pages/media-pages/programManagement/TemplateAdd')),
	TEMPLATE_EDIT: lazy(() => import('../pages/media-pages/programManagement/TemplateEdit')),

	//设备类型列表
	EQUIPMENT_TYPE_LIST: lazy(
		() => import('../pages/media-pages/panelPointModel/PanelPointTypeList'),
	),

	//设备列表
	EQUIPMENT_LIST: lazy(() => import('../pages/media-pages/equipmentManagement/EquipmentList')),

	//设备列表
	EQUIPMENT_LISTS: lazy(() => import('../pages/media-pages/equipmentManagement/EquipmentLists')),

	//设备组列表
	EQUIPMENT_GROUP_LIST: lazy(
		() => import('../pages/media-pages/equipmentManagement/EquipmentGroupList'),
	),

	//定时开关机列表
	FIXED_TIME_LIST: lazy(
		() => import('../pages/media-pages/equipmentManagement/FixedTimeList'),
	),


	//选择设备组
	ISSUED_DEVICE_GROUP_OPERATE: lazy(
		() => import('../pages/media-pages/programManagement/_common/DeviceGroupTablePage'),
	),

	//选择设备
	ISSUED_DEVICE_OPERATE: lazy(
		() => import('../pages/media-pages/programManagement/_common/DeviceTablePage'),
	),

	// 人流
	ABORTION_LIST: lazy(
		() => import('../pages/media-pages/peopleCountingModel/AbortionList'),
	),

	ABORTION_SUM_LIST: lazy(
		() => import('../pages/media-pages/peopleCountingModel/AbortionSumList'),
	),


	//更新包类型
	PACKAGE_TYPE_LIST: lazy(() => import('../pages/media-pages/packageModel/PackageTypeList')),

	//软件更新包
	VERSION_CONTROL: lazy(() => import('../pages/media-pages/packageModel/PackageList')),

	//上传软件更新包
	VERSION_UPLOAD: lazy(() => import('../pages/media-pages/packageModel/PackageUpload')),

	//屏幕列表
	SCREEN_LIST: lazy(() => import('../pages/media-pages/equipmentManagement/ScreenList')),

	//坐席列表
	SEATS_LIST: lazy(() => import('../pages/media-pages/equipmentManagement/SeatsList')),

	//播放历史
	PLAY_HISTORY_LIST: lazy(() => import('../pages/media-pages/playHistoryModel/PlayHistoryList')),

	//操作日志
	OPERATION_LOG_MANAGEMENT: lazy(
		() => import('../pages/media-pages/journalModel/OperationLogList'),
	),
	ERROR_LOG_MANAGEMENT: lazy(
		() => import('../pages/media-pages/journalModel/ErrorLogList'),
	),

	//系统
	USER_MANAGEMENT: lazy(() => import('../pages/media-pages/systemManagement/UserManagement')),

	ROLE_MANAGEMENT: lazy(() => import('../pages/media-pages/systemManagement/RoleManagement')),

	// 角色
	OPERATE_ROLE_MANAGEMENT: lazy(() => import('../pages/media-pages/systemManagement/OperateRoleManagement')),

	DOWNLOAD_LOG_MANAGEMENT: lazy(
		() => import('../pages/media-pages/journalModel/DownloadLogList'),
	),

	// 菜单
	MENU_MANAGEMENT: lazy(() => import('../pages/media-pages/systemManagement/MenuManagement')),

	// 配置
	CONFIGURATION_MANAGEMENT: lazy(
		() => import('../pages/media-pages/systemManagement/ConfigurationManagement'),
	),

	// 部门
	DIVISIONAL_MANAGEMENT: lazy(
		() => import('../pages/media-pages/systemManagement/DepartmentModel'),
	),

	DIVISIONAL_MANAGEMENTS: lazy(
		() => import('../pages/media-pages/systemManagement/DepartmentModels'),
	),

	// 组织
	ORGANIZATION_MODEL: lazy(
		() => import('../pages/media-pages/systemManagement/OrganizationModel'),
	),


	// 套餐列表
	PACKAGE_INFO: lazy(
		() => import('../pages/media-pages/orderModel/PackageInfo'),
	),

	// 订单列表
	ORDER_LIST: lazy(
		() => import('../pages/media-pages/orderModel/OrderList'),
	),

	// 消耗列表
	DOWNLOAD_LIST: lazy(
		() => import('../pages/media-pages/orderModel/DownloadList'),
	),


	ABOUT: lazy(() => import('../pages/media-pages/other/About')),



	COMPREHENSIVE_LIST: lazy(() => import('../pages/media-pages/mobilePhoneScanModel/ComprehensiveList')),
};




const mediaAppointment = [
	//仪表盘
	{
		path: mediaPages.dashboard.path,
		element: <MEDIA_FILE.DASHBOARD />,
		exact: true,
	},

	//播放历史
	{
		path: mediaPages.playManagement.subMenu.playHistoryList.path,
		element: <MEDIA_FILE.PLAY_HISTORY_LIST />,
		exact: true,
	},

	//设备默认播放
	{
		path: mediaPages.playManagement.subMenu.defaultPlayList.path,
		element: <MEDIA_FILE.DEFAULT_PLAYBACK />,
		exact: true,
	},

	//任务列表
	{
		path: mediaPages.taskManagement.subMenu.taskList.path,
		element: <MEDIA_FILE.TASK_LISTS />,
		exact: true,
	},

	//预案列表
	{
		path: mediaPages.planManagement.subMenu.planList.path,
		element: <MEDIA_FILE.PLAN_LIST />,
		exact: true,
	},

	//区域列表
	{
		path: mediaPages.equipmentManagement.subMenu.regionList.path,
		element: <MEDIA_FILE.REGION_LIST />,
		exact: true,
	},

	//模板列表
	{
		path: mediaPages.programManagement.subMenu.templateList.path,
		element: <MEDIA_FILE.TEMPLATE_LIST />,
		exact: true,
	},

	//节目列表
	{
		path: mediaPages.programManagement.subMenu.programList.path,
		element: <MEDIA_FILE.PROGRAM_LIST />,
		exact: true,
	},

	//节目排程
	{
		path: mediaPages.playManagement.subMenu.schedulingList.path,
		element: <MEDIA_FILE.SCHEDULING_LIST />,
		exact: true,
	},

	//节目排程
	{
		path: mediaPages.playManagement.subMenu.schedulingLists.path,
		element: <MEDIA_FILE.SCHEDULING_LISTS />,
		exact: true,
	},

	//节目组
	{
		path: mediaPages.programManagement.subMenu.programGroupList.path,
		element: <MEDIA_FILE.PROGRAM_GROUP_LIST />,
		exact: true,
	},

	// 选择设备组
	{
		path: `${mediaPages.programManagement.subMenu.issuedDeviceGroupOperate.path}/:layoutId/:layoutCode`,
		element: <MEDIA_FILE.ISSUED_DEVICE_GROUP_OPERATE />,
		exact: true,
	},

	// 选择设备
	{
		path: `${mediaPages.programManagement.subMenu.issuedDeviceOperate.path}/:layoutId/:layoutCode`,
		element: <MEDIA_FILE.ISSUED_DEVICE_OPERATE />,
		exact: true,
	},

	// 人流统计
	{
		path: `${mediaPages.peopleCountingModel.subMenu.abortionList.path}`,
		element: <MEDIA_FILE.ABORTION_LIST />,
		exact: true,
	},

	// 人流统计
	{
		path: `${mediaPages.peopleCountingModel.subMenu.abortionSumList.path}`,
		element: <MEDIA_FILE.ABORTION_SUM_LIST />,
		exact: true,
	},

	//素材库
	{
		path: mediaPages.programManagement.subMenu.materialLibrary.path,
		element: <MEDIA_FILE.MATERIAL_LIBRARY />,
		exact: true,
	},

	//监控流
	{
		path: mediaPages.programManagement.subMenu.monitoringFlow.path,
		element: <MEDIA_FILE.MONITOR_LIST />,
		exact: true,
	},

	//输入流
	{
		path: mediaPages.panelPointModel.subMenu.panelPointList.path,
		element: <MEDIA_FILE.INPUT_STREAM_LIST />,
		exact: true,
	},

	//紧急插播
	{
		path: mediaPages.playManagement.subMenu.insertionList.path,
		element: <MEDIA_FILE.INSERTION_LIST />,
		exact: true,
	},

	//添加节目
	{
		path: `${mediaPages.programManagement.subMenu.programAdd.path}/:templateId`,
		element: <MEDIA_FILE.PROGRAM_ADD />,
		exact: true,
	},

	//编辑节目
	{
		path: `${mediaPages.programManagement.subMenu.programEdit.path}/:layoutId`,
		element: <MEDIA_FILE.PROGRAM_EDIT />,
		exact: true,
	},

	//添加模板
	{
		path: `${mediaPages.programManagement.subMenu.templateAdd.path}/:id`,
		element: <MEDIA_FILE.TEMPLATE_ADD />,
		exact: true,
	},

	//编辑模板
	{
		path: `${mediaPages.programManagement.subMenu.templateEdit.path}/:templateId`,
		element: <MEDIA_FILE.TEMPLATE_EDIT />,
		exact: true,
	},

	//设备类型列表
	{
		path: mediaPages.panelPointModel.subMenu.panelPointTypeList.path,
		element: <MEDIA_FILE.EQUIPMENT_TYPE_LIST />,
		exact: true,
	},

	//屏幕列表
	{
		path: mediaPages.equipmentManagement.subMenu.screenList.path,
		element: <MEDIA_FILE.SCREEN_LIST />,
		exact: true,
	},

	//坐席列表
	{
		path: mediaPages.equipmentManagement.subMenu.seatsList.path,
		element: <MEDIA_FILE.SEATS_LIST />,
		exact: true,
	},

	//设备列表
	{
		path: mediaPages.equipmentManagement.subMenu.equipmentList.path,
		element: <MEDIA_FILE.EQUIPMENT_LIST />,
		exact: true,
	},

	{
		path: mediaPages.equipmentManagement.subMenu.equipmentLists.path,
		element: <MEDIA_FILE.EQUIPMENT_LISTS />,
		exact: true,
	},

	//设备组
	{
		path: mediaPages.equipmentManagement.subMenu.equipmentGroupList.path,
		element: <MEDIA_FILE.EQUIPMENT_GROUP_LIST />,
		exact: true,
	},

	//定时开关机
	{
		path: mediaPages.equipmentManagement.subMenu.fixedTimeList.path,
		element: <MEDIA_FILE.FIXED_TIME_LIST />,
		exact: true,
	},



	//更新包类型
	{
		path: mediaPages.packageModel.subMenu.packageTypeList.path,
		element: <MEDIA_FILE.PACKAGE_TYPE_LIST />,
		exact: true,
	},
	//更新包控制
	{
		path: mediaPages.packageModel.subMenu.packageList.path,
		element: <MEDIA_FILE.VERSION_CONTROL />,
		exact: true,
	},
	//更新包上传控制
	{
		path: mediaPages.packageModel.subMenu.packageUpload.path,
		element: <MEDIA_FILE.VERSION_UPLOAD />,
		exact: true,
	},
	//操作日志
	{
		path: mediaPages.logModel.subMenu.operationLogManagement.path,
		element: <MEDIA_FILE.OPERATION_LOG_MANAGEMENT />,
		exact: true
	},

	//操作日志
	{
		path: mediaPages.logModel.subMenu.errorLogManagement.path,
		element: <MEDIA_FILE.ERROR_LOG_MANAGEMENT />,
		exact: true
	},

	//用户管理
	{
		path: mediaPages.systemManagement.subMenu.userManagement.path,
		element: <MEDIA_FILE.USER_MANAGEMENT />,
		exact: true,
	},

	//角色管理
	{
		path: mediaPages.systemManagement.subMenu.roleManagement.path,
		element: <MEDIA_FILE.ROLE_MANAGEMENT />,
		exact: true,
	},

	//角色操作
	{
		path: mediaPages.systemManagement.subMenu.operateRoleManagement.path + "/:powerId",
		element: <MEDIA_FILE.OPERATE_ROLE_MANAGEMENT />,
		exact: true,
	},

	//菜单管理
	{
		path: mediaPages.systemManagement.subMenu.menuManagement.path,
		element: <MEDIA_FILE.MENU_MANAGEMENT />,
		exact: true,
	},

	//配置管理
	{
		path: mediaPages.systemManagement.subMenu.configurationManagement.path,
		element: <MEDIA_FILE.CONFIGURATION_MANAGEMENT />,
		exact: true,
	},

	//部门管理
	{
		path: mediaPages.systemManagement.subMenu.departmentModel.path,
		element: <MEDIA_FILE.DIVISIONAL_MANAGEMENT />,
		exact: true,
	},

	//部门管理
	{
		path: mediaPages.systemManagement.subMenu.departmentModels.path,
		element: <MEDIA_FILE.DIVISIONAL_MANAGEMENTS />,
		exact: true,
	},

	//组织管理
	{
		path: mediaPages.systemManagement.subMenu.organizationModel.path,
		element: <MEDIA_FILE.ORGANIZATION_MODEL />,
		exact: true,
	},

	// 套餐列表
	{
		path: mediaPages.orderModel.subMenu.packageInfo.path,
		element: <MEDIA_FILE.PACKAGE_INFO />,
		exact: true
	},

	// 订单列表
	{
		path: mediaPages.orderModel.subMenu.orderList.path,
		element: <MEDIA_FILE.ORDER_LIST />,
		exact: true
	},

	// 消耗列表
	{
		path: mediaPages.orderModel.subMenu.downloadList.path,
		element: <MEDIA_FILE.DOWNLOAD_LIST />,
		exact: true
	},

	{
		path: mediaPages.logModel.subMenu.downloadLogManagement.path,
		element: <MEDIA_FILE.DOWNLOAD_LOG_MANAGEMENT />,
		exact: true
	},

	//
	{
		path: mediaPages.mobilePhoneScanModel.subMenu.empowerVersionList.path,
		element: <MEDIA_FILE.COMPREHENSIVE_LIST />,
		exact: true
	},

	//关于
	{
		path: mediaPages.about.path,
		element: <MEDIA_FILE.ABOUT />,
		exact: true,
	},
];

/**
 * 人体无感识别
 */
const IDENTIFY_FILE = {
	DASHBOARD: lazy(() => import('../pages/identify-pages/other/Dashboard')),
	HUMANIDENTIFY: lazy(
		() => import('../pages/identify-pages/identifyManagement/humanIdentify'),
	),
	Camera: lazy(
		() => import('../pages/identify-pages/identifyManagement/Camera'),
	),
	PRODUCTS_VIEW1: lazy(
		() => import('../pages/identify-pages/identifyManagement/ProductViewPage'),
	),
};
const identifyAppointment = [
	{
		path: "identify/dashboard",
		element: <IDENTIFY_FILE.DASHBOARD />,
		exact: true,
	},
	{
		path: "identify/identifyManagement/human-identify",
		element: <IDENTIFY_FILE.HUMANIDENTIFY />,
		exact: true,
	},
	{
		path: "identify/identifyManagement/camera",
		element: <IDENTIFY_FILE.Camera />,
		exact: true,
	},
	{
		path: `identify/identifyManagement/camera/product/:id`,
		element: <IDENTIFY_FILE.PRODUCTS_VIEW1 />,
		exact: true,
	},
];


/**
 * 访客预约
 */
const VISITOR_FILE = {
	DASHBOARD: lazy(() => import('../pages/visitor-pages/other/Dashboard2')),
	PERSON: lazy(() => import('../pages/visitor-pages/PersonnelManagement')),
	SIGNATUREREVIEW: lazy(() => import('../pages/visitor-pages/signatureManagement/SignatureReview')),
	ACCESSTRAJECTORY: lazy(() => import('../pages/visitor-pages/signatureManagement/AccessTrajectory')),
	TRAJECTORYImg: lazy(() => import('../pages/visitor-pages/signatureManagement/TrajectoryImg'),),
	SIGNATURELIST: lazy(() => import('../pages/visitor-pages/signatureManagement/SignatureList')),
	INVITATIONLIST: lazy(() => import('../pages/visitor-pages/signatureManagement/InvitationList')),
	SIGNATURESTATEMENT: lazy(() => import('../pages/visitor-pages/signatureManagement/SignatureStatement')),
	RegionalManagement: lazy(() => import('../pages/visitor-pages/signatureManagement/RegionalManagement')),
	USEROPERATIONLOG: lazy(() => import('../pages/visitor-pages/systemManagement/UserOperationLog')),
	NOTICELOG: lazy(() => import('../pages/visitor-pages/systemManagement/NoticeLog')),
	Camera: lazy(() => import('../pages/visitor-pages/systemSetting/Camera')),
	RuleManagement: lazy(() => import('../pages/visitor-pages/systemSetting/RuleManagement')),

	// 运营配置
	OperationConfiguration: lazy(() => import('../pages/visitor-pages/systemManagement/OperationConfiguration')),
};

const visitorAppointment = [
	{
		path: "visitor/dashboard",
		element: <VISITOR_FILE.DASHBOARD />,
		exact: true,
	},
	{
		path: "visitor/personnelManagement",
		element: <VISITOR_FILE.PERSON />,
		exact: true,
	},
	{
		path: "visitor/signatureManagement/signatureReview",
		element: <VISITOR_FILE.SIGNATUREREVIEW />,
		exact: true,
	},
	{
		path: "visitor/signatureManagement/accessTrajectory",
		element: <VISITOR_FILE.ACCESSTRAJECTORY />,
		exact: true,
	},
	{
		path: "visitor/signatureManagement/trajectoryImg",
		element: <VISITOR_FILE.TRAJECTORYImg />,
		exact: true,
	},
	{
		path: "visitor/signatureManagement/signatureList",
		element: <VISITOR_FILE.SIGNATURELIST />,
		exact: true,
	},
	{
		path: "visitor/signatureManagement/invitationList",
		element: <VISITOR_FILE.INVITATIONLIST />,
		exact: true,
	},
	{
		path: "visitor/signatureManagement/signatureStatement",
		element: <VISITOR_FILE.SIGNATURESTATEMENT />,
		exact: true,
	},
	{
		path: "visitor/RegionalManagement",
		element: <VISITOR_FILE.RegionalManagement />,
		exact: true,
	},
	{
		path: "visitor/systemManagement/userOperationLog",
		element: <VISITOR_FILE.USEROPERATIONLOG />,
		exact: true,
	},
	{
		path: "visitor/systemManagement/noticeLog",
		element: <VISITOR_FILE.NOTICELOG />,
		exact: true,
	},
	{
		path: "visitor/systemSetting/Camera",
		element: <VISITOR_FILE.Camera />,
		exact: true,
	},
	{
		path: "visitor/ruleManagement",
		element: <VISITOR_FILE.RuleManagement />,
		exact: true,
	},
	{
		path: "visitor/operationConfiguration",
		element: <VISITOR_FILE.OperationConfiguration />,
		exact: true,
	},
];

/**
 * 会议预约
 */
const MEETING_FILE = {
	DASHBOARD: lazy(() => import('../pages/meeting-pages/other/Dashboard2')),
	DASHBOARD2: lazy(() => import('../pages/meeting-pages/other/Dashboard')),

	APPOINTMENTMEETING: lazy(
		() => import('../pages/meeting-pages/bookingManagement/AppointmentMeeting'),
	),
	CALENDARSCHEDULEDMEETING: lazy(() => import('../pages/meeting-pages/bookingManagement/CalendarScheduledMeeting')),

	MY_MEETING: lazy(() => import('../pages/meeting-pages/bookingManagement/MyMeeting')),
	MEETING_MANAGEMENT: lazy(
		() => import('../pages/meeting-pages/bookingManagement/MeetingManagement'),
	),
	MEETING_REVIEW: lazy(() => import('../pages/meeting-pages/bookingManagement/MeetingReview')),

	REGIONAL_MANAGEMENT: lazy(
		() => import('../pages/meeting-pages/meetingRoomManagement/RegionalManagement'),
	),
	ROOM_MANAGEMENT: lazy(
		() => import('../pages/meeting-pages/meetingRoomManagement/RoomManagement'),
	),
	FILE_MANAGEMENT: lazy(
		() => import('../pages/meeting-pages/meetingRoomManagement/FileManagement'),
	),
	CAROUSEMANAGEMENT: lazy(
		() => import('../pages/meeting-pages/systemManagement/CarouselManagement'),
	),
	PERSONNEL_PREPOSITION: lazy(
		() => import('../pages/meeting-pages/meetingRoomManagement/PersonnelPreposition'),
	),
	// TABLET_TEMPLATE: lazy(() => import('../pages/meeting-pages/meetingRoomManagement/TabletTemplate')),
	PRODUCTS_GRID: lazy(
		() => import('../pages/meeting-pages/meetingRoomManagement/ProductsGridPage'),
	),
	PRODUCTS_VIEW: lazy(
		() => import('../pages/meeting-pages/meetingRoomManagement/ProductViewPage'),
	),
	PRODUCTS_VIEW2: lazy(
		() => import('../pages/meeting-pages/meetingRoomManagement/ProductViewPage2'),
	),
	// 墨水屏
	INKSCREEN_MANAGEMENT: lazy(
		() => import('../pages/meeting-pages/meetingRoomManagement/InkscreenManagement'),
	),
	INKSCREEN_TEMPLATE_MANAGEMENT: lazy(
		() => import('../pages/meeting-pages/meetingRoomManagement/InkscreenTemplateManagement'),
	),
	INKSCREEN_TEMPLATE_ADD: lazy(
		() => import('../pages/meeting-pages/meetingRoomManagement/InkscreenTemplateAdd'),
	),
	INKSCREEN_TEMPLATE_EDIT: lazy(
		() => import('../pages/meeting-pages/meetingRoomManagement/InkscreenTemplateEdit'),
	),
	INKSCREENTEST: lazy(
		() => import('../pages/meeting-pages/systemManagement/InkScreenTest'),
	),

	EQUIPMENT_LIST: lazy(() => import('../pages/meeting-pages/equipmentManagement/EquipmentList')),
	DEVICE_MANAGEMENT: lazy(() => import('../pages/meeting-pages/equipmentManagement/DeviceManagement')),
	// EQUIPMENT_GROUP_LIST: lazy(() => import('../pages/meeting-pages/equipmentManagement/EquipmentGroupList')),
	EQUIPMENTTYPE_LIST: lazy(
		() => import('../pages/meeting-pages/equipmentManagement/EquipmentTypeList'),
	),

	USER_MANAGEMENT: lazy(() => import('../pages/meeting-pages/systemManagement/UserManagement')),
	ROLE_MANAGEMENT: lazy(() => import('../pages/meeting-pages/systemManagement/RoleManagement')),
	MENU_MANAGEMENT: lazy(() => import('../pages/meeting-pages/systemManagement/MenuManagement')),
	RENT_MANAGEMENT: lazy(() => import('../pages/meeting-pages/rentManagement/rentPriceManagement')),
	ORDER_MANAGEMENT: lazy(() => import('../pages/meeting-pages/rentManagement/rentOrderManagement')),
	ORDER_MANAGEMENTS: lazy(() => import('../pages/meeting-pages/rentManagement/rentOrderManagements')),
	CONFIGURATION_MANAGEMENT: lazy(
		() => import('../pages/meeting-pages/systemManagement/ConfigurationManagement'),
	),
	DIVISIONAL_MANAGEMENT: lazy(
		() => import('../pages/meeting-pages/systemManagement/DivisionalManagement'),
	),
	ORGANIZATION_MANAGEMENT: lazy(
		() => import('../pages/meeting-pages/systemManagement/OrganizationManagement'),
	),

	MEETING_FREE: lazy(
		() => import('../pages/meeting-pages/statementManagement/MeetingFree2'),
	),
	BODYSTATEMENT: lazy(
		() => import('../pages/meeting-pages/statementManagement/BodyStatement'),
	),
	MEETING_STATEMENT: lazy(
		() => import('../pages/meeting-pages/statementManagement/MeetingStatement'),
	),
	MEETING_SIGNATURE: lazy(
		() => import('../pages/meeting-pages/statementManagement/MeetingSignature'),
	),
	MEETING_SIGNATURE_LEAVE: lazy(
		() => import('../pages/meeting-pages/statementManagement/MeetingSignatureLeave'),
	),
	MEETING_DECISION: lazy(
		() => import('../pages/meeting-pages/statementManagement/MeetingDecision'),
	),
	MEETING_EMAILREPLY: lazy(
		() => import('../pages/meeting-pages/statementManagement/MeetingEmailReply'),
	),
	MEETING_STATEMENT2: lazy(
		() => import('../pages/meeting-pages/statementManagement/MeetingStatement2'),
	),
	MEETING_SUMMARY: lazy(
		() => import('../pages/meeting-pages/statementManagement/MeetingSummary'),
	),
	MEETING_SERVICE: lazy(
		() => import('../pages/meeting-pages/statementManagement/MeetingService'),
	),
	MEETING_SIGNATURE2: lazy(
		() => import('../pages/meeting-pages/statementManagement/MeetingSignature2'),
	),
	MEETING_VIDEO: lazy(
		() => import('../pages/meeting-pages/statementManagement/VideoMeetingManagement'),
	),
	MEETING_QUESTIONNAIRE: lazy(
		() => import('../pages/meeting-pages/statementManagement/MeetingQuestionnaire'),
	),
	MEETING_INVITATION: lazy(
		() => import('../pages/meeting-pages/statementManagement/MeetingInvitation'),
	),

	ACTION_LOG: lazy(() => import('../pages/meeting-pages/logManagement/ActionLog')),
	API_LOG: lazy(() => import('../pages/meeting-pages/logManagement/ApiLog')),
	TIMETASKS: lazy(() => import('../pages/meeting-pages/logManagement/TimeTasks')),

	DATABASE_BACKUP: lazy(() => import('../pages/meeting-pages/databaseManagement/DatabaseBackup')),
	DATABASE_REDUCTION: lazy(
		() => import('../pages/meeting-pages/databaseManagement/DatabaseReduction'),
	),

	ABOUT: lazy(() => import('../pages/meeting-pages/other/About')),
	REPAIR: lazy(() => import('../pages/meeting-pages/other/Repair')),

	//安装包列表
	InstallListManage: lazy(
		() => import('../pages/meeting-pages/systemManagement/InstallListManage'),
	),
	//安装包管理
	InstallationPackageManagement: lazy(
		() => import('../pages/meeting-pages/systemManagement/InstallationPackageManagement'),
	),

	//会议室租赁管理
	MeetingSummary: lazy(
		() => import('../pages/meeting-pages/meetingRoomLeaseManagement/MeetingSummary'),
	),
	MeetingRoomRental: lazy(
		() => import('../pages/meeting-pages/meetingRoomLeaseManagement/MeetingRoomRental'),
	),
	LeasedEquipmentManagement: lazy(
		() => import('../pages/meeting-pages/meetingRoomLeaseManagement/LeasedEquipmentManagement'),
	),
};


const meetingAppointment = [
	{
		path: meetingRoomPages.dashboard.path,
		element: <MEETING_FILE.DASHBOARD />,
		exact: true,
	},
	{
		path: "meeting/dashboard2",
		element: <MEETING_FILE.DASHBOARD2 />,
		exact: true,
	},
	{
		path: meetingRoomPages.bookingManagement.subMenu.appointmentMeeting.path,
		element: <MEETING_FILE.APPOINTMENTMEETING />,
		exact: true,
	},
	{
		path: meetingRoomPages.bookingManagement.subMenu.calendarScheduledMeeting.path,
		element: <MEETING_FILE.CALENDARSCHEDULEDMEETING />,
		exact: true,
	},
	{
		path: meetingRoomPages.bookingManagement.subMenu.myMeeting.path,
		element: <MEETING_FILE.MY_MEETING />,
		exact: true,
	},
	{
		path: meetingRoomPages.bookingManagement.subMenu.meetingManagement.path,
		element: <MEETING_FILE.MEETING_MANAGEMENT />,
		exact: true,
	},
	{
		path: meetingRoomPages.bookingManagement.subMenu.meetingReview.path,
		element: <MEETING_FILE.MEETING_REVIEW />,
		exact: true,
	},

	{
		path: meetingRoomPages.meetingRoomManagement.subMenu.regionalManagement.path,
		element: <MEETING_FILE.REGIONAL_MANAGEMENT />,
		exact: true,
	},
	{
		path: meetingRoomPages.meetingRoomManagement.subMenu.roomManagement.path,
		element: <MEETING_FILE.ROOM_MANAGEMENT />,
		exact: true,
	},
	{
		path: "meeting/meetingRoomManagement/FileManagement",
		element: <MEETING_FILE.FILE_MANAGEMENT />,
		exact: true,
	},
	{
		path: "meeting/systemManagement/CarouselManagement",
		element: <MEETING_FILE.CAROUSEMANAGEMENT />,
		exact: true,
	},
	{
		path: meetingRoomPages.meetingRoomManagement.subMenu.personnelPreposition.path,
		element: <MEETING_FILE.PERSONNEL_PREPOSITION />,
		exact: true,
	},
	// {
	// 	path: meetingRoomPages.meetingRoomManagement.subMenu.tabletTemplate.path,
	// 	element: <MEETING_FILE.TABLET_TEMPLATE />,
	// 	exact: true,
	// },
	{
		path: meetingRoomPages.meetingRoomManagement.subMenu.productsGrid.path,
		element: <MEETING_FILE.PRODUCTS_GRID />,
		exact: true,
	},
	{
		path: `${meetingRoomPages.meetingRoomManagement.subMenu.productID.path}/:id`,
		element: <MEETING_FILE.PRODUCTS_VIEW />,
		exact: true,
	},
	{
		path: `${meetingRoomPages.meetingRoomManagement.subMenu.productID2.path}/:id`,
		element: <MEETING_FILE.PRODUCTS_VIEW2 />,
		exact: true,
	},
	//墨水屏
	{
		path: meetingRoomPages.meetingRoomManagement.subMenu.inkscreenManagement.path,
		element: <MEETING_FILE.INKSCREEN_MANAGEMENT />,
		exact: true,
	},
	{
		path: meetingRoomPages.meetingRoomManagement.subMenu.inkscreenTemplateManagement.path,
		element: <MEETING_FILE.INKSCREEN_TEMPLATE_MANAGEMENT />,
		exact: true,
	},
	{
		path: `${meetingRoomPages.meetingRoomManagement.subMenu.inkscreenTemplateAdd.path}/:id`,
		element: <MEETING_FILE.INKSCREEN_TEMPLATE_ADD />,
		exact: true,
	},
	{
		path: `${meetingRoomPages.meetingRoomManagement.subMenu.inkscreenTemplateEdit.path}/:id`,
		element: <MEETING_FILE.INKSCREEN_TEMPLATE_EDIT />,
		exact: true,
	},
	// 墨水屏测试
	{
		path: meetingRoomPages.systemManagement.subMenu.inkScreenTest.path,
		element: <MEETING_FILE.INKSCREENTEST />,
		exact: true,
	},
	{
		path: meetingRoomPages.equipmentManagement.subMenu.equipmentList.path,
		element: <MEETING_FILE.EQUIPMENT_LIST />,
		exact: true,
	},
	{
		path: "meeting/equipmentManagement/DeviceManagement",
		element: <MEETING_FILE.DEVICE_MANAGEMENT />,
		exact: true,
	},
	// {
	// 	path: meetingRoomPages.equipmentManagement.subMenu.equipmentGroupList.path,
	// 	element: <MEETING_FILE.EQUIPMENT_GROUP_LIST />,
	// 	exact: true,
	// },
	{
		path: meetingRoomPages.equipmentManagement.subMenu.equipmentTypeList.path,
		element: <MEETING_FILE.EQUIPMENTTYPE_LIST />,
		exact: true,
	},

	{
		path: meetingRoomPages.systemManagement.subMenu.userManagement.path,
		element: <MEETING_FILE.USER_MANAGEMENT />,
		exact: true,
	},
	{
		path: meetingRoomPages.systemManagement.subMenu.roleManagement.path,
		element: <MEETING_FILE.ROLE_MANAGEMENT />,
		exact: true,
	},
	{
		path: meetingRoomPages.systemManagement.subMenu.menuManagement.path,
		element: <MEETING_FILE.MENU_MANAGEMENT />,
		exact: true,
	},
	{
		path: "meeting/rentManagement/rentPriceManagement",
		element: <MEETING_FILE.RENT_MANAGEMENT />,
		exact: true,
	},
	{
		path: "meeting/rentManagement/rentOrderManagement",
		element: <MEETING_FILE.ORDER_MANAGEMENT />,
		exact: true,
	},
	{
		path: "meeting/rentManagement/rentOrderManagements",
		element: <MEETING_FILE.ORDER_MANAGEMENTS />,
		exact: true,
	},
	{
		path: "meeting/statementManagement/MeetingFree",
		element: <MEETING_FILE.MEETING_FREE />,
		exact: true,
	},
	{
		path: "meeting/statementManagement/BodyStatement",
		element: <MEETING_FILE.BODYSTATEMENT />,
		exact: true,
	},
	{
		path: meetingRoomPages.systemManagement.subMenu.organizationManagement.path,
		element: <MEETING_FILE.ORGANIZATION_MANAGEMENT />,
		exact: true,
	},
	{
		path: meetingRoomPages.systemManagement.subMenu.configurationManagement.path,
		element: <MEETING_FILE.CONFIGURATION_MANAGEMENT />,
		exact: true,
	},
	{
		path: meetingRoomPages.systemManagement.subMenu.divisionalManagement.path,
		element: <MEETING_FILE.DIVISIONAL_MANAGEMENT />,
		exact: true,
	},

	{
		path: meetingRoomPages.statementManagement.subMenu.meetingStatement.path,
		element: <MEETING_FILE.MEETING_STATEMENT />,
		exact: true,
	},
	{
		path: meetingRoomPages.statementManagement.subMenu.meetingSignature.path,
		element: <MEETING_FILE.MEETING_SIGNATURE_LEAVE />,
		exact: true,
	},
	{
		path: "meeting/statementManagement/MeetingDecision",
		element: <MEETING_FILE.MEETING_DECISION />,
		exact: true,
	},
	{
		path: "meeting/statementManagement/MeetingEmailReply",
		element: <MEETING_FILE.MEETING_EMAILREPLY />,
		exact: true,
	},
	{
		path: meetingRoomPages.meetingStatementManagement.path,
		element: <MEETING_FILE.MEETING_STATEMENT2 />,
		exact: true,
	},
	{
		path: "meeting/statementManagement/MeetingSummary",
		element: <MEETING_FILE.MEETING_SUMMARY />,
		exact: true,
	},
	{
		path: meetingRoomPages.meetingSignManagement.path,
		element: <MEETING_FILE.MEETING_SIGNATURE2 />,
		exact: true,
	},
	{
		path: "meeting/statementManagement/MeetingService",
		element: <MEETING_FILE.MEETING_SERVICE />,
		exact: true,
	},
	{
		path: meetingRoomPages.statementManagement.subMenu.meetingVideo.path,
		element: <MEETING_FILE.MEETING_VIDEO />,
		exact: true,
	},
	{
		path: meetingRoomPages.statementManagement.subMenu.meetingQuestionnaire.path,
		element: <MEETING_FILE.MEETING_QUESTIONNAIRE />,
		exact: true,
	},
	{
		path: meetingRoomPages.statementManagement.subMenu.meetingInvitation.path,
		element: <MEETING_FILE.MEETING_INVITATION />,
		exact: true,
	},

	{
		path: meetingRoomPages.logManagement.subMenu.actionLog.path,
		element: <MEETING_FILE.ACTION_LOG />,
		exact: true,
	},
	{
		path: 'meeting/logManagement/api-log',
		element: <MEETING_FILE.API_LOG />,
		exact: true,
	},
	{
		path: 'meeting/logManagement/time-tasks',
		element: <MEETING_FILE.TIMETASKS />,
		exact: true,
	},

	{
		path: meetingRoomPages.databaseManagement.subMenu.databaseBackup.path,
		element: <MEETING_FILE.DATABASE_BACKUP />,
		exact: true,
	},
	{
		path: meetingRoomPages.databaseManagement.subMenu.databaseReduction.path,
		element: <MEETING_FILE.DATABASE_REDUCTION />,
		exact: true,
	},

	{
		path: meetingRoomPages.repairCenter.path,
		element: <MEETING_FILE.REPAIR />,
		exact: true,
	},

	{
		path: meetingRoomPages.about.path,
		element: <MEETING_FILE.ABOUT />,
		exact: true,
	},

	{
		path: "meeting/systemManagement/InstallListManage",
		element: <MEETING_FILE.InstallListManage />,
		exact: true,
	},
	//安装包上传
	{
		path: "meeting/systemManagement/installationPackageManagement",
		element: <MEETING_FILE.InstallationPackageManagement />,
		exact: true,
	},


	// 会议室租赁管理
	{
		path: meetingRoomPages.meetingRoomLeaseManagement.subMenu.meetingSummary.path,
		element: <MEETING_FILE.MeetingSummary />,
		exact: true,
	},
	{
		path: meetingRoomPages.meetingRoomLeaseManagement.subMenu.meetingRoomRental.path,
		element: <MEETING_FILE.MeetingRoomRental />,
		exact: true,
	},
	{
		path: meetingRoomPages.meetingRoomLeaseManagement.subMenu.leasedEquipmentManagement.path,
		element: <MEETING_FILE.LeasedEquipmentManagement />,
		exact: true,
	},
];

/**
 * 访客考勤后台管理
 */
const ATTENDANCE_FILE = {
	//未完成会议列表
	NotCompletedMeeting: lazy(() => import('../pages/attendance-pages/meetingList/NotCompletedMeeting')),

	//会议详情
	MeetingDetails: lazy(() => import('../pages/attendance-pages/meetingList/_details/MeetingDetails')),

	//会议详情页
	MeetingDetail: lazy(() => import('../pages/attendance-pages/meetingList/_details/MeetingDetail')),

	//参会人员
	Participants: lazy(() => import('../pages/attendance-pages/meetingList/_details/Participants')),

	//添加参会人员
	AddParticipants: lazy(() => import('../pages/attendance-pages/meetingList/_details/AddParticipants')),

	//文件管理
	FileManagement: lazy(() => import('../pages/attendance-pages/meetingList/_details/FileManagement')),

	//视频管理
	VideoManagement: lazy(() => import('../pages/attendance-pages/meetingList/_details/VideoManagement')),

	//表决管理
	VotingManagement: lazy(
		() => import('../pages/attendance-pages/meetingList/_details/VotingManagement'),
	),

	//表决详细
	VotingDetailedManagement: lazy(
		() => import('../pages/attendance-pages/meetingList/_details/VotingDetailedManagement'),
	),

	//表决统计
	VotingStatistics: lazy(
		() => import('../pages/attendance-pages/meetingList/_details/VotingStatistics'),
	),

	//投票组管理
	VotingGroupManagement: lazy(
		() => import('../pages/attendance-pages/meetingList/_details/VotingGroupManagement'),
	),

	VotingGroupDetailedManagement: lazy(
		() => import('../pages/attendance-pages/meetingList/_details/VotingGroupDetailedManagement'),
	),

	//消息管理
	InformationManagement: lazy(
		() => import('../pages/attendance-pages/meetingList/_details/InformationManagement'),
	),

	//会议记录
	ConferenceRecords: lazy(
		() => import('../pages/attendance-pages/meetingList/_details/ConferenceRecords'),
	),

	//座位图管理
	SeatMapManagement: lazy(
		() => import('../pages/attendance-pages/meetingList/_details/SeatMapManagement'),
	),

	//座位图安排
	SeatMapArrangement: lazy(
		() => import('../pages/attendance-pages/meetingList/_details/SeatMapArrangement'),
	),

	//语音纪要
	VoiceSummary: lazy(
		() => import('../pages/attendance-pages/meetingList/_details/VoiceSummary'),
	),

	//会议预约
	AppointmentMeeting: lazy(() => import('../pages/attendance-pages/meetingList/AppointmentMeeting')),

	//已归档会议列表
	ArchivedMeeting: lazy(() => import('../pages/attendance-pages/meetingList/ArchivedMeeting')),

	//显示模板管理
	TemplateMeeting: lazy(() => import('../pages/attendance-pages/meetingList/TemplateMeeting')),

	//密码管理
	PasswordManagement: lazy(
		() => import('../pages/attendance-pages/personalAdministration/PasswordManagement'),
	),

	//人员管理
	PersonnelManagement: lazy(
		() => import('../pages/attendance-pages/personalAdministration/PersonnelManagement'),
	),
	//人员管理2
	PersonnelManagement2: lazy(
		() => import('../pages/attendance-pages/personalAdministration/PersonnelManagement2'),
	),

	//用户组管理
	UserGroupManagement: lazy(
		() => import('../pages/attendance-pages/personalAdministration/UserGroupManagement'),
	),
	//工作单位
	WorkUnitManagement: lazy(
		() => import('../pages/attendance-pages/personalAdministration/WorkUnitManagement'),
	),
	//班组管理
	TeamManagement: lazy(
		() => import('../pages/attendance-pages/personalAdministration/TeamManagement'),
	),

	//部门管理
	DepartmentManagement: lazy(
		() => import('../pages/attendance-pages/personalAdministration/DepartmentManagement'),
	),

	//管理员列表
	AdministratorList: lazy(
		() => import('../pages/attendance-pages/personalAdministration/AdministratorList'),
	),

	//用户搜索
	UserSearch: lazy(() => import('../pages/attendance-pages/personalAdministration/UserSearch')),

	//签到列表
	SignatureList: lazy(() => import('../pages/attendance-pages/signatureManagement/SignatureList')),

	//签到报表
	SignatureStatement: lazy(() => import('../pages/attendance-pages/signatureManagement/SignatureStatement')),

	//权限管理
	PermissionManagement: lazy(
		() => import('../pages/attendance-pages/systemSetting/PermissionManagement'),
	),
	//添加权限
	AddPermission: lazy(
		() => import('../pages/attendance-pages/systemSetting/AddPermission'),
	),

	//设备管理
	DeviceManagement: lazy(() => import('../pages/attendance-pages/systemSetting/DeviceManagement')),

	//会议室管理
	MeetingRoomManagement: lazy(
		() => import('../pages/attendance-pages/systemSetting/MeetingRoomManagement'),
	),

	//会议室管理
	MeetingRoomImg: lazy(
		() => import('../pages/attendance-pages/systemSetting/MeetingRoomImg'),
	),
	//区域管理
	AreaManagement: lazy(() => import('../pages/attendance-pages/systemSetting/AreaManagement')),

	//排班管理
	WorkforceManagement: lazy(() => import('../pages/attendance-pages/systemSetting/WorkforceManagement')),

	//请假管理
	LeaveManagement: lazy(() => import('../pages/attendance-pages/systemSetting/LeaveManagement')),

	//节假日管理
	HolidayManagement: lazy(() => import('../pages/attendance-pages/systemSetting/HolidayManagement')),

	//时段管理
	TimeSetting: lazy(() => import('../pages/attendance-pages/systemSetting/TimeSetting')),

	//菜单管理
	MenuManagement: lazy(() => import('../pages/attendance-pages/systemSetting/MenuManagement')),

	//用户操作日志
	UserOperationLog: lazy(() => import('../pages/attendance-pages/systemSetting/UserOperationLog')),

	//座位管理
	SeatManagement: lazy(() => import('../pages/attendance-pages/systemSetting/SeatManagement')),


}

const attendanceAppointment = [
	//未完成会议列表
	{
		path: attendancePages.meetingList.subMenu.notCompletedMeeting.path,
		element: <ATTENDANCE_FILE.NotCompletedMeeting />,
		exact: true,
	},
	//会议详情
	{
		path: 'attendance/meetingList/_details/meetingDetails/:id',
		element: <ATTENDANCE_FILE.MeetingDetails />,
		exact: true,
		hideInMenu: true,
		componment: { MeetingDetails },
	},
	//会议详情页
	{
		path: 'attendance/meetingList/_details/meetingDetail/:id',
		element: <ATTENDANCE_FILE.MeetingDetail />,
		exact: true,
		hideInMenu: true,
		componment: { MeetingDetail },
	},
	//参会人员
	{
		path: 'attendance/meetingList/_details/participants/:id',
		element: <ATTENDANCE_FILE.Participants />,
		exact: true,
		hideInMenu: true,
	},
	//添加参会人员
	{
		path: 'attendance/meetingList/_details/addParticipants/:id',
		element: <ATTENDANCE_FILE.AddParticipants />,
		exact: true,
		hideInMenu: true,
	},
	//文件管理
	{
		path: 'attendance/meetingList/_details/fileManagement/:id',
		element: <ATTENDANCE_FILE.FileManagement />,
		exact: true,
		hideInMenu: true,
	},
	//视频管理
	{
		path: 'attendance/meetingList/_details/videoManagement/:id',
		element: <ATTENDANCE_FILE.VideoManagement />,
		exact: true,
		hideInMenu: true,
	},
	//表决管理
	{
		path: 'attendance-pages/meetingList/_details/votingManagement/:id',
		element: <ATTENDANCE_FILE.VotingManagement />,
		exact: true,
		hideInMenu: true,
	},

	//表决详细
	{
		path: 'attendance/meetingList/_details/votingDetailedManagement/:id',
		element: <ATTENDANCE_FILE.VotingDetailedManagement />,
		exact: true,
		hideInMenu: true,
	},

	//表决统计
	{
		path: 'attendance/meetingList/votingStatistics/:id',
		element: <ATTENDANCE_FILE.VotingStatistics />,
		exact: true,
		hideInMenu: true,
	},

	//投票组详细
	{
		path: 'attendance/meetingList/_details/votingGroupDetailedManagement/:id',
		element: <ATTENDANCE_FILE.VotingGroupDetailedManagement />,
		exact: true,
		hideInMenu: true,
	},

	//投票组管理
	{
		path: 'attendance/meetingList/_details/votingGroupManagement/:id',
		element: <ATTENDANCE_FILE.VotingGroupManagement />,
		exact: true,
		hideInMenu: true,
	},
	//消息管理
	{
		path: 'attendance/meetingList/_details/informationManagement/:id',
		element: <ATTENDANCE_FILE.InformationManagement />,
		exact: true,
		hideInMenu: true,
	},
	//会议记录
	{
		path: 'attendance/meetingList/_details/conferenceRecords/:id',
		element: <ATTENDANCE_FILE.ConferenceRecords />,
		exact: true,
		hideInMenu: true,
	},
	//座位图管理
	{
		path: 'attendance/meetingList/_details/seatMapManagement/:id',
		element: <ATTENDANCE_FILE.SeatMapManagement />,
		exact: true,
		hideInMenu: true,
	},
	//座位图安排
	{
		path: 'attendance/meetingList/_details/seatMapArrangement/:id',
		element: <ATTENDANCE_FILE.SeatMapArrangement />,
		exact: true,
		hideInMenu: true,
	},
	//语音纪要
	{
		path: 'attendance/meetingList/_details/voiceSummary/:id',
		element: <ATTENDANCE_FILE.VoiceSummary />,
		exact: true,
		hideInMenu: true,
	},
	//会议预约
	{
		path: attendancePages.meetingList.subMenu.appointmentMeeting.path,
		element: <ATTENDANCE_FILE.AppointmentMeeting />,
		exact: true,
	},
	//已归档会议列表
	{
		path: attendancePages.meetingList.subMenu.archivedMeeting.path,
		element: <ATTENDANCE_FILE.ArchivedMeeting />,
		exact: true,
	},
	//显示模板管理
	{
		path: attendancePages.meetingList.subMenu.templateMeeting.path,
		element: <ATTENDANCE_FILE.TemplateMeeting />,
		exact: true,
	},
	//密码管理
	{
		path: attendancePages.personalAdministration.subMenu.passwordManagement.path,
		element: <ATTENDANCE_FILE.PasswordManagement />,
		exact: true,
	},
	//人员管理
	{
		path: attendancePages.personalAdministration.subMenu.personnelManagement.path,
		element: <ATTENDANCE_FILE.PersonnelManagement />,
		exact: true,
	},
	//人员管理2
	{
		path: attendancePages.personalAdministration.subMenu.personnelManagement2.path,
		element: <ATTENDANCE_FILE.PersonnelManagement2 />,
		exact: true,
	},
	//用户组管理
	{
		path: attendancePages.personalAdministration.subMenu.userGroupManagement.path,
		element: <ATTENDANCE_FILE.UserGroupManagement />,
		exact: true,
	},
	//工作单位
	{
		path: attendancePages.personalAdministration.subMenu.workUnitManagement.path,
		element: <ATTENDANCE_FILE.WorkUnitManagement />,
		exact: true,
	},
	//班组管理
	{
		path: attendancePages.personalAdministration.subMenu.teamManagement.path,
		element: <ATTENDANCE_FILE.TeamManagement />,
		exact: true,
	},
	//部门管理
	{
		path: attendancePages.personalAdministration.subMenu.departmentManagement.path,
		element: <ATTENDANCE_FILE.DepartmentManagement />,
		exact: true,
	},
	//管理员列表
	{
		path: attendancePages.personalAdministration.subMenu.administratorList.path,
		element: <ATTENDANCE_FILE.AdministratorList />,
		exact: true,
	},
	//用户搜索
	{
		path: attendancePages.personalAdministration.subMenu.userSearch.path,
		element: <ATTENDANCE_FILE.UserSearch />,
		exact: true,
	},
	//签到列表
	{
		path: attendancePages.signatureManagement.subMenu.signatureList.path,
		element: <ATTENDANCE_FILE.SignatureList />,
		exact: true,
	},
	//签到报表
	{
		path: attendancePages.signatureManagement.subMenu.signatureStatement.path,
		element: <ATTENDANCE_FILE.SignatureStatement />,
		exact: true,
	},
	//权限管理
	{
		path: attendancePages.systemSetting.subMenu.permissionManagement.path,
		element: <ATTENDANCE_FILE.PermissionManagement />,
		exact: true,
	},
	//添加权限
	{
		path: attendancePages.systemSetting.subMenu.addPermission.path,
		element: <ATTENDANCE_FILE.AddPermission />,
		exact: true,
	},
	//设备管理
	{
		path: attendancePages.systemSetting.subMenu.deviceManagement.path,
		element: <ATTENDANCE_FILE.DeviceManagement />,
		exact: true,
	},
	//会议室管理
	{
		path: attendancePages.systemSetting.subMenu.meetingRoomManagement.path,
		element: <ATTENDANCE_FILE.MeetingRoomManagement />,
		exact: true,
	},
	//座位图
	{
		path: 'attendance/systemSetting/meetingRoomImg/:id',
		element: <ATTENDANCE_FILE.MeetingRoomImg />,
		exact: true,
		hideInMenu: true,
	},
	//区域管理
	{
		path: attendancePages.systemSetting.subMenu.areaManagement.path,
		element: <ATTENDANCE_FILE.AreaManagement />,
		exact: true,
		hideInMenu: true,
	},
	//排班管理
	{
		path: attendancePages.systemSetting.subMenu.workforceManagement.path,
		element: <ATTENDANCE_FILE.WorkforceManagement />,
		exact: true,
		hideInMenu: true,
	},
	//请假管理
	{
		path: attendancePages.systemSetting.subMenu.leaveManagement.path,
		element: <ATTENDANCE_FILE.LeaveManagement />,
		exact: true,
		hideInMenu: true,
	},
	//节假日管理
	{
		path: attendancePages.systemSetting.subMenu.holidayManagement.path,
		element: <ATTENDANCE_FILE.HolidayManagement />,
		exact: true,
		hideInMenu: true,
	},
	//时段管理
	{
		path: attendancePages.systemSetting.subMenu.timeSetting.path,
		element: <ATTENDANCE_FILE.TimeSetting />,
		exact: true,
		hideInMenu: true,
	},
	//菜单管理
	{
		path: attendancePages.systemSetting.subMenu.menuManagement.path,
		element: <ATTENDANCE_FILE.MenuManagement />,
		exact: true,
	},
	//用户操作日志
	{
		path: attendancePages.systemSetting.subMenu.userOperationLog.path,
		element: <ATTENDANCE_FILE.UserOperationLog />,
		exact: true,
	},
	//座位管理
	{
		path: attendancePages.systemSetting.subMenu.seatManagement.path,
		element: <ATTENDANCE_FILE.SeatManagement />,
		exact: true,
	},
];


/**
 * 门禁系统
 */
const DOORCONTROLSYSTEM_FILE = {
	DASHBOARD: lazy(() => import('../pages/door-control-system-pages/other/Dashboard')),
	// 规则管理
	RuleManagement: lazy(() => import('../pages/door-control-system-pages/systemSetting/RuleManagement')),

	// 区域管理
	AreaManagement: lazy(() => import('../pages/door-control-system-pages/systemSetting/AreaManagement')),

	// 操作日志
	OperationLog: lazy(() => import('../pages/door-control-system-pages/systemSetting/OperationLog')),

	// 人员管理
	PersonnelManagement: lazy(() => import('../pages/door-control-system-pages/personalAdministration/PersonnelManagement')),

	// 用户组管理
	UserGroupManagement: lazy(() => import('../pages/door-control-system-pages/personalAdministration/UserGroupManagement')),

	// 部门管理
	DepartmentManagement: lazy(() => import('../pages/door-control-system-pages/personalAdministration/DepartmentManagement')),

	// 人员删除
	PersonnelDelete: lazy(() => import('../pages/door-control-system-pages/personalAdministration/PersonnelDelete')),

	// 设备列表
	DeviceList: lazy(() => import('../pages/door-control-system-pages/deviceManagement/DeviceList')),

	// 设备组
	DeviceGroup: lazy(() => import('../pages/door-control-system-pages/deviceManagement/DeviceGroup')),

	// 设备人员
	DevicePersonnel: lazy(() => import('../pages/door-control-system-pages/deviceManagement/DevicePersonnel')),

	// 设备列表
	DeviceList2: lazy(() => import('../pages/door-control-system-pages/deviceManagements/DeviceList')),

	// 设备组
	DeviceGroup2: lazy(() => import('../pages/door-control-system-pages/deviceManagements/DeviceGroup')),

	// 设备人员
	DevicePersonnel2: lazy(() => import('../pages/door-control-system-pages/deviceManagements/DevicePersonnel')),

	// 通行记录
	TrafficRecord: lazy(() => import('../pages/door-control-system-pages/trafficManagement/TrafficRecord')),

	// 通行报表
	TrafficReport: lazy(() => import('../pages/door-control-system-pages/trafficManagement/TrafficReport')),
}
const doorControlSystemAppointment = [
	{
		path: doorControlSystemPages.dashboard.path,
		element: <DOORCONTROLSYSTEM_FILE.DASHBOARD />,
		exact: true,
	},
	{
		path: doorControlSystemPages.systemSetting.subMenu.ruleManagement.path,
		element: <DOORCONTROLSYSTEM_FILE.RuleManagement />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: doorControlSystemPages.systemSetting.subMenu.areaManagement.path,
		element: <DOORCONTROLSYSTEM_FILE.AreaManagement />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: doorControlSystemPages.systemSetting.subMenu.operationLog.path,
		element: <DOORCONTROLSYSTEM_FILE.OperationLog />,
		exact: true,
		hideInMenu: true,
	},

	{
		path: doorControlSystemPages.personalAdministration.subMenu.personnelManagement.path,
		element: <DOORCONTROLSYSTEM_FILE.PersonnelManagement />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: doorControlSystemPages.personalAdministration.subMenu.userGroupManagement.path,
		element: <DOORCONTROLSYSTEM_FILE.UserGroupManagement />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: doorControlSystemPages.personalAdministration.subMenu.departmentManagement.path,
		element: <DOORCONTROLSYSTEM_FILE.DepartmentManagement />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: doorControlSystemPages.personalAdministration.subMenu.personnelDelete.path,
		element: <DOORCONTROLSYSTEM_FILE.PersonnelDelete />,
		exact: true,
		hideInMenu: true,
	},

	{
		path: doorControlSystemPages.deviceManagement.subMenu.deviceList.path,
		element: <DOORCONTROLSYSTEM_FILE.DeviceList />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: doorControlSystemPages.deviceManagement.subMenu.deviceGroup.path,
		element: <DOORCONTROLSYSTEM_FILE.DeviceGroup />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: doorControlSystemPages.deviceManagement.subMenu.devicePersonnel.path,
		element: <DOORCONTROLSYSTEM_FILE.DevicePersonnel />,
		exact: true,
		hideInMenu: true,
	},

	{
		path: "doorControlSystem/deviceManagements/deviceList",
		element: <DOORCONTROLSYSTEM_FILE.DeviceList2 />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: "doorControlSystem/deviceManagements/deviceGroup",
		element: <DOORCONTROLSYSTEM_FILE.DeviceGroup2 />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: "doorControlSystem/deviceManagements/devicePersonnel",
		element: <DOORCONTROLSYSTEM_FILE.DevicePersonnel2 />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: doorControlSystemPages.trafficManagement.subMenu.trafficRecord.path,
		element: <DOORCONTROLSYSTEM_FILE.TrafficRecord />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: doorControlSystemPages.trafficManagement.subMenu.trafficReport.path,
		element: <DOORCONTROLSYSTEM_FILE.TrafficReport />,
		exact: true,
		hideInMenu: true,
	},
];


/**
 * 无感考勤系统
 */
const STEALTHATTENDANCESYSTEM_FILE = {
	// 仪表盘
	DASHBOARD: lazy(() => import('../pages/stealth-attendance-system-pages/other/Dashboard')),
	// 仪表盘2
	DASHBOARD2: lazy(() => import('../pages/stealth-attendance-system-pages/other2/Dashboard')),

	// 人员管理
	PersonnelManagement: lazy(() => import('../pages/stealth-attendance-system-pages/personalAdministration/PersonnelManagement')),

	// 用户组管理
	UserGroupManagement: lazy(() => import('../pages/stealth-attendance-system-pages/personalAdministration/UserGroupManagement')),

	// 部门管理
	DepartmentManagement: lazy(() => import('../pages/stealth-attendance-system-pages/personalAdministration/DepartmentManagement')),

	// 识别记录
	IdentificationRecord: lazy(() => import('../pages/stealth-attendance-system-pages/attendanceManagement/IdentificationRecord')),

	// 考勤报表
	AttendanceReport: lazy(() => import('../pages/stealth-attendance-system-pages/attendanceManagement/AttendanceReport')),

	// 考勤汇总
	AttendanceSummary: lazy(() => import('../pages/stealth-attendance-system-pages/attendanceManagement/AttendanceSummary')),

	// 设备管理
	DeviceManagement: lazy(() => import('../pages/stealth-attendance-system-pages/systemSetting/DeviceManagement')),

	// 排班管理
	WorkforceManagement: lazy(() => import('../pages/stealth-attendance-system-pages/systemSetting/WorkforceManagement')),

	// 时段管理
	TimeSlotManagement: lazy(() => import('../pages/stealth-attendance-system-pages/systemSetting/TimeSlotManagement')),

	// 区域管理
	AreaManagement: lazy(() => import('../pages/stealth-attendance-system-pages/systemSetting/AreaManagement')),

	// 操作日志
	OperationLog: lazy(() => import('../pages/stealth-attendance-system-pages/systemSetting/OperationLog')),

	// 配置管理
	ConfigurationManagement: lazy(() => import('../pages/stealth-attendance-system-pages/systemSetting/ConfigurationManagement')),
}
const stealthAttendanceSystemAppointment = [
	// 仪表盘
	{
		path: stealthAttendanceSystemPages.dashboard.path,
		element: <STEALTHATTENDANCESYSTEM_FILE.DASHBOARD2 />,
		exact: true,
	},

	// 人事管理
	{
		path: stealthAttendanceSystemPages.personalAdministration.subMenu.personnelManagement.path,
		element: <STEALTHATTENDANCESYSTEM_FILE.PersonnelManagement />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: stealthAttendanceSystemPages.personalAdministration.subMenu.userGroupManagement.path,
		element: <STEALTHATTENDANCESYSTEM_FILE.UserGroupManagement />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: stealthAttendanceSystemPages.personalAdministration.subMenu.departmentManagement.path,
		element: <STEALTHATTENDANCESYSTEM_FILE.DepartmentManagement />,
		exact: true,
		hideInMenu: true,
	},

	//考勤管理
	{
		path: stealthAttendanceSystemPages.attendanceManagement.subMenu.identificationRecord.path,
		element: <STEALTHATTENDANCESYSTEM_FILE.IdentificationRecord />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: stealthAttendanceSystemPages.attendanceManagement.subMenu.attendanceReport.path,
		element: <STEALTHATTENDANCESYSTEM_FILE.AttendanceReport />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: stealthAttendanceSystemPages.attendanceManagement.subMenu.attendanceSummary.path,
		element: <STEALTHATTENDANCESYSTEM_FILE.AttendanceSummary />,
		exact: true,
		hideInMenu: true,
	},

	// 系统设置
	{
		path: stealthAttendanceSystemPages.systemSetting.subMenu.deviceManagement.path,
		element: <STEALTHATTENDANCESYSTEM_FILE.DeviceManagement />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: stealthAttendanceSystemPages.systemSetting.subMenu.workforceManagement.path,
		element: <STEALTHATTENDANCESYSTEM_FILE.WorkforceManagement />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: stealthAttendanceSystemPages.systemSetting.subMenu.timeSlotManagement.path,
		element: <STEALTHATTENDANCESYSTEM_FILE.TimeSlotManagement />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: stealthAttendanceSystemPages.systemSetting.subMenu.areaManagement.path,
		element: <STEALTHATTENDANCESYSTEM_FILE.AreaManagement />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: stealthAttendanceSystemPages.systemSetting.subMenu.operationLog.path,
		element: <STEALTHATTENDANCESYSTEM_FILE.OperationLog />,
		exact: true,
		hideInMenu: true,
	},
	{
		path: stealthAttendanceSystemPages.systemSetting.subMenu.configurationManagement.path,
		element: <STEALTHATTENDANCESYSTEM_FILE.ConfigurationManagement />,
		exact: true,
		hideInMenu: true,
	},
];


/**
 * 无纸化会议管理系统
 */
const PAPERLESS_FILE = {

	PdfPreview: lazy(() => import('../pages/paperless/PdfPreview')),

	//未完成会议列表
	NotCompletedMeeting: lazy(() => import('../pages/paperless/meetingList/NotCompletedMeeting')),

	//会议详情
	MeetingDetails: lazy(() => import('../pages/paperless/meetingList/_details/MeetingDetails')),

	//会议详情页
	MeetingDetail: lazy(() => import('../pages/paperless/meetingList/_details/MeetingDetail')),

	//参会人员
	Participants: lazy(() => import('../pages/paperless/meetingList/_details/Participants')),

	//添加参会人员
	AddParticipants: lazy(() => import('../pages/paperless/meetingList/_details/AddParticipants')),

	//批注记录
	AnnotationRecord: lazy(() => import('../pages/paperless/meetingList/_details/AnnotationRecord')),

	//文件管理
	FileManagement: lazy(() => import('../pages/paperless/meetingList/_details/FileManagement')),

	//视频管理
	VideoManagement: lazy(() => import('../pages/paperless/meetingList/_details/VideoManagement')),

	//表决管理
	VotingManagement: lazy(
		() => import('../pages/paperless/meetingList/_details/VotingManagement'),
	),

	//表决详细
	VotingDetailedManagement: lazy(
		() => import('../pages/paperless/meetingList/_details/VotingDetailedManagement'),
	),

	//表决统计
	VotingStatistics: lazy(
		() => import('../pages/paperless/meetingList/_details/VotingStatistics'),
	),

	//投票组管理
	VotingGroupManagement: lazy(
		() => import('../pages/paperless/meetingList/_details/VotingGroupManagement'),
	),

	VotingGroupDetailedManagement: lazy(
		() => import('../pages/paperless/meetingList/_details/VotingGroupDetailedManagement'),
	),


	//消息管理
	InformationManagement: lazy(
		() => import('../pages/paperless/meetingList/_details/InformationManagement'),
	),

	//会议记录
	ConferenceRecords: lazy(
		() => import('../pages/paperless/meetingList/_details/ConferenceRecords'),
	),

	//座位图管理
	SeatMapManagement: lazy(
		() => import('../pages/paperless/meetingList/_details/SeatMapManagement'),
	),

	//座位图安排
	SeatMapArrangement: lazy(
		() => import('../pages/paperless/meetingList/_details/SeatMapArrangement'),
	),

	//语音纪要
	VoiceSummary: lazy(
		() => import('../pages/paperless/meetingList/_details/VoiceSummary'),
	),

	//会议预约
	AppointmentMeeting: lazy(() => import('../pages/paperless/meetingList/AppointmentMeeting')),

	//已归档会议列表
	ArchivedMeeting: lazy(() => import('../pages/paperless/meetingList/ArchivedMeeting')),

	//显示模板管理
	TemplateMeeting: lazy(() => import('../pages/paperless/meetingList/TemplateMeeting')),

	//密码管理
	PasswordManagement: lazy(
		() => import('../pages/paperless/personalAdministration/PasswordManagement'),
	),

	//人员管理
	PersonnelManagement: lazy(
		() => import('../pages/paperless/personalAdministration/PersonnelManagement'),
	),

	//用户组管理
	UserGroupManagement: lazy(
		() => import('../pages/paperless/personalAdministration/UserGroupManagement'),
	),

	//部门管理
	DepartmentManagement: lazy(
		() => import('../pages/paperless/personalAdministration/DepartmentManagement'),
	),

	//管理员列表
	AdministratorList: lazy(
		() => import('../pages/paperless/personalAdministration/AdministratorList'),
	),

	//用户搜索
	UserSearch: lazy(() => import('../pages/paperless/personalAdministration/UserSearch')),

	//安装包管理
	InstallationPackageManagement: lazy(
		() => import('../pages/paperless/systemSettings/InstallationPackageManagement'),
	),

	//安装包列表
	installListManage: lazy(
		() => import('../pages/paperless/systemSettings/InstallListManage'),
	),

	//权限管理
	PermissionManagement: lazy(
		() => import('../pages/paperless/systemSettings/PermissionManagement'),
	),

	roleManagement: lazy(
		() => import('../pages/paperless/systemSettings/RoleManagement'),
	),

	operateRoleManagement: lazy(
		() => import('../pages/paperless/systemSettings/OperateRoleManagement'),
	),

	//添加权限
	AddPermission: lazy(
		() => import('../pages/paperless/systemSettings/AddPermission'),
	),

	//网站编辑
	WebsiteEditing: lazy(() => import('../pages/paperless/systemSettings/WebsiteEditing')),

	//设备管理
	DeviceManagement: lazy(() => import('../pages/paperless/systemSettings/DeviceManagement')),

	//AP设备管理
	ApDevicesManagement: lazy(() => import('../pages/paperless/systemSettings/ApDevicesManagement')),

	//墨水屏设备管理
	InkscreenManagement: lazy(() => import('../pages/paperless/systemSettings/InkscreenManagement')),

	//墨水屏模板管理
	InkscreenTemplateManagement: lazy(
		() => import('../pages/paperless/systemSettings/InkscreenTemplateManagement'),
	),

	//添加墨水屏模板
	InkscreenTemplateAdd: lazy(
		() => import('../pages/paperless/systemSettings/InkscreenTemplateAdd'),
	),

	//编辑墨水屏模板
	InkscreenTemplateEdit: lazy(
		() => import('../pages/paperless/systemSettings/InkscreenTemplateEdit'),
	),

	//会议室管理
	MeetingRoomManagement: lazy(
		() => import('../pages/paperless/systemSettings/MeetingRoomManagement'),
	),

	//会议室图管理
	MeetingRoomImg: lazy(
		() => import('../pages/paperless/systemSettings/MeetingRoomImg'),
	),

	//菜单管理
	MenuManagement: lazy(() => import('../pages/paperless/systemSettings/MenuManagement')),

	//呼叫服务管理
	CallServiceManagement: lazy(
		() => import('../pages/paperless/systemSettings/CallServiceManagement'),
	),

	//桌牌样式管理
	TableStyleManagement: lazy(
		() => import('../pages/paperless/systemSettings/TableStyleManagement'),
	),

	//表决模板管理
	VotingTemplateManagement: lazy(
		() => import('../pages/paperless/systemSettings/VotingTemplateManagement'),
	),

	//android配置页面
	AndroidConfigurationPage: lazy(
		() => import('../pages/paperless/systemSettings/AndroidConfigurationPage'),
	),

	//客户端菜单管理
	ClientMenuManagement: lazy(
		() => import('../pages/paperless/systemSettings/ClientMenuManagement'),
	),

	//导入数据
	ImportData: lazy(() => import('../pages/paperless/systemSettings/ImportData')),

	//用户操作日志
	UserOperationLog: lazy(() => import('../pages/paperless/systemSettings/UserOperationLog')),

	//通信服务管理
	CommunicationServiceManagement: lazy(
		() => import('../pages/paperless/systemSettings/CommunicationServiceManagement'),
	),

	//win程序管理
	WinManagement: lazy(() => import('../pages/paperless/systemSettings/WinManagement')),

	//座位管理
	SeatManagement: lazy(() => import('../pages/paperless/systemSettings/SeatManagement')),

	//桌牌安装包管理
	TableInstallationManagement: lazy(
		() => import('../pages/paperless/systemSettings/TableInstallationManagement'),
	),

	//历史会议管理
	HistoricalConferenceManagement: lazy(
		() => import('../pages/paperless/systemSettings/HistoricalConferenceManagement'),
	),

	//水牌安装包管理
	WaterSignInstallationManagement: lazy(
		() => import('../pages/paperless/systemSettings/WaterSignInstallationManagement'),
	),

	//大屏程序管理
	LargeScreenProgramManagement: lazy(
		() => import('../pages/paperless/systemSettings/LargeScreenProgramManagement'),
	),

	//备份设置
	BackupSettings: lazy(() => import('../pages/paperless/systemSettings/BackupSettings')),

	//语音识别词语配置
	SpeechRecognitionConfiguration: lazy(
		() => import('../pages/paperless/systemSettings/SpeechRecognitionConfiguration'),
	),

	//公共资料库
	PublicDatabase: lazy(() => import('../pages/paperless/knowledgeManagement/PublicDatabase')),

	//用户管理
	UserManagement: lazy(() => import('../pages/paperless/userManagement/UserManagement')),

	//多文件上传
	FileUpload: lazy(() => import('../pages/paperless/fileUpload/FileUpload')),

	//未审核议题列表
	UnauditedTopicList: lazy(() => import('../pages/paperless/issueManagement/UnauditedTopicList')),

	//已审核议题列表
	ApprovedTopicList: lazy(() => import('../pages/paperless/issueManagement/ApprovedTopicList')),

	//工具管理
	ToolManagement: lazy(() => import('../pages/paperless/toolManagement/ToolManagement')),
};

const paperlessAppointment = [
	//未完成会议列表
	{
		path: "PdfPreview/:fileMd5",
		element: <PAPERLESS_FILE.PdfPreview />,
		exact: true,
	},

	//未完成会议列表
	{
		path: paperlessPages.meetingList.subMenu.notCompletedMeeting.path,
		element: <PAPERLESS_FILE.NotCompletedMeeting />,
		exact: true,
	},
	//会议详情
	{
		path: 'paperless/meetingList/_details/meetingDetails/:id',
		element: <PAPERLESS_FILE.MeetingDetails />,
		exact: true,
		hideInMenu: true,
		componment: { MeetingDetails },
	},
	//会议详情页
	{
		path: 'paperless/meetingList/_details/meetingDetail/:id',
		element: <PAPERLESS_FILE.MeetingDetail />,
		exact: true,
		hideInMenu: true,
		componment: { MeetingDetail },
	},
	//参会人员
	{
		path: 'paperless/meetingList/_details/participants/:id',
		element: <PAPERLESS_FILE.Participants />,
		exact: true,
		hideInMenu: true,
	},
	//添加参会人员
	{
		path: 'paperless/meetingList/_details/addParticipants/:id',
		element: <PAPERLESS_FILE.AddParticipants />,
		exact: true,
		hideInMenu: true,
	},
	//批注记录
	{
		path: 'paperless/meetingList/_details/annotationRecord/:id',
		element: <PAPERLESS_FILE.AnnotationRecord />,
		exact: true,
		hideInMenu: true,
	},
	//文件管理
	{
		path: 'paperless/meetingList/_details/fileManagement/:id',
		element: <PAPERLESS_FILE.FileManagement />,
		exact: true,
		hideInMenu: true,
	},
	//视频管理
	{
		path: 'paperless/meetingList/_details/videoManagement/:id',
		element: <PAPERLESS_FILE.VideoManagement />,
		exact: true,
		hideInMenu: true,
	},
	//表决管理
	{
		path: 'paperless/meetingList/_details/votingManagement/:id',
		element: <PAPERLESS_FILE.VotingManagement />,
		exact: true,
		hideInMenu: true,
	},

	//表决详细
	{
		path: 'paperless/meetingList/_details/votingDetailedManagement/:id',
		element: <PAPERLESS_FILE.VotingDetailedManagement />,
		exact: true,
		hideInMenu: true,
	},

	//表决统计
	{
		path: 'paperless/meetingList/votingStatistics/:id',
		element: <PAPERLESS_FILE.VotingStatistics />,
		exact: true,
		hideInMenu: true,
	},

	//投票组详细
	{
		path: 'paperless/meetingList/_details/votingGroupDetailedManagement/:id',
		element: <PAPERLESS_FILE.VotingGroupDetailedManagement />,
		exact: true,
		hideInMenu: true,
	},

	//投票组管理
	{
		path: 'paperless/meetingList/_details/votingGroupManagement/:id',
		element: <PAPERLESS_FILE.VotingGroupManagement />,
		exact: true,
		hideInMenu: true,
	},
	//消息管理
	{
		path: 'paperless/meetingList/_details/informationManagement/:id',
		element: <PAPERLESS_FILE.InformationManagement />,
		exact: true,
		hideInMenu: true,
	},
	//会议记录
	{
		path: 'paperless/meetingList/_details/conferenceRecords/:id',
		element: <PAPERLESS_FILE.ConferenceRecords />,
		exact: true,
		hideInMenu: true,
	},
	//座位图管理
	{
		path: 'paperless/meetingList/_details/seatMapManagement/:id',
		element: <PAPERLESS_FILE.SeatMapManagement />,
		exact: true,
		hideInMenu: true,
	},
	//座位图安排
	{
		path: 'paperless/meetingList/_details/seatMapArrangement/:id',
		element: <PAPERLESS_FILE.SeatMapArrangement />,
		exact: true,
		hideInMenu: true,
	},
	//语音纪要
	{
		path: 'paperless/meetingList/_details/voiceSummary/:id',
		element: <PAPERLESS_FILE.VoiceSummary />,
		exact: true,
		hideInMenu: true,
	},
	//会议预约
	{
		path: paperlessPages.meetingList.subMenu.appointmentMeeting.path,
		element: <PAPERLESS_FILE.AppointmentMeeting />,
		exact: true,
	},
	//已归档会议列表
	{
		path: paperlessPages.meetingList.subMenu.archivedMeeting.path,
		element: <PAPERLESS_FILE.ArchivedMeeting />,
		exact: true,
	},
	//显示模板管理
	{
		path: paperlessPages.meetingList.subMenu.templateMeeting.path,
		element: <PAPERLESS_FILE.TemplateMeeting />,
		exact: true,
	},
	//密码管理
	{
		path: paperlessPages.personalAdministration.subMenu.passwordManagement.path,
		element: <PAPERLESS_FILE.PasswordManagement />,
		exact: true,
	},
	//人员管理
	{
		path: paperlessPages.personalAdministration.subMenu.personnelManagement.path,
		element: <PAPERLESS_FILE.PersonnelManagement />,
		exact: true,
	},
	//用户组管理
	{
		path: paperlessPages.personalAdministration.subMenu.userGroupManagement.path,
		element: <PAPERLESS_FILE.UserGroupManagement />,
		exact: true,
	},
	//部门管理
	{
		path: paperlessPages.personalAdministration.subMenu.departmentManagement.path,
		element: <PAPERLESS_FILE.DepartmentManagement />,
		exact: true,
	},
	//管理员列表
	{
		path: paperlessPages.personalAdministration.subMenu.administratorList.path,
		element: <PAPERLESS_FILE.AdministratorList />,
		exact: true,
	},
	//用户搜索
	{
		path: paperlessPages.personalAdministration.subMenu.userSearch.path,
		element: <PAPERLESS_FILE.UserSearch />,
		exact: true,
	},
	//安装包上传
	{
		path: paperlessPages.systemSettings.subMenu.installationPackageManagement.path,
		element: <PAPERLESS_FILE.InstallationPackageManagement />,
		exact: true,
	},

	//安装包列表
	{
		path: paperlessPages.systemSettings.subMenu.installListManage.path,
		element: <PAPERLESS_FILE.installListManage />,
		exact: true,
	},

	//权限管理
	{
		path: paperlessPages.systemSettings.subMenu.roleManagement.path,
		element: <PAPERLESS_FILE.roleManagement />,
		exact: true,
	},

	{
		path: paperlessPages.systemSettings.subMenu.operateRoleManagement.path + "/:powerId",
		element: <PAPERLESS_FILE.operateRoleManagement />,
		exact: true,
	},

	//权限管理
	{
		path: paperlessPages.systemSettings.subMenu.permissionManagement.path,
		element: <PAPERLESS_FILE.PermissionManagement />,
		exact: true,
	},

	//添加权限
	{
		path: paperlessPages.systemSettings.subMenu.addPermission.path,
		element: <PAPERLESS_FILE.AddPermission />,
		exact: true,
	},
	//网站编辑
	{
		path: paperlessPages.systemSettings.subMenu.websiteEditing.path,
		element: <PAPERLESS_FILE.WebsiteEditing />,
		exact: true,
	},
	//设备管理
	{
		path: paperlessPages.systemSettings.subMenu.deviceManagement.path,
		element: <PAPERLESS_FILE.DeviceManagement />,
		exact: true,
	},
	//AP设备管理
	{
		path: paperlessPages.systemSettings.subMenu.apDevicesManagement.path,
		element: <PAPERLESS_FILE.ApDevicesManagement />,
		exact: true,
	},
	//墨水屏设备管理
	{
		path: paperlessPages.systemSettings.subMenu.inkscreenManagement.path,
		element: <PAPERLESS_FILE.InkscreenManagement />,
		exact: true,
	},
	//墨水屏模板管理
	{
		path: paperlessPages.systemSettings.subMenu.inkscreenTemplateManagement.path,
		element: <PAPERLESS_FILE.InkscreenTemplateManagement />,
		exact: true,
	},
	//添加墨水屏模板
	{
		path: `${paperlessPages.systemSettings.subMenu.inkscreenTemplateAdd.path}/:id`,
		element: <PAPERLESS_FILE.InkscreenTemplateAdd />,
		exact: true,
	},
	//编辑墨水屏模板
	{
		path: `${paperlessPages.systemSettings.subMenu.inkscreenTemplateEdit.path}/:id`,
		element: <PAPERLESS_FILE.InkscreenTemplateEdit />,
		exact: true,
	},
	//会议室管理
	{
		path: paperlessPages.systemSettings.subMenu.meetingRoomManagement.path,
		element: <PAPERLESS_FILE.MeetingRoomManagement />,
		exact: true,
	},
	//座位图
	{
		path: 'paperless/systemSettings/meetingRoomImg/:id',
		element: <PAPERLESS_FILE.MeetingRoomImg />,
		exact: true,
		hideInMenu: true,
	},
	//菜单管理
	{
		path: paperlessPages.systemSettings.subMenu.menuManagement.path,
		element: <PAPERLESS_FILE.MenuManagement />,
		exact: true,
	},
	//呼叫服务管理
	{
		path: paperlessPages.systemSettings.subMenu.callServiceManagement.path,
		element: <PAPERLESS_FILE.CallServiceManagement />,
		exact: true,
	},
	//桌牌样式管理
	{
		path: paperlessPages.systemSettings.subMenu.tableStyleManagement.path,
		element: <PAPERLESS_FILE.TableStyleManagement />,
		exact: true,
	},
	//表决模板管理
	{
		path: paperlessPages.systemSettings.subMenu.votingTemplateManagement.path,
		element: <PAPERLESS_FILE.VotingTemplateManagement />,
		exact: true,
	},
	//android配置页面
	{
		path: paperlessPages.systemSettings.subMenu.androidConfigurationPage.path,
		element: <PAPERLESS_FILE.AndroidConfigurationPage />,
		exact: true,
	},
	//客户端菜单管理
	{
		path: paperlessPages.systemSettings.subMenu.clientMenuManagement.path,
		element: <PAPERLESS_FILE.ClientMenuManagement />,
		exact: true,
	},
	//导入数据
	{
		path: paperlessPages.systemSettings.subMenu.importData.path,
		element: <PAPERLESS_FILE.ImportData />,
		exact: true,
	},
	//用户操作日志
	{
		path: paperlessPages.systemSettings.subMenu.userOperationLog.path,
		element: <PAPERLESS_FILE.UserOperationLog />,
		exact: true,
	},
	//通信服务管理
	{
		path: paperlessPages.systemSettings.subMenu.communicationServiceManagement.path,
		element: <PAPERLESS_FILE.CommunicationServiceManagement />,
		exact: true,
	},
	//win程序管理
	{
		path: paperlessPages.systemSettings.subMenu.winManagement.path,
		element: <PAPERLESS_FILE.WinManagement />,
		exact: true,
	},
	//座位管理
	{
		path: paperlessPages.systemSettings.subMenu.seatManagement.path,
		element: <PAPERLESS_FILE.SeatManagement />,
		exact: true,
	},
	//桌牌安装包管理
	{
		path: paperlessPages.systemSettings.subMenu.tableInstallationManagement.path,
		element: <PAPERLESS_FILE.TableInstallationManagement />,
		exact: true,
	},
	//历史会议管理
	{
		path: paperlessPages.systemSettings.subMenu.historicalConferenceManagement.path,
		element: <PAPERLESS_FILE.HistoricalConferenceManagement />,
		exact: true,
	},
	//水牌安装包管理
	{
		path: paperlessPages.systemSettings.subMenu.waterSignInstallationManagement.path,
		element: <PAPERLESS_FILE.WaterSignInstallationManagement />,
		exact: true,
	},
	//大屏程序管理
	{
		path: paperlessPages.systemSettings.subMenu.largeScreenProgramManagement.path,
		element: <PAPERLESS_FILE.LargeScreenProgramManagement />,
		exact: true,
	},
	//备份设置
	{
		path: paperlessPages.systemSettings.subMenu.backupSettings.path,
		element: <PAPERLESS_FILE.BackupSettings />,
		exact: true,
	},
	//语音识别词语配置
	{
		path: paperlessPages.systemSettings.subMenu.speechRecognitionConfiguration.path,
		element: <PAPERLESS_FILE.SpeechRecognitionConfiguration />,
		exact: true,
	},
	//公共资料库
	{
		path: paperlessPages.knowledgeManagement.subMenu.publicDatabase.path,
		element: <PAPERLESS_FILE.PublicDatabase />,
		exact: true,
	},
	//用户管理
	{
		path: paperlessPages.userManagement.path,
		element: <PAPERLESS_FILE.UserManagement />,
		exact: true,
	},
	//多文件上传
	{
		path: paperlessPages.fileUpload.path,
		element: <PAPERLESS_FILE.FileUpload />,
		exact: true,
	},
	//未审核议题列表
	{
		path: paperlessPages.issueManagement.subMenu.unauditedTopicList.path,
		element: <PAPERLESS_FILE.UnauditedTopicList />,
		exact: true,
	},
	//已审核议题列表
	{
		path: paperlessPages.issueManagement.subMenu.approvedTopicList.path,
		element: <PAPERLESS_FILE.ApprovedTopicList />,
		exact: true,
	},
	//工具管理
	{
		path: paperlessPages.toolManagement.path,
		element: <PAPERLESS_FILE.ToolManagement />,
		exact: true,
	},
];

/**
 * 智慧管廊
 */
const PIPEGALLERY_FILE = {
	// 系统监控
	DASHBOARD: lazy(() => import('../pages/pipe-gallery-pages/systemMonitoring/SystemHome')),
	// SystemHome: lazy(() => import('../pages/pipe-gallery-pages/systemMonitoring/SystemHome')),
	LARGE_VISUAL_SCREEN: lazy(
		() => import('../pages/pipe-gallery-pages/systemMonitoring/LargeVisualScreen'),
	),

	//告警处理日志
	ALARM_PROCESSING_LOG: lazy(
		() => import('../pages/pipe-gallery-pages/equipmentManagement/AlarmProcessingLog'),
	),

	//报损报废
	SCRAP_AND_SCRAP: lazy(
		() => import('../pages/pipe-gallery-pages/maintenanceManagement/ScrapAndScrap'),
	),

	//注册信息
	REGISTRATION_INFORMATION: lazy(
		() => import('../pages/pipe-gallery-pages/accountManagement/RegistrationInformation'),
	),

	//安装信息
	MAINTENANCE_RECORD: lazy(
		() => import('../pages/pipe-gallery-pages/accountManagement/MaintenanceRecord'),
	),

	//调试信息
	DEBUG_INFORMATION: lazy(
		() => import('../pages/pipe-gallery-pages/maintenanceManagement/MaintenanceRecord'),
	),

	//合同列表
	CONTRACT_LIST: lazy(() => import('../pages/pipe-gallery-pages/accountManagement/ContractList')),

	//设备配置信息
	DEVICE_CONFIGURATION: lazy(
		() => import('../pages/pipe-gallery-pages/equipmentManagement/DeviceConfiguration'),
	),
	//设备详情信息
	DEVICE_DETAILS: lazy(
		() => import('../pages/pipe-gallery-pages/equipmentManagement/DeviceDetails'),
	),

	// 设备集中监控
	REALTIME_DATA_COLLECTION: lazy(
		() =>
			import(
				'../pages/pipe-gallery-pages/centralizedEquipmentMonitoring/RealtimeDataCollection'
			),
	),
	//
	HISTORICAL_DATA_QUERY: lazy(
		() =>
			import(
				'../pages/pipe-gallery-pages/centralizedEquipmentMonitoring/HistoricalDataQuery'
			),
	),
	//
	DATA_ANALYSIS_REPORT: lazy(
		() =>
			import('../pages/pipe-gallery-pages/centralizedEquipmentMonitoring/DataAnalysisReport'),
	),
	//
	DEVICE_STATUS_MONITORING: lazy(
		() =>
			import(
				'../pages/pipe-gallery-pages/centralizedEquipmentMonitoring/DeviceStatusMonitoring'
			),
	),

	// 设备管理
	EQUIPMENT_LIST: lazy(
		() => import('../pages/pipe-gallery-pages/equipmentManagement/EquipmentList'),
	),
	EQUIPMENT_GROUP_LIST: lazy(
		() => import('../pages/pipe-gallery-pages/equipmentManagement/EquipmentGroupList'),
	),
	EQUIPMENT_TYPE_LIST: lazy(
		() => import('../pages/pipe-gallery-pages/equipmentManagement/EquipmentTypeList'),
	),
	EQUIPMENT_EVENT_LIST: lazy(
		() => import('../pages/pipe-gallery-pages/equipmentManagement/EquipmentEventList'),
	),
	RULE_MANAGEMENT: lazy(
		() => import('../pages/pipe-gallery-pages/equipmentManagement/RuleManagement'),
	),
	ALARM_HANDLING_INFORMATION: lazy(
		() => import('../pages/pipe-gallery-pages/equipmentManagement/AlarmHandlingInformation'),
	),
	ALARM_INFORMATION: lazy(
		() => import('../pages/pipe-gallery-pages/equipmentManagement/AlarmInformation'),
	),

	VENDOR_LIST: lazy(() => import('../pages/pipe-gallery-pages/supplierManagement/VendorList')),

	MONITOR_LIST: lazy(
		() => import('../pages/pipe-gallery-pages/monitoringManagement/MonitorList'),
	),

	MAINTENANCE_PLAN: lazy(
		() => import('../pages/pipe-gallery-pages/maintenanceManagement/MaintenancePlan'),
	),

	KNOWLEDGE_LIST: lazy(
		() => import('../pages/pipe-gallery-pages/knowledgeManagement/KnowledgeList'),
	),
	LABEL_DATABASE: lazy(
		() => import('../pages/pipe-gallery-pages/knowledgeManagement/LabelDatabase'),
	),

	// 设备地图监控
	MAP_DEVICE: lazy(() => import('../pages/pipe-gallery-pages/gisMap/MapDevice')),

	// 设备卡片监控
	EquipmentCardMonitoring: lazy(
		() => import('../pages/pipe-gallery-pages/deviceCard/EquipmentCardMonitoring'),
	),

	// 三维数字孪生
	PIPE_GALLERY3D: lazy(() => import('../pages/pipe-gallery-pages/twinDigital/PipeGallery3d')),

	USER_MANAGEMENT: lazy(() => import('../pages/meeting-pages/systemManagement/UserManagement')),
	ROLE_MANAGEMENT: lazy(() => import('../pages/meeting-pages/systemManagement/RoleManagement')),
	MENU_MANAGEMENT: lazy(() => import('../pages/meeting-pages/systemManagement/MenuManagement')),
	CONFIGURATION_MANAGEMENT: lazy(
		() => import('../pages/meeting-pages/systemManagement/ConfigurationManagement'),
	),
	DIVISIONAL_MANAGEMENT: lazy(
		() => import('../pages/meeting-pages/systemManagement/DivisionalManagement'),
	),
	REGION_LIST: lazy(() => import('../pages/pipe-gallery-pages/regionManagement/RegionList')),
};

//智慧管廊系统
const pipeGallery = [
	//告警处理日志
	{
		path: pipeGalleryPages.eventAlarmManagement.subMenu.alarmProcessingLog.path,
		element: <PIPEGALLERY_FILE.ALARM_PROCESSING_LOG />,
		exact: true,
	},
	//报损报废
	{
		path: pipeGalleryPages.maintenanceManagement.subMenu.scrapAndScrap.path,
		element: <PIPEGALLERY_FILE.SCRAP_AND_SCRAP />,
		exact: true,
	},
	//注册信息
	{
		path: pipeGalleryPages.accountManagement.subMenu.registrationInformation.path,
		element: <PIPEGALLERY_FILE.REGISTRATION_INFORMATION />,
		exact: true,
	},
	//安装信息
	{
		path: pipeGalleryPages.accountManagement.subMenu.installationInformation.path,
		element: <PIPEGALLERY_FILE.MAINTENANCE_RECORD />,
		exact: true,
	},
	//调试信息
	{
		path: pipeGalleryPages.accountManagement.subMenu.debugInformation.path,
		element: <PIPEGALLERY_FILE.DEBUG_INFORMATION />,
		exact: true,
	},
	//合同列表
	{
		path: pipeGalleryPages.accountManagement.subMenu.contractList.path,
		element: <PIPEGALLERY_FILE.CONTRACT_LIST />,
		exact: true,
	},
	//设备配置信息
	{
		path: `${pipeGalleryPages.equipmentManagement.subMenu.deviceConfiguration.path}/:deviceOid/:deviceName`,
		element: <PIPEGALLERY_FILE.DEVICE_CONFIGURATION />,
		exact: true,
	},
	//设备详情信息
	{
		path: `${pipeGalleryPages.equipmentManagement.subMenu.deviceDetails.path}/*`,
		element: <PIPEGALLERY_FILE.DEVICE_DETAILS />,
		exact: true,
	},

	{
		path: pipeGalleryPages.equipmentManagement.subMenu.regionList.path,
		element: <PIPEGALLERY_FILE.REGION_LIST />,
		exact: true,
	},

	// 系统监控
	// {
	// 	path: pipeGalleryPages.systemMonitoring.subMenu.systemHome.path,
	// 	element: <PIPEGALLERY_FILE.SystemHome />,
	// 	exact: true,
	// },
	// {
	// 	path: pipeGalleryPages.systemMonitoring.subMenu.largeVisualScreen.path,
	// 	element: <PIPEGALLERY_FILE.LargeVisualScreen />,
	// 	exact: true,
	// },
	{
		path: pipeGalleryPages.dashboard.path,
		element: <PIPEGALLERY_FILE.DASHBOARD />,
		exact: true,
	},
	{
		path: pipeGalleryPages.largeVisualScreen.path,
		element: <PIPEGALLERY_FILE.LARGE_VISUAL_SCREEN />,
		exact: true,
	},

	// 设备集中监控
	{
		path: pipeGalleryPages.centralizedEquipmentMonitoring.subMenu.realtimeDataCollection.path,
		element: <PIPEGALLERY_FILE.REALTIME_DATA_COLLECTION />,
		exact: true,
	},
	{
		path: pipeGalleryPages.centralizedEquipmentMonitoring.subMenu.historicalDataQuery.path,
		element: <PIPEGALLERY_FILE.HISTORICAL_DATA_QUERY />,
		exact: true,
	},
	{
		path: pipeGalleryPages.centralizedEquipmentMonitoring.subMenu.dataAnalysisReport.path,
		element: <PIPEGALLERY_FILE.DATA_ANALYSIS_REPORT />,
		exact: true,
	},
	// {
	// 	path: pipeGalleryPages.centralizedEquipmentMonitoring.subMenu.deviceStatusMonitoring.path,
	// 	element: <PIPEGALLERY_FILE.DEVICE_STATUS_MONITORING />,
	// 	exact: true,
	// },

	// 设备管理
	{
		path: pipeGalleryPages.equipmentManagement.subMenu.equipmentList.path,
		element: <PIPEGALLERY_FILE.EQUIPMENT_LIST />,
		exact: true,
	},
	{
		path: pipeGalleryPages.equipmentManagement.subMenu.equipmentGroupList.path,
		element: <PIPEGALLERY_FILE.EQUIPMENT_GROUP_LIST />,
		exact: true,
	},
	{
		path: pipeGalleryPages.equipmentManagement.subMenu.equipmentTypeList.path,
		element: <PIPEGALLERY_FILE.EQUIPMENT_TYPE_LIST />,
		exact: true,
	},
	{
		path: pipeGalleryPages.eventAlarmManagement.subMenu.equipmentEventList.path,
		element: <PIPEGALLERY_FILE.EQUIPMENT_EVENT_LIST />,
		exact: true,
	},
	{
		path: pipeGalleryPages.equipmentManagement.subMenu.ruleManagement.path,
		element: <PIPEGALLERY_FILE.RULE_MANAGEMENT />,
		exact: true,
	},
	{
		path: pipeGalleryPages.eventAlarmManagement.subMenu.alarmHandlingInformation.path,
		element: <PIPEGALLERY_FILE.ALARM_HANDLING_INFORMATION />,
		exact: true,
	},
	{
		path: pipeGalleryPages.eventAlarmManagement.subMenu.alarmInformation.path,
		element: <PIPEGALLERY_FILE.ALARM_INFORMATION />,
		exact: true,
	},

	{
		path: pipeGalleryPages.accountManagement.subMenu.vendorList.path,
		element: <PIPEGALLERY_FILE.VENDOR_LIST />,
		exact: true,
	},

	{
		path: pipeGalleryPages.monitoringManagement.subMenu.monitorList.path,
		element: <PIPEGALLERY_FILE.MONITOR_LIST />,
		exact: true,
	},

	{
		path: pipeGalleryPages.maintenanceManagement.subMenu.maintenancePlan.path,
		element: <PIPEGALLERY_FILE.MAINTENANCE_PLAN />,
		exact: true,
	},
	{
		path: pipeGalleryPages.maintenanceManagement.subMenu.maintenanceRecord.path,
		element: <PIPEGALLERY_FILE.MAINTENANCE_RECORD />,
		exact: true,
	},

	{
		path: pipeGalleryPages.knowledgeManagement.subMenu.labelDatabase.path,
		element: <PIPEGALLERY_FILE.LABEL_DATABASE />,
		exact: true,
	},
	{
		path: pipeGalleryPages.knowledgeManagement.subMenu.knowledgeList.path,
		element: <PIPEGALLERY_FILE.KNOWLEDGE_LIST />,
		exact: true,
	},

	// 设备地图监控
	{
		path: pipeGalleryPages.gisMap.subMenu.mapDevice.path,
		element: <PIPEGALLERY_FILE.MAP_DEVICE />,
		exact: true,
	},

	// 设备卡片监控
	{
		path: pipeGalleryPages.deviceCard.subMenu.equipmentCardMonitoring.path,
		element: <PIPEGALLERY_FILE.EquipmentCardMonitoring />,
		exact: true,
	},

	// 三维数字孪生
	{
		path: pipeGalleryPages.twinDigital.subMenu.pipeGallery3d.path,
		element: <PIPEGALLERY_FILE.PIPE_GALLERY3D />,
		exact: true,
	},

	{
		path: pipeGalleryPages.systemManagement.subMenu.userManagement.path,
		element: <PIPEGALLERY_FILE.USER_MANAGEMENT />,
		exact: true,
	},
	{
		path: pipeGalleryPages.systemManagement.subMenu.roleManagement.path,
		element: <PIPEGALLERY_FILE.ROLE_MANAGEMENT />,
		exact: true,
	},
	{
		path: pipeGalleryPages.systemManagement.subMenu.menuManagement.path,
		element: <PIPEGALLERY_FILE.MENU_MANAGEMENT />,
		exact: true,
	},
	{
		path: pipeGalleryPages.systemManagement.subMenu.configurationManagement.path,
		element: <PIPEGALLERY_FILE.CONFIGURATION_MANAGEMENT />,
		exact: true,
	},
	{
		path: pipeGalleryPages.systemManagement.subMenu.divisionalManagement.path,
		element: <PIPEGALLERY_FILE.DIVISIONAL_MANAGEMENT />,
		exact: true,
	},
];

/**
 * 能耗
 */
const ENERGYCONSUMPTION_FILE = {
	DASHBOARD: lazy(() => import('../pages/energy-consumption-pages/other/Dashboard')),

	EQUIPMENT_LIST: lazy(
		() => import('../pages/energy-consumption-pages/equipmentManagement/EquipmentList'),
	),
	EQUIPMENT_GROUP_LIST: lazy(
		() => import('../pages/energy-consumption-pages/equipmentManagement/EquipmentGroupList'),
	),

	REGIONAL_MANAGEMENT: lazy(
		() => import('../pages/energy-consumption-pages/controlManagement/RegionalManagement'),
	),
	ROOM_MANAGEMENT: lazy(
		() => import('../pages/energy-consumption-pages/controlManagement/RoomManagement'),
	),
	ARTICLE_MANAGEMENT: lazy(
		() => import('../pages/energy-consumption-pages/controlManagement/ArticleList'),
	),
	ARTICLE_TYPE_MANAGEMENT: lazy(
		() => import('../pages/energy-consumption-pages/controlManagement/ArticleTypeList'),
	),
};
const energyConsumption = [
	{
		path: energyConsumptionPages.dashboard.path,
		element: <ENERGYCONSUMPTION_FILE.DASHBOARD />,
		exact: true,
	},

	{
		path: energyConsumptionPages.equipmentManagement.subMenu.equipmentList.path,
		element: <ENERGYCONSUMPTION_FILE.EQUIPMENT_LIST />,
		exact: true,
	},
	{
		path: energyConsumptionPages.equipmentManagement.subMenu.equipmentGroupList.path,
		element: <ENERGYCONSUMPTION_FILE.EQUIPMENT_GROUP_LIST />,
		exact: true,
	},

	{
		path: energyConsumptionPages.controlManagement.subMenu.regionalManagement.path,
		element: <ENERGYCONSUMPTION_FILE.REGIONAL_MANAGEMENT />,
		exact: true,
	},
	{
		path: energyConsumptionPages.controlManagement.subMenu.roomManagement.path,
		element: <ENERGYCONSUMPTION_FILE.ROOM_MANAGEMENT />,
		exact: true,
	},
	{
		path: energyConsumptionPages.controlManagement.subMenu.articleManagement.path,
		element: <ENERGYCONSUMPTION_FILE.ARTICLE_MANAGEMENT />,
		exact: true,
	},
	{
		path: energyConsumptionPages.controlManagement.subMenu.articleTypeManagement.path,
		element: <ENERGYCONSUMPTION_FILE.ARTICLE_TYPE_MANAGEMENT />,
		exact: true,
	},
];

/**
 * 中控|数字孪生
 */
const CENTRALCONTROL_FILE = {
	DASHBOARD: lazy(() => import('../pages/central-control-pages/other/Dashboard')),

	REGIONAL_MANAGEMENT: lazy(
		() => import('../pages/central-control-pages/controlManagement/RegionalManagement'),
	),
	ROOM_MANAGEMENT: lazy(
		() => import('../pages/central-control-pages/controlManagement/RoomManagement'),
	),
	ARTICLE_MANAGEMENT: lazy(
		() => import('../pages/central-control-pages/controlManagement/ArticleList'),
	),
	ARTICLE_TYPE_MANAGEMENT: lazy(
		() => import('../pages/central-control-pages/controlManagement/ArticleTypeList'),
	),

	EQUIPMENT_LIST: lazy(
		() => import('../pages/central-control-pages/equipmentManagement/EquipmentList'),
	),
	EQUIPMENT_GROUP_LIST: lazy(
		() => import('../pages/central-control-pages/equipmentManagement/EquipmentGroupList'),
	),
	EQUIPMENT_TYPE_LIST: lazy(
		() => import('../pages/central-control-pages/equipmentManagement/EquipmentTypeList'),
	),
	GATEWAY_LIST: lazy(
		() => import('../pages/central-control-pages/equipmentManagement/GatewayList'),
	),
	GRAFFITI_LIST: lazy(
		() => import('../pages/central-control-pages/equipmentManagement/GraffitiList'),
	),

	CONTROL_HISTORY: lazy(() => import('../pages/central-control-pages/reportForm/ControlHistory')),
	EQUIPMENT_FAILURE: lazy(
		() => import('../pages/central-control-pages/reportForm/EquipmentFailure'),
	),

	PRODUCTS_GRID: lazy(
		() => import('../pages/central-control-pages/controlPanel/ProductsGridPage'),
	),
	PRODUCTS_VIEW: lazy(
		() => import('../pages/central-control-pages/controlPanel/ProductViewPage'),
	),
	DIGITAL_TWIN: lazy(() => import('../pages/central-control-pages/controlPanel/DigitalTwin')),
	DIGITAL_TWIN2: lazy(() => import('../pages/central-control-pages/controlPanel/DigitalTwin2')),

	USER_MANAGEMENT: lazy(
		() => import('../pages/central-control-pages/systemManagement/UserManagement'),
	),
	ROLE_MANAGEMENT: lazy(
		() => import('../pages/central-control-pages/systemManagement/RoleManagement'),
	),
	MENU_MANAGEMENT: lazy(
		() => import('../pages/central-control-pages/systemManagement/MenuManagement'),
	),
	// RULE_MANAGEMENT: lazy(() => import('../pages/central-control-pages/ruleBase/RuleManagement')),
	RULE_LIST: lazy(() => import('../pages/central-control-pages/ruleBase/RuleList')),
	RULE_ADD: lazy(() => import('../pages/central-control-pages/ruleBase/RuleAdd')),
	SCENE_MANAGEMENT: lazy(() => import('../pages/central-control-pages/ruleBase/SceneManagement')),
	SIGHT_MANAGEMENT: lazy(() => import('../pages/central-control-pages/ruleBase/SightManagement')),
	CONFIGURATION_MANAGEMENT: lazy(
		() => import('../pages/central-control-pages/systemManagement/ConfigurationManagement'),
	),
	DIVISIONAL_MANAGEMENT: lazy(
		() => import('../pages/central-control-pages/systemManagement/DivisionalManagement'),
	),
	HOLIDAYMANAGEMENT: lazy(
		() => import('../pages/central-control-pages/systemManagement/HolidayManagement'),
	),
	DOOROPENINGRECORD: lazy(
		() => import('../pages/central-control-pages/systemManagement/DoorOpeningRecord'),
	),

	// 事件列表
	EVEN_MODEL: lazy(
		() => import('../pages/central-control-pages/eventModel/EvenList'),
	),
	// 按键事件列表
	KEY_EVEN_MODEL: lazy(
		() => import('../pages/central-control-pages/eventModel/KeyEvenList'),
	),
	PRODUCTS_GRID2: lazy(
		() => import('../pages/central-control-pages/equipmentManagement/ProductsGridPage'),
	),
	PRODUCTS_VIEW1: lazy(
		() => import('../pages/central-control-pages/equipmentManagement/ProductViewPage'),
	),
	PRODUCTS_VIEW2: lazy(
		() => import('../pages/central-control-pages/equipmentManagement/ProductViewPage2'),
	),

	// 操作日志
	OPERATIONLOG: lazy(() => import('../pages/central-control-pages/systemManagement/UserOperationLog')),
	// 控制日志
	CONTROLLOG: lazy(() => import('../pages/central-control-pages/systemManagement/DashboardEventCenterPage')),
	// 通知中心
	NOTIFICACTIONCENTER: lazy(() => import('../pages/central-control-pages/systemManagement/DashboardNoticeCenterPage')),
	// 安装包管理
	INSTALLLISTMANAGE: lazy(() => import('../pages/central-control-pages/systemManagement/InstallListManage')),
	//安装包上传
	INSTALLATIONPACKAGEMANAGEMENT: lazy(() => import('../pages/central-control-pages/systemManagement/InstallationPackageManagement')),
};
const centralControl = [
	{
		path: centralControlPages.dashboard.path,
		element: <CENTRALCONTROL_FILE.DASHBOARD />,
		exact: true,
	},

	{
		path: centralControlPages.controlManagement.subMenu.regionalManagement.path,
		element: <CENTRALCONTROL_FILE.REGIONAL_MANAGEMENT />,
		exact: true,
	},
	{
		path: centralControlPages.controlManagement.subMenu.roomManagement.path,
		element: <CENTRALCONTROL_FILE.ROOM_MANAGEMENT />,
		exact: true,
	},
	{
		path: centralControlPages.controlManagement.subMenu.articleManagement.path,
		element: <CENTRALCONTROL_FILE.ARTICLE_MANAGEMENT />,
		exact: true,
	},
	{
		path: centralControlPages.controlManagement.subMenu.articleTypeManagement.path,
		element: <CENTRALCONTROL_FILE.ARTICLE_TYPE_MANAGEMENT />,
		exact: true,
	},

	{
		path: `${centralControlPages.equipmentManagement.subMenu.equipmentList.path}/:typeCode/:isState`,
		element: <CENTRALCONTROL_FILE.EQUIPMENT_LIST />,
		exact: true,
	},
	{
		path: centralControlPages.equipmentManagement.subMenu.equipmentGroupList.path,
		element: <CENTRALCONTROL_FILE.EQUIPMENT_GROUP_LIST />,
		exact: true,
	},
	{
		path: centralControlPages.equipmentManagement.subMenu.equipmentTypeList.path,
		element: <CENTRALCONTROL_FILE.EQUIPMENT_TYPE_LIST />,
		exact: true,
	},
	{
		path: centralControlPages.equipmentManagement.subMenu.gatewayList.path,
		element: <CENTRALCONTROL_FILE.GATEWAY_LIST />,
		exact: true,
	},
	{
		path: centralControlPages.equipmentManagement.subMenu.graffitiList.path,
		element: <CENTRALCONTROL_FILE.GRAFFITI_LIST />,
		exact: true,
	},

	{
		path: centralControlPages.reportForm.subMenu.controlHistory.path,
		element: <CENTRALCONTROL_FILE.CONTROL_HISTORY />,
		exact: true,
	},
	{
		path: centralControlPages.reportForm.subMenu.equipmentFailure.path,
		element: <CENTRALCONTROL_FILE.EQUIPMENT_FAILURE />,
		exact: true,
	},

	{
		path: centralControlPages.controlPanel.subMenu.productsGrid.path,
		element: <CENTRALCONTROL_FILE.PRODUCTS_GRID />,
		exact: true,
	},
	{
		path: "centralControl/equipmentManagement/grid",
		element: <CENTRALCONTROL_FILE.PRODUCTS_GRID2 />,
		exact: true,
	},
	{
		path: `centralControl/equipmentManagement/product/:id`,
		element: <CENTRALCONTROL_FILE.PRODUCTS_VIEW1 />,
		exact: true,
	},
	{
		path: `centralControl/equipmentManagement/product2/:id`,
		element: <CENTRALCONTROL_FILE.PRODUCTS_VIEW2 />,
		exact: true,
	},
	{
		path: `${centralControlPages.controlPanel.subMenu.productID.path}/*`,
		element: <CENTRALCONTROL_FILE.PRODUCTS_VIEW />,
		exact: true,
	},
	{
		path: centralControlPages.controlPanel.subMenu.digitalTwin.path,
		element: <CENTRALCONTROL_FILE.DIGITAL_TWIN />,
		exact: true,
	},
	{
		path: dashboardMenu.digitalTwin.path,
		element: <CENTRALCONTROL_FILE.DIGITAL_TWIN2 />,
		exact: true,
	},

	{
		path: centralControlPages.systemManagement.subMenu.userManagement.path,
		element: <CENTRALCONTROL_FILE.USER_MANAGEMENT />,
		exact: true,
	},
	{
		path: centralControlPages.systemManagement.subMenu.roleManagement.path,
		element: <CENTRALCONTROL_FILE.ROLE_MANAGEMENT />,
		exact: true,
	},
	{
		path: centralControlPages.systemManagement.subMenu.menuManagement.path,
		element: <CENTRALCONTROL_FILE.MENU_MANAGEMENT />,
		exact: true,
	},
	{
		path: centralControlPages.systemManagement.subMenu.configurationManagement.path,
		element: <CENTRALCONTROL_FILE.CONFIGURATION_MANAGEMENT />,
		exact: true,
	},
	{
		path: centralControlPages.systemManagement.subMenu.divisionalManagement.path,
		element: <CENTRALCONTROL_FILE.DIVISIONAL_MANAGEMENT />,
		exact: true,
	},
	{
		path: centralControlPages.systemManagement.subMenu.holidayManagement.path,
		element: <CENTRALCONTROL_FILE.HOLIDAYMANAGEMENT />,
		exact: true,
	},
	{
		path: centralControlPages.systemManagement.subMenu.doorOpeningRecord.path,
		element: <CENTRALCONTROL_FILE.DOOROPENINGRECORD />,
		exact: true,
	},
	// {
	// 	path: centralControlPages.ruleBase.subMenu.ruleManagement.path,
	// 	element: <CENTRALCONTROL_FILE.RULE_MANAGEMENT />,
	// 	exact: true,
	// },
	{
		path: centralControlPages.ruleBase.subMenu.ruleList.path,
		element: <CENTRALCONTROL_FILE.RULE_LIST />,
		exact: true,
	},
	{
		path: `${centralControlPages.ruleBase.subMenu.ruleAdd.path}/:id`,
		element: <CENTRALCONTROL_FILE.RULE_ADD />,
		exact: true,
	},
	{
		path: centralControlPages.ruleBase.subMenu.sceneManagement.path,
		element: <CENTRALCONTROL_FILE.SCENE_MANAGEMENT />,
		exact: true,
	},
	{
		path: centralControlPages.ruleBase.subMenu.sightManagement.path,
		element: <CENTRALCONTROL_FILE.SIGHT_MANAGEMENT />,
		exact: true,
	},

	//事件列表
	{
		path: `${centralControlPages.EvenModel.subMenu.EvenList.path}/:subType/:startTime/:endTime`,
		element: <CENTRALCONTROL_FILE.EVEN_MODEL />,
		exact: true,
	},
	{
		path: `${centralControlPages.EvenModel.subMenu.KeyEvenList.path}/:subType/:startTime/:endTime`,
		element: <CENTRALCONTROL_FILE.KEY_EVEN_MODEL />,
		exact: true,
	},

	//操作日志
	{
		path: centralControlPages.systemManagement.subMenu.userOperationLog.path,
		element: <CENTRALCONTROL_FILE.OPERATIONLOG />,
		exact: true,
	},
	//控制日志
	{
		path: centralControlPages.systemManagement.subMenu.eventCenter.path,
		element: <CENTRALCONTROL_FILE.CONTROLLOG />,
		exact: true,
	},
	//通知中心
	{
		path: centralControlPages.systemManagement.subMenu.noticeCenter.path,
		element: <CENTRALCONTROL_FILE.NOTIFICACTIONCENTER />,
		exact: true,
	},
	//安装包管理
	{
		path: centralControlPages.systemManagement.subMenu.installListManage.path,
		element: <CENTRALCONTROL_FILE.INSTALLLISTMANAGE />,
		exact: true,
	},
	//安装包上传
	{
		path: centralControlPages.systemManagement.subMenu.installationPackageManagement.path,
		element: <CENTRALCONTROL_FILE.INSTALLATIONPACKAGEMANAGEMENT />,
		exact: true,
	},
];

/**
 * 系统管理
 */
const SYSTEM_FILE = {
	CONFIGURATION_MANAGEMENT: lazy(
		() => import('../pages/system-management-pages/ConfigurationManagement'),
	),
	ABOUT: lazy(() => import('../pages/system-management-pages/About')),
};
const systemAppointment = [
	{
		path: systemLayoutPages.systemManagement.subMenu.configurationManagement.path,
		element: <SYSTEM_FILE.CONFIGURATION_MANAGEMENT />,
		exact: true,
	},
	{
		path: systemLayoutPages.systemManagement.subMenu.about.path,
		element: <SYSTEM_FILE.ABOUT />,
		exact: true,
	},
];

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD_BOOKING />,
		exact: true,
	},
	{
		path: dashboardMenu.eventCenter.path,
		element: <LANDING.Event_Center />,
		exact: true,
	},
	{
		path: dashboardMenu.noticeCenter.path,
		element: <LANDING.Notice_Center />,
		exact: true,
	},
	{
		path: dashboardMenu.summary.path,
		element: <LANDING.SUMMARY />,
		exact: true,
	},

	/** ************************************************** */

	/**
	 * Pages
	 */

	/**
	 * Single Pages
	 */
	{
		path: demoPages.singlePages.subMenu.boxedSingle.path,
		element: <SINGLE.BOXED />,
		exact: true,
	},
	{
		path: demoPages.singlePages.subMenu.fluidSingle.path,
		element: <SINGLE.FLUID />,
		exact: true,
	},

	/**
	 * List
	 */
	{
		path: demoPages.listPages.subMenu.listBoxed.path,
		element: <LIST.BOXED />,
		exact: true,
	},
	{
		path: demoPages.listPages.subMenu.listFluid.path,
		element: <LIST.FLUID />,
		exact: true,
	},

	/**
	 * Grid
	 */
	{
		path: demoPages.gridPages.subMenu.gridBoxed.path,
		element: <GRID.BOXED />,
		exact: true,
	},
	{
		path: demoPages.gridPages.subMenu.gridFluid.path,
		element: <GRID.FLUID />,
		exact: true,
	},

	/**
	 * Edit
	 */
	{
		path: demoPages.editPages.subMenu.editModern.path,
		element: <EDIT.MODERN />,
		exact: true,
	},
	{
		path: demoPages.editPages.subMenu.editBoxed.path,
		element: <EDIT.BOXED />,
		exact: true,
	},
	{
		path: demoPages.editPages.subMenu.editFluid.path,
		element: <EDIT.FLUID />,
		exact: true,
	},
	{
		path: demoPages.editPages.subMenu.editWizard.path,
		element: <EDIT.WIZARD />,
		exact: true,
	},
	{
		path: demoPages.editPages.subMenu.editInCanvas.path,
		element: <EDIT.IN_CANVAS />,
		exact: true,
	},
	{
		path: demoPages.editPages.subMenu.editInModal.path,
		element: <EDIT.IN_MODAL />,
		exact: true,
	},

	{
		path: demoPages.pricingTable.path,
		element: <PRICING.PRICING_TABLE />,
		exact: true,
	},

	/**
	 * END - Pages
	 */

	/**
	 * Auth Page
	 */
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: "check-in",
		element: <CheckIn />,
		exact: true,
	},
	{
		path: "callback",
		element: <Callback />,
		exact: true,
	},
	//{
	//	path: demoPages.generateKeyPage.path,
	//	element: <GenerateKeyPage />,
	//	exact: true,
	//},
	{
		path: demoPages.empowerPage.path,
		element: <EmpowerPage />,
		exact: true,
	},
	{
		path: demoPages.configuration.path,
		element: <Configuration />,
		exact: true,
	},
	{
		path: `${demoPages.editPassword.path}/:username`,
		element: <EditPassword />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},
	{
		path: demoPages.systemPage.path,
		element: <UPDATE_SYSTEM_PAGE />,
		exact: true,
	},

	/**
	 * App
	 */

	/**
	 * App > Project Management
	 */
	{
		path: demoPages.projectManagement.subMenu.list.path,
		element: <APP.PROJECT_MANAGEMENT.PROJECTS_LIST />,
		exact: true,
	},
	{
		path: `${demoPages.projectManagement.subMenu.itemID.path}/:id`,
		element: <APP.PROJECT_MANAGEMENT.PROJECT />,
		exact: true,
	},
	{
		path: `${demoPages.employeeID.path}/:id`,
		element: <APP.APPOINTMENT.EMPLOYEE_VIEW />,
		exact: true,
	},

	/**
	 * App > Knowledge
	 */
	{
		path: demoPages.knowledge.subMenu.grid.path,
		element: <APP.KNOWLEDGE.GRID />,
		exact: true,
	},
	{
		path: `${demoPages.knowledge.subMenu.itemID.path}/:id`,
		element: <APP.KNOWLEDGE.VIEW />,
		exact: true,
	},

	/**
	 * App > Sales
	 */
	{
		path: demoPages.sales.subMenu.transactions.path,
		element: <APP.SALES.TRANSACTIONS />,
		exact: true,
	},
	{
		path: demoPages.sales.subMenu.salesList.path,
		element: <APP.SALES.PRODUCTS />,
		exact: true,
	},
	{
		path: demoPages.sales.subMenu.productsGrid.path,
		element: <APP.SALES.PRODUCTS_GRID />,
		exact: true,
	},
	{
		path: `${demoPages.sales.subMenu.productID.path}/:id`,
		element: <APP.SALES.PRODUCTS_VIEW />,
		exact: true,
	},

	/**
	 * App > CRM
	 */
	{
		path: demoPages.crm.subMenu.dashboard.path,
		element: <APP.CRM.CRM_DASHBOARD />,
		exact: true,
	},
	{
		path: demoPages.crm.subMenu.customersList.path,
		element: <APP.CRM.CUSTOMERS />,
		exact: true,
	},
	{
		path: `${demoPages.crm.subMenu.customerID.path}/:id`,
		element: <APP.CRM.CUSTOMER />,
		exact: true,
	},

	/**
	 * App > Chat
	 */
	{
		path: demoPages.chat.subMenu.withListChat.path,
		element: <APP.CHAT.WITH_LIST />,
		exact: true,
	},
	{
		path: demoPages.chat.subMenu.onlyListChat.path,
		element: <APP.CHAT.ONLY_LIST />,
		exact: true,
	},

	/**
	 * END - App
	 */

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: layoutMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
		exact: true,
	},
	{
		path: layoutMenu.excelMap.path,
		element: <PAGE_LAYOUTS.EXCELMAP />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
		exact: true,
	},
];
const documentation = [
	/**
	 * Bootstrap
	 */

	/**
	 * Content
	 */
	{
		path: componentsMenu.content.path,
		element: <CONTENT.CONTENTS />,
		exact: true,
	},
	{
		path: componentsMenu.content.subMenu.typography.path,
		element: <CONTENT.TYPOGRAPHY />,
		exact: true,
	},
	{
		path: componentsMenu.content.subMenu.images.path,
		element: <CONTENT.IMAGES />,
		exact: true,
	},
	{
		path: componentsMenu.content.subMenu.tables.path,
		element: <CONTENT.TABLES />,
		exact: true,
	},
	{
		path: componentsMenu.content.subMenu.figures.path,
		element: <CONTENT.FIGURES />,
		exact: true,
	},

	/**
	 * Forms
	 */
	{
		path: componentsMenu.forms.path,
		element: <FORMS_PAGE.FORMS />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.formGroup.path,
		element: <FORMS_PAGE.FORM_GROUP />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.formControl.path,
		element: <FORMS_PAGE.FORM_CONTROLS />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.select.path,
		element: <FORMS_PAGE.SELECT />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.checksAndRadio.path,
		element: <FORMS_PAGE.CHECKS_AND_RADIO />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.range.path,
		element: <FORMS_PAGE.RANGE />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.inputGroup.path,
		element: <FORMS_PAGE.INPUT_GROUP />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.validation.path,
		element: <FORMS_PAGE.VALIDATION />,
		exact: true,
	},
	{
		path: componentsMenu.forms.subMenu.wizard.path,
		element: <FORMS_PAGE.WIZARD />,
		exact: true,
	},

	/**
	 * Components
	 */
	{
		path: componentsMenu.components.path,
		element: <COMPONENTS_PAGE.COMPONENTS />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.tooltip.path,
		element: <COMPONENTS_PAGE.TOOLTIP />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.toasts.path,
		element: <COMPONENTS_PAGE.TOASTS />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.scrollspy.path,
		element: <COMPONENTS_PAGE.SCROLLSPY />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.carousel.path,
		element: <COMPONENTS_PAGE.CAROUSEL />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.spinners.path,
		element: <COMPONENTS_PAGE.SPINNER />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.listGroup.path,
		element: <COMPONENTS_PAGE.LIST_GROUP />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.breadcrumb.path,
		element: <COMPONENTS_PAGE.BREADCRUMB />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.collapse.path,
		element: <COMPONENTS_PAGE.COLLAPSE />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.pagination.path,
		element: <COMPONENTS_PAGE.PAGINATION />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.progress.path,
		element: <COMPONENTS_PAGE.PROGRESS />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.card.path,
		element: <COMPONENTS_PAGE.CARD />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.button.path,
		element: <COMPONENTS_PAGE.BUTTON />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.buttonGroup.path,
		element: <COMPONENTS_PAGE.BUTTON_GROUP />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.alert.path,
		element: <COMPONENTS_PAGE.ALERT />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.badge.path,
		element: <COMPONENTS_PAGE.BADGE />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.popovers.path,
		element: <COMPONENTS_PAGE.POPOVERS />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.dropdowns.path,
		element: <COMPONENTS_PAGE.DROPDOWN />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.accordion.path,
		element: <COMPONENTS_PAGE.ACCORDION />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.modal.path,
		element: <COMPONENTS_PAGE.MODAL />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.navsTabs.path,
		element: <COMPONENTS_PAGE.NAVS_TABS />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.offcanvas.path,
		element: <COMPONENTS_PAGE.OFF_CANVAS />,
		exact: true,
	},
	{
		path: componentsMenu.components.subMenu.table.path,
		element: <COMPONENTS_PAGE.TABLE />,
		exact: true,
	},

	/**
	 * Utilities
	 */
	{
		path: componentsMenu.utilities.path,
		element: <UTILITIES.UTILITIES />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.api.path,
		element: <UTILITIES.API />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.background.path,
		element: <UTILITIES.BACKGROUND />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.borders.path,
		element: <UTILITIES.BORDERS />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.colors.path,
		element: <UTILITIES.COLORS />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.display.path,
		element: <UTILITIES.DISPLAY />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.flex.path,
		element: <UTILITIES.FLEX />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.float.path,
		element: <UTILITIES.FLOAT />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.interactions.path,
		element: <UTILITIES.INTERACTIONS />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.overflow.path,
		element: <UTILITIES.OVERFLOW />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.position.path,
		element: <UTILITIES.POSITION />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.shadows.path,
		element: <UTILITIES.SHADOWS />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.sizing.path,
		element: <UTILITIES.SIZING />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.spacing.path,
		element: <UTILITIES.SPACING />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.text.path,
		element: <UTILITIES.TEXT />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.verticalAlign.path,
		element: <UTILITIES.VERTICAL_ALIGN />,
		exact: true,
	},
	{
		path: componentsMenu.utilities.subMenu.visibility.path,
		element: <UTILITIES.VISIBILITY />,
		exact: true,
	},

	/**
	 * Extra
	 */

	/**
	 * Icons
	 */
	{
		path: componentsMenu.icons.path,
		element: <ICONS.ICONS_LIST />,
		exact: true,
	},
	{
		path: componentsMenu.icons.subMenu.icon.path,
		element: <ICONS.ICON />,
		exact: true,
	},
	{
		path: componentsMenu.icons.subMenu.material.path,
		element: <ICONS.MATERIAL />,
		exact: true,
	},

	/**
	 * Charts
	 */
	{
		path: componentsMenu.charts.path,
		element: <CHARTS_PAGE.CHART_LIST />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsUsage.path,
		element: <CHARTS_PAGE.GENERAL_USAGE />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsSparkline.path,
		element: <CHARTS_PAGE.SPARKLINE />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsLine.path,
		element: <CHARTS_PAGE.LINE />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsArea.path,
		element: <CHARTS_PAGE.AREA />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsColumn.path,
		element: <CHARTS_PAGE.COLUMN />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsBar.path,
		element: <CHARTS_PAGE.BAR />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsMixed.path,
		element: <CHARTS_PAGE.MIXED />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsTimeline.path,
		element: <CHARTS_PAGE.TIMELINE />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsCandleStick.path,
		element: <CHARTS_PAGE.CANDLESTICK />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsBoxWhisker.path,
		element: <CHARTS_PAGE.BOX_WHISKER />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsPieDonut.path,
		element: <CHARTS_PAGE.PIE_DONUT />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsRadar.path,
		element: <CHARTS_PAGE.RADAR />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsPolar.path,
		element: <CHARTS_PAGE.POLAR />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsRadialBar.path,
		element: <CHARTS_PAGE.RADIAL_BAR />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsBubble.path,
		element: <CHARTS_PAGE.BUBBLE />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsScatter.path,
		element: <CHARTS_PAGE.SCATTER />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsHeatMap.path,
		element: <CHARTS_PAGE.HEAT_MAP />,
		exact: true,
	},
	{
		path: componentsMenu.charts.subMenu.chartsTreeMap.path,
		element: <CHARTS_PAGE.TREE_MAP />,
		exact: true,
	},

	{
		path: componentsMenu.notification.path,
		element: <EXTRA.NOTIFICATION />,
		exact: true,
	},
	{
		path: componentsMenu.hooks.path,
		element: <EXTRA.HOOKS />,
		exact: true,
	},
];
const contents = [
	...presentation,
	...documentation,
	...mediaAppointment,
	...stationAppointment,
	...identifyAppointment,
	...visitorAppointment,
	...meetingAppointment,
	...paperlessAppointment,
	...attendanceAppointment,
	...pipeGallery,
	...energyConsumption,
	...centralControl,
	...systemAppointment,
	...antiEpidemicAppointment,
	...doorControlSystemAppointment,
	...stealthAttendanceSystemAppointment,
];

export default contents;
