import React, { SVGProps } from 'react';

const Meeting = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 39.42 39.42" fill='currentColor' className='svg-icon' {...props}>
			<g id="图层_2" data-name="图层 2">
				<g id="图层_1-2" data-name="图层 1">
					<rect className="cls-1" width="39.42" height="39.42" rx="8" />
					<path className="cls-2" d="M21.9,17.52a1.86,1.86,0,1,0,1.85-1.86A1.86,1.86,0,0,0,21.9,17.52Z" />
					<path className="cls-2" d="M28.07,10.1H10.75a1.24,1.24,0,0,0-1.24,1.24V23.7a1.24,1.24,0,0,0,1.24,1.24h6.56l-3.65,3.25a.62.62,0,0,0-.16.86.6.6,0,0,0,.85.17.43.43,0,0,0,.12-.11l4.7-4.17h.92l4.7,4.17a.57.57,0,0,0,.41.16.62.62,0,0,0,.61-.63.65.65,0,0,0-.2-.45L22,24.94h6.11A1.23,1.23,0,0,0,29.3,23.7V11.34A1.23,1.23,0,0,0,28.07,10.1ZM12.4,15.29a.61.61,0,0,1,.61-.61h5a.62.62,0,1,1,0,1.23H13A.61.61,0,0,1,12.4,15.29Zm2.26,4.46a.62.62,0,0,1,.62-.62H18a.62.62,0,1,1,0,1.23H15.28A.61.61,0,0,1,14.66,19.75Zm9.09.86a3.09,3.09,0,1,1,3.08-3.1h0a3.09,3.09,0,0,1-3.08,3.09Z" />
				</g>
			</g>
		</svg>
	);
};

export default Meeting;
