import React, { SVGProps } from 'react';

const EntranceGuard = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 1024 1024" fill='currentColor' className='svg-icon' {...props}>
			<g id="图层_2" data-name="图层 2">
				<g id="图层_1-2" data-name="图层 1">
					<rect className="cls-1" width="1024" height="1024" rx="208" />
					<path d="M874.666667 405.333333H533.333333c-23.466667 0-42.666667 19.2-42.666666 42.666667v42.666667h426.666666v-42.666667c0-23.466667-19.2-42.666667-42.666666-42.666667zM490.666667 661.333333c0 23.466667 19.2 42.666667 42.666666 42.666667h341.333334c23.466667 0 42.666667-19.2 42.666666-42.666667v-128H490.666667v128z" p-id="2355" className="cls-2"></path><path d="M533.333333 341.333333h170.666667V192c0-46.933333-38.4-85.333333-85.333333-85.333333H192C145.066667 106.666667 106.666667 145.066667 106.666667 192v640c0 46.933333 38.4 85.333333 85.333333 85.333333h426.666667c46.933333 0 85.333333-38.4 85.333333-85.333333v-64h-170.666667c-59.733333 0-106.666667-46.933333-106.666666-106.666667V448c0-59.733333 46.933333-106.666667 106.666666-106.666667z m-170.666666 106.666667c0 17.066667-14.933333 32-32 32S298.666667 465.066667 298.666667 448c0-130.133333 104.533333-234.666667 234.666666-234.666667 17.066667 0 32 14.933333 32 32S550.4 277.333333 533.333333 277.333333c-93.866667 0-170.666667 76.8-170.666666 170.666667z" p-id="2356" className="cls-2"></path>
				</g>
			</g>
		</svg>
	);
};

export default EntranceGuard;
