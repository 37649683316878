import React, { SVGProps } from 'react';

const SvgCustomUsa = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props} viewBox="0 0 1024 1024"><path d="M716.8 76.8c127.488 0 230.4 102.912 230.4 230.4v409.6c0 127.488-102.912 230.4-230.4 230.4H307.2c-127.488 0-230.4-102.912-230.4-230.4V307.2c0-127.488 102.912-230.4 230.4-230.4h409.6m0-76.8H307.2C137.728 0 0 137.728 0 307.2v409.6C0 886.272 137.728 1024 307.2 1024h409.6c169.472 0 307.2-137.728 307.2-307.2V307.2C1024 137.728 886.272 0 716.8 0z" p-id="4885" fill="#2c2c2c"></path><path d="M393.728 360.448h-189.44c-20.992 0-37.888-16.896-37.888-37.888 0-20.992 16.896-37.888 37.888-37.888h189.44c20.992 0 37.888 16.896 37.888 37.888 0 20.48-17.408 37.888-37.888 37.888z m-47.616 189.44H203.776c-20.992 0-37.888-16.896-37.888-37.888s16.896-37.888 37.888-37.888h142.336C367.104 474.112 384 491.008 384 512s-16.896 37.888-37.888 37.888z m47.616 189.44h-189.44c-20.992 0-37.888-16.896-37.888-37.888 0-20.992 16.896-37.888 37.888-37.888h189.44c20.992 0 37.888 16.896 37.888 37.888 0 20.992-17.408 37.888-37.888 37.888z" p-id="4886" fill="#2c2c2c"></path><path d="M203.776 739.328c-20.992 0-37.888-16.896-37.888-37.888V322.56c0-20.992 16.896-37.888 37.888-37.888s37.888 16.896 37.888 37.888v378.88c0 20.992-16.896 37.888-37.888 37.888z m616.448 0c-11.264 0-22.528-5.632-30.208-15.36L573.44 436.224V701.44c0 20.992-16.896 37.888-37.888 37.888s-37.888-16.896-37.888-37.888V322.56c0-15.872 10.24-31.232 25.6-35.84 15.36-4.608 32.256 0 42.496 13.312l216.064 288.256V322.56c0-20.992 16.896-37.888 37.888-37.888S857.6 301.568 857.6 322.56v378.88c0 15.872-10.24 31.232-25.6 35.84-4.096 1.536-8.192 2.048-11.776 2.048z" p-id="4887" fill="#2c2c2c"></path></svg>
);

export default SvgCustomUsa;
