import {
	Badge,
	Button as Buttons,
	Descriptions,
	Divider,
	InputNumber,
	Popconfirm,
	Radio,
	Select,
	Space,
	Steps,
	Tabs,
	Typography
} from "antd";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import {
	addEventLog, authentication,
	delEmpower,
	encryptionModel,
	getActivationConfig,
	getEmpower, getOrganization,
	openNotificationWithIcon
} from "../../../api/common";
import PaginationButtons, { PER_COUNT, dataPagination } from "../../../components/PaginationButtons";
import Breadcrumb from "../../../components/bootstrap/Breadcrumb";
import Button from "../../../components/bootstrap/Button";
import Card, { CardActions, CardBody, CardHeader } from "../../../components/bootstrap/Card";
import Dropdown, { DropdownMenu, DropdownToggle } from "../../../components/bootstrap/Dropdown";
import Checks from "../../../components/bootstrap/forms/Checks";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Label from "../../../components/bootstrap/forms/Label";
import Icon from "../../../components/icon/Icon";
import AuthContext from '../../../contexts/authContext';
import useSelectTable3 from "../../../hooks/useSelectTable3";
import useSortableData from "../../../hooks/useSortableData";
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import { demoPages, mediaPages } from "../../../menu";
import { JsDate, getRecords, mediaEventLogData, systemInfo, systemPriceInfo } from "../../../utils";



const Configuration = () => {
	//多语言
	const { t } = useTranslation('common');
	// 路由控制
	const navigate = useNavigate();

	// 用户信息
	const { setUser, userData } = useContext(AuthContext);

	//获取角色列表
	const [empowerFind, setEmpowerFind] = useState<any>(null);
	const [organizationFind, setOrganizationFind] = useState<any>(null);
	const getEmpowerFindData = async () => {
		try {
			let response = await getRecords(getEmpower, {
				filter: [
					`org_num,in,${userData.orgNum}`,
					`system_code,in,1`
				],
				include: "id,start_time,end_time,system_version_id,think_system_version.name",
				size: 1
			})
			setEmpowerFind(response[0])

			const response2 = await getRecords(getOrganization, {
				filter: [
					`org_num,eq,${userData.orgNum}`,
				],
				include: "org_num,org_name,mobile,address,think_system_version.name,validation",
				size: 1
			})
			if (response2.length >= 1) {
				setOrganizationFind(response2[0])
			}
		} catch (error: any) {
			//记录 错误日志
			await addEventLog({
				...mediaEventLogData(),
				name: mediaPages.about.text,
				desc: error.message,
				creator: userData.id,
				org_num: userData.orgNum,
				operation: "Query"
			})

			return openNotificationWithIcon(
				"异常提示",
				error.message,
				"error",
			);
		}
	}
	useEffect(() => {
		if (JSON.stringify(userData) !== "{}") {
			console.log(userData)
			getEmpowerFindData().then(() => { })
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData])


	//取消-激活
	const deactivateFun = async () => {
		try {
			if ((userData?.view_permissions ?? 0) <= 1) {
				return openNotificationWithIcon(
					t('Message Alert'),
					`${t('Unauthorized Operation')}。`,
					"warning",
				);
			}

			if (!empowerFind) {
				return openNotificationWithIcon(
					"异常提示",
					"激活码不存在!",
					"error",
				);
			}

			//修改文件
			let activationData: any = await getActivationConfig()
			for (let i = 0; i < activationData.length; i++) {
				if (activationData[i].orgNum === userData.orgNum) {
					activationData.splice(i, 1);
					i--;
				}
			}

			let writeCode: any = await encryptionModel({
				address: "react/activationConfig.json",
				data: JSON.stringify(activationData),
				method: "editActivationConfig"
			})
			if ("code" in writeCode) {
				return openNotificationWithIcon(
					"异常提示",
					writeCode.details.message,
					"error",
				);
			}

			let delEmpowerCode = await delEmpower(empowerFind.id)
			if (delEmpowerCode) {
				if (setUser) setUser('');

				navigate(`../${demoPages.empowerPage.path}`)

				window.location.reload();
			}
		} catch (error: any) {
			//记录 错误日志
			await addEventLog({
				...mediaEventLogData(),
				name: mediaPages.about.text,
				desc: error.message,
				creator: userData.id,
				org_num: userData.orgNum,
				operation: "Delete"
			})

			return openNotificationWithIcon(
				"异常提示",
				error.message,
				"error",
			);
		}
	}

	//系统类型
	const systemChange = (value: any) => {
		setPurchaseInfo({
			...purchaseInfo,
			systemCode: value
		})
	}

	//设备数量
	const deviceNumberChange = (value: any) => {
		setPurchaseInfo({
			...purchaseInfo,
			deviceNumber: value
		})
	}

	//有效期
	const priceChange = (value: any) => {
		setPurchaseInfo({
			...purchaseInfo,
			price: value
		})
	}

	// 支付方式
	const paymentChange = (value: any) => {
		setPurchaseInfo({
			...purchaseInfo,
			paymentCode: value
		})
	}



	const systemHtml = () => {
		return (
			<>
				<div style={{
					margin: "2rem 0rem",
					width: 500
				}}>
					<Select
						size={"large"}
						defaultValue={purchaseInfo.systemCode}
						style={{ width: "100%" }}
						onChange={systemChange}
						options={systemInfo()}
					/>
				</div>
			</>
		)
	}



	const deviceHtml = () => {
		return (
			<>
				<div style={{
					margin: "2rem 0rem",
					width: 500
				}}>
					<InputNumber
						size="large"
						style={{ width: "20%" }}
						min={1}
						max={1000}
						defaultValue={purchaseInfo.deviceNumber}
						onChange={deviceNumberChange}
					/>
				</div>
			</>
		)
	}

	const termHtml = () => {
		return (
			<>
				<div style={{
					margin: "2rem 0rem",
					width: 500
				}}>
					<Select
						size={"large"}
						defaultValue={purchaseInfo.price}
						style={{ width: "100%" }}
						onChange={priceChange}
						options={[
							{
								value: 3,
								label: '1年',
							},
							{
								value: 2,
								label: '3个月',
							},
							{
								value: 1,
								label: '1个月',
							},
						]}
					/>
				</div>
			</>
		)
	}

	const paymentMethodHtml = () => {
		return (
			<>
				<div style={{
					margin: "2rem 0rem",
					width: 500
				}}>
					<Radio.Group defaultValue={purchaseInfo.paymentCode} size={"large"} onChange={paymentChange} style={{
						padding: "10px"
					}}>
						<Space>
							<Radio.Button value={1} >
								<Space>
									<svg d="1686729511925" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3070" width="16" height="16">
										<path d="M1024.0512 701.0304V196.864A196.9664 196.9664 0 0 0 827.136 0H196.864A196.9664 196.9664 0 0 0 0 196.864v630.272A196.9152 196.9152 0 0 0 196.864 1024h630.272a197.12 197.12 0 0 0 193.8432-162.0992c-52.224-22.6304-278.528-120.32-396.4416-176.64-89.7024 108.6976-183.7056 173.9264-325.3248 173.9264s-236.1856-87.2448-224.8192-194.048c7.4752-70.0416 55.552-184.576 264.2944-164.9664 110.08 10.3424 160.4096 30.8736 250.1632 60.5184 23.1936-42.5984 42.496-89.4464 57.1392-139.264H248.064v-39.424h196.9152V311.1424H204.8V267.776h240.128V165.632s2.1504-15.9744 19.8144-15.9744h98.4576V267.776h256v43.4176h-256V381.952h208.8448a805.9904 805.9904 0 0 1-84.8384 212.6848c60.672 22.016 336.7936 106.3936 336.7936 106.3936zM283.5456 791.6032c-149.6576 0-173.312-94.464-165.376-133.9392 7.8336-39.3216 51.2-90.624 134.4-90.624 95.5904 0 181.248 24.4736 284.0576 74.5472-72.192 94.0032-160.9216 150.016-253.0816 150.016z" fill="#009FE8" p-id="3071"></path>
									</svg>
									支付宝
								</Space>
							</Radio.Button>
							<Radio.Button value={2}>
								<Space>
									<svg d="1686729390238" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2695" width="16" height="16">
										<path d="M404.511405 600.865957c-4.042059 2.043542-8.602935 3.223415-13.447267 3.223415-11.197016 0-20.934798-6.169513-26.045189-15.278985l-1.959631-4.296863-81.56569-178.973184c-0.880043-1.954515-1.430582-4.14746-1.430582-6.285147 0-8.251941 6.686283-14.944364 14.938224-14.944364 3.351328 0 6.441713 1.108241 8.94165 2.966565l96.242971 68.521606c7.037277 4.609994 15.433504 7.305383 24.464181 7.305383 5.40101 0 10.533914-1.00284 15.328104-2.75167l452.645171-201.459315C811.496653 163.274644 677.866167 100.777241 526.648117 100.777241c-247.448742 0-448.035176 167.158091-448.035176 373.361453 0 112.511493 60.353576 213.775828 154.808832 282.214547 7.582699 5.405103 12.537548 14.292518 12.537548 24.325012 0 3.312442-0.712221 6.358825-1.569752 9.515724-7.544837 28.15013-19.62599 73.202209-20.188808 75.314313-0.940418 3.529383-2.416026 7.220449-2.416026 10.917654 0 8.245801 6.692423 14.933107 14.944364 14.933107 3.251044 0 5.89015-1.202385 8.629541-2.7793l98.085946-56.621579c7.377014-4.266164 15.188934-6.89913 23.790846-6.89913 4.577249 0 9.003048 0.703011 13.174044 1.978051 45.75509 13.159718 95.123474 20.476357 146.239666 20.476357 247.438509 0 448.042339-167.162184 448.042339-373.372709 0-62.451354-18.502399-121.275087-51.033303-173.009356L407.778822 598.977957 404.511405 600.865957z" fill="#00C800" p-id="2696"></path>
									</svg>
									微信
								</Space>
							</Radio.Button>
						</Space>
					</Radio.Group>
				</div>
			</>
		)
	}


	const [purchaseInfo, setPurchaseInfo] = useState({
		systemCode: 1,
		deviceNumber: 1,
		price: 1,
		term: 0,
		paymentCode: 1
	})



	// 表格分页
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [perPage, setPerPage] = useState<number>(PER_COUNT['10']);
	// 表格
	const { items, requestSort, getClassNamesFor } = useSortableData([]); //filteredData
	const onCurrentPageItems = dataPagination(items, currentPage, perPage);
	const { selectTable, SelectAllCheck } = useSelectTable3(onCurrentPageItems);


	// 条件搜索
	const [filterMenu, setFilterMenu] = useState<boolean>(false);
	const formik3 = useFormik({
		initialValues: {
			deviceId: '',
			deviceName: '',
			regionId: '',
			isState: '',
		},
		// 点击搜索
		onSubmit: async (values) => {
			setFilterMenu(false);

			// await getAllDevicesData(
			// 	true,
			// 	values.deviceId ?? null,
			// 	values.deviceName ?? null,
			// 	oneRegionId !== "8ce305c7d16d4b05" ? Number(oneRegionId) : null,
			// 	Number(values.isState) ?? null,
			// );
		},
	});


	//刷新列表
	const RefreshList = async function () {

	}

	return (
		<PageWrapper >
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[
							{ title: t('About'), to: '' }
						]}
					/>
				</SubHeaderLeft>
			</SubHeader>
			<Page container="fluid">
				<Card stretch data-tour="list">
					<Tabs defaultActiveKey="1" style={{
						margin: "2rem 1rem"
					}} tabPosition={"left"}>
						<Tabs.TabPane tab="激活" key="1">
							<div id='bootstrap'>
								<Descriptions title={t("Enterprise  Activation Details")} layout="vertical" bordered size={"small"} style={{
									margin: "1.5rem"
								}}>
									<Descriptions.Item label={t('Company Number')}>
										{organizationFind ? organizationFind?.org_num : "-"}
									</Descriptions.Item>

									<Descriptions.Item label={t('Company Name')}>
										{organizationFind ? organizationFind?.org_name : "-"}
									</Descriptions.Item>

									<Descriptions.Item label={t('Contact number')}>
										{organizationFind ? organizationFind?.mobile : "-"}
									</Descriptions.Item>
									<Descriptions.Item label={t('Full address')}>
										{organizationFind ? organizationFind?.address : "-"}
									</Descriptions.Item>

									<Descriptions.Item label="激活版本">
										<Badge status="processing" text={empowerFind ? empowerFind?.system_version_id?.name : "-"} />
									</Descriptions.Item>

									{
										organizationFind !== null && organizationFind.validation !== 0 ? (
											<>
												<Descriptions.Item label={t('Activation Time')}>
													<Badge status="success" text={empowerFind ? JsDate(empowerFind.start_time, "DataTime") : "-"} />
												</Descriptions.Item>
												<Descriptions.Item label={t('End Time')}>
													<Badge status="error" text={empowerFind ? JsDate(empowerFind.end_time, "DataTime") : "-"} />
												</Descriptions.Item>
												<Descriptions.Item label={t('Deactivation')}>
													<Popconfirm
														placement="bottom" title={"确认取消激活吗？"}
														onConfirm={
															() => deactivateFun()
														}
														okText={t('Ok')}
														cancelText={t('Cancel')}
														zIndex={1060}
													>
														<Badge status="warning" style={{
															color: "#4a8bf9"
														}} text="点击-取消激活" />
													</Popconfirm>
												</Descriptions.Item>
											</>
										) : (
											<>
												<Descriptions.Item label={t('Activation Period')}>
													<Badge status="success" text={t('Permanently valid')} />
												</Descriptions.Item>
											</>
										)
									}
								</Descriptions>

								<Descriptions title={t('System Details')} layout="vertical" bordered size={"small"} style={{
									margin: "1.5rem"
								}}>
									<Descriptions.Item label={t('System Name')}>ZUAD信息发布系统</Descriptions.Item>
									<Descriptions.Item label={t('Version Number')} >
										<Badge status="processing" text="1.0.25" />
									</Descriptions.Item>
									<Descriptions.Item label="版权所属">ZUAD联展科技</Descriptions.Item>
									<Descriptions.Item label={t('Release Time')} span={4}>2018-04-24 18:00:00</Descriptions.Item>

									<Descriptions.Item label={t('Run the system')}>
										Ubuntu/18.04.1+
									</Descriptions.Item>
									<Descriptions.Item label={t('Service environment')}>Nginx/1.14.0</Descriptions.Item>
									<Descriptions.Item label={t('PHP Version')}>Php7.1.33</Descriptions.Item>
									<Descriptions.Item label={t('Backend Framework')} >ThinkPHP5.0.24</Descriptions.Item>
									<Descriptions.Item label={t('Frontend frame')}>React + TS + Ant Design</Descriptions.Item>
									<Descriptions.Item label={t('Upload restrictions')}>
										<Badge status="warning" text="20000000Mb" />
									</Descriptions.Item>
								</Descriptions>

							</div>
						</Tabs.TabPane>

						<Tabs.TabPane tab="购买" key="2">
							<div style={{
								display: "flex"
							}}>
								<Steps
									progressDot
									direction="vertical"
									current={5}
									style={{
										width: "50%",
										padding: "5%"
									}}
									items={[
										{
											title: '选择系统',
											description: systemHtml(),
										},
										{
											title: '选择设备数量',
											description: deviceHtml(),
										},
										{
											title: '选择有效期限',
											description: termHtml(),
										},
										{
											title: '选择支付方式',
											description: paymentMethodHtml(),
										},
									]}
								/>


								<div style={{
									width: "35%",
									borderLeft: "3px solid #dee2e6",
									padding: "2%"
								}}>
									<div style={{
										height: "auto",
										borderBottom: "2px solid #dee2e6",
										paddingBottom: "4%"
									}}>
										<Divider orientation="left">
											<Typography.Title level={3}>
												{
													systemInfo().find((i: any) => i.value === purchaseInfo.systemCode)?.label ?? "-"
												}
											</Typography.Title>
										</Divider>

										<div style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "1rem"
										}}>
											<Typography.Text style={{
												fontSize: "14px"
											}}>
												设备数量
											</Typography.Text>
										</div>


										<div style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "1rem",
											paddingLeft: "0.5rem"
										}}>
											<Typography.Text style={{
												fontSize: "15px"
											}}>
												{purchaseInfo.deviceNumber}台
											</Typography.Text>
										</div>

										<div style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "1rem"
										}}>
											<Typography.Text style={{
												fontSize: "14px"
											}}>
												价格
											</Typography.Text>
										</div>

										<div style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "1rem",
											paddingLeft: "0.5rem"
										}}>
											<Typography.Text style={{
												fontSize: "15px"
											}}>
												￥{systemPriceInfo(purchaseInfo.systemCode, purchaseInfo.price)} * {purchaseInfo.deviceNumber}
											</Typography.Text>
										</div>

										<div style={{
											display: "flex",
											justifyContent: "space-between",
											paddingBottom: "1rem",
										}}>
											<Typography.Text style={{
												fontSize: "15px"
											}}>
												有效期限：2024-06-13
											</Typography.Text>
										</div>
									</div>

									<div style={{
										height: "30%",
										paddingTop: "5%"
									}}>

										<div style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											paddingBottom: "5%"
										}}>
											<Typography.Text style={{
												fontSize: "16px"
											}}>
												订单金额
											</Typography.Text>

											<h3 style={{
												marginBottom: 0
											}}>
												￥{systemPriceInfo(purchaseInfo.systemCode, purchaseInfo.price) * purchaseInfo.deviceNumber}
											</h3>
										</div>

										<div style={{
											paddingBottom: "5%"
										}}>
											<Buttons type="primary" size={"large"} style={{
												width: "100%",
												padding: "1%"
											}}>
												确认付款
											</Buttons>
										</div>

										<ul>
											<li>客服热线：4343-4332 9453</li>
											<li>微信：0898-2567 7634</li>
										</ul>
									</div>
								</div>
							</div>
						</Tabs.TabPane>

						<Tabs.TabPane tab="订单" key="3">
							<Card stretch data-tour='list'>
								<CardHeader>
									<CardActions>
										<Button icon="Refresh" color="primary" onClick={RefreshList} isLight >{t('Refresh')}</Button>
									</CardActions>

									<SubHeaderRight>
										<Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu}>
											<DropdownToggle hasIcon={false}>
												<Button icon='ManageSearch' color='primary' isLight>
												{t('Search')}
												</Button>
											</DropdownToggle>

											<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
												<div className='container py-2'>
													<form className='row g-3' onSubmit={formik3.handleSubmit}>
														<div className='col-12'>
															<FormGroup>
																<Label htmlFor='deviceId'>{t('Device ID')}</Label>
																<Input
																	id='deviceId'
																	placeholder={t('Please enter keywords')}
																	ariaLabel={t('Please enter keywords')}
																	ariaDescribedby='button-addon2'
																	onChange={formik3.handleChange}
																	value={formik3.values.deviceId}
																/>
															</FormGroup>
														</div>

														<div className='col-12'>
															<FormGroup>
																<Label htmlFor='deviceName'>{t('Device Name')}</Label>
																<Input
																	id='deviceName'
																	placeholder={t('Please enter keywords')}
																	ariaLabel={t('Please enter keywords')}
																	ariaDescribedby='button-addon2'
																	onChange={formik3.handleChange}
																	value={formik3.values.deviceName}
																/>
															</FormGroup>
														</div>

														<div className='col-6'>
															<Button
																color='primary'
																isOutline
																className='w-100'
																onClick={formik3.resetForm}>
																{t('Resetting')}
															</Button>
														</div>

														<div className='col-6'>
															<Button
																color='primary'
																className='w-100'
																type='submit'>
																{t('Confirm')}
															</Button>
														</div>
													</form>
												</div>
											</DropdownMenu>
										</Dropdown>
									</SubHeaderRight>
								</CardHeader>
								<CardBody className='table-responsive text-center' isScrollable>
									<table className='table table-modern table-hover'>
										<thead>
											<tr>
												<th scope='col'>
													{
														SelectAllCheck
													}
												</th>
												<th
													scope='col'
													onClick={() => requestSort('id')}
													className='cursor-pointer text-decoration-underline'>
													订单号{' '}
													<Icon
														size='lg'
														className={getClassNamesFor('id')}
														icon='FilterList'
													/>
												</th>
												<th scope='col'>购买系统</th>
												<th
													scope='col'
													onClick={() => requestSort('device_name')}
													className='cursor-pointer text-decoration-underline'>
													购买数量{' '}
													<Icon
														size='lg'
														className={getClassNamesFor('device_name')}
														icon='FilterList'
													/>
												</th>
												<th scope='col'>购买时长</th>
												<th scope='col'>支付方式</th>
												<th scope='col'>实付金额</th>

												<th
													scope='col'
													onClick={() => requestSort('is_state')}
													className='cursor-pointer text-decoration-underline'>
													支付状态{' '}
													<Icon
														size='lg'
														className={getClassNamesFor('is_state')}
														icon='FilterList'
													/>
												</th>

												<th
													scope='col'
													onClick={() => requestSort('opening_time')}
													className='cursor-pointer text-decoration-underline'>
													支付时间{' '}
													<Icon
														size='lg'
														className={getClassNamesFor('opening_time')}
														icon='FilterList'
													/>
												</th>
												<th scope='col'>{t('Operate')}</th>
											</tr>
										</thead>
										<tbody>
											{
												onCurrentPageItems.map((item: any, key: number) => (
													<tr key={key}>
														<th scope='row'>
															<Checks
																id={
																	item.id.toString()
																}
																name="selectedList"
																value={item.id}
																onChange={selectTable.handleChange}
																checked={selectTable.values.selectedList.includes(
																	// @ts-ignore
																	item.id.toString(),
																)}
															/>
														</th>

														<th scope='row'>{key + 1}</th>

														<th scope='row'>
															{

															}
														</th>

														<td>
															<Button
																isLink
																color='success'
																onClick={
																	() => {

																	}
																}
															>
																{item.device_name}
															</Button>
														</td>

														<td>
															{

															}
														</td>

														<td>
															{

															}
														</td>

														<td>
															{

															}
														</td>

														<td>
															<span className='text-nowrap'>
																{

																}
															</span>
														</td>

														<td>
															{ }
														</td>

														<td>
															<Space size={"small"}>
																<Button
																	color='dark'
																	isLight
																	icon='Edit'
																	title={"设备编辑"}
																	onClick={
																		() => {

																		}
																	}
																/>
															</Space>
														</td>
													</tr>
												))
											}
										</tbody>
									</table>
								</CardBody>
								<PaginationButtons
									data={items}
									label='items'
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									perPage={perPage}
									setPerPage={setPerPage}
								/>
							</Card>
						</Tabs.TabPane>
					</Tabs>
				</Card>
			</Page>
		</PageWrapper>
	);
};

export default Configuration;
