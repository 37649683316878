import React, { SVGProps } from 'react';

const Station = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 39.42 39.42" fill='currentColor' className='svg-icon' {...props}>
			<g id="图层_2" data-name="图层 2">
				<g id="图层_1-2" data-name="图层 1">
					<rect className="cls-1" width="39.42" height="39.42" rx="8" />
					<path className="cls-2" d="M29.44,18.47h-1V13.83a.4.4,0,1,0-.8,0v4.56H25.19V15.51a.4.4,0,1,0-.8,0v2.88H16.86v-.88h4.25a.72.72,0,0,0,.72-.72V10.86a.72.72,0,0,0-.72-.72H11.73c-.4-.08-.72.32-.72.72v6a.72.72,0,0,0,.72.72H16v.88H10.05a.73.73,0,0,0-.72.73v9.29a.79.79,0,0,0,.72.8h1.36a.38.38,0,0,0,.4-.4V21H22.63v7.93a.38.38,0,0,0,.4.4h6.33a.72.72,0,0,0,.72-.72V19.2A.62.62,0,0,0,29.44,18.47Z" />
				</g>
			</g>
		</svg>
	);
};

export default Station;
