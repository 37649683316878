import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Nav, { NavItem } from '../../components/bootstrap/Nav';
import { selectTopmenu, selectLeftmenu } from '../../store/actions2';
import Icon from '../../components/icon/Icon';
// import './top.css'

const TopMenu = () => {
    const { i18n } = useTranslation<any>();
    let navigate = useNavigate();

    const dispatch = useDispatch();
    const menuData = useSelector((state: any) => state.menuData);
    const [menuJson, setMenuJson] = useState<any>([])

    useEffect(() => {
        setMenuJson(menuData.menuAll);
    }, [menuData.menuAll])

    const handleSubMenu = (menuObj: any) => {
        if (menuObj.subMenu) {
            const firstPropName = Object.keys(menuObj.subMenu)[0];
            const subMenuObj = menuObj.subMenu[firstPropName];
            handleSubMenu(subMenuObj);
        } else {
            // 处理没有 subMenu 的情况
            dispatch(selectLeftmenu(menuObj.id));
            navigate(menuObj.path);
        }
    }

    // 点击菜单项触发状态改变
    function handleSelect(menu: any) {
        dispatch(selectTopmenu(menu));

        const obj1 = menuData.menuAll[menu]
        console.log(obj1)

        if (obj1) {
            handleSubMenu(obj1)
        }
    }

    // console.log(menuJson)
    // const routeChange = useCallback(() => {
    //     let path = `${process.env.PUBLIC_URL}/${meetingRoomPages.dashboard.path}`;
    //     navigate(path);
    // }, [navigate])

    return (
        <div style={{ overflowX: 'auto' }}>
            <div className='d-flex' style={{ flexDirection: 'initial', whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
                {menuJson.map((item: any, index: number) => (
                    <div className='text-center' title={i18n.language === 'zh_CN' ? item["text"]:item["en_title"]  } key={item["id"]} style={{ marginRight: '1.5rem' }} onClick={() => {
                        handleSelect(index.toString())
                    }}>
                        {/* color={menuData.topMenu === index.toString() ? 'info' : 'dark'} */}
                        <Icon icon={item["icon"]} className={menuData.topMenu === index.toString() ? 'menuActive' : 'menuItem'} />
                        <p className="mb-0" style={{ fontSize: '0.875rem', marginTop: '0.1rem' }}>
                            {i18n.language === 'zh_CN' ?  item["text"]:item["en_title"] }
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TopMenu
