import request from '../utils/request2';
import Config from '../config';
import showNotification from '../components/extras/showNotification';

const server = Config.siteAddress;
const server2 = Config.refreshTableCardServer;

const on_off = Config.on_off ? Config.on_off : false;
/**
 * 异常提示
 * @param abnormalText
 * @param typeCode // "default" || "info" || "warning" || "success" || "danger",
 * @constructor
 */
export function abnormalPrompt(abnormalText: string, typeCode?: string | 'warning') {
	showNotification(
		'异常提示', // String, HTML or Component
		abnormalText, // String, HTML or Component
		typeCode,
	);

	//return abnormalPrompt(error.message,"danger")
	return false;
}

export function echoPutBack(item: any) {
	let TipsCode = 'success';

	if (Number(item.resultCode) === 0) {
		TipsCode = 'warning';
	} else if (Number(item.resultCode) === -1) {
		TipsCode = 'danger';
	}

	showNotification(
		'提示信息', // String, HTML or Component
		item.resultMsg, // String, HTML or Component
		TipsCode, // "default" || "info" || "warning" || "success" || "danger",
	);
}

// =======================================================================
// 获取菜单列表
export function getAllMenu(query: any) {
	return request({
		url: server + '/api.php/records/think_auth_rule',
		method: 'get',
		params: query,
	});
}

// 获取菜单列表-条件搜索
export function getMenuBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_auth_rule',
		method: 'get',
		params: query,
	});
}

// 添加菜单
export function addMenu(data: any) {
	return request({
		url: server + '/api.php/records/think_auth_rule',
		method: 'post',
		data,
	});
}

// 编辑菜单
export function editMenu(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_auth_rule/' + id,
		method: on_off ? 'post' :"PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
	});
}

// 删除菜单
export function delMenu(data: any) {
	return request({
		url: server + '/api.php/records/think_auth_rule/' + data.id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data,
	});
}

// 停用或启用菜单
export function doSetMenuStatus(data: any) {
	return request({
		url: server + '/api.php/records/think_auth_rule/' + data.id,
		method: 'post',
		data,
	});
}

// ===============================================================================================

// 获取系统整体信息
export function getBases(query: any) {
	return request({
		url: server + '/api/v5.entry/getBases',
		method: 'post',
		params: query,
	});
}

// ===============================================================================================

// 获取设备列表
export function getAllDevices(query: any) {
	return request({
		//url: server+"/api/v5.entry/getAllDevices",
		url: server + '/api.php/records/think_device?database=paperless',
		method: 'get',
		params: query,
	});
}

// 获取设备列表-条件搜索
export function getDeviceBySearch(query: any) {
	return request({
		//url: server+"/api/v5.entry/getDeviceBySearch",
		url: server + '/api.php/records/think_device?database=paperless',
		method: 'get',
		params: query,
	});
}

// 添加设备
export function addDevice(data: any) {
	return request({
		//url: server+'/api/v5.entry/addDevice',
		url: server + '/api.php/records/think_device?database=paperless',
		method: 'post',
		data,
	});
}

// 编辑设备
export function editDevice(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_device/' + id,
		method: on_off ? 'post' : "PUT" ,headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}
// 删除设备
export function delDevice(data: any) {
	return request({
		url: server + '/api.php/records/think_device/' + data.id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}

// ===============================================================================================

// 获取会议室列表
export function getAllRooms(query: any) {
	return request({
		url: server + '/api.php/records/think_room?database=paperless',
		method: 'post',
		data: query,
	});
}

// 获取会议室列表-条件搜索
export function getRoomsBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_room?database=paperless',
		method: 'get',
		params: query,
	});
}

// 获取可用的会议室列表-条件搜索
export function getRoomBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_room?database=paperless',
		method: 'get',
		params: query,
	});
}

// 获取指定会议室
export function getRoomByKey(query: any) {
	return request({
		url: server + '/api.php/records/think_room?database=paperless',
		method: 'get',
		data: query,
	});
}

// 获取所有会议室座位信息
export function getAllTodayMeetings(query: any) {
	return request({
		url: server + '/api.php/records/think_room?database=paperless',
		method: 'get',
		data: query,
	});
}

// 添加会议室
export function addRoom(data: any) {
	return request({
		//url: server+'/api/v5.entry/addRoom',
		url: server + '/api.php/records/think_room?database=paperless',
		method: 'post',
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 编辑会议室
export function editRoom(data: any) {
	const id = JSON.parse(data).id;
	return request({
		//url: server+'/api/v5.entry/editRoom',
		url: server + '/api.php/records/think_room/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 删除会议室
export function delRoom(data: any) {
	return request({
		//url: server+'/api/v5.entry/delRoom',
		url: server + '/api.php/records/think_room/' + data.id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data: data,
		params: {
			database: 'paperless',
		},
	});
}

// 停用或启用会议室
export function doSetRoomStatus(data: any) {
	return request({
		//url: server + '/api/v5.entry/doSetRoomStatus',
		url: server + '/api.php/records/think_room/' + data.id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data: data,
		params: {
			database: 'paperless',
		},
	});
}


// =======================================================================

// 获取会议消息
export function getAllMeetingMessage(query: any) {
	return request({
		url: server + '/api.php/records/think_meeting_message?database=paperless',
		method: 'get',
		params: query,
	});
}

// 添加表决
export function addMeetingMessage(data: any) {
	return request({
		url: server + '/api.php/records/think_meeting_message?database=paperless',
		method: 'post',
		data,
	});
}

// 编辑会议消息
export function editMeetingMessage(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_meeting_message/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}


// 删除会议消息
export function delMeetingMessage(id: any) {
	return request({
		url: server + '/api.php/records/think_meeting_message/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}




// =======================================================================

// 获取座位列表
export function getAllSeats(query: any) {
	return request({
		url: server + '/api.php/records/think_seat?database=paperless',
		method: 'get',
		data: query,
	});
}

// 获取座位列表-条件搜索
export function getSeatsBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_seat?database=paperless',
		method: 'get',
		params: query,
	});
}

// 获取可用的座位列表-条件搜索
export function getSeatBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_seat?database=paperless',
		method: 'get',
		params: query,
	});
}
// 获取指定座位
export function getSeatByKey(query: any) {
	return request({
		url: server + '/api.php/records/think_seat?database=paperless',
		method: 'get',
		data: query,
	});
}

// 获取所有会议室座位信息
export function getAllMeetingSeats(query: any) {
	return request({
		url: server + '/api.php/records/think_seat?database=paperless',
		method: 'get',
		data: query,
	});
}

// 添加座位
export function addSeat(data: any) {
	return request({
		url: server + '/api.php/records/think_seat?database=paperless',
		method: 'post',
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 编辑座位
export function editSeat(data: any) {
	var id = JSON.parse(data).id;
	return request({
		url: server + '/api.php/records/think_seat/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 删除座位
export function delSeat(data: any) {
	return request({
		url: server + '/api.php/records/think_seat/' + data.id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data: data,
		params: {
			database: 'paperless',
		},
	});
}

// =======================================================================

// 获取部门列表
export function getAllDepartments(query: any) {
	return request({
		url: server + '/api.php/records/think_department?database=zuad_common',
		method: 'get',
		params: query,
	});
}

// 获取部门列表-条件搜索
export function getDepartmentBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_department?database=zuad_common',
		method: 'get',
		params: query,
	});
}

// 添加部门
export function addDepartment(data: any) {
	return request({
		url: server + '/api.php/records/think_department?database=zuad_common',
		method: 'post',
		data: data,
		params: {
			database: 'zuad_common',
		},
	});
}

// 编辑部门
export function editDepartment(data: any) {
	return request({
		url: server + '/api.php/records/think_department/' +data.id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data: data,
		params: {
			database: 'zuad_common',
		},
	});
}

// 删除部门
export function delDepartment(data: any) {
	return request({
		url: server + '/api.php/records/think_department/'+data.id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data: data,
		params: {
			database: 'zuad_common',
		},
	});
}
// 停用或启用部门
export function doSetDepartmentStatus(data: any) {
	return request({
		url: server + '/api.php/records/think_department?database=zuad_common',
		method: 'post',
		data: data,
		params: {
			database: 'zuad_common',
		},
	});
}

// =======================================================================
// 会议签到列表
export function getAllSignIn(query: any) {
	return request({
		url: server + '/api.php/records/think_entry_sign_in?database=paperless',
		method: 'get',
		params: query,
	});
}

export function addAllSignIn(data: any) {
	return request({
		url: server + '/api.php/records/think_entry_sign_in',
		method: 'post',
		data,
		params: {
			database: 'paperless',
		},
		headers: {
			'Content-Type': 'multipart/form-data', // 关键
		},
	});
}

//修改
export function editAllSignIn(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_entry_sign_in/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}

export function deleteAllSignIn(data: any) {
	return request({
		url: server + '/api.php/records/think_entry_sign_in/' + JSON.parse(data).id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}

// =======================================================================

// 获取用户列表
export function getAllUsers(query: any) {
	return request({
		url: server + '/api.php/records/think_admin?database=zuad_common',
		method: 'get',
		params: query,
	});
}


// 获取所有用户，并按部门划分
export function getAllUserToDepartment(query: any) {
	return request({
		url: server + '/api.php/records/think_department&think_admin?database=zuad_common',
		method: 'get',
		params: query,
	});
}


// 获取用户列表-条件搜索
export function getUserBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_admin?database=zuad_common&order=id,desc',
		method: 'get',
		params: query,
	});
}

// 用户登录
export function doLogin(query: any) {
	return request({
		url: server + '/api.php/login',
		method: 'post',
		data: query,
	});
}

// 用户注册
export function userRegister(query: any) {
	return request({
		url: server + '/api.php/register',
		method: 'post',
		data: query,
	});
}

// 添加用户
export function addUser(data: any) {
	return request({
		url: server + '/api.php/records/think_admin?database=zuad_common',
		method: 'post',
		data,
	});
}

// 编辑用户
export function editUser(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_admin/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'zuad_common',
		},
	});
}

// 删除用户
export function delUser(data: any) {
	return request({
		url: server + '/api.php/records/think_admin/' + data.id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data,
		params: {
			database: 'zuad_common',
		},
	});
}

// 导入用户
export function labelUpUser(data: any) {
	return request({
		url: server + '/api.php/records/think_admin',
		method: 'get',
		data,
		params: {
			database: 'zuad_common',
		},
	});
}

// 导出用户
export function labelDownUser(data: any) {
	return request({
		url: server + '/api.php/records/think_admin',
		method: 'get',
		data,
		params: {
			database: 'zuad_common',
		},
	});
}

// =======================================================================
// 获取角色列表
export function getAllRoles(query: any) {
	return request({
		url: server + '/api/Zuad/getAllRoles',
		method: 'get',
		data: query,
	});
}

// 获取角色列表-条件搜索
export function getRoleBySearch(query: any) {
	return request({
		url: server + '/api/Zuad/getRoleBySearch',
		method: 'post',
		params: query,
	});
}

// 添加角色
export function addRole(data: any) {
	return request({
		url: server + '/api/Zuad/addRole',
		method: 'post',
		data,
	});
}

// 编辑角色
export function editRole(data: any) {
	return request({
		url: server + '/api/Zuad/editRole',
		method: 'post',
		data,
	});
}

// 删除角色
export function delRole(data: any) {
	return request({
		url: server + '/api/Zuad/delRole',
		method: 'post',
		data: data,
	});
}

// 启用或禁用角色
export function doSetRoleStatus(data: any) {
	return request({
		url: server + '/api/Zuad/doSetRoleStatus',
		method: 'post',
		data: data,
	});
}

// 获取用户角色及权限
export function getUserRoleByKey(data: any) {
	return request({
		url: server + '/api/Zuad/getUserRoleByKey',
		method: 'post',
		data: data,
	});
}

// 获取用户权限树
export function getUserRole(data: any) {
	return request({
		url: server + '/api/Zuad/getUserRole',
		method: 'post',
		data: data,
	});
}

// 分配用户权限
export function setUserRole(data: any) {
	return request({
		url: server + '/api/Zuad/setUserRole',
		method: 'post',
		data: data,
	});
}

// 获取所有用户权限
export function getUserRoles(data: any) {
	return request({
		url: server + '/api/Zuad/getUserRoles',
		method: 'post',
		data: data,
	});
}

//=======================================================================

// 查询用户组
export function getUserGroup(query: any) {
	return request({
		url: server + '/api.php/records/think_admin_group?database=zuad_common',
		method: 'get',
		params: query,
	});
}
// 添加用户组
export function addUserGroup(data: any) {
	return request({
		url: server + '/api.php/records/think_admin_group',
		method: 'post',
		data,
	});
}

// 编辑用户组
export function editUserGroup(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_admin_group/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data
	});
}

// 删除用户组
export function delUserGroup(data: any) {
	return request({
		url: server + '/api.php/records/think_admin_group/' + data.id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data: data
	});
}




// =======================================================================

// 获取组织列表
export function getAllOrganizations(query: any) {
	return request({
		url: server + '/api.php/records/think_organization?database=paperless',
		method: 'get',
		data: query,
	});
}

// 获取组织列表-条件搜索
export function getOrganizationBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_organization?database=paperless',
		method: 'get',
		params: query,
	});
}

// 添加组织
export function addOrganization(data: any) {
	return request({
		url: server + '/api.php/records/think_organization?database=paperless',
		method: 'post',
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 编辑组织
export function editOrganization(data: any) {
	return request({
		url: server + '/api.php/records/think_organization/' + data.org_num,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 删除组织
export function delOrganization(data: any) {
	return request({
		url: server + '/api.php/records/think_organization/' + data.id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data: data,
		params: {
			database: 'paperless',
		},
	});
}
// 停用或启用组织
export function doSetOrganizationStatus(data: any) {
	return request({
		url: server + '/api.php/records/think_organization/' + data.id,
		method: 'post',
		data: data,
		params: {
			database: 'paperless',
		},
	});
}

// =======================================================================

// 行为日志
export function getAllLogs(query: any) {
	return request({
		url: server + '/api.php/records/think_log?database=paperless&order=add_time,desc&limit=5000',
		method: 'get',
		params: query,
	});
}

export function getOperateLog(query: any) {
	return request({
		url: server + '/api.php/records/think_log?database=paperless',
		method: 'get',
		params: query,
	});
}

export function addOperateLog(query: any) {
	return request({
		url: server + '/api.php/records/think_log',
		method: 'post',
		data: query,
		params: {
			database: 'paperless',
		},
	});
}

export function editOperateLog(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_log/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}

export function delOperateLog(id: any) {
	return request({
		url: server + '/api.php/records/think_log/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}

// =======================================================================
// 获取模板列表
export function getAllShows(query: any) {
	return request({
		url: server + '/api.php/records/think_show?database=paperless',
		method: 'get',
		data: query,
	});
}

// 获取指定模板
export function getShowByKey(query: any) {
	return request({
		url: server + '/api.php/records/think_show?database=paperless',
		method: 'get',
		data: query,
	});
}
// 模糊搜索模板列表
export function getShowsBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_show?database=paperless',
		method: 'get',
		params: query,
	});
}
// 添加模板
export function addShow(data: any) {
	return request({
		url: server + '/api.php/records/think_show?database=paperless',
		method: 'post',
		data,
	});
}

// 编辑模板
export function editShow(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_show/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 删除模板
export function delShow(data: any) {
	return request({
		url: server + '/api.php/records/think_show/' + data.id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data: data,
		params: {
			database: 'paperless',
		},
	});
}

// =======================================================================
// 会议审核列表
export function getAllAuditMeeting(query: any) {
	return request({
		url: server + '/api.php/records/think_meeting?database=paperless&join=think_room&join=think_voting_group&join=think_voting&join=think_entry_sign_in',
		method: 'get',
		params: query,
	});
}
// 管理员审核
export function auditMeeting(query: any) {
	return request({
		url: server + '/api.php/records/think_meeting?database=paperless&order=end_time,desc',
		method: 'get',
		params: query,
	});
}

// 我的会议列表
export function getMyMeetings(query: any) {
	return request({
		url:
			server +
			'/api.php/records/think_meeting?database=paperless&join=think_room&order=end_time,desc',
		method: 'get',
		params: query,
	});
}

// 获取指定会议
export function getMeetingByKey(query: any) {
	return request({
		url: server + '/api.php/records/think_meeting?database=paperless&join=think_room&join=think_issue&join=think_entry_sign_in',
		method: 'get',
		params: query,
	});
}

// 获取会议列表
export function getAllMeetings(query: any) {
	return request({
		url: server + '/api.php/records/think_meeting?database=paperless&order=end_time,desc',
		method: 'get',
		params: query,
	});
}

// 搜索会议
export function getMeetingsBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_meeting?database=paperless&order=end_time,desc',
		method: 'get',
		params: query,
	});
}

// 添加会议
export function addMeeting(data: any) {
	return request({
		url: server + '/api.php/records/think_meeting?database=paperless',
		method: 'post',
		data,
	});
}

// 编辑会议
export function editMeeting(data: any) {
	return request({
		url: server + '/api.php/records/think_meeting/' + data.id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 删除会议
export function delMeeting(data: any) {
	return request({
		url: server + '/api.php/records/think_meeting/' + data.id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data: data,
		params: {
			database: 'paperless',
		},
	});
}

// 获取待进行会议
export function getToMeeting(data: any) {
	return request({
		url: server + '/api/v5.entry/getToMeeting',
		method: 'post',
		data: data,
		params: {
			database: 'paperless',
		},
	});
}
// 获取最近会议
export function getOldMeeting(data: any) {
	return request({
		url: server + '/api/v5.entry/getOldMeeting',
		method: 'post',
		data: data,
		params: {
			database: 'paperless',
		},
	});
}

// 补签
export function counterSign(data: any) {
	return request({
		url: server + '/api/v5.entry/counterSign',
		method: 'post',
		data: data,
		params: {
			database: 'paperless',
		},
	});
}
// 上传会后文件
export function uploadEntryFile(data: any) {
	return request({
		url: server + '/api/v5.entry/uploadEntryFile',
		method: 'post',
		data: data,
		params: {
			database: 'paperless',
		},
	});
}

// 发送会后通知
export function sendEntryNotice(data: any) {
	return request({
		url: server + '/api/v5.entry/sendEntryNotice',
		method: 'post',
		data: data,
		params: {
			database: 'paperless',
		},
	});
}

// ========================================================
// 拖拉预约会议
export function day(data: any) {
	return request({
		url: server + '/api/v5.entry/day',
		method: 'post',
		data,
		params: {
			database: 'paperless',
		},
	});
}

// =======================================================================

// 获取全部配置
export function getAllService(query: any) {
	return request({
		url: server + '/api.php/records/think_config',
		method: 'get',
		params: query,
	});
}

// 编辑配置
export function editService(data: any) {
	return request({
		//url: server+"/api/v5.entry/editConfig",
		url: server + '/api.php/records/think_config/' + data.id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
	});
}

// 保存配置
export function saveService(query: any) {
	return request({
		url: server + '/api.php/records/think_config',
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		params: query,
	});
}

// =======================================================================

// 获取样式列表
export function getAllStyles(query: any) {
	return request({
		url: server + '/api.php/records/think_style?database=paperless',
		method: 'get',
		data: query,
	});
}

// 获取样式列表-条件搜索
export function getStylesBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_style?database=paperless',
		method: 'get',
		params: query,
	});
}

// 获取指定样式
export function getStyleByKey(query: any) {
	return request({
		url: server + '/api.php/records/think_style?database=paperless',
		method: 'get',
		data: query,
	});
}

// 添加样式
export function addStyle(data: any) {
	return request({
		url: server + '/api.php/records/think_style',
		method: 'post',
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 编辑样式
export function editStyle(data: any) {
	var id = JSON.parse(data).id;
	return request({
		url: server + '/api.php/records/think_style/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 删除样式
export function delStyle(data: any) {
	return request({
		url: server + '/api.php/records/think_style/' + data.id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data: data,
		params: {
			database: 'paperless',
		},
	});
}

// =======================================================================

// 获取议题列表
export function getAllIssues(query: any) {
	return request({
		url: server + '/api.php/records/think_issue?database=paperless',
		method: 'get',
		params: query,
	});
}

// 获取议题列表-条件搜索
export function getIssuesBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_issue?database=paperless',
		method: 'get',
		params: query,
	});
}

// 获取指定议题
export function getIssueByKey(query: any) {
	return request({
		url: server + '/api.php/records/think_issue?database=paperless',
		method: 'get',
		params: query,
	});
}

// 添加议题
export function addIssue(data: any) {
	return request({
		url: server + '/api.php/records/think_issue',
		method: 'post',
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 编辑议题
export function editIssue(data: any) {
	var id = JSON.parse(data).id;
	return request({
		url: server + '/api.php/records/think_issue/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 删除议题
export function delIssue(data: any) {
	return request({
		url: server + '/api.php/records/think_issue/' + data.id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data: data,
		params: {
			database: 'paperless',
		},
	});
}

//=========================安装包========================================



// 获取安装包
export function getApkRenew(query: any) {
	return request({
		url: server + '/api.php/records/think_apk_renew?database=paperless&order=create_time,desc&limit=1000',
		method: 'get',
		params: query,
	});
}
// 获取安装包
export function getApkRenews(data: any) {
	return request({
		url: server + '/apikey.php/records/think_apk_renew?database=paperless&order=create_time,desc&limit=1000',
		method: 'get',
		params: data,
		headers: {
			"X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
		}
	});
}

// 添加安装包
export function addApkRenew(data: any) {
	return request({
		url: server + '/api.php/records/think_apk_renew?database=paperless',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'multipart/form-data', // 关键
		},
	});
}

// 编辑安装包
export function editApkRenew(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_apk_renew/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}


// 删除安装包
export function delApkRenew(id: any) {
	return request({
		url: server + '/api.php/records/think_apk_renew/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}



//=========================文件批注========================================



// 获取文件批注
export function getMarginalia(query: any) {
	return request({
		url: server + '/api.php/records/think_marginalia?database=paperless',
		method: 'get',
		params: query,
	});
}

// 添加文件批注
export function addMarginalia(data: any) {
	return request({
		url: server + '/api.php/records/think_marginalia?database=paperless',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'multipart/form-data', // 关键
		},
	});
}

// 编辑文件批注
export function editMarginalia(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_marginalia/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}


// 删除文件批注
export function delMarginalia(id: any) {
	return request({
		url: server + '/api.php/records/think_marginalia/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}



// =======================================================================

// 获取权限列表
export function getAllPermissions(query: any) {
	return request({
		url: server + '/api.php/records/think_auth_group',
		method: 'get',
		params: query,
	});
}

// 获取权限列表-条件搜索
export function getPermissionsBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_auth_group',
		method: 'get',
		params: query,
	});
}

// 添加权限
export function addPermission(data: any) {
	return request({
		url: server + '/api.php/records/think_auth_group',
		method: 'post',
		data,
	});
}

// 编辑权限
export function editPermission(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_auth_group/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
	});
}

// 删除权限
export function delPermission(id: any) {
	return request({
		url: server + '/api.php/records/think_auth_group/' + id,
		method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
	});
}

// =======================================================================

// 获取表决
export function getAllVoting(query: any) {
	return request({
		url: server + '/api.php/records/think_voting?database=paperless&join=think_voting_title&join=think_voting_user&order=create_time,desc',
		method: 'get',
		params: query,
	});
}

// 添加表决
export function addVoting(data: any) {
	return request({
		url: server + '/api.php/records/think_voting?database=paperless',
		method: 'post',
		data,
	});
}

// 编辑表决
export function editVoting(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_voting/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}


// 删除表决
export function delVoting(id: any) {
	return request({
		url: server + '/api.php/records/think_voting/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}

//==============================================================================================


// 获取表决标题
export function getVotingSite(query: any) {
	return request({
		url: server + '/api.php/records/think_voting_title?database=paperless&join=think_voting_content&join=think_voting_result',
		method: 'get',
		params: query,
	});
}


// 添加表决标题
export function addVotingSite(data: any) {
	return request({
		url: server + '/api.php/records/think_voting_title?database=paperless',
		method: 'post',
		data,
	});
}


// 删除表决标题
export function delVotingSite(id: any) {
	return request({
		url: server + '/api.php/records/think_voting_title/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}

//========================================================================================================

// 添加表决内容
export function addVotingContent(data: any) {
	return request({
		url: server + '/api.php/records/think_voting_content',
		method: 'post',
		data,
		params: {
			database: 'paperless',
		},
	});
}


// 删除表决内容
export function delVotingContent(id: any) {
	return request({
		url: server + '/api.php/records/think_voting_content/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}

//=========================================================================================================

//添加表决用户
export function addVotingUser(data: any) {
	return request({
		url: server + '/api.php/records/think_voting_user',
		method: 'post',
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 删除表决用户
export function delVotingUser(id: any) {
	return request({
		url: server + '/api.php/records/think_voting_user/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}

//===============================================================================================================

//获取表决记录
export function getVotingResult(query: any) {
	return request({
		url: server + '/api.php/records/think_voting_result?database=paperless',
		method: 'get',
		params: query,
	});
}


//添加表决记录
export function addVotingResult(data: any) {
	return request({
		url: server + '/api.php/records/think_voting_result',
		method: 'post',
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 删除表决记录数据
export function delVotingResult(id: any) {
	return request({
		url: server + '/api.php/records/think_voting_result/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}

//===================================================================================================

// 获取表决组
export function getVotingGroup(query: any) {
	return request({
		url: server + '/api.php/records/think_voting_group?database=paperless&join=think_voting_group_user&join=think_voting_group_title',
		method: 'get',
		params: query,
	});
}

// 添加表决组
export function addVotingGroup(data: any) {
	return request({
		url: server + '/api.php/records/think_voting_group?database=paperless',
		method: 'post',
		data,
	});
}

// 编辑表决组
export function editVotingGroup(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_voting_group/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}


// 删除表决组
export function delVotingGroup(id: any) {
	return request({
		url: server + '/api.php/records/think_voting_group/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}


// ======================================================================



// 获取表决组用户
export function getVotingGroupUser(query: any) {
	return request({
		url: server + '/api.php/records/think_voting_group_user?database=paperless',
		method: 'get',
		params: query,
	});
}

// 添加表决组用户
export function addVotingGroupUser(data: any) {
	return request({
		url: server + '/api.php/records/think_voting_group_user?database=paperless',
		method: 'post',
		data,
	});
}

// 编辑表决组用户
export function editVotingGroupUser(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_voting_group_user/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}


// 删除表决组用户
export function delVotingGroupUser(id: any) {
	return request({
		url: server + '/api.php/records/think_voting_group_user/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}

//=======================================================================


// 获取表决组标题
export function getVotingGroupTitle(query: any) {
	return request({
		url: server + '/api.php/records/think_voting_group_title?database=paperless&join=think_voting_group_content',
		method: 'get',
		params: query,
	});
}

// 添加表决组标题
export function addVotingGroupTitle(data: any) {
	return request({
		url: server + '/api.php/records/think_voting_group_title?database=paperless',
		method: 'post',
		data,
	});
}

// 编辑表决组标题
export function editVotingGroupTitle(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_voting_group_title/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}


// 删除表决组标题
export function delVotingGroupTitle(id: any) {
	return request({
		url: server + '/api.php/records/think_voting_group_title/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}



//=======================================================================
// 获取表决组标题内容
export function getVotingGroupContent(query: any) {
	return request({
		url: server + '/api.php/records/think_voting_group_content?database=paperless',
		method: 'get',
		params: query,
	});
}

// 添加表决组标题内容
export function addVotingGroupContent(data: any) {
	return request({
		url: server + '/api.php/records/think_voting_group_content?database=paperless',
		method: 'post',
		data,
	});
}

// 编辑表决组标题内容
export function editVotingGroupContent(id: number, data: any) {
	return request({
		url: server + '/api.php/records/think_voting_group_content/' + id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}


// 删除表决组标题内容
export function delVotingGroupContent(id: any) {
	return request({
		url: server + '/api.php/records/think_voting_group_content/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}

// =======================================================================

// 获取目录列表
export function getAllCatalogues(query: any) {
	return request({
		url: server + '/api.php/records/think_file?database=paperless',
		method: 'get',
		params: query,
	});
}

// 获取指定目录
export function getCatalogueByKey(query: any) {
	return request({
		url: server + '/api.php/records/think_file?database=paperless',
		method: 'get',
		params: query,
	});
}
// 模糊搜索目录列表
export function getCataloguesBySearch(query: any) {
	return request({
		url: server + '/api.php/records/think_file?database=paperless',
		method: 'get',
		params: query,
	});
}
// 添加目录
export function addCatalogue(data: any) {
	return request({
		url: server + '/api.php/records/think_file?database=paperless',
		method: 'post',
		data,
	});
}

// 编辑目录
export function editCatalogue(data: any) {
	return request({
		url: server + '/api.php/records/think_file/' + data.id,
		method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
		data,
		params: {
			database: 'paperless',
		},
	});
}

// 删除目录
export function delCatalogue(data: any) {
	return request({
		url: server + '/api.php/records/think_file/' + data.id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		data: data,
		params: {
			database: 'paperless',
		},
	});
}

//===================================================
//分组列表
export function getAllGrouping(query: any) {
	return request({
		// url: server + '/api/v5/grouping_model/getAllGrouping',
		url: server + '/api.php/records/think_grouping?database=paperless',
		method: 'get',
		params: query,
	});
}

// 添加分组
export function addGrouping(data: any) {
	return request({
		url: server + '/api.php/records/think_grouping',
		method: 'post',
		data,
		params: {
			database: 'paperless',
		},
		headers: {
			'Content-Type': 'multipart/form-data', // 关键
		},
	});
}

// 编辑分组
export function editGrouping(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_grouping/' + id,
		method: on_off ? 'post' :"PUT",
		data,
		params: {
			database: 'paperless',
		},
		headers: on_off ?{
			'Content-Type': 'multipart/form-data', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'multipart/form-data'
		},
	});
}

// 删除分组
export function groupingDelete(id: any) {
	return request({
		// url: server + '/api/v5/grouping_model/groupingDelete',
		url: server + '/api.php/records/think_grouping/' + id,
		method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
		params: {
			database: 'paperless',
		},
	});
}

// =======================================================================

/**
 * 文件上传
 * @param data
 */
export function uploadFile(data: any) {
	return request({
		url: server + '/api.php/uploadMaterialFile',
		method: 'post',
		data: data,
	});
}

// 获取素材列表
export function getAllMedia(query: any) {
	return request({
		url: server + '/api.php/records/think_document?database=paperless&order=last_time,desc&limit=1000',
		method: 'get',
		params: query,
	});
}
// 获取素材列表
export function getAllMedias(query: any) {
	return request({
		url: server + '/apikey.php/records/think_document?database=paperless&order=last_time,desc',
		method: 'get',
		params: query,
		headers: {
			"X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
		}
	});
}

// 添加素材
export function addMedia(data: any) {
	return request({
		url: server + '/api.php/records/think_document',
		method: 'post',
		data,
		params: {
			database: 'paperless',
		},
		headers: {
			'Content-Type': 'multipart/form-data', // 关键
		},
	});
}

// 编辑素材
export function editMedia(id:number,data: any) {
	return request({
		url: server + '/api.php/records/think_document/' + id,
		method: on_off ? 'post' :"PUT",
		data,
		params: {
			database: 'paperless',
		},
		headers: on_off ?{
			'Content-Type': 'multipart/form-data', "X-HTTP-Method-Override": "PUT"
		} : {
			'Content-Type': 'multipart/form-data'
		},
	});
}

// 删除素材
export function delMedia(id: any) {
	return request({
		url: server + '/api.php/records/think_document/' + id,
		method: on_off ? 'post' : "DELETE",
		params: {
			database: 'paperless',
		},
		headers: on_off ? {
			'Content-Type': 'multipart/form-data', "X-HTTP-Method-Override": "DELETE"
		} : {
			'Content-Type': 'multipart/form-data'
		},
	});
}

// =======================================================================

// 获取配置信息
export function getSystemInfo2(data: any) {
	return request({
		url: server + '/apikey.php/records/think_configs/?filter=org_num,eq,'+ data.orgNum,
		method: 'get',
		params: data,
		headers: {
			"X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
		}
	})
  }

  // 获取配置信息
  export function getSystemInfo3(data: any) {
	  return request({
		  url: server + '/api.php/records/think_configs',
		  method: 'get',
		  params: data
	  })
	}

// =======================================================================

// 获取墨水屏模板列表-条件搜索
export function getInkscreenTemplateBySearch(data: any) {
    return request({
        //url: server + "/api/v5.entry/getInkscreenTemplateBySearch",
        url: server + '/api.php/records/think_template?database=paperless',
        method: 'get',
        params: data
    })
}

// 添加墨水屏模板
export function inkscreenTemplateAdd(data: any) {
    return request({
        url: server + '/api.php/records/think_template',
        method: 'post',
        data,
        params: {
            database: "paperless"
        }
    })
}

// 获取指定节目模板数据
export function getInkscreenTemplateByKey(id: any) {
    return request({
        url: server + '/api.php/records/think_template/'+id,
        method: 'get',
        params: { database: 'paperless' },
    })
}

// 编辑墨水屏模板
export function inkscreenTemplateEdit(id: any, data: any) {
    return request({
        url: server + '/api.php/records/think_template/' + id,
        method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'paperless' },
        data,
    })
}

// 删除墨水屏模板
export function inkscreenTemplateDel(data: any) {
    return request({
        url: server + '/api.php/records/think_template/' + data.id,
        method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "paperless"
        }
    })
}

// =======================================================================

// 获取墨水屏列表-条件搜索
export function getInkscreenBySearch(data: any) {
    return request({
        url: server + '/api.php/records/think_inkscreen?database=paperless',
        method: 'get',
        params: data
    })
}

// 获取墨水屏-会议室
export function getInkscreenRoomAll(query: any) {
    return request({
        url: server + '/api.php/records/think_inkscreen?database=paperless',
        method: 'post',
        params: query
    })
}

// 添加墨水屏
export function inkscreenAdd(data: any) {
    return request({
        url: server + '/api.php/records/think_inkscreen',
        method: 'post',
        data,
        params: {
            database: "paperless"
        }
    })
}

// 编辑墨水屏
export function inkscreenEdit(data: any) {
    return request({
        url: server + '/api.php/records/think_inkscreen/'+data.id,
        method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "paperless"
        }
    })
}

// 删除墨水屏
export function inkscreenDel(data: any) {
    return request({
        url: server + '/api.php/records/think_inkscreen/'+data.id,
        method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "paperless"
        }
    })
}

// 刷新桌牌
export function inkscreenRefresh(data: any) {
    return request({
        url: server2 + '/tableCard',
        method: 'post',
        data,
    })
}

// =======================================================================

// 获取AP设备列表-条件搜索
export function getApDevicesBySearch(data: any) {
    return request({
        url: server + '/api.php/records/think_ap_device?database=paperless',
        method: 'get',
        params: data
    })
}

// 获取AP设备-会议室
export function getApDevicesRoomAll(query: any) {
    return request({
        url: server + '/api.php/records/think_ap_device?database=paperless',
        method: 'post',
        params: query
    })
}

// 添加AP设备
export function apDevicesAdd(data: any) {
    return request({
        url: server + '/api.php/records/think_ap_device',
        method: 'post',
        data,
        params: {
            database: "paperless"
        }
    })
}

// 编辑AP设备
export function apDevicesEdit(data: any) {
    return request({
        url: server + '/api.php/records/think_ap_device/'+data.id,
        method: on_off ? 'post' : "PUT",headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "paperless"
        }
    })
}

// 删除AP设备
export function apDevicesDel(data: any) {
    return request({
        url: server + '/api.php/records/think_ap_device/'+data.id,
        method: on_off ? 'post' : "DELETE",headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        data,
        params: {
            database: "paperless"
        }
    })
}

// =======================================================================

// 上传文件
export function upload(data: any) {
    return request({
        url: server + '/api.php/uploadMaterialFile',
        method: 'post',
        data: data
    })
}

// =========================================