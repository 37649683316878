import React, { SVGProps } from 'react';

const SenselessAttendance = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 39.42 39.42" fill='currentColor' className='svg-icon' {...props}>
			<g id="图层_2" data-name="图层 2">
				<g id="图层_1-2" data-name="图层 1"><rect className="cls-1" width="39.42" height="39.42" rx="8" /><path className="cls-2" d="M11.32,11.41a1.48,1.48,0,0,0-1.48,1.48V28.68a1.48,1.48,0,0,0,1.48,1.48H28.1a1.48,1.48,0,0,0,1.48-1.48V12.89a1.48,1.48,0,0,0-1.48-1.48M19.71,26.05l-.35.35a1.48,1.48,0,0,1-2.09,0l-.35-.35L14.82,24a1,1,0,0,1,0-1.39h0a1,1,0,0,1,1.39,0l1.05,1.05a1.48,1.48,0,0,0,2.1,0l3.84-3.84a1,1,0,0,1,1.39,0h0a1,1,0,0,1,0,1.39Zm6.42-12.66a1.48,1.48,0,0,1,1.48,1.48h0a1.49,1.49,0,0,1-1.48,1.48H13.3a1.48,1.48,0,0,1-1.48-1.48h0a1.48,1.48,0,0,1,1.48-1.48" /><rect className="cls-2" x="14.11" y="9.26" width="1.99" height="5.96" rx="0.99" /><rect className="cls-2" x="23.04" y="9.26" width="1.99" height="5.96" rx="0.99" /></g>
			</g>
		</svg>
	);
};

export default SenselessAttendance;
