import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLeftmenu } from '../../store/actions2';
import Navigation from '../../layout/Navigation/Navigation';

const LeftMenu = () => {
    const dispatch = useDispatch();
    const menuData = useSelector((state: any) => state.menuData);
    const [menuJson, setMenuJson] = useState<any>({})


    useEffect(() => {
        const obj1 = menuData.menuAll[menuData.topMenu]
        // console.log(obj1)

        if (obj1 && obj1.subMenu) {
            setMenuJson(obj1.subMenu)
        } else {
            setMenuJson({})
        }
        // Object.keys().map((key: any) => (
        //     console.log(menuData.menuAll[key])
        // ))
    }, [menuData.topMenu, menuData.menuAll])

    // 点击菜单项触发状态改变
    function handleSelect(menu: any) {
        dispatch(selectLeftmenu(menu));
    }

    return (
        <div className="overflow-y-auto" style={{ height: 'calc(100vh - 56px)' }}>
            <Navigation menu={menuJson} id={"aside-dashboard"} />
        </div>
    );
}

export default LeftMenu
