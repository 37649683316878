import { InboxOutlined } from "@ant-design/icons";
import {
	Button as Buttons,
	Form,
	Input,
	Upload,
	UploadProps,
	notification
} from "antd";
import { useContext, useState } from "react";
import {
	addEventLog,
	addOperateLog,
	addRecord, openNotificationInteraction,
	openNotificationWithIcon,
	systemUpdateSh
} from "../../api/common";
import Config from "../../config";
import AuthContext from '../../contexts/authContext';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { curTimeStamp, mediaEventLogData, uuid2 } from "../../utils";
import { useTranslation } from 'react-i18next';



const UpdateSystemPage = () => {
	// 用户信息
	const { userData } = useContext(AuthContext);

	//操作
	const [formUnit] = Form.useForm(); //form表单组件
	const onFinish = async (values: any) => {
		try {
			const systemUpdateShCode: any = await systemUpdateSh({})
			if ("code" in systemUpdateShCode) return openNotificationWithIcon(t('Tip'), systemUpdateShCode.details.message, 'error');

			let response = await addRecord({
				...values,
				oid: uuid2(32, 16),
				last_time: curTimeStamp(),
			})

			//操作日志
			await addOperateLog({
				oid: uuid2(32, 16),
				org_num: userData.orgNum,
				uid: userData.id,
				system_id: 1,
				is_state: Boolean(response) ? 1 : 0,
				describe: t('System version upgrade'),
				create_time: curTimeStamp()
			})


			const description = (
				<p style={{
					marginBottom: "0rem"
				}}>
					{t('The system upgrade was successful. It is recommended to use [Ctrl+F5] to force a refresh to ensure that the system cache is up-to-date')}！
				</p>
			)
			//消息提示
			return openNotificationInteraction("success", description, (
				<>
					<Buttons type="link" size="small" onClick={
						() => {
							notification.destroy()
						}
					}>
						{t('I see')}
					</Buttons>
				</>
			))
		} catch (error: any) {
			//记录 错误日志
			await addEventLog({
				...mediaEventLogData(),
				name: t('System version upgrade'),
				desc: error.message,
				creator: userData.id,
				org_num: userData.orgNum,
				operation: "Upgrade"
			})

			return openNotificationWithIcon(
				t('Tip'),
				error.message,
				"error",
			);
		}
	}


	//文件上传
	const [loadingFile, setLoadingFile] = useState(false)
	const props: UploadProps = {
		name: 'fileInfo',
		multiple: true,
		action: Config.siteAddress + '/api.php/systemVersionUpdate',
		onChange(info: any) {
			const fileInfo = info.file;
			//上传中
			if (fileInfo.status === 'uploading') {
				if (!loadingFile) {
					setLoadingFile(true)
				}
			}

			//上传完成
			if (fileInfo.status === 'done') {
				if ("code" in (fileInfo.response)) {
					openNotificationWithIcon(t('Tip'), fileInfo.response.details.message, 'error');
				} else {
					openNotificationWithIcon(t('Tip'), fileInfo.response.message, 'success');
				}
				setLoadingFile(false)
			}

			//上传失败
			if (fileInfo.status === 'error') {
				setLoadingFile(false)
			}
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
		onRemove() {
			setLoadingFile(false)
		}
	};

	//多语言
	const { t } = useTranslation('common' || 'menu');


	return (
		<PageWrapper >
			<Page container="fluid">
				<Form
					form={formUnit}
					layout='vertical'
					name='dynamic_form_complex'
					style={{ width: "50%" }}
					onFinish={onFinish}
					autoComplete='off'>
					<Form.Item name='version_number' label={t('Version Number')} style={{ width: "100%" }} >
						<Input placeholder={t('Please enter the version number')} min={1} max={20} style={{ width: "100%" }} />
					</Form.Item>

					<Form.Item name='describe' label={t('Version update content')}>
						<Input.TextArea rows={4} />
					</Form.Item>

					<Form.Item label={t('Upgrade Compressed Package')}>
						<Upload.Dragger {...props} maxCount={1} accept={"application/zip"}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">{t('Click File')}</p>
							<p className="ant-upload-hint">{t('Support Single Upload')}</p>
						</Upload.Dragger>
					</Form.Item>

					<Form.Item shouldUpdate>
						<Buttons
							type="primary"
							htmlType="submit"
							className='w-100'
							disabled={
								loadingFile
							}
						>
							{t('Save')}
						</Buttons>
					</Form.Item>
				</Form>
			</Page>
		</PageWrapper>
	);
};

export default UpdateSystemPage;
