import axios from 'axios';
import { getStorage, setStorage } from './sessionStorage';
import { getStorages, setStorages } from "./localStorage";

axios.defaults.withCredentials = true;
const callbackCode = getStorages('callbackCode');


//  创建一个新的axios实例对象  这样做的目的就是 不会进入之前的请求拦截和响应 防止进入死循环
const requestFreshToken = axios.create();

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // baseURL: serverAddres.value,
  withCredentials: true,
  timeout: 1000000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  async (config: any) => {
    // setStorage("SignCode", 'fevQznbQDwP3c');
      config.headers['Sign-Code'] = getStorage("SignCode")
    // config.headers.Token = '99999999999999999999'

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  async (response) => {
    return response.data
  },
    error => {
      if (!error.response) { // 如果什么都没有`response` 那就是网络异常 直接抛出一个错误
        return Promise.reject(error)
      }

      if (error.response.status === 401) { // 如果不等于401 那么不做操作，直接将错误抛出
        const res = error.response.data
        if (res.code === 1011) {
          //请求要求身份验证 跳转到登录页
            setStorage('facit_authUsername', '');
            if (getStorages('callbackCode') != undefined && getStorages('callbackCode') != '' && getStorages('login') == undefined) {
                setStorages('callbackCode', "");
                let userAgent = navigator.userAgent;
                if (userAgent.indexOf('Firefox') !== -1 || userAgent.indexOf('Chrome') !== -1) {
                    window.location.replace('about:blank');
                } else {
                    window.opener = null;
                    window.open('', '_self');
                }
                window.close();
            } else if (getStorages('callbackCode') != undefined && getStorages('callbackCode') != '' && getStorages('login') != undefined) {

            } else {
                window.location.hash = "/auth-pages/login";
            }
        }

        return Promise.reject(res)
      } else {
        return Promise.reject(error.response.data)
      }
    }
)

export default service
