import React, { SVGProps } from 'react';

const Paperless = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 39.42 39.42" fill='currentColor' className='svg-icon' {...props}>
			<g id="图层_2" data-name="图层 2">
			<g id="图层_1-2" data-name="图层 1"><rect className="cls-1" width="39.42" height="39.42" rx="8"/><path className="cls-2" d="M12.26,9.78h14.9A1.23,1.23,0,0,1,28.4,11V25.3H21a1.23,1.23,0,0,0-1.21,1l-.69,3.36H12.26A1.24,1.24,0,0,1,11,28.4V11A1.23,1.23,0,0,1,12.26,9.78Zm2.79,3.1a.93.93,0,1,0,0,1.86h9.32a.93.93,0,0,0,0-1.86Zm0,3.73a.93.93,0,1,0,0,1.86h9.32a.93.93,0,0,0,0-1.86Zm0,3.72a.93.93,0,0,0,0,1.86h6.83a.93.93,0,1,0,0-1.86Zm6.68,6.21H28.4l-8.07,3.11.8-2.67A.62.62,0,0,1,21.73,26.54Z"/></g>
			</g>
		</svg>
	);
};

export default Paperless;
