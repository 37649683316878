import { Button as Buttons, Menu, Tooltip } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getMeetingByKey, getUserBySearch } from '../../../../api/paperless';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import showNotification from '../../../../components/extras/showNotification';
import AuthContext from '../../../../contexts/authContext';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';



const MeetingDetails = () => {

	const { id } = useParams();
	console.log('id', id);

	//上一层
	const navigate = useNavigate();

	// 会议状态
	const [controlType, setControlType] = useState(false);

	// 用户信息
	const { userData, setUser } = useContext(AuthContext);

	//多语言
	const { t } = useTranslation('common');


	const getMeetingInfo = useCallback(() => {

		let arra: any = [];
		if (userData.id != "1") {
			arra.push(`org_num,eq,${userData.orgNum}`);
		}
		getUserBySearch({
			filter: arra
		}).then((response: any) => {
			let resultData = response.records;
			if (!resultData) {
				resultData = []
			}

			getMeetingByKey({
				filter: [`id,eq,${id}`]
			}).then((response2: any) => {

				let meetingInfo: any = response2.records[0];

				if(meetingInfo.control_type == 4){
					setControlType(false)
				}else{
					setControlType(true)
				}


			}).catch((error) => {
				console.error(error)
				showNotification(
					'错误', // String, HTML or Component
					error.message, // String, HTML or Component
					'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
				);
			});
		}).catch((error) => {
			console.error(error)
			showNotification(
				'错误', // String, HTML or Component
				error.message, // String, HTML or Component
				'warning' // 'default' || 'info' || 'warning' || 'success' || 'danger',
			);
		})
	}, [userData]);

	useEffect(() => {

		getMeetingInfo();
		
	}, []);

	const treeData = [
		{
			label: t('meetingDetail'),
			key: 'paperless/meetingList/_details/meetingDetail',
		},
		{
			label: t('participants'),
			key: 'paperless/meetingList/_details/participants',
		},
		{
			label: t('fileManagement'),
			key: 'paperless/meetingList/_details/fileManagement',
		},
		{
			label: t('videoManagement'),
			key: 'paperless/meetingList/_details/videoManagement',
		},
		{
			label: t('votingManagement'),
			key: 'paperless/meetingList/_details/votingManagement',
		},
		// {
		// 	label: t('votingGroupManagement'),
		// 	key: 'paperless/meetingList/_details/votingGroupManagement',
		// },
		{
			label: t('informationManagement'),
			key: 'paperless/meetingList/_details/informationManagement',
		},
		{
			label: t('conferenceRecords'),
			key: 'paperless/meetingList/_details/conferenceRecords',
		},
		{
			label: t('voiceSummary'),
			key: 'paperless/meetingList/_details/voiceSummary',
		},
		// {
		// 	label: '座位图管理',
		// 	key: 'paperless/meetingList/_details/seatMapManagement',
		// },
		{
			label: t('seatMapArrangement'),
			key: 'paperless/meetingList/_details/seatMapArrangement',
		},
	];
	//节点点击
	const [routeUrl, setRoute] = useState('paperless/meetingList/_details/meetingDetail');
	const onClick: any['onClick'] = (keys: any) => {
		setRoute(keys.key);
	};

	return (
		<PageWrapper >
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb
						list={[		
							{ title: t('Meeting List'), to:''},
							{ title: t('Meeting Detail Page'), to: '' },
						]}
					/>
				</SubHeaderLeft>
				{/* <SubHeaderRight>
					<Buttons
						type="primary"
						htmlType="submit"
						className='w-100'
						onClick={() => navigate(-1)}
					>
						返回（上一级）
					</Buttons>
				</SubHeaderRight> */}
				<SubHeaderRight>
				 <Tooltip title={t('Return To Meeting List')}>
				 {controlType ? (<Link to={`/paperless/meetingList/notCompletedMeeting`}>
					<Buttons
						type="primary"
						htmlType="submit"
						className='w-100'>
						{t('Return')}
					</Buttons>
				  </Link>) : (<Link to={`/paperless/meetingList/archivedMeeting`}>
					<Buttons
						type="primary"
						htmlType="submit"
						className='w-100'>
						{t('Return')}
					</Buttons>
				  </Link>)}
				  

				 </Tooltip>
				</SubHeaderRight>
			</SubHeader>
			<Page container='fluid'>
				<div className='row h-100'>
					<div className='col-xxl-2 col-xl-4 col-lg-4'>
						<Card stretch>
							<CardBody isScrollable>
								<div className='row g-3'>
									<>
										<Menu
											onClick={onClick}
											defaultSelectedKeys={['1']}
											defaultOpenKeys={['sub1']}
											mode='inline'
											items={treeData}
											className='border-0'
										/>
									</>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xxl-10 col-xl-8 col-lg-8'>
						<iframe
							src={`#/` + routeUrl + '/' + id}
							style={{ width: '100%', height: '100%', border: 'none' }}></iframe>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default MeetingDetails;
