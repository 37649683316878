export const SELECT_ALL_MENU = 'SELECT_ALL_MENU';
export const SELECT_TOP_MENU = 'SELECT_TOP_MENU';
export const SELECT_LEFT_MENU = 'SELECT_LEFT_MENU';
export const RESET_MENU = 'RESET_MENU';

export const selectAllmenu = (data) => ({
    type: SELECT_ALL_MENU,
    menu: data
});

export const selectTopmenu = (data) => ({
    type: SELECT_TOP_MENU,
    menu: data
});

export const selectLeftmenu = (data) => ({
    type: SELECT_LEFT_MENU,
    menu: data
});

export const resetMenu = () => ({
    type: RESET_MENU
});
