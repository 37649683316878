import { createStore } from 'redux';
import reducers from './reducers';

let persistedState = {}
try {
    const state = sessionStorage.getItem("userData");
    if (state) {
        persistedState.userData = JSON.parse(state)
    }
} catch (e) {
    console.log(e);
}
try {
    const state = sessionStorage.getItem("menuData");
    if (state) {
        persistedState.menuData = JSON.parse(state)
    }
} catch (e) {
    console.log(e);
}

const store = createStore(reducers, persistedState);

store.subscribe(() => {
    try {
        const state = JSON.stringify(store.getState().userData);
        // console.log(state)
        sessionStorage.setItem("userData", state);
    } catch (e) {
        console.log(e);
    }
    try {
        const state = JSON.stringify(store.getState().menuData);
        // console.log(state)
        sessionStorage.setItem("menuData", state);
    } catch (e) {
        console.log(e);
    }
});

export default store;
