import {
	Popconfirm,
} from "antd";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	addEventLog, addOperateLog, delBackupsRecord, echoPutBacks, getBackupsRecord,
	openNotificationWithIcon
} from "../../api/common";
import { authentication } from "../../api/media";
import Button from "../../components/bootstrap/Button";
import { CardActions, CardBody, CardHeader } from "../../components/bootstrap/Card";
import Dropdown, { DropdownMenu, DropdownToggle } from "../../components/bootstrap/Dropdown";
import Checks from "../../components/bootstrap/forms/Checks";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Input from "../../components/bootstrap/forms/Input";
import Label from "../../components/bootstrap/forms/Label";
import Icon from "../../components/icon/Icon";
import Config from "../../config";
import AuthContext from '../../contexts/authContext';
import useSelectTable3 from "../../hooks/useSelectTable3";
import useSortableData from "../../hooks/useSortableData";
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { SubHeaderRight } from "../../layout/SubHeader/SubHeader";
import {
	JsDate,
	curTimeStamp,
	downloadFile, getRecordFind, getRecords,
	mediaEventLogData,
	uuid2
} from "../../utils";



const BackupsRecordPage = () => {
	// 用户信息
	const { userData } = useContext(AuthContext);

	//多语言
	const { t } = useTranslation('common' || 'menu');

	// 更新包列表
	const [allApkRenewInfo, setAllApkRenewInfo] = useState([]);
	const getAllBackupsRecordData = async (
		pointOut: boolean = false,
		database?: string | null,
		date?: string | null
	) => {
		try {
			let Where = [

			]

			if (database) {
				Where.push(`database,cs,${database}`)
			}

			if (date) {
				Where.push(`date,eq,${date}`)
			}

			let response = await getRecords(getBackupsRecord, {
				filter: Where,
				order: "last_time,desc"
			})
			if (pointOut) echoPutBacks({
				records: response
			})

			setAllApkRenewInfo(response);
		} catch (error: any) {
			//记录 错误日志
			await addEventLog({
				...mediaEventLogData(),
				name: "数据库-备份记录",
				desc: error.message,
				creator: userData.id,
				org_num: userData.orgNum,
				operation: "Query"
			})

			return openNotificationWithIcon(
				"异常提示",
				error.message,
				"error",
			);
		}
	};
	useEffect(() => {
		getAllBackupsRecordData().then(() => { })

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// 表格
	const { requestSort, getClassNamesFor } = useSortableData(allApkRenewInfo); //filteredData
	const { selectTable, SelectAllCheck } = useSelectTable3(allApkRenewInfo);

	//删除
	const deleteBackupsRecord = async () => {
		try {
			if (!authentication(userData)) {
				return openNotificationWithIcon(
					"消息提示",
					"您暂无权限操作该项",
					"warning",
				);
			}

			const id = selectTable.values.selectedList.map(Number)
			if (id.length <= 0) {
				return openNotificationWithIcon("信息提示", "未选中任意数据！", "warning")
			}

			let result = { success: 0, error: 0 };
			for (const i of id) {
				let backupsRecord = await getRecordFind(getBackupsRecord, {
					filter: [
						`id,eq,${i}`
					],
					include: "database",
					size: 1
				})
				const response: any = await delBackupsRecord(i); response ? result.success++ : result.error++;

				//操作日志
				await addOperateLog({
					oid: uuid2(32, 16),
					org_num: userData.orgNum,
					uid: userData.id,
					system_id: 1,
					is_state: response,
					describe: `删除-数据库-备份记录,删除【${backupsRecord.database}】`,
					create_time: curTimeStamp()
				})
			}

			// 数据刷新
			await RefreshList()

			return openNotificationWithIcon(
				"信息提示",
				`删除【${result.success}】条数据，失败【${result.error}】条数据！`,
				result.success >= 1 ? 'success' : 'error'
			);
		} catch (error: any) {
			//记录 错误日志
			await addEventLog({
				...mediaEventLogData(),
				name: "数据库-备份记录",
				desc: error.message,
				creator: userData.id,
				org_num: userData.orgNum,
				operation: "Delete"
			})

			return openNotificationWithIcon(
				"异常提示",
				error.message,
				"error",
			);
		}
	};


	// 条件搜索
	const [filterMenu, setFilterMenu] = useState<boolean>(false);
	const formik3 = useFormik({
		initialValues: {
			database: "",
			date: ""
		},
		// 点击搜索
		onSubmit: async (values) => {
			setFilterMenu(false);
			await getAllBackupsRecordData(
				true,
				values.database ?? null,
				values.date ?? null
			)
		},
	});

	//刷新列表
	const RefreshList = async function (pointOut: boolean = false) {
		await getAllBackupsRecordData(pointOut)
	}


	return (
		<PageWrapper >
			<CardHeader>
				<CardActions>
					<Popconfirm placement="bottom" title={t('Delete Confirm')} onConfirm={
						deleteBackupsRecord
					} okText={t('Ok')} cancelText={t('Cancel')}>
						<Button
							color='danger'
							icon='Delete'
							isLight
							id='button-deletion'>
							{t('Delete')}
						</Button>
					</Popconfirm>

					<Button icon="Refresh" color="primary" onClick={
						() => RefreshList(true)
					} isLight >{t('Refresh')}</Button>
				</CardActions>

				<SubHeaderRight>
					<Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu}>
						<DropdownToggle hasIcon={false}>
							<Button icon="ManageSearch" color="primary" isLight >{t('Search')}</Button>
						</DropdownToggle>

						<DropdownMenu isAlignmentEnd size="lg" isCloseAfterLeave={false}>
							<div className="container py-2">
								<form className="row g-3" onSubmit={formik3.handleSubmit}>
									<div className="col-12">
										<FormGroup>
											<Label htmlFor="typeName">{t('Name')}</Label>
											<Input
												id="typeName"
												placeholder={t('Please enter keywords')}
												ariaLabel={t('Please enter keywords')}
												ariaDescribedby="button-addon2"
												onChange={formik3.handleChange}
												value={formik3.values.database}
											/>
										</FormGroup>
									</div>

									<div className="col-6">
										<Button
											color="primary"
											isOutline
											className="w-100"
											onClick={formik3.resetForm}>
											{t('Resetting')}
										</Button>
									</div>

									<div className="col-6">
										<Button color="primary" className="w-100" type="submit">
											{t('Confirm')}
										</Button>
									</div>
								</form>
							</div>
						</DropdownMenu>
					</Dropdown>


				</SubHeaderRight>
			</CardHeader>

			<CardBody className="table-responsive text-center" style={{
				height: "100%"
			}} isScrollable>
				<table className="table table-modern table-hover">
					<thead>
						<tr>
							<th scope="col" style={{
								width: "3rem"
							}} >{SelectAllCheck}</th>
							<th
								scope="col"
								onClick={() => requestSort("id")}
								style={{
									width: "4rem"
								}}
								className="cursor-pointer text-decoration-underline">
								{t('Number')}
								<Icon
									size="lg"
									className={getClassNamesFor("id")}
									icon="FilterList"
								/>
							</th>

							<th
								scope="col"
								onClick={() => requestSort("type_name")}
								className="cursor-pointer text-decoration-underline">
								{t('Name')}
								<Icon
									size="lg"
									className={getClassNamesFor("type_name")}
									icon="FilterList"
								/>
							</th>

							<th
								scope="col"
								onClick={() => requestSort("create_time")}
								className="cursor-pointer text-decoration-underline">
								{t('Update Time')}
								<Icon
									size="lg"
									className={getClassNamesFor("create_time")}
									icon="FilterList"
								/>
							</th>

							<th scope="col">{t('Operate')}</th>
						</tr>
					</thead>
					<tbody>
						{
							allApkRenewInfo.map((item: any, key: number) => (
								<tr key={item.id}>
									<td>
										<Checks
											id={item.id.toString()}
											name="selectedList"
											value={item.id}
											onChange={selectTable.handleChange}
											checked={selectTable.values.selectedList.includes(
												// @ts-ignore
												item.id.toString(),
											)}
										/>
									</td>

									<th scope="row">
										{key + 1}
									</th>

									<th scope="row">
										{item.database}
									</th>

									<td>
										{JsDate(item.last_time, "datetime")}
									</td>

									<td>
										<Button
											onClick={
												() => downloadFile({
													orgNum: userData.orgNum ?? 0,
													uid: userData.id ?? 0,
													username: userData.username ?? ""
												}, item.database, Config.siteAddress + '/' + item.route)
											}
											color='info'
											isLight
											icon='Download'
										/>
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</CardBody>
		</PageWrapper>
	);
};

export default BackupsRecordPage;
