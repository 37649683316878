import React from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../../../layout/Header/Header';
import Navigation from '../../../../layout/Navigation/Navigation';
import { pipeGalleryPages } from '../../../../menu';
import useDeviceScreen from '../../../../hooks/useDeviceScreen';
import CommonHeaderRight from '../CommonHeaderRight';
import CommonHeaderChat from '../CommonHeaderChat';

const GisMapHeader = () => {
	const { width } = useDeviceScreen();

	return (
		<Header>
			<HeaderLeft>
				<Navigation
					menu={pipeGalleryPages.gisMap.subMenu}
					id={`${pipeGalleryPages.gisMap.id}top-menu`}
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
					}
				/>
			</HeaderLeft>
			<HeaderRight>
				<CommonHeaderChat />
			</HeaderRight>
		</Header>
	);
};

export default GisMapHeader;
