import React, { SVGProps } from 'react';

const CentralControl = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox="0 0 39.42 39.42" fill='currentColor' className='svg-icon' {...props}>
			<g id="图层_2" data-name="图层 2">
			<g id="图层_1-2" data-name="图层 1"><rect className="cls-1" width="39.42" height="39.42" rx="8"/><path className="cls-2" d="M13.37,16.81a.8.8,0,0,1-.81.8.81.81,0,0,1,0-1.61A.81.81,0,0,1,13.37,16.81Z"/><path className="cls-2" d="M19.7,21.81a.8.8,0,1,0,.81.81A.8.8,0,0,0,19.7,21.81Zm0,0a.8.8,0,1,0,.81.81A.8.8,0,0,0,19.7,21.81Zm9.9-11.12H9.81A1.83,1.83,0,0,0,8,12.53V26.89a1.84,1.84,0,0,0,1.83,1.85H29.6a1.85,1.85,0,0,0,1.84-1.85V12.53A1.84,1.84,0,0,0,29.6,10.69ZM13.42,19v3.64a.8.8,0,0,1-1.59,0v-3.6a2.37,2.37,0,0,1-1.65-2.27,2.41,2.41,0,0,1,1.65-2.28v-.36a.8.8,0,0,1,1.59,0v.4a2.39,2.39,0,0,1,0,4.47Zm7.14,5.81v.39a.8.8,0,1,1-1.6,0v-.35a2.39,2.39,0,0,1-1.64-2.27A2.42,2.42,0,0,1,19,20.33v-3.6a.8.8,0,0,1,1.6,0v3.65a2.39,2.39,0,0,1,0,4.47ZM27.7,19v3.64a.8.8,0,0,1-1.6,0v-3.6a2.38,2.38,0,0,1-1.64-2.27,2.41,2.41,0,0,1,1.64-2.28v-.36a.8.8,0,0,1,1.6,0v.4a2.39,2.39,0,0,1,0,4.47Zm-.86-3a.81.81,0,1,0,.81.81A.8.8,0,0,0,26.84,16ZM19.7,21.81a.8.8,0,1,0,.81.81A.8.8,0,0,0,19.7,21.81Z"/></g>
			</g>
		</svg>
	);
};

export default CentralControl;
