export const ADD_USERDATA = 'ADD_USERDATA';
export const UPDATE_USERDATA = 'UPDATE_USERDATA';
export const DELETE_USERDATA = 'DELETE_USERDATA';

export const addUserData = (data) => ({
    type: ADD_USERDATA,
    data: data
});

export const updateUserData = (data) => ({
    type: UPDATE_USERDATA,
    data: data
});

export const deleteUserData = () => ({
    type: DELETE_USERDATA
});
