import React, {FC} from 'react';
import Header, { HeaderLeft } from '../../../../layout/Header/Header';
import Navigation from '../../../../layout/Navigation/Navigation';
import { meetingRoomPages } from '../../../../menu';
import useDeviceScreen from '../../../../hooks/useDeviceScreen';
import CommonHeaderRight from '.././CommonHeaderRight';
import useDarkMode from '../../../../hooks/useDarkMode';

import classNames from 'classnames';

interface ITitleProps {
	title: string;
}
const DashboardHeader: FC<ITitleProps> = ({ title }) => {
	const { darkModeStatus } = useDarkMode();
	const { width } = useDeviceScreen();

	return (
		<Header>
			
			<HeaderLeft>
				<div className='d-flex align-items-center'>
					<div className='row g-4'>
						<div className='col-md-auto'>
							<div
								className={classNames('fs-3', 'fw-bold', {
									'text-dark': !darkModeStatus,
								})}>
								{title}
							</div>
						</div>
					</div>
				</div>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DashboardHeader;
