import React, { lazy, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import AuthContext from "../../contexts/authContext";

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const ContentRoutes = () => {
	// 用户信息
	const { userData} = useContext(AuthContext);

	const [menuInfo, setMenuArray] = useState(contents)

	// 去除不属于当前用户的菜单
	useEffect(() => {
		if (("validation" in userData) && userData.validation !== 0 && userData?.refer) {
			const referNames = userData.refer.map((ref: any) => ref.name).concat([
				"auth-pages/login",
				"auth-pages/edit-password/:username",
				"appointment/employee/:id",
				"auth-pages/empower",
				"auth-pages/configuration",
				"common/system-page",
				"meeting/meetingRoomManagement/product2/:id"
			]);

			const referNamesWithoutColon = referNames.map((path: any) => path.split(':')[0]);

			const referNamesSet = new Set(referNamesWithoutColon);

			const menu = contents.filter(content =>
				Array.from(referNamesSet).some((path: any) => content.path.startsWith(path))
			);

			setMenuArray(menu)
		}

	}, [userData])

	return (
		<Routes>
			{
				menuInfo.map((page) => (

					// eslint-disable-next-line react/jsx-props-no-spreading
					<Route key={page.path} {...page} />
				))
			}
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
