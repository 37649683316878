import React, { useState, useEffect } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header2';
import Navigation from '../../../layout/NavigationTop/Navigation';
import { componentsMenu, layoutMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import CommonHeaderRight from './CommonHeaderRight';
import { useDispatch, useSelector } from 'react-redux';
import TopMenu from '../../../layout/Menu/TopMenu'

const DefaultHeader = () => {
	const { width } = useDeviceScreen();

	// const dispatch = useDispatch();
	// const menuData = useSelector((state: any) => state.menuData);
	// const [menuJson, setMenuJson] = useState<any>({})

	// useEffect(() => {
	// 	let jsonObject: any = {}
	// 	let arr1 = Object.keys(menuData.menuAll)

	// 	for (let i = 0; i < arr1.length; i++) {
	// 		const key = arr1[i]
	// 		jsonObject[key] = {
	// 			id: menuData.menuAll[key]['id'],
	// 			text: menuData.menuAll[key]['text'],
	// 			path: menuData.menuAll[key]['path'],
	// 			icon: menuData.menuAll[key]['icon']
	// 		};
	// 	}

	// 	setMenuJson(jsonObject);
	// }, [menuData.menuAll, dispatch])

	return (
		<Header>
			<HeaderLeft>
				{/* <Navigation
					menu={{ ...layoutMenu, ...componentsMenu }}
					id='header-top-menu'
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
					}
				/> */}
				{/* <Navigation
					menu={{ ...menuJson }}
					id='header-top-menu'
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
					}
				/> */}
				<TopMenu />
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DefaultHeader;
