import React, { SVGProps } from 'react';

const Trajectory = (props: SVGProps<SVGSVGElement>) => {
	return (
		// <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.42 41.43"  fill='currentColor' className='svg-icon' {...props}>
		//     <g id="图层_2" data-name="图层 2">
		// 		<rect className="cls-1" width="39.42" height="39.42" rx="8"/>
		// 		<text className="cls-2" transform="translate(7.71 35.14)">人员轨迹</text>
		// 		<path className="cls-3" d="M23.75,7.73a5.86,5.86,0,0,0-7.85,0,5.13,5.13,0,0,0-1.68,3.81,4.48,4.48,0,0,0,.07.71c.37,2.06,2.07,4.43,3.07,5.68a12.73,12.73,0,0,0,1.86,1.81h0a1,1,0,0,0,1.2,0,13.82,13.82,0,0,0,1.85-1.82c1.22-1.53,2.74-3.75,3.08-5.66a4.9,4.9,0,0,0,.07-.74A5.11,5.11,0,0,0,23.75,7.73Zm-3.94,6.86A2.69,2.69,0,0,1,17,12a2.79,2.79,0,0,1,5.57,0A2.69,2.69,0,0,1,19.81,14.59Z"/>
		// 		<path className="cls-3" d="M14.14,24.31h13a2,2,0,0,0,2.05-1.84,2,2,0,0,0-2.05-1.84H12.29a.69.69,0,0,1-.74-.61.68.68,0,0,1,.74-.61h2.32a.63.63,0,1,0,0-1.25H12.29a2,2,0,0,0-2,1.84,2,2,0,0,0,2,1.85H27.14a.68.68,0,0,1,.73.61.69.69,0,0,1-.73.61h-13a.64.64,0,0,0-.66.62A.65.65,0,0,0,14.14,24.31Z"/>
		// 	</g>
		// </svg>
		<svg viewBox="0 0 39.42 39.42" fill='currentColor' className='svg-icon' {...props}>
			<g id="图层_2" data-name="图层 2">
				<g id="图层_1-2" data-name="图层 1"><rect className="cls-1" width="39.42" height="39.42" rx="8" /><path className="cls-2" d="M23.75,12.18a5.86,5.86,0,0,0-7.85,0A5.12,5.12,0,0,0,14.22,16a4.41,4.41,0,0,0,.07.7c.37,2.06,2.07,4.43,3.07,5.69a13.19,13.19,0,0,0,1.86,1.8h0a1,1,0,0,0,1.2,0,13.82,13.82,0,0,0,1.85-1.82c1.22-1.53,2.74-3.75,3.08-5.66a4.8,4.8,0,0,0,.07-.74A5.09,5.09,0,0,0,23.75,12.18ZM19.81,19A2.69,2.69,0,0,1,17,16.45a2.79,2.79,0,0,1,5.57,0A2.69,2.69,0,0,1,19.81,19Z" /><path className="cls-2" d="M14.14,28.75h13a1.85,1.85,0,1,0,0-3.68H12.29a.62.62,0,1,1,0-1.22h2.32a.65.65,0,0,0,.66-.62.64.64,0,0,0-.66-.62H12.29a1.85,1.85,0,1,0,0,3.68H27.14a.62.62,0,1,1,0,1.22h-13a.65.65,0,0,0-.66.62A.64.64,0,0,0,14.14,28.75Z" /></g>
			</g>
		</svg>

	);
};

export default Trajectory;
