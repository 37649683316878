import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';
import { TColor } from '../type/color-type';
import { getStorage, setStorage } from '../utils/sessionStorage'
import UserImage3 from '../assets/img/wanna/wanna3.png';
import UserImage3Webp from '../assets/img/wanna/wanna3.webp';
import {getAuthGroup, getAuthRule} from "../api/common";

interface IServiceProps {
	name: string;
	icon: string;
	color: TColor;
}

interface IntAuthGroup {
	rules?: string | null;
	camera_rules?: string | null;
	video_rules?: string | null;
}




interface IUserProps {
	id: string;
	username: string;
	sign_id: string;
	name: string;
	nickname: string;
	surname: string;
	position: string;
	email?: string;
	src: string;
	srcSet: string;
	isOnline: boolean;
	isReply?: boolean;
	color: TColor;
	fullImage?: string;
	services?: IServiceProps[];
	phone: string;
	password: string;
	orgNum: number;
	viewPermissions: number;
	menu: any;
	depart_room: any;
	department_id: any;
	real_name: string;
	portrait: string;
	enter_wechat_id: string;
	resetting_code: number;
	groupid: number;
	groupname: string;
	view_permissions: number;
	refer: any;
	validation: number;
	englishName: any;
	access_token: any;
	devicelist: any;
	deviceArray: any;
}

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	userData: Partial<IUserProps>;
	useAuthGroup: Partial<IntAuthGroup>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(getStorage('fact_authUsername') || '');
	const [userData, setUserData] = useState<Partial<IUserProps>>({});
	const [useAuthGroup, setUserAuthGroup] = useState<Partial<IntAuthGroup>>({});

	useEffect(() => {

		setStorage('fact_authUsername', user);

	}, [user]);





	useEffect(() => {
		if (user !== '') {
			let json = JSON.parse(user)

			getAuthGroup({
				filter: [
					`org_num,eq,${json.org_num}`,
					`id,eq,${json.groupid}`
				],
				size: 1
			}).then((response: any) => {
				let authGroup = response.records.length >= 1 ? response.records[0] : [];
				if (authGroup) {
					setUserAuthGroup({
						rules: authGroup.rules ?? null,
						camera_rules: authGroup.camera_rules ?? null,
						video_rules:  authGroup.video_rules ?? null,
					})
				}
			})


			json.src = json.portrait?json.portrait:UserImage3;
			json.srcSet = json.portrait?json.portrait:UserImage3Webp;
			json.name = json.username;
			json.orgNum = json.org_num;
			json.viewPermissions = json.view_permissions
			json.surname = '';
			setUserData(json);



			setStorage("SignCode", json.sign_id);
		} else {
			setUserData({});
		}
	}, [user]);

	const value = useMemo(
		() => ({
			user,
			setUser,
			userData,
			useAuthGroup
		}),
		[user, userData, useAuthGroup],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
