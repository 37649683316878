import React from 'react';
import Header, { HeaderLeft } from '../../../../layout/Header/Header';
import Navigation from '../../../../layout/Navigation/Navigation';
import { meetingRoomPages } from '../../../../menu';
import useDeviceScreen from '../../../../hooks/useDeviceScreen';
import CommonHeaderRight from '../CommonHeaderRight';

const DatabaseManagementHeader = () => {
	const { width } = useDeviceScreen();

	return (
		<Header>
			<HeaderLeft>
				<Navigation
					menu={meetingRoomPages.databaseManagement.subMenu}
					id={`${meetingRoomPages.databaseManagement.id}top-menu`}
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
					}
				/>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DatabaseManagementHeader;
