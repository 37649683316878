import request from "../utils/request3";
import { uuid2 } from "../utils/index"
import Config from "../config";

const on_off = Config.on_off ? Config.on_off : false;
const server = Config.siteAddress + '/api.php/'
const server2 = Config.siteAddress
// const server = Config.newSiteAddress + '/apikey.php/records/'
// console.log(server)

// 开门记录=================================================================
export function getAllDoorOpeningRecord(query: any) {
    return request({
        url: server + 'records/think_door_opening_record',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}
export function delDoorOpeningRecord(ids: any) {
    return request({
        url: server + 'records/think_door_opening_record/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}

// 节假日管理=================================================================
export function getAllHoliday(query: any) {
    return request({
        url: server + 'records/think_holiday',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}
export function addHoliday(data: any) {
    return request({
        url: server + 'records/think_holiday',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}
export function editHoliday(data: any) {
    return request({
        url: server + 'records/think_holiday/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}
export function delHoliday(ids: any) {
    return request({
        url: server + 'records/think_holiday/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}

// 区域管理=================================================================
//获取区域列表
export function getAllArea(query: any) {
    return request({
        url: server + 'records/think_area',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}

//更新区域
export function editArea(id: any, data: any) {
    return request({
        url: server + 'records/think_area/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}

//添加区域
export function addArea(data: any) {
    return request({
        url: server + 'records/think_area',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//删除区域
export function delArea(ids: any) {
    return request({
        url: server + 'records/think_area/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}

// ========================================================
//获取房间列表
export function getAllRooms(query: any) {
    return request({
        url: server + 'records/think_room',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}
//获取房间信息
export function getRoomInfo(id: number) {
    return request({
        url: server + 'records/think_room/' + id,
        method: "get",
        params: { database: 'central_control' }
    })
}

//更新房间
export function editRoom(id: any, data: any) {
    return request({
        url: server + 'records/think_room/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}

//添加房间
export function addRoom(data: any) {
    return request({
        url: server + 'records/think_room',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//删除房间
export function delRoom(ids: any) {
    return request({
        url: server + 'records/think_room/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}


// ========================================================
//获取物品列表
export function getAllSubstance(query: any) {
    return request({
        url: server + 'records/think_substance',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}

//添加物品
export function addSubstance(data: any) {
    return request({
        url: server + 'records/think_substance',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//更新物品
export function editSubstance(id: any, data: any) {
    return request({
        url: server + 'records/think_substance/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}

//删除物品
export function delSubstance(ids: any) {
    return request({
        url: server + 'records/think_substance/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}




// ===================================================================
//获取物品类型列表
export function getAllSubstanceType(query: any) {
    return request({
        url: server + 'records/think_substance_type',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}

//添加物品类型
export function addSubstanceType(data: any) {
    return request({
        url: server + 'records/think_substance_type',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//更新物品类型
export function editSubstanceType(id: any, data: any) {
    return request({
        url: server + 'records/think_substance_type/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}

//删除物品
export function delSubstanceType(ids: any) {
    return request({
        url: server + 'records/think_substance_type/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}





// ===========================设备运行日志==================================================
//获取设备列表
export function getHistoryPirState(query: any) {
    return request({
        url: server + 'records/think_history_pir_state',
        method: "get",
        params: {
            ...query,
            database: 'central_control'
        }
    })
}

//添加设备
export function addHistoryPirState(data: any) {
    return request({
        url: server + 'records/think_history_pir_state',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//更新设备
export function editHistoryPirState(id: any, data: any) {
    return request({
        url: server + 'records/think_history_pir_state/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}

//删除设备
export function delHistoryPirState(ids: any) {
    return request({
        url: server + 'records/think_history_pir_state/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}





// =============================================================================
//获取设备列表
export function getAllDevices(query: any) {
    return request({
        url: server + 'records/think_device',
        method: "get",
        params: { database: 'central_control',...query }
    })
}

//添加设备
export function addDevice(data: any) {
    return request({
        url: server + 'records/think_device',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//更新设备
export function editDevice(id: any, data: any) {
    return request({
        url: server + 'records/think_device/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}

//删除设备
export function delDevice(ids: any) {
    return request({
        url: server + 'records/think_device/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}


// =============================================================
//获取设备类型列表
export function getAllDeviceTypes(query: any) {
    return request({
        url: server + 'records/think_device_type',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}

//获取设备类型信息
export function getDeviceTypesInfo(id: number) {
    return request({
        url: server + 'records/think_device_type/' + id,
        method: "get",
        params: { database: 'central_control' }
    })
}

//添加设备类型
export function addDeviceType(data: any) {
    return request({
        url: server + 'records/think_device_type',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//更新设备类型
export function editDeviceType(id: any, data: any) {
    return request({
        url: server + 'records/think_device_type/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}

//删除设备类型
export function delDeviceType(ids: any) {
    return request({
        url: server + 'records/think_device_type/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}


// =============================================================
//获取涂鸦账号列表
export function getAllGraffiti(query: any) {
    return request({
        url: server + 'records/think_graffiti',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}

//添加涂鸦账号
export function addGraffiti(data: any) {
    return request({
        url: server + 'records/think_graffiti',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//更新涂鸦账号
export function editGraffiti(id: any, data: any) {
    return request({
        url: server + 'records/think_graffiti/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}

//删除涂鸦账号
export function delGraffiti(ids: any) {
    return request({
        url: server + 'records/think_graffiti/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}


// =============================================================
//获取规则列表
export function getRuleBySearch(query: any) {
    return request({
        url: server + 'records/think_rule',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}

//添加规则
export function addRule(data: any) {
    return request({
        url: server + 'records/think_rule',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//更新规则
export function editRule(id: any, data: any) {
    return request({
        url: server + 'records/think_rule/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}

//删除规则
export function delRule(ids: any) {
    return request({
        url: server + 'records/think_rule/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}


// =============================================================
//获取场景列表
export function getAllScene(query: any) {
    return request({
        url: server + 'records/think_scene',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}

//添加场景
export function addScene(data: any) {
    return request({
        url: server + 'records/think_scene',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//更新场景
export function editScene(id: any, data: any) {
    return request({
        url: server + 'records/think_scene/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}

//删除场景
export function delScene(ids: any) {
    return request({
        url: server + 'records/think_scene/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}


// =============================================================
//获取情景列表
export function getAllSight(query: any) {
    return request({
        url: server + 'records/think_sight',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}

//添加情景
export function addSight(data: any) {
    return request({
        url: server + 'records/think_sight',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//更新情景
export function editSight(id: any, data: any) {
    return request({
        url: server + 'records/think_sight/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}

//删除情景
export function delSight(ids: any) {
    return request({
        url: server + 'records/think_sight/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}

// =============================================================
//获取规则列表(think_rule_list)
export function getAllRuleList(query: any) {
    return request({
        url: server + 'records/think_rules',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}
//获取单个规则
export function getOneRuleList(id: any) {
    return request({
        url: server + 'records/think_rules/' + id,
        method: "get",
        params: { database: 'central_control' }
    })
}

//添加规则
export function addRuleList(data: any) {
    return request({
        url: server + 'records/think_rules',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//更新规则
export function editRuleList(id: any, data: any) {
    return request({
        url: server + 'records/think_rules/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}

//删除规则
export function delRuleList(ids: any) {
    return request({
        url: server + 'records/think_rules/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}


// =============================================================
//获取设备故障报表
export function getAllFaultLog(query: any) {
    return request({
        url: server + 'records/think_fault_log',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}

//添加设备故障
export function addFaultLog(data: any) {
    return request({
        url: server + 'records/think_fault_log',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//删除设备故障
export function delFaultLog(ids: any) {
    return request({
        url: server + 'records/think_fault_log/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}


// =============================================================
//获取历史报表
export function getAllControlLog(query: any) {
    return request({
        url: server + 'records/think_control_log',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}

//添加历史报表情景
export function addControlLog(data: any) {
    return request({
        url: server + 'records/think_control_log',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}

//删除历史报表历史报表
export function delControlLog(ids: any) {
    return request({
        url: server + 'records/think_control_log/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}



// ============================================================
//获取会议室列表
export function getAllMeetingRooms(query: any) {
    return request({
        url: server + 'records/think_room',
        method: "get",
        params: { ...query, database: 'meeting_dingding' }
    })
}
//获取会议室信息
export function getMeetingRoomInfo(id: number) {
    return request({
        url: server + 'records/think_room/' + id,
        method: "get",
        params: { database: 'meeting_dingding' }
    })
}


// 基础规则
export function getAllBasicRule(query: any) {
    return request({
        url: server + 'records/think_basic_rule',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}



// =======================================================================
// 获取控制模板列表
export function getAllControlTemplates(query: any) {
    return request({
        url: server + "records/think_control_template",
        method: 'get',
        params: { ...query, database: 'central_control' }
    })
}


// =======================================================================
// 获取显示屏模板列表
export function getAllShows(query: any) {
    return request({
        url: server + "records/think_show",
        method: 'get',
        params: { ...query, database: 'central_control' }
    })
}
// 获取指定模板
export function getShowByKey(query: any) {
    return request({
        url: server + "records/think_show",
        method: 'get',
        params: { ...query, database: 'central_control' }
    })
}
// 模糊搜索显示屏模板列表
export function getShowsBySearch(query: any) {
    return request({
        url: server + "records/think_show",
        method: 'get',
        params: { ...query, database: 'central_control' }
    })
}
// 添加显示屏模板
export function addShow(data: any) {
    return request({
        url: server + "records/think_show",
        method: 'post',
        data,
        params: { database: 'central_control' }
    })
}

// 编辑显示屏模板
export function editShow(data: any) {
    return request({
        url: server + "records/think_show/" + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: { database: "central_control" }
    })
}

// 删除显示屏模板
export function delShow(id: any) {
    return request({
        url: server + "records/think_show/" + id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: "central_control" }
    })
}


// =================================================================
// 系统管理
// 获取全部配置
export function getAllConfig(query: any) {
    return request({
        url: server + 'records/think_config',
        method: 'get',
        params: { ...query, database: "zuad_common" }
    })
}

// 添加配置
export function addConfig(data: any) {
    return request({
        url: server + "records/think_config",
        method: 'post',
        data,
        params: { database: 'zuad_common' }
    })
}

// 编辑配置
export function editConfig(data: any) {
    return request({
        url: server + 'records/think_config/' + data.id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: { database: "zuad_common" }
    })
}

// 上传文件
export function upload(data: any) {
    return request({
        url: server + 'uploadMaterialFile',
        method: 'post',
        data: data
    })
}


/**
 * 获取-系统配置
 */
export async function getSetting() {
    return request({
        url: '/setting.json?t=' + Math.round(Number(new Date()) / 1000),
        method: 'get',
    });
}

// 编辑配置
export function editConfigz(data: any) {
    return request({
        url: server + '/api.php/sendpost',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data', // 关键
        },
    })
}

// 编辑组织
export function editOrganization(data: any) {
    return request({
        url: server + "/api.php/records/think_organization/" + data.org_num,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: {
            database: "zuad_common"
        }
    })
}


// ===============================================
// 系统操作日志
export function getAllLogs(query: any) {
    return request({
        url: server + 'records/think_log',
        method: 'get',
        params: { ...query, database: 'central_control' },
    });
}
export async function addOperateLog(data: any) {
    let result = null
    try {
        result = await request({
            url: server + 'records/think_log',
            method: 'post',
            data: { ...data, add_time: Math.round(Date.now() / 1000) },
            params: { database: 'central_control' },
        });
    } catch (error) {
        console.log(error)
    }
    return result
}
// 删除日志
export function deleteOperateLog(ids: any) {
    return request({
        url: server + 'records/think_log/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}

// ============================================================
// 安装包管理
// 获取素材列表
export function getAllMedia(query: any) {
    return request({
        url: server + 'records/think_document',
        method: 'get',
        params: { ...query, database: 'central_control' },
    });
}

// 获取素材列表
export function getAllMedias(query: any) {
    return request({
        url: server2 + '/apikey.php/records/think_document',
        method: 'get',
        params: { ...query, database: 'central_control' },
        headers: {
            "X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
        }
    });
}
// 添加素材
export function addMedia(data: any) {
    return request({
        url: server + 'records/think_document',
        method: 'post',
        data,
        params: { database: 'central_control' },
        headers: {
            'Content-Type': 'multipart/form-data', // 关键
        },
    });
}
// 获取安装包
export function getApkRenew(query: any) {
    return request({
        url: server + 'records/think_apk_renew',
        method: 'get',
        params: { ...query, database: 'central_control' },
    });
}
// 添加安装包
export function addApkRenew(data: any) {
    return request({
        url: server + 'records/think_apk_renew',
        method: 'post',
        data,
        params: { database: 'central_control' },
        headers: {
            'Content-Type': 'multipart/form-data', // 关键
        },
    });
}
// 编辑安装包
export function editApkRenew(id: number, data: any) {
    return request({
        url: server + 'records/think_apk_renew/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        data,
        params: { database: 'central_control' },
    });
}
// 删除安装包
export function delApkRenew(id: any) {
    return request({
        url: server + 'records/think_apk_renew/' + id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    });
}


// ================================================================
// 获取安装包类型
export function getAllInstallationPackageType(query: any) {
    return request({
        url: server + "records/think_installation_package_type",
        method: 'get',
        params: { ...query, database: 'central_control' }
    })
}
//获取安装包列表
export function getAllInstallationPackage(query: any) {
    return request({
        url: server + 'records/think_installation_package',
        method: "get",
        params: { ...query, database: 'central_control' }
    })
}

// 获取素材列表
export function getAllInstallationPackage2(query: any) {
    return request({
        url: server2 + '/apikey.php/records/think_installation_package',
        method: 'get',
        params: { ...query, database: 'central_control' },
        headers: {
            "X-API-Key": "02c042aa-c3c2-4d11-9dae-1a6e230ea95e"
        }
    });
}
//添加安装包
export function addInstallationPackage(data: any) {
    return request({
        url: server + 'records/think_installation_package',
        method: "post",
        params: { database: 'central_control' },
        data: data
    })
}
//更新安装包
export function editInstallationPackage(id: any, data: any) {
    return request({
        url: server + 'records/think_installation_package/' + id,
        method: on_off ? 'post' : "PUT", headers: on_off ? { "X-HTTP-Method-Override": "PUT" } : {},
        params: { database: 'central_control' },
        data: data
    })
}
//删除安装包
export function delInstallationPackage(ids: any) {
    return request({
        url: server + 'records/think_installation_package/' + ids,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'central_control' },
    })
}



// =========================================
// 控制日志|事件中心
export function addCloudLog(data: any) {
    const logInfo = {
        name: '', //事件名称
        desc: '', //描述
        type: 1, //事件类型，1通用，2预警，3告警
        model: 3, //关联模块，包含1信发，2会议，3中控，4能耗，5墨水屏，6无纸化
        create_time: Math.round(Date.now() / 1000), //创建时间
        creator: 0, //创建人
        org_num: 999999,
        oid: uuid2(32, 16),
        status: 0,//事件状态，0正常，1失败
        sub_type: 0,//事件子类型 0:默认 1:会议结束控制设备事件 2:会议开始控制设备事件3:人体感应联动事件4:设备按键事件 5:中控控制事件 6:管理员控制设备事件 7:场景控制设备事件 8:情景控制设备事件 9:普通设备联动 10:自动化场景控制 11:自动化情景控制
    }
    return request({
        url: server + 'records/think_event',
        method: "post",
        data: { ...logInfo, ...data },
        params: { database: 'zuad_common' }
    })
}

// 获取事件
export function getAllEvent(query: any) {
    return request({
        url: server + 'records/think_event',
        method: 'get',
        params: { ...query, database: 'zuad_common' },
    })
}
// 删除事件
export function delEvent(id: any) {
    return request({
        url: server + 'records/think_event/' + id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'zuad_common' },
    })
}


// ====================================================
// 通知中心
// 获取通知
export function getAllNotice(query: any) {
    return request({
        url: server + 'records/think_notice',
        method: 'get',
        params: { ...query, database: 'zuad_common' },
    })
}
// 删除通知
export function delNotice(id: any) {
    return request({
        url: server + 'records/think_notice/' + id,
        method: on_off ? 'post' : "DELETE", headers: on_off ? { "X-HTTP-Method-Override": "DELETE" } : {},
        params: { database: 'zuad_common' },
    })
}


// ==================================================================
// 上传文件
export function uploadFiles(data: any, callback = (arg: any) => { }) {
    return request({
        url: server + 'uploadMaterialFile',
        method: 'post',
        data: data,
        timeout: 30 * 60 * 1000,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: function (progressEvent: any) { //原生获取上传进度的事件
            console.log(progressEvent.progress)
            if (progressEvent.upload) {
                //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
                const upLoadProgress = Math.round(progressEvent.loaded / progressEvent.total * 10000) / 100.00 //实时获取上传进度
                callback(upLoadProgress)
            }
        }
    })
}

// 保存上传文件数据
export async function setUploadFilesData(data: any) {
    let arr1 = []
    try {
        const query = { filter: 'fileMd5,eq,' + data.fileMd5 }
        const response: any = await request({
            url: server + 'records/think_document',
            method: "get",
            params: { ...query, database: 'paperless' },
        })
        arr1 = response.records
    } catch (error) {
        console.error(error)
    }

    if (arr1.length < 1) {
        return request({
            url: server + 'records/think_document',
            method: "post",
            params: { database: 'paperless' },
            data: data
        })
    } else {
        return null
    }
}

// ====================================================================
// 获取用户列表-条件搜索
export function getUserBySearch(query: any) {
    return request({
        url: server + 'records/think_admin',
        method: 'get',
        params: { ...query, database: 'zuad_common' },
    })
}
