import React, { SVGProps } from 'react';

const SvgCustomChina = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns='http://www.w3.org/2000/svg'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props} viewBox="0 0 1024 1024"><path d="M716.8 76.8a230.4 230.4 0 0 1 230.4 230.4v409.6a230.4 230.4 0 0 1-230.4 230.4H307.2A230.4 230.4 0 0 1 76.8 716.8V307.2A230.4 230.4 0 0 1 307.2 76.8h409.6m0-76.8H307.2A307.2 307.2 0 0 0 0 307.2v409.6A307.2 307.2 0 0 0 307.2 1024h409.6A307.2 307.2 0 0 0 1024 716.8V307.2A307.2 307.2 0 0 0 716.8 0z" fill="#2c2c2c" p-id="3592"></path><path d="M335.616 322.048a489.642667 489.642667 0 0 1-48.384 68.266667l-54.272-35.072a366.933333 366.933333 0 0 0 78.08-128l61.44 13.568-9.728 25.6H512v55.808h-76.8a300.373333 300.373333 0 0 1 29.44 48.64l-56.32 21.504a535.296 535.296 0 0 0-39.424-70.144z m-61.952 143.36h63.744v332.8h-63.744z m79.104-97.792a628.565333 628.565333 0 0 1 73.728 76.8l-47.104 32.512a768 768 0 0 0-72.192-80.384z m45.056 122.368h235.264v241.92H397.824z m60.672 94.464H573.44v-42.666667H458.24z m114.944 94.72v-44.117333H458.24v44.288zM636.672 793.6l-15.36-57.856 54.016 2.048c12.8 0 19.2-8.533333 19.2-25.6v-261.12H470.784v-58.368H757.76V729.6c0 43.264-23.296 65.024-70.144 65.024z m-39.168-471.552a491.008 491.008 0 0 1-37.632 59.733333l-54.016-35.072a338.432 338.432 0 0 0 63.232-119.466666l60.928 13.568a579.498667 579.498667 0 0 0-8.533333 25.6H791.04v55.808H702.976a332.8 332.8 0 0 1 27.136 43.776l-56.576 21.504a533.589333 533.589333 0 0 0-37.376-65.28z" fill="#2c2c2c" p-id="3593"></path></svg>
);

export default SvgCustomChina;
