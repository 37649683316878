import React, { useContext } from 'react';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import AuthContext from '../../../contexts/authContext';
import LeftMenu from '../../../layout/Menu/LeftMenu'

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	// 用户
	const { userData } = useContext(AuthContext);

	const menu: any = userData?.menu;

	const arr: any = [];

	const arr2: any = {};

	const arr3: any = [];

	for (let i in menu) {

		if (!menu[i].child) {

			arr2[menu[i].oid] = {
				id: menu[i].oid,
				text: menu[i].title,
				icon: menu[i].css,
				path: menu[i].name,
				subMenu: null,
			};


		} else {

			arr3.push(menu[i]);

		}

	}

	for (let k in arr3) {

		arr[k] = {};

		arr[k].pages = {
			id: arr3[k].oid,
			text: arr3[k].title,
			icon: arr3[k].css,
		};

		let list = arr3[k].child;

		for (let ke in list) {

			if (list[ke].path == "/") {

				arr[k].dashboard = {
					id: list[ke].oid,
					text: list[ke].title,
					icon: list[ke].css,
					path: "/",
					subMenu: null,
				};

			} else {

				arr[k][list[ke].oid] = {
					id: list[ke].oid,
					text: list[ke].title,
					icon: list[ke].css,
					path: list[ke].name,
					subMenu: null,
				};

				if (list[ke].child?.length > 0) {

					arr[k][list[ke].oid].subMenu = {};

					let array = list[ke].child;

					for (let key in array) {

						arr[k][list[ke].oid].subMenu[array[key].oid] = {
							id: array[key].oid,
							text: array[key].title,
							icon: array[key].css,
							path: array[key].name,
							subMenu: null,
						}

					}

				}

			}

		}

	}

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={arr2} id='aside-dashboard' />

				{/* {arr.map((item: any, index: any) => {
					return (
						<div key={index}>
							<NavigationLine />

							<Navigation menu={item} id={"aside-dashboard" + index} />
						</div>
					)
				})} */}
				<LeftMenu />
			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
