import { combineReducers } from 'redux';
import { ADD_USERDATA, UPDATE_USERDATA, DELETE_USERDATA } from './actions';
import { SELECT_ALL_MENU, SELECT_TOP_MENU, SELECT_LEFT_MENU, RESET_MENU } from './actions2';
// import { menuAll } from './menu'

const initialTodosState = null;
const userDataReducer = (state = initialTodosState, action) => {
  switch (action.type) {
    case ADD_USERDATA:
      return action.data;
    case UPDATE_USERDATA:
      return { ...state, ...action.data };
    case DELETE_USERDATA:
      return initialTodosState;
    default:
      return state;
  }
};

const initialState = {
  topMenu: 'meetingManagement',
  leftMenu: 'overview',
  menuAll: []
};
const userDataReducer2 = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_ALL_MENU:
      return {
        ...state,
        menuAll: action.menu
      };
    case SELECT_TOP_MENU:
      return {
        ...state,
        topMenu: action.menu
      };
    case SELECT_LEFT_MENU:
      return {
        ...state,
        leftMenu: action.menu
      };
    case RESET_MENU:
      return initialState;
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  userData: userDataReducer,
  menuData: userDataReducer2
});

export default rootReducer;
